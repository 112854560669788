<!-- 合格证打印功能-->
<template>
  <div style="margin:0 auto;float:top;margin-top:1px;width:100%">
<!--    <table v-if="cerdata !=null" width="1080" border="0px solid black" cellspacing="0"  style="margin:0 auto">-->
<!--      <tr>-->
<!--        <td align="center" valign="bottom">-->
<!--          <div style="margin:0 auto">-->
<!--            <h2>电动车自行车产品合格证</h2>-->
<!--            <h3>第一部分车辆总体信息</h3>-->
<!--          </div>-->
<!--          <table  width="540" border="1px solid black" cellspacing="0" style="font-size:11px;" >-->
<!--            <tr >-->
<!--              <td width="50%" style="padding-top:5px;padding-bottom:5px">0.0 整车编码：</td>-->
<!--              <td width="50%">{{cerdata.vehicleNo}}</td>-->
<!--            </tr>-->
<!--            <tr >-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.1 车辆制造商：</td>-->
<!--              <td >{{cerdata.manufacturer}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.2 生产企业名称：</td>-->
<!--              <td >{{cerdata.producer}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.3 生产企业地址</td>-->
<!--              <td >{{cerdata.produceraddr}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.4 车辆中文商标：</td>-->
<!--              <td >{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.5 车辆英文商标：</td>-->
<!--              <td>{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.6 产品型号：</td>-->
<!--              <td>{{cerdata.modelno}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.7 驱动方式：</td>-->
<!--              <td>{{cerdata.drivetype}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.8 长*宽*高(mm)：</td>-->
<!--              <td>{{cerdata.bodysize}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.9 前后轮中心距(mm)：</td>-->
<!--              <td>{{cerdata.wheeltrack}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.10 装配完整的电动自行车的整车质量(kg)：</td>-->
<!--              <td>{{cerdata.totalweight}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.11 最高设计车速(km/h)：</td>-->
<!--              <td>{{cerdata.maxspeed}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.12 铭牌固定位置：</td>-->
<!--              <td>{{cerdata.cardposition}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.13 车架上车辆编码的位置：</td>-->
<!--              <td>{{cerdata.codeposition}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.14 电动机编码：</td>-->
<!--              <td>{{cerdata.motorNo}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.15 续行里程(km)：</td>-->
<!--              <td>{{cerdata.driverange}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.16 百公里电耗(kw.h/100km)：</td>-->
<!--              <td>{{cerdata.powerconsumption}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.17.1 CCC证书编号：</td>-->
<!--              <td>{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.17.2 CCC证书版本号：</td>-->
<!--              <td>{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.17.3 CCC证书发证日期：</td>-->
<!--              <td>{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.18 车辆生产日期：</td>-->
<!--              <td>{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.19 产品名称：</td>-->
<!--              <td>{{cerdata.productname}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td style="padding-top:5px;padding-bottom:5px">0.20 车身颜色：</td>-->
<!--              <td>{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--        </td>-->
<!--        <td align="center" valign="bottom" style="padding-left:20px">-->
<!--          <table  width="540" border="1px solid black" cellspacing="0" style="font-size:11px;">-->
<!--            <tr>-->
<!--              <td colspan="4" align="left" style="padding-top:5px;padding-bottom:5px">产品合格证编号：{{cerdata.certificateno}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="4" align="center" style="padding-top:5px;padding-bottom:5px"><h2>第二部分产品合格证参数</h2></td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="4" align="center">-->
<!--                <img ref="shimgref" width="400px" height="200px"  :src="bikeimgurl" />-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">1.1 电动机生产企业</td>-->
<!--              <td width="31%">{{cerdata.motorproducer}}</td>-->
<!--              <td width="19%">1.2 电动机型号</td>-->
<!--              <td width="31%">{{cerdata.motormodel}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">1.3 电动机型式</td>-->
<!--              <td width="31%">{{cerdata.motortype}}</td>-->
<!--              <td width="19%">1.4 额定转速(r/min)</td>-->
<!--              <td width="31%">{{cerdata.rotationspeed}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">1.5 额定连续输出功率(W)</td>-->
<!--              <td width="31%">{{cerdata.outputpower}}</td>-->
<!--              <td width="19%">1.6 额定电压(V)</td>-->
<!--              <td width="31%">{{cerdata.voltage}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">2.1 控制器生产企业</td>-->
<!--              <td width="31%">{{cerdata.controllerproducer}}</td>-->
<!--              <td width="19%">2.2 控制器型号</td>-->
<!--              <td width="31%">{{cerdata.controllerno}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">2.3 欠压保护值(V)</td>-->
<!--              <td width="31%">{{cerdata.undervoltage}}</td>-->
<!--              <td width="19%">2.4 过流保护值(A)</td>-->
<!--              <td width="31%">{{cerdata.overcurrent}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">3.1 蓄电池类型</td>-->
<!--              <td width="31%">{{cerdata.celltype}}</td>-->
<!--              <td width="19%">3.2 蓄电池生产企业</td>-->
<!--              <td width="31%">{{cerdata.cellproducer}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">3.3 蓄电池容量(Ah)</td>-->
<!--              <td width="31%">{{cerdata.cellvolume}}</td>-->
<!--              <td width="19%">3.4 蓄电池型号</td>-->
<!--              <td width="31%">{{cerdata.cellno}}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">4.1 前轮轮胎规格</td>-->
<!--              <td width="31%">{{cerdata.frontwheel}}</td>-->
<!--              <td width="19%">4.2 后轮轮胎规格</td>-->
<!--              <td width="31%">{{cerdata.backwheel}}</td>-->
<!--            </tr>-->
<!--            <tr v-if="cerdata.area=='上海'" >-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">5.1 充电器商标</td>-->
<!--              <td width="31%">{{cerdata.chargerbrand}}</td>-->
<!--              <td width="50%" colspan="2" rowspan="3">-->
<!--                <vue-qr v-if="showccc" :text="cccUrl" :correctLevel=2  :size="100" :margin="1" :callback="test1" qid="sid1" style="margin-left:10px;float:left;width:95px;height:95px"></vue-qr>-->
<!--                <vue-qr v-if="showself" :text="selfUrl" :correctLevel=2 :size="100" :margin="1" :callback="test2" qid="sid2" style="margin-right:10px;float:right;width:95px;height:95px"></vue-qr>-->
<!--                &lt;!&ndash;                             <div id="qrcode" style="display:inline-block;margin-left:10px;float:left;width:80px;height:80px"></div>-->
<!--                                            <div id="qrcode2" style="display:inline-block;margin-right:10px;float:right;width:80px;height:80px"></div> &ndash;&gt;-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr v-if="cerdata.area=='上海'" >-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">5.2 充电器型号规格</td>-->
<!--              <td width="31%">{{cerdata.chargerspecs}}</td>-->
<!--            </tr>-->
<!--            <tr v-if="cerdata.area=='上海'" height="80px">-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">5 备注</td>-->
<!--              <td width="31%">{{(cerdata.note =='null' ||cerdata.note ==null)?'':cerdata.note}}</td>-->
<!--            </tr>-->
<!--            <tr v-if="cerdata.area!='上海'" height="96px">-->
<!--              <td width="19%" style="padding-top:5px;padding-bottom:5px">5 备注</td>-->
<!--              <td width="31%">{{(cerdata.note =='null' ||cerdata.note ==null)?'':cerdata.note}}</td>-->
<!--              <td width="50%" colspan="2">-->
<!--                <vue-qr v-if="showccc" :text="cccUrl" :correctLevel=3  :size="100" :margin="1" :callback="test1" qid="cid1" style="margin-left:10px;float:left;width:95px;height:95px"></vue-qr>-->
<!--                <vue-qr v-if="showself" :text="selfUrl" :correctLevel=2 :callback="test2" :size="100" :margin="1" qid="cid2" style="margin-right:10px;float:right;width:95px;height:95px"></vue-qr>-->
<!--                &lt;!&ndash;                             <div id="qrcode" style="display:inline-block;margin-left:10px;float:left;width:80px;height:80px"></div>-->
<!--                                            <div id="qrcode2" style="display:inline-block;margin-right:10px;float:right;width:80px;height:80px"></div> &ndash;&gt;-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="4" >本产品经过检验，符合{{cerdata.standardname}}的要求，特此证明。</td>-->
<!--            </tr>-->
<!--          </table>-->
<!--          <img border="0" v-if="cerdata.printseal=='true'" width="150px" height="150px" transcolor="#FFFFFF" :src="'/api/files/get/seal?id='+cerdata.sealaddrid" style="z-index: -1; position: absolute; left:650px; top:470px;" />-->
<!--        </td>-->
<!--      </tr>-->
<!--    </table>-->
    <table v-if="cerdata !=null" width="1180" border="0px solid black" cellspacing="0"  style="margin:0 auto">
      <tr>
        <td align="center" valign="bottom">
          <div style="margin:0 auto">
            <h2>电动车自行车产品合格证</h2>
            <h3>第一部分车辆总体信息</h3>
          </div>
          <table  width="590"  cellspacing="0" style="font-size:12px;height: 850px" >
            <tr >
              <td width="50%" style="padding-top:10px;padding-bottom:10px">0.0 整车编码：{{cerdata.vehicleNo}}</td>
              <!--                        <td width="50%">{{cerdata.vehicleNo}}</td>-->
            </tr>
            <tr >
              <td style="padding-top:10px;padding-bottom:10px">0.1 车辆制造商：{{cerdata.manufacturer}}</td>
              <!--                        <td >{{cerdata.manufacturer}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.2 生产企业名称：{{cerdata.producer}}</td>
              <!--                        <td >{{cerdata.producer}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.3 生产企业地址：{{cerdata.produceraddr}}</td>
              <!--                        <td >{{cerdata.produceraddr}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.4 车辆中文商标：{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>
              <!--                        <td >{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.5 车辆英文商标：{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>
              <!--                        <td>{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.6 产品型号：{{cerdata.modelno}}</td>
              <!--                        <td>{{cerdata.modelno}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.7 驱动方式：{{cerdata.drivetype}}</td>
              <!--                        <td>{{cerdata.drivetype}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.8 长*宽*高(mm)：{{cerdata.bodysize}}</td>
              <!--                        <td>{{cerdata.bodysize}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.9 前后轮中心距(mm)：{{cerdata.wheeltrack}}</td>
              <!--                        <td>{{cerdata.wheeltrack}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.10 装配完整的电动自行车的整车质量(kg)：{{cerdata.totalweight}}</td>
              <!--                        <td>{{cerdata.totalweight}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.11 最高设计车速(km/h)：{{cerdata.maxspeed}}</td>
              <!--                        <td>{{cerdata.maxspeed}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.12 铭牌固定位置：{{cerdata.cardposition}}</td>
              <!--                        <td>{{cerdata.cardposition}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.13 车架上车辆编码的位置：{{cerdata.codeposition}}</td>
              <!--                        <td>{{cerdata.codeposition}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.14 电动机编码：{{cerdata.motorNo}}</td>
              <!--                        <td>{{cerdata.motorNo}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.15 续行里程(km)：{{cerdata.driverange}}</td>
              <!--                        <td>{{cerdata.driverange}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.16 百公里电耗(kw.h/100km)：{{cerdata.powerconsumption}}</td>
              <!--                        <td>{{cerdata.powerconsumption}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.17.1 CCC证书编号：{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>
              <!--                        <td>{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.17.2 CCC证书版本号：{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>
              <!--                        <td>{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.17.3 CCC证书发证日期：{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>
              <!--                        <td>{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
            </tr>
            <tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.18 车辆制造日期：{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>
              <!--                      <td>{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.19 产品名称：{{cerdata.productname}}</td>
              <!--                      <td>{{cerdata.productname}}</td>-->
            </tr>
            <tr>
              <td style="padding-top:10px;padding-bottom:10px">0.20 车身颜色：{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>
              <!--                      <td>{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>-->
            </tr>
          </table>
        </td>
        <td align="center" valign="bottom" style="padding-left:20px">
          <table  width="590" border="1px solid black" cellspacing="0" style="font-size:11px;">
            <tr>
              <td colspan="4" align="left" style="padding-top:10px;padding-bottom:10px">产品合格证编号：{{cerdata.certificateno}}</td>
            </tr>
            <tr>
              <td colspan="4" align="center" style="padding-top:10px;padding-bottom:10px"><h2>第二部分产品合格证参数</h2></td>
            </tr>
            <tr>
              <td colspan="4" align="center">
                <img ref="shimgref" width="400px" height="280px"  :src="bikeimgurl" />
              </td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">1.1 电动机生产企业</td>
              <td width="31%">{{cerdata.motorproducer}}</td>
              <td width="19%">1.2 电动机型号</td>
              <td width="31%">{{cerdata.motormodel}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">1.3 电动机型式</td>
              <td width="31%">{{cerdata.motortype}}</td>
              <td width="19%">1.4 额定转速(r/min)</td>
              <td width="31%">{{cerdata.rotationspeed}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">1.5 额定连续输出功率(W)</td>
              <td width="31%">{{cerdata.outputpower}}</td>
              <td width="19%">1.6 额定电压(V)</td>
              <td width="31%">{{cerdata.voltage}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">2.1 控制器生产企业</td>
              <td width="31%">{{cerdata.controllerproducer}}</td>
              <td width="19%">2.2 控制器型号</td>
              <td width="31%">{{cerdata.controllerno}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">2.3 欠压保护值(V)</td>
              <td width="31%">{{cerdata.undervoltage}}</td>
              <td width="19%">2.4 过流保护值(A)</td>
              <td width="31%">{{cerdata.overcurrent}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">3.1 蓄电池类型</td>
              <td width="31%">{{cerdata.celltype}}</td>
              <td width="19%">3.2 蓄电池生产企业</td>
              <td width="31%">{{cerdata.cellproducer}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">3.3 蓄电池容量(Ah)</td>
              <td width="31%">{{cerdata.cellvolume}}</td>
              <td width="19%">3.4 蓄电池型号</td>
              <td width="31%">{{cerdata.cellno}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">4.1充电器生产企业</td>
              <td width="31%">{{cerdata.chargerProducer}}</td>
              <td width="19%">4.2 充电器型号</td>
              <td width="31%">{{cerdata.chargerspecs}}</td>
            </tr>

            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">4.3充电方式</td>
              <td width="31%">{{cerdata.chargerMethod}}</td>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">5.1 前轮轮胎型号</td>
              <td width="31%">{{cerdata.frontwheel}}</td>
            </tr>
            <tr>
              <td width="19%" style="padding-top:10px;padding-bottom:10px">5.2 后轮轮胎型号</td>
              <td width="31%">{{cerdata.backwheel}}</td>
              <td width="19%" rowspan="2">
                <vue-qr v-if="showccc" :text="cccUrl" :correctLevel=3  :size="100" :margin="1" :callback="test1" qid="cid1" style="margin-left:10px;float:left;width:95px;height:95px"></vue-qr>
                <vue-qr v-if="showself" :text="selfUrl" :correctLevel=2 :callback="test2" :size="100" :margin="1" qid="cid2" style="margin-right:10px;float:right;width:95px;height:95px"></vue-qr>
              </td>
              <td width="31%" rowspan="2"></td>
            </tr>
            <tr  height="145px">
              <td width="19%" style="padding-top:10px;padding-bottom:10px">6 备注</td>
              <td width="31%">{{(cerdata.note =='null' ||cerdata.note ==null)?'':cerdata.note}}</td>
            </tr>
            <tr>
              <td colspan="4" >
                <span>本产品经过检验，符合</span>
<!--                <input type="checkbox" id="checkbox1" name="checkbox1" value="GB17761-2018《电动自行车安全技术规范》" >-->
<!--                <label for="checkbox1">GB17761-2018《电动自行车安全技术规范》</label>-->

<!--                <input type="checkbox" id="checkbox2" name="checkbox2" value="GB42295-2022《电动自行车电气安全要求》">-->
<!--                <label for="checkbox2">GB42295-2022《电动自行车电气安全要求》</label>-->

<!--                <input type="checkbox" id="checkbox3" name="checkbox3" value="GB42296-2022《电动自行车用充电器安全技术要求》" >-->
<!--                <label for="checkbox3">GB42296-2022《电动自行车用充电器安全技术要求》</label>-->
                  <span v-if="cerdata.standardname2.split(',').includes('GB17761-2018《电动自行车安全技术规范》')">
                    <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB17761-2018《电动自行车安全技术规范》
                  </span>
                  <span v-else>
                    <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB17761-2018《电动自行车安全技术规范》
                  </span>
                  <span v-if="cerdata.standardname2.split(',').includes('GB42295-2022《电动自行车电气安全要求》')">
                      <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB42295-2022《电动自行车电气安全要求》
                    </span>
                  <span v-else>
                      <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB42295-2022《电动自行车电气安全要求》
                    </span>
                  <span v-if="cerdata.standardname2.split(',').includes('GB42296-2022《电动自行车用充电器安全技术要求》')">
                      <img ref="shimgref" width="10px" height="10px"  src="./gou.jpg" /> GB42296-2022《电动自行车用充电器安全技术要求》
                    </span>
                  <span v-else>
                      <img ref="shimgref" width="10px" height="10px"  src="./kuai.jpg" /> GB42296-2022《电动自行车用充电器安全技术要求》
                  </span>
                <span>的要求,特此证明。</span>
              </td>
            </tr>
          </table>
          <img border="0" v-if="cerdata.printseal=='true'" width="150px" height="150px" transcolor="#FFFFFF" :src="'/api/files/get/seal?id='+cerdata.sealaddrid" style="z-index: -1; position: absolute; left:650px; top:470px;" />
        </td>
      </tr>
    </table>
<!--    <cptable3 :cerdata="cerdata" :bikeimgurl="bikeimgurl" :showccc="showccc" :showself="showself" :selfUrl="selfUrl" :cccUrl="cccUrl" :cccqrcodeFinish="cccqrcodeFinish" :selfqrcodeFinish="selfqrcodeFinish" v-if="this.cerdata.cccindex==3"></cptable3>-->
  </div>
</template>

<script>
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
import Cptable3 from './cptable3'
import QRCode from 'qrcodejs2'
import vueQr from 'vue-qr'
Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

function makeImgurl(path){
  var imgurl
  if(path ==null ||path ==''){
    imgurl =null
  }
  else{
    imgurl =window.location.protocol+"//"+window.location.host+'/api/files/get?imgpath='+encodeURIComponent(path)
  }
  return imgurl
}

export default {
  name:'CertificatePrint3to23',
  props:['cerprintdata'],
  components: {
    Cptable3,
    vueQr
  },
  data(){
    return {
      cerdata:{reserved:0},
      ccclist:this._self.$root.$store.state.CccList,
      bikeimgurl:'',
      cccUrl:'',
      selfUrl:'',
      supportinfo:null,
      showccc:false,
      showself:false,
      cccqrcodeFinish:false,
      selfqrcodeFinish:false
    }
  },
  methods:{
    qrcode() {
      var prefix=''
      var suffix=''
      var cccindex = this.cerdata.cccindex
      for(var i=0;i<this.ccclist.length;i++){
        if(this.ccclist[i].id == cccindex){
          prefix = this.ccclist[i].prefix
          suffix = this.ccclist[i].suffix
          break
        }
      }

      this.cccUrl = prefix+this.cerdata.vehicleNo+suffix
      return
      var qrelement=document.getElementById('qrcode')
      if(qrelement !=null){
        console.log(qrelement.innerHTML)
        qrelement.innerHTML =''
      }
      let qrcodedata = new QRCode('qrcode', {
        width: 80,
        height: 80,
        text:prefix+this.cerdata.vehicleNo+suffix, // 二维码地址
        colorDark : "#000",
        colorLight : "#fff",
        //降低级别，否则可能识别不出来
        correctLevel:QRCode.CorrectLevel.L
      })
    },
    qrcode2() {
      var path
      if(this.cerdata.area=='上海'){
        path = "/public/#/certificate/showdetail_shanghai/"
      }
      else{
        path = "/public/#/certificate/showdetail/?vinCode="
      }

      this.supportinfo = this._self.$root.$store.state.SupportInfo
      console.log(this.supportinfo)
      var url= /*window.location.protocol+"//"+window.location.host*/this.supportinfo.qrcodedomain+path+this.cerdata.vehicleNo

      this.selfUrl = url
      return
      let qrcodedata2 = new QRCode('qrcode2', {
        width: 80,
        height: 80,
        text: url, // 二维码地址
        colorDark : "#000",
        colorLight : "#fff",
        correctLevel:QRCode.CorrectLevel.L
      })
    },
    refreshCccList(){
      this.$axios({
        method:'post',
        url:"/api/ccc/query",
        data:null,
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
          var str = [];
          for(var p in obj){
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
          return str.join("&");
        }
      }).then(function(res){
        if(res.data.resultCode ==0){
          var rspdata = res.data.data
          this.ccclist =rspdata
          this.$store.commit('changeCccList',this.ccclist)
        }
        else{
          this.$Message.error({content:res.data.data.msg, duration:3})
        }
      }.bind(this));
    },
    reloaddata(cerprintdata){
      console.log(cerprintdata)
      if(cerprintdata!=null){
        this.cerdata = this.extend({},cerprintdata.cer,cerprintdata.vehicle)
        console.log(this.cerdata)
        this.cccqrcodeFinish = false
        this.selfqrcodeFinish = false
        if(this.cerdata.qrcode=='认证中心'){
          this.showself = false
          this.qrcode()
          this.showccc = true
        }
        else if(this.cerdata.qrcode=='厂家'||this.cerdata.qrcode=='上海'){
          this.showccc = false
          this.qrcode2()
          this.showself = true
        }
        else{
          this.qrcode()
          this.qrcode2()
          this.showccc = true
          this.showself = true
        }

        if(this.cerdata.area == '上海' ){
          this.bikeimgurl = makeImgurl(this.cerdata.picshanghaiaddr)
        }
        else if(this.cerdata.area == '全国' ){
          this.bikeimgurl = makeImgurl(this.cerdata.picchinaaddr)
        }
      }

      console.log(this.bikeimgurl)
      console.log(this.cccUrl+'--》this.showccc='+this.showccc)
      console.log(this.selfUrl+'--》this.showself='+this.showself)

      this.supportinfo = this._self.$root.$store.state.SupportInfo
      // var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      // console.log("checkboxes",checkboxes)
      // checkboxes.forEach(item=>{
      //   if (this.cerdata.standardname2.split(",").includes(item.value)) {
      //     item.checked = true;
      //   }
      // })
    },
    test1(dataUrl,id){
      console.log(id+" ::::-> "+dataUrl)
      this.cccqrcodeFinish = true
      this.checkqrcodefinish()
    },
    test2(dataUrl,id){
      console.log(id+" ::::-> "+dataUrl)
      this.selfqrcodeFinish = true
      this.checkqrcodefinish()
    },
    checkqrcodefinish(){
      if((this.showccc == true && this.cccqrcodeFinish == true && this.showself == false) ||
          (this.showself == true && this.selfqrcodeFinish == true && this.showccc == false) ||
          (this.showccc == true && this.cccqrcodeFinish == true && this.showself == true && this.selfqrcodeFinish == true)
      ){
        console.log('qrcode generate finished')
        this.$emit('qrcodesfinished','')
      }

    }
  },
  watch:{
    /* cerprintdata (n){
        if(n !=null){
            this.cerdata = this.extend({},n.cer,n.vehicle)
        }
        console.log(n)
    },
    cerdata (n){
        console.log(n)
        if(n!=null){
            this.$nextTick(function () {
                if(this.cerdata.qrcode=='认证中心'){
                    this.qrcode()
                }
                else if(this.cerdata.qrcode=='厂家'||this.cerdata.qrcode=='上海'){
                    this.qrcode2()
                }
                else{
                    this.qrcode()
                    this.qrcode2()
                }

            // Code that will run only after the
            // entire view has been rendered
            })
            this.reloaddom == 'none' ?'inline':'none'
            if(this.cerdata.area == '上海' ){
                this.bikeimgurl = makeImgurl(this.cerdata.picshanghaiaddr)
            }
            else if(this.cerdata.area == '全国' ){
                this.bikeimgurl = makeImgurl(this.cerdata.picchinaaddr)
            }
        }
    } */
  },
  mounted(){
    if(this.cerprintdata !=null){
      this.cerdata = this.extend({},this.cerprintdata.cer,this.cerprintdata.vehicle)
    }
    console.log(this.cerdata)
    var vin =this.$route.params['vin']
    console.log(vin)
    if(this.ccclist ==null){
      this.refreshCccList()
    }

    if(vin !=null){
      this.$axios({
        method:'post',
        url:"/api/vehicle/querycerdetail",
        data:{vehicleno:vin},
        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
        transformRequest: function(obj) {
          var str = [];
          for(var p in obj){
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
          return str.join("&");
        }
      }).then(function(res){
        if(res.data.resultCode ==0){
          //this.afterAddVehicle()
          console.log(res.data.data)
          if(res.data.data.picshanghaiaddr=='null' ||res.data.data.picshanghaiaddr==''){
            res.data.data.picshanghaiaddr=null
          }
          if(res.data.data.picchinaaddr=='null' ||res.data.data.picchinaaddr==''){
            res.data.data.picchinaaddr=null
          }
          this.cerdata = res.data.data
        }
        else{
          this.$Message.error({content:res.data.msg, duration:3})
        }
      }.bind(this));
    }
  },

}
</script>

<style type="text/css">
.printtd{
  padding-top:5px;
  padding-bottom:5px;
  height:40px;
}
</style>
