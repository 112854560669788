<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false>
        <p slot="header" style="color:#f60;text-align:left">
            <span>商家账号激活</span>
        </p>
        <Form ref="activateForm" :model="formInline" :label-width="100" label-position="right" >
            <Row  >
              <i-col span="24">
                <FormItem  label="激活对象:">
                    <p>{{companyname}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
              <i-col span="24">
                <FormItem  label="当前有效期:">
                    <p>{{expiryTime==null?'无':expiryTime}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="expiryDate" label="赠送有效期:">
                    <Row>
                        <i-col span="7">
                            <Select v-bind:disabled="!present" @on-change="onSelect">
                            <Option v-for="item in [1,2,3,4,5,6,7,8,9,10]" :value="item" :key="item">{{item}}年</Option>
                            </Select>
                        </i-col>
                        <i-col span="16" style="padding-left:10px">
                            <DatePicker type="date" v-bind:disabled="!present" v-model="formInline.expiryDate" placeholder="选择日期" ></DatePicker>
                            <Checkbox v-model="present" style="margin-left:8px" @on-change="onChange">赠送</Checkbox>  
                        </i-col>
                    </Row>
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('activateForm')">激活</Button>
        </div>
    </Modal>
</template>
<script>
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
    // 例子： 
    // (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
    // (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
    Date.prototype.Format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "H+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }
    export default {
        name:'activate',
        props:['show','companyid','companyname','expiryTime'],
        data () {
            return {
                formInline: {
                    expiryDate: ''
                },
                present:true
            }
        },
        methods: {
            handleSubmit (name) {
                this.$axios({
                    method:'post',
                    url:"/api/adminuser/activate",
                    data:{
                        accountid:this.companyid,
                        actionuserid:this._self.$root.$store.state.LogonInfo.userid,
                        expirytime:new Date(this.formInline.expiryDate).Format('yyyy-MM-dd'),
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success(res.data.msg)
                        this.$emit('CloseActivate','')
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                    
                }.bind(this))
            },
            oncancel(){
                this.$emit('CloseActivate','')
            },
            onChange(v){
                present = !v
            },
            onSelect(v){
                console.log(v)
                var nowedate = this.expiryTime == null? new Date() :new Date(this.expiryTime)
                nowedate.setFullYear(nowedate.getFullYear()+v);
                this.formInline.expiryDate = nowedate
                console.log(nowedate)
                
            }
        }
    }
</script>