import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Testpage from '../views/Testpage.vue'
import AdminLogin from '../views/admin/AdminLogin.vue'
import Register from '../views/admin/Register.vue'
import UserLogin from '../views/business/UserLogin.vue'
//import WorkDesk from '../views/admin/WorkDesk.vue'
//import CertificateList from '../views/admin/CertificateList.vue'
import CertificateTemplate from '../views/admin/CertificateTemplate.vue'

import SubAccount from '../views/admin/SubAccount.vue'
import AdminSettings from '../views/admin/AdminSettings.vue'
import AdminHome from '../views/admin/AdminHome.vue'
import SuperAdmin from '../views/admin/systemowner/SuperAdmin.vue'
import SelfAccount from '../views/admin/systemowner/SelfAccount.vue'
import CccSetting from '../views/admin/systemowner/CccSetting.vue'
import SupportSetting from '../views/admin/systemowner/SupportSetting.vue'
import CertificateEdit from '../views/admin/CertificateEdit.vue'
import WaterMark from '../views/admin/WaterMark.vue'
import Seal from '../views/admin/Seal.vue'
import VehicleList from '../views/admin/VehicleList.vue'
import WorkShop from '../views/business/workshop/WorkShop.vue'
import SellDesktop from '../views/business/workshop/sell/SellDesktop.vue'
import FinanceDesktop from '../views/business/workshop/finance/FinanceDesktop.vue'
import PlanDesktop from '../views/business/workshop/plan/PlanDesktop.vue'
import WareHouse from '../views/business/workshop/stock/WareHouse.vue'
import PartHouse from '@/views/business/workshop/partstock/PartHouse'
import CertificatePrint from '../views/business/workshop/CertificatePrint.vue'


// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/showcertificate/:vin',
    name:'certificateDetail',
    component:CertificatePrint
  },
  {
    path: '/fact/test',
    name: 'Testpage',
    component: Testpage,
    meta: { requireAuth: true }
  },
  {
    path: '/adminlogin',
    name: 'adminlogin',
    component: AdminLogin
  },
  {
    path:'/yclogin',
    name:'yclogin',
    component:()=>import('../views/business/workshop/checkdept/account/YcUserLogon.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  //CertificateShow
   {
    //查看合格证
    path:'/public/certificate/showdetail/:vin',
    name:'certificateshowold',
    alias: '/certificateshowold',
    component:()=> import( '../views/public/CertificateShow.vue')
  }, 
  {
    //查看合格证
    path:'/public/certificate/showdetail/',
    name:'certificateshow',
    alias: '/certificateshow',
    component:()=> import( '../views/public/CertificateShow.vue')
  },  
  {
    //查看合格证
    path:'/public/certificate/showdetail_shanghai/:vin',
    name:'certificateshowsh',
    alias: '/certificateshowsh',
    component:()=> import( '../views/public/CertificateShowSH.vue')
  },
  {
    //业务用户登录
    path:'/business/depart/userlogin',
    name:'userlogin',
    alias: '/userlogin',
    component:UserLogin,
    redirect:'userlogon'
  },
  {
    //业务用户登录V2
    path:'/business/userlogon',
    name:'userlogon',
    alias: '/userlogon',
    component:()=>import('@/views/business/UserLoginV2.vue')
  },
  {
    //业务主页面
    path:'/business/:companyCode/workhome',
    name:'workhome',
    alias: '/workhome',
    component:()=>import('@/views/business/WorkerHome.vue'),
    redirect: '/business/workhome/:companyCode/welcome',
    children:[
      {
        //welcome
        path:'/business/workhome/:companyCode/welcome',
        name:'welcome',
        alias:'/welcome',
        meta:{title:'欢迎页',requireAuth:true,role:['slave']},
        component:()=>import("@/views/business/WorkHomeWelcome.vue")
      }, 
      {
        //登录车间工作台路由中转中心
        path:'/business/workhome/:companyCode/workshop',
        name:'workshop',
        alias:'/workshop',
        meta:{title:'车间工作台',requireAuth:true,role:['slave'],permission:"workshop:entry"},
        component:WorkShop
      }, 
      {
        //仓库入库发货管理
        path: '/business/workhome/:companyCode/WareHouse',
        name: 'WareHouse',
        alias: '/WareHouse',
        component: WareHouse,
        meta:{title:'仓库工作台',requireAuth:true,role:['slave'],permission:"productwarehouse:entry"},
      }, 
      {
        //零件库管理
        path: '/business/workhome/:companyCode/PartHouse',
        name: 'PartWareHouse',
        alias: '/PartHouse',
        component: PartHouse,
        meta:{title:'零件库工作台',requireAuth:true,role:['slave'],permission:"partwarehouse:entry"},
      }, 
      {
        //技术部门系统管理
        path: '/business/workhome/:companyCode/Technology',
        name: 'Technology',
        alias: '/Technology',
        component: ()=>import('../views/business/workshop/technology/TechDesktop.vue'),
        meta:{title:'技术部门工作台',requireAuth:true,role:['slave','master'],permission:"model:entry"},
      },  
      {
        //财务部门系统管理
        path: '/business/workhome/:companyCode/Finance',
        name: 'Finance',
        alias: '/Finance',
        component: FinanceDesktop,
        meta:{title:'财务部门工作台',requireAuth:true,role:['slave'],permission:"finacial:entry"},
      }, 
      {
        //计划部门系统管理
        path: '/business/workhome/:companyCode/Plan',
        name: 'Plan',
        alias: '/Plan',
        component: PlanDesktop,
        meta:{title:'计划部门工作台',requireAuth:true,role:['slave'],permission:"plan:entry"},
      }, 

      {
        //采购部门系统管理
        path: '/business/workhome/:companyCode/Purchase',
        name: 'Purchase',
        alias: '/Purchase',
        component: ()=>import('../views/business/workshop/purchase/PurchaseDesktop.vue'),
        meta:{title:'技术部门工作台',requireAuth:true,role:['slave'],permission:"purchase:entry"},
      }, 
      {
        //销售部门系统管理
        path: '/business/workhome/:companyCode/Sell',
        name: 'Sell',
        alias: '/Sell',
        component: SellDesktop,
        meta:{title:'销售工作台',requireAuth:true,role:['slave'],permission:"sale:entry"},
      }, 
      {
        path:'/techdesk/CertificateEdit/:action',
        component: CertificateEdit,
        name:'SlaveCertificateEdit',
        meta:{title:'编辑合格证',requireAuth:true,role:['master','slave']},
      },
    ]
  },
  {
    //登录车间工作台路由中转中心
    path:'/business/depart/:companyCode/workshop',
    name:'workshop',
    alias:'/workshop',
    meta:{title:'车间工作台',requireAuth:true,role:['slave'],deptid:[101]},
    component:WorkShop
/*     beforeEnter: (to, from, next) => {
      next({name:'VehicleDownline',params:to.params,replace:true})
    }, */
  },  
/*   {
    //车间合格证打印
    path: '/business/depart/:companyCode/workshop/VehicleDownline',
    name: 'VehicleDownline',
    alias: '/vertificationPrint',
    meta:{title:'工作台',requireAuth:true},
    component: VehicleDownline,
  }, */
  {
    //仓库入库发货管理
    path: '/business/depart/:companyCode/WareHouse',
    name: 'WareHouse',
    alias: '/WareHouse',
    component: WareHouse,
    meta:{title:'仓库工作台',requireAuth:true,role:['slave'],deptid:[102]},
  }, 
  {
    //零件库管理
    path: '/business/depart/:companyCode/PartHouse',
    name: 'WareHouse',
    alias: '/PartHouse',
    component: PartHouse,
    meta:{title:'零件库工作台',requireAuth:true,role:['slave'],deptid:[108]},
  }, 
  
  {
    //技术部门系统管理
    path: '/business/depart/:companyCode/Technology',
    name: 'Technology',
    alias: '/Technology',
    component: ()=>import('../views/business/workshop/technology/TechDesktop.vue'),
    meta:{title:'技术部门工作台',requireAuth:true,role:['slave','master'],deptid:[103]},
  },  
  {
    //财务部门系统管理
    path: '/business/depart/:companyCode/Finance',
    name: 'Finance',
    alias: '/Finance',
    component: FinanceDesktop,
    meta:{title:'财务部门工作台',requireAuth:true,role:['slave'],deptid:[104]},
  }, 
  {
    //计划部门系统管理
    path: '/business/depart/:companyCode/Plan',
    name: 'Plan',
    alias: '/Plan',
    component: PlanDesktop,
    meta:{title:'计划部门工作台',requireAuth:true,role:['slave'],deptid:[105]},
  }, 

  {
    //采购部门系统管理
    path: '/business/depart/:companyCode/Purchase',
    name: 'Purchase',
    alias: '/Purchase',
    component: ()=>import('../views/business/workshop/purchase/PurchaseDesktop.vue'),
    meta:{title:'技术部门工作台',requireAuth:true,role:['slave'],deptid:[106]},
  }, 
  {
    //销售部门系统管理
    path: '/business/depart/:companyCode/Sell',
    name: 'Sell',
    alias: '/Sell',
    component: SellDesktop,
    meta:{title:'销售工作台',requireAuth:true,role:['slave'],deptid:[107]},
  }, 
  {
    path:'/techdesk/CertificateEdit/:action',
    component: CertificateEdit,
    name:'SlaveCertificateEdit',
    meta:{title:'编辑合格证',requireAuth:true,role:['master','slave']},
  },
  {
    path:'/workdesk/TemplateEdit',
    name:'checktemplateedit',
    component: ()=> import('@/views/business/workshop/checkdept/TemplateEdit.vue'),
    meta:{title:'认证文件模板编辑',requireAuth:true,role:['superadmin','slave'],deptid:[150]}
  },
  {
    path:'/workdesk/TemplateView',
    name:'checktemplateview',
    component: ()=> import('@/views/business/workshop/checkdept/TemplateView.vue'),
    meta:{title:'认证文件模板查看',requireAuth:true,role:['superadmin','slave'],deptid:[150]}
  },
  {
    path:'/workdesk/PdfView',
    name:'checkpdfview',
    component: ()=> import('@/views/business/workshop/checkdept/PdfView.vue'),
    meta:{title:'pdf查看',requireAuth:true,role:['superadmin','slave'],deptid:[150]}
  },
  {
    path:'/workdesk/DocView',
    name:'docview',
    component: ()=> import('@/views/common/DocView.vue'),
    meta:{title:'文档内容查看',requireAuth:true,role:['superadmin','slave','third'],deptid:[150]}
  },
  {
    path:'/workdesk/DocEdit',
    name:'docedit',
    component: ()=> import('@/views/business/workshop/checkdept/FileContentEdit.vue'),
    meta:{title:'文档编辑',requireAuth:true,role:['superadmin','slave'],deptid:[150]}
  },
  {
    path:'/workdesk/CghtView',
    name:'cghtview',
    component: ()=> import('@/views/business/workshop/checkdept/purchase/CghtPrint.vue'),
    meta:{title:'采购合同查看',requireAuth:true,role:['superadmin','slave'],deptid:[150]}
  },
  {
    path:'/business/depart/:companyCode/checkdept/supplierscore/:supplierid',
    name:'supplierscore',
    component: ()=>import('../views/business/workshop/checkdept/supplier/SupplierScore.vue'),
    meta:{title:'工作台',requireAuth:true,role:['slave'],deptid:[150]}
  },
  {
    //验厂部门系统管理
    path: '/business/depart/:companyCode/checkdept',
    name: 'checkdept',
    alias: '/checkdept',
    component: ()=>import('../views/business/workshop/checkdept/CheckDeptDesktop.vue'),
    meta:{title:'验厂部门工作台',requireAuth:true,role:['slave','third'],deptid:[150]},
    redirect: '/business/depart/:companyCode/checkdept/CheckHome',
    meta:{title:'工作台',requireAuth:true,role:['slave']},
    children:[
      {
        path:'/business/depart/:companyCode/checkdept/CheckHome',
        component: ()=>import('../views/business/workshop/checkdept/CheckHome.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/baseconfig',
        component: ()=>import('../views/business/workshop/checkdept/BaseConfig.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/template',
        component: ()=>import('../views/business/workshop/checkdept/FileTemplateManage.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/standard',
        component: ()=>import('../views/business/workshop/checkdept/StandardManage.vue'),
        meta:{title:'工作台',requireAuth:true,role:['superadmin','slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/ziliao',
        component: ()=>import('../views/business/workshop/checkdept/ziliao.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/ziliaoAdd',
        component: ()=>import('../views/business/workshop/checkdept/ziliaoAdd.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/ziliaoFiles',
        component: ()=>import('../views/business/workshop/checkdept/ziliaoFiles.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/supplier',
        component: ()=>import('../views/business/workshop/checkdept/supplier/Supplier.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/cgjh',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Cgjh.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/shd',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Shd.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/rkd',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Rkd.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/ckd',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Ckd.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/scjh',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Scjh.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/cght',
        component: ()=>import('../views/business/workshop/checkdept/purchase/Cght.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/ycwj/:classid',
        component: ()=>import('../views/business/workshop/checkdept/YCWJManage.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/cerrecord',
        component: ()=>import('../views/business/workshop/checkdept/special/CerRecord.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/partcerinfo',
        component: ()=>import('../views/business/workshop/checkdept/partcerinfo/PartCerInfoMain.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/rzbzrecord',
        component: ()=>import('../views/business/workshop/checkdept/special/RzbzRecord.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/cccheckrecord',
        component: ()=>import('../views/business/workshop/checkdept/qc/Ccjy.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
      {
        path:'/business/depart/:companyCode/checkdept/scsbdj',
        component: ()=>import('../views/business/workshop/checkdept/equipment/Scsbdj.vue'),
        meta:{title:'工作台',requireAuth:true,role:['slave','third'],deptid:[150]}
      },
    ]
  },
  {
    path: '/workdesk',
    name: 'workdesk',
    component: ()=> import( '../views/admin/WorkDesk.vue'),
    redirect: '/workdesk/AdminHome',
    meta:{title:'工作台',requireAuth:true,role:['superadmin','master']},
    children:[
      {
        path:'/workdesk/AdminHome',
        component: AdminHome,
        meta:{title:'工作台',requireAuth:true,role:['superadmin','master']}
      },
      {
        path:'/workdesk/SuperAdmin',
        component: SuperAdmin,
        meta:{title:'商家管理系统',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/CccSetting',
        component: CccSetting,
        meta:{title:'商家管理系统',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/SupportSetting',
        component: SupportSetting,
        meta:{title:'商家管理系统',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/SelfAccount',
        component: SelfAccount,
        meta:{title:'账号管理系统',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/ModifyPassword',
        component: ()=> import('../views/admin/systemowner/ModifyPassword.vue'),
        meta:{title:'密码修改',requireAuth:true,role:['superadmin','master']}
      },  
      {
        path:'/workdesk/SuperAdmin/CheckFileTemplate',
        component: ()=> import('@/views/business/workshop/checkdept/FileTemplateManage.vue'),
        meta:{title:'认证文件模板',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/TemplateEdit',
        component: ()=> import('@/views/business/workshop/checkdept/TemplateEdit.vue'),
        meta:{title:'认证文件模板编辑',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/CheckAccount',
        component: ()=> import('@/views/business/workshop/checkdept/account/Account.vue'),
        meta:{title:'认证账号',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/checkdept/standard',
        component: ()=>import('@/views/business/workshop/checkdept/StandardManage.vue'),
        meta:{title:'标准文档',requireAuth:true,role:['superadmin']}
      },
      {
        path:'/workdesk/SuperAdmin/CheckCompany',
        component: ()=> import('@/views/business/workshop/checkdept/companys/CheckCompany.vue'),
        meta:{title:'验厂功能管理',requireAuth:true,role:['superadmin']}
      },
      {
        name:'viewcompanydetail',
        path:'/workdesk/SuperAdmin/ViewCompanyDetail/:companyid',
        component: ()=> import('../views/admin/systemowner/ViewCompanyDetail.vue'),
        meta:{title:'查看详情',requireAuth:true,role:['superadmin','master']}
      },  
      {
        path:'/workdesk/CertificateList',
        component: ()=> import( '../views/admin/CertificateList.vue'),
        meta:{title:'合格证列表',requireAuth:true,role:['superadmin','master']}
      },
      {
        path:'/workdesk/VehicleList',
        component: VehicleList,
        meta:{title:'整车编码列表',requireAuth:true,role:['superadmin','master']}
      },
      {
        path:'/workdesk/SealSetting',
        component: Seal,
        meta:{title:'印章管理',requireAuth:true,role:['superadmin','master']}
      },    
      {
        path:'/workdesk/WaterMarkSetting',
        component: WaterMark,
        meta:{title:'水印管理',requireAuth:true,role:['superadmin','master']}
      },     
      {
        path:'/workdesk/SubAccount',
        component: SubAccount,
        meta:{title:'公司子账号管理',requireAuth:true,role:['superadmin','master']}
      },   
      {
        path:'/workdesk/ErpConfig',
        component: ()=>import("@/views/admin/ErpConfig.vue"),
        meta:{title:'ERP配置',requireAuth:true,role:['superadmin','master']}
      },   
      {
        path:'/workdesk/CompanyInfo',
        component: ()=> import("../views/admin/CompanyInfo.vue"),
        meta:{title:'信息修改',requireAuth:true,role:['superadmin','master']}
      },  
      {
        path:'/workdesk/CertificateTemplateHome',
        component: () => import('../views/admin/CertificateTemplateHome.vue'),
        meta:{title:'合格证模板',requireAuth:true,role:['superadmin','master']},
        redirect:'/workdesk/CertificateTemplateHome/CertificateTemplate',
        children:[
          {
            path:'/workdesk/CertificateTemplateHome/CertificateTemplate',
            component: CertificateTemplate,
            meta:{title:'合格证模板',requireAuth:true,role:['superadmin','master']},
          },
        ]
      },
      {
        path:'/workdesk/AdminSettings',
        component: AdminSettings,
        meta:{title:'设置',requireAuth:true,role:['superadmin','master']}
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})
export default router
