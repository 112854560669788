<!-- 打印统计查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="left">
                        <FormItem  label="打印日期:" style="margin-bottom:5px;">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.printdate"  @on-change="formInline.printdate=$event"></DatePicker>
                        </FormItem>  
                    </td>  
                    <td  align="right">
                        <div>
                            <Button type="primary" @click="handleQuery()" >统计查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">打印统计</label>  
        </div>
        <!-- 整车编码列表-->
         <div style="margin:0 auto;justify-content:center;align-items:center;">
            <table border="1px solid black" cellspacing="0"  class="summarytbl">
                <tr >
                    <th>日期</th>
                    <th>合格证编号</th>
                    <th>合格证别名</th>
                    <th>产品型号</th>
                    <th>打印数量</th>
                    <th>查看详情</th>
                    <th>合计</th>
                </tr>   
                <tbody v-for="(item,index) in tbldata" :key="index">
                    <tr v-for="(item2,index2) in item.cerRecordList" :key="index2">
                        <td :rowspan="item.cerRecordList.length" v-if="index2==0">{{new Date(item.printdate).Format("yyyy-MM-dd")}}</td>
                        <td>{{item2.certificateno}}</td>
                        <td>{{item2.certificatealias}}</td>
                        <td>{{item2.modelno}}</td>
                        <td>{{item2.count}}</td>
                        <td>
                            <Tooltip placement="left" >
                                <Button type="warning" size="small" >查看详情</Button>
                                <div slot="content">
                                    <p v-for="(item3,index3) in item2.colorRecordList" :key="index3">{{item3.color}} : {{item3.count}}</p>
                                </div>
                            </Tooltip>                            
                        </td>
                        <td :rowspan="item.cerRecordList.length" v-if="index2==0">{{item.count}}</td>
                    </tr>
                </tbody>          
                <tr>
                    <td>总合计</td>
                    <td colspan="5"> - </td>
                    <td>{{totalcount}}</td>
                </tr>    
            </table>             
            <Table v-show="false" ref="vehicletable" border :columns="tblcolumns" :data="tbldata">
                <template slot-scope="{ row }" slot="certificateno">
                    <strong>{{ row.certificateno }}</strong>
                </template>
                <template slot-scope="{ row,index }" slot="action">
                    <Tooltip placement="left" v-if="index<tbldata.length-1">
                        <Button type="warning" size="small"  @click="showdetail(index)">查看详情</Button>
                        <div slot="content">
                            <p v-for="(item,index) in row.colorsummary" :key="index">{{item.color}} : {{item.count}}</p>
                        </div>
                    </Tooltip>
                </template>
            </Table>
        </div> 

    </div>
</template>

<script>

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'PrintSummary',
    props:['liushuixianid'],
    data () {
            return {
                formInline: {
                    companyid:'',
                    userid:null,
                    printdate: [(new Date()).Format("yyyy-MM-dd"), (new Date()).Format("yyyy-MM-dd")],
                    printdatestart:null,
                    printdateend:null
                },
                totalcount:0,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '合格证编号',
                        slot: 'certificateno',
                    },
                    {
                        title: '合格证别名',
                        key: 'certificatealias',
                    },
                    {
                        title: '中文产品型号',
                        key: 'cnmodel',
                    },    
                    {
                        title: '产品型号',
                        key: 'modelno',
                    },            
                    {
                        title: '打印数量',
                        key: 'count',
                    },  
                    {
                        title: '操作',
                        slot: 'action',
                    },
                ],
                tbldata: [],
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.formInline.userid = this.liushuixianid
        },
        methods: {
            handleQuery() {

                console.log(this.formInline.printdate)
                if(this.formInline.printdate ==null){
                    this.$Message.error({content:'请选择打印日期范围进行统计查询',duration:3})
                    return
                }

                var postquerydata={
                        companyid: this.formInline.companyid,
                        userid: this.formInline.userid,
                        printdatestart : this.formatDate(this.formInline.printdate[0]),
                        printdateend : this.formatDate(this.formInline.printdate[1])                        
                    }    
                postquerydata = this.removeEmptyChilds(postquerydata)    
                console.log(postquerydata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/printsummary/query",
                    data:postquerydata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata = res.data.data

                        this.totalcount = 0
                        if(this.tbldata != null){
                            for(var i=0;i<this.tbldata.length;i++){
                                this.totalcount = this.totalcount + this.tbldata[i].count
                            }
                        }
                        console.log(this.tbldata)
                        //this.tbldata.push({'certificateno':'合计','certificatealias':'-','cnmodel':'-','modelno':'-','count':totalcount})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   

            },
            handleReset (name) {
                this.formInline.printdate=null
                this.totalcount = 0
                console.log(this.$refs[name])
                this.tbldata=[]
                this.formInline.userid = this.liushuixianid
               // this.handleQuery()
            },
            reload (){
                var logonInfo = this._self.$root.$store.state.LogonInfo
                this.formInline.companyid = logonInfo.companyid
                this.formInline.userid = this.liushuixianid
                this.formInline.printdate= [(new Date()).Format("yyyy-MM-dd"), (new Date()).Format("yyyy-MM-dd")]
                this.handleQuery()
            },
            showdetail (index){

            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            }
        }
}
</script>

<style type="text/css">
    .summarytbl {
        line-height:1.0;width:100%
    }

    .summarytbl th{
        padding:20px 10px;
    }

    .summarytbl td{
        padding:10px 10px;
    }
</style>