<!-- 整车出入库功能-->
<template>
    <div class="StockInOut">
        <Row style="background:#eee;padding:20px">
            <i-col span="11">
                <Card :bordered="false" style="height:450px">
                    <p slot="title">整车编码列表</p>
                    <i-input type="textarea" v-model="vehicleNoList" :maxlength="2000" :rows="17"
                    placeholder="
                    批量车架号录入，以分号(;)或换行进行分割，
                    比如：
                    687345909087880;687345909087881;687345909087882
                    或
                    687345909087880
                    687345909087881
                    687345909087882"/>
                </Card>
            </i-col>
            <i-col span="12" offset="1">
                <Card shadow style="height:450px">
                    <div style="position:absolute;bottom:30px;width:100%" align="left">
                        <div style="width:35%;height:130px;float:left;">
                            <Button type="warning" @click="handleOut()" style="height:80px;width:100%">发货出库</Button>
                            <Button type="info" ghost @click="handlePrint()" style="height:40px;;width:100%;margin-top:10px">打印出库贴</Button>
                        </div>
                        <div style="float:right;width:63%;margin-top:5px">
                            <RadioGroup v-model="city" size="small">
                                <Radio label="上海区域" border></Radio>
                                <Radio label="全国其它区域" border></Radio>
                            </RadioGroup>
                            <i-input v-model="address" placeholder="详细地址" style="margin-top:10px;width:90%"></i-input>
                        </div>

                    </div>
                </Card>
            </i-col>
        </Row>
        <Modal v-model="showoutconfirm" title="确定要出库吗？" @on-ok="confirmout">共计{{postvinlist.length}}个车辆编码即将发货出库</Modal>
        <Modal v-model="showprint" title="出库贴打印" width="800px" :mask-closable="false">
          <stockprint />
        </Modal>
        <Modal v-model="showresult" width="600px" height="90%" scrollable>
            <p slot="header" style="color:#f60;text-align:left">
                <span >库存操作结果</span>
            </p>
            <div style="display:block; overflow: auto; height:450px">
                <table v-if="showresult" border="1px solid black" cellspacing="0" style="margin-top: 20px;width: 100%;text-align: left;">
                    <tr>
                        <th style="width: 40%;">整车编码</th>
                        <th style="width: 40%;">结果</th>
                    </tr>
                    <tr v-for="(item,key) in stockresult.failedList" style="background: rgb(240, 203, 203);">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                    <tr v-for="(item,key) in stockresult.successList">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                </table>
            </div>

        </Modal>
        <loading ref='loading' msg="正在出库..." />
    </div>
</template>

<script>
//import cityList from '@/components/zsjs/citylist.js'
import StockOutPrint from './StockOutPrint.vue'
import Loading from '@/components/Loading'
export default {
    name:'StockOut',
    data() {
        return {
            vehicleNoList: '',
            postvinlist:[],
            city: '全国其它区域',
            address:'',
            stockresult:null,
            showresult:false,
            showinconfirm:false,
            showoutconfirm:false,
            showprint:false
        }
    },
    mounted(){
        /*
        for(var item in this.citys){
            console.log(item)
            console.log(this.citys[item].provice)
        }*/
    },
    methods:{
        formatdata(){
            var inputdata=this.vehicleNoList.replace(new RegExp("\n","gm"),';').replace(new RegExp("；","gm"),';').replace(new RegExp(";;","gm"),';').replace(new RegExp(";;;","gm"),';')
            var arr=inputdata.split(";")
            Array.prototype.remove = function(val) {
                var index = this.indexOf(val);
                if (index > -1) {
                    this.splice(index, 1);
                }
            }
            arr.remove("")
            this.postvinlist =[]
            for(let item=0;item<arr.length;item++){

                var itemdata=arr[item]
                if(itemdata.indexOf("，") != -1){
                    var no= (arr[item].split("，"))[1]
                    this.postvinlist.push(no)
                }
                else{
                    this.postvinlist.push(arr[item])
                }
            }
        },

        handleOut(){
            if(this.vehicleNoList==null || this.vehicleNoList.length==0){
                this.$Message.error('请输入整车编码')
                return
            }
            this.formatdata()
            console.log(this.postvinlist)
            this.showoutconfirm = true
        },
        confirmout(){
            var postdata= this.extend({},this._self.$root.$store.state.LogonInfo,{vehiclenos:this.postvinlist,receiverarea:this.city,receiveraddress:this.address,stocktype:101})
            delete postdata.expiryTime
            this.$refs['loading'].startloading()
            this.$axios({
                method:'post',
                url:"/api/vehicleIn/stockout",
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.stockresult = res.data.data
                    console.log("库存操作结果",this.stockresult)
                    this.$Message.success({content:res.data.msg, duration:3, closable:true})
                    this.showresult = true
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                this.$refs['loading'].stoploading()
            }.bind(this));
            //this.$Message.success('入库成功')
        },
        handlePrint(){
            this.showprint = true
        }

    },
    components: {
        stockprint:StockOutPrint,
        loading: Loading
    },
}
</script>
