<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem prop="vehicleno" label="整车编码:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.vehicleno">
                            </i-input>
                        </FormItem>
                    </td>
                    <td width="23%">
                        <FormItem prop="cnmodelno" label="中文型号" style="margin-bottom:5px;margin-top:10px">
                            <Select ref="cnmodelselect" v-model="formInline.cnmodelno" @on-change="onCnModelSelect">
                                <Option value='' :key=-1>请选择</Option>
                                <Option v-for="(item,index) in CnModelList" :value="item" :key="index">{{ item }}</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td align="left" width="23%">
                        <Row  index="">
                          <i-col span="18">
                            <FormItem prop="certificateno" label="合格证:" style="margin-bottom:5px;margin-top:10px">
    <!--                             <i-input type="text" v-model="formInline.certificateno">
                                </i-input> -->
                                <Select  v-model="formInline.cerindex" @on-change="onTmpSelect">
                                    <Option :value=0 :key=0>请选择</Option>
                                    <Option v-for="item in CertificateTemplateShowList" :value="item.cerdisplay.value" :key="item.cerdisplay.value">{{item.cerdisplay.label+"("+item.cerdisplay.standard+")" }}</Option>
                                </Select>
                            </FormItem>
                          </i-col>
                          <i-col span="6" style="padding-left:5px;padding-top:18px">
                              <Button type="success" v-bind:disabled="syncdisabled" size="small" @click="refreshCerlist" >{{synctext}}</Button>
                          </i-col>
                        </Row>
                    </td>

                    <td rowspan="2" colspan="2" align="right">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >车辆查询</Button>
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td align="left">
                        <FormItem  label="打印日期:" style="margin-bottom:5px;">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.printdate"  @on-change="formInline.printdate=$event" @on-clear="formInline.printdate=null"></DatePicker>
                        </FormItem>
                    </td>
                    <td align="right">
                        <FormItem  label="制造日期:" style="margin-bottom:5px;">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.madedate"  @on-change="formInline.madedate=$event" @on-clear="formInline.madedate=null"></DatePicker>
                        </FormItem>
                    </td>
                    <td align="left" width="23%">
                        <FormItem v-if="departid== '102'" label="车辆状态:" style="margin-bottom:5px">
                            <Select  @on-change="onstatusselect" >
                                <Option value=1 key="1">全部库存</Option>
                                <Option value=2 key="2">已入库未锁定</Option>
                                <Option value=3 key="3">已入库已锁定</Option>
                            </Select>
                        </FormItem>
                        <FormItem v-if="departid== '101' || departid== '200'" label="状态：" style="margin-bottom:5px;">
                            <Select  v-model="formInline.exportstatus" @on-change="onselectexportstatus">
                                <Option value=null key="100">全部</Option>
                                <Option value=0 key="0">未导出</Option>
                                <Option value=1 key="1">已导出</Option>
                            </Select>
                        </FormItem>
                    </td>
<!--                     <td align="left">
                        <FormItem v-show="false" prop="motorno" label="电动机编码:" style="margin-bottom:5px;">
                            <i-input type="text" v-model="formInline.motorno">
                            </i-input>
                        </FormItem>
                    </td> -->
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">车辆列表</label>
            <Button type="info" ghost icon="md-arrow-round-down" style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="exportVerhicles('')">导出列表中车辆</Button>
            <Button type="success" ghost icon="md-arrow-round-down" style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="exportVerhicles('selected')">导出本页选中车辆</Button>
            <Button type="warning" ghost icon="md-arrow-round-down" style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="exportVerhicles('unselected')">导出本页未选中车辆</Button>
            <Button type="primary" ghost icon="md-arrow-round-up" style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="showuploadwin=true" v-show="showuploadbtn">上传数据</Button>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>

        <!-- 整车编码列表-->
        <div>
            <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata"  @on-selection-change="onselectionchange">
            <template slot-scope="{ row }" slot="vehicleno">
                <strong>{{ row.vehicleno }}</strong>
            </template>
            <template  slot-scope="{ row }" slot="status">
                {{ departid == '102'? getstatustext(row.status) :(row.exportstatus==true?'已导出':'未导出') }}
            </template>
            <template slot-scope="{ row }" slot="standard">
                {{ getstandardtext(row.standard) }}
            </template>
            <template slot-scope="{ row }" slot="printdate">
                {{ formatDate(row.printdate) }}
            </template>
            <template slot-scope="{ row }" slot="madedate">
                {{ formatDate(row.madedate) }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small"  style="margin-right: 5px" @click="exportsingle(index)">导出</Button>
                <Button v-if="departid == '200'" type="error" size="small" @click="remove(index)">删除</Button>
            </template>
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>

        </div>
        <Modal v-model="showuploadwin" title="上传数据" @on-ok="showuploadwin=false" @on-cancel="showuploadwin = false"  ok-text="关闭上传窗口" width="90" height="90">
            <iframe v-if="uploadlinkaddr !=null" :src="uploadlinkaddr" width="100%" height="600px" style="overflow: auto;">

            </iframe>
        </Modal>
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'StockSummary',
    props:['departid'],
    data () {
            return {
                formInline: {
                    companyid:'',
                    certificateno: '',
                    cnmodelno:'',
                    cerindex:0,
                    motorno: '',
                    vehicleno: '',
                    status:[2,3],
                    exportstatus:"0",
                    printdate:null,
                    madedate:null,
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:100
                },
                tblcolumns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '整车编码',
                        slot: 'vehicleno',
                        width:180,
                        resizable:true
                    },
                    {
                        title: '合格证编号',
                        key: 'certificateno',
                        width:180,
                        resizable:true
                    },
                    {
                        title: '电动机编号',
                        key: 'motorno',
                        width:180,
                        resizable:true
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                        width:80,
                    },
                    {
                        title: '状态',
                        slot: 'status'       ,
                        width:80,
                    },
                    {
                        title: '打印日期',
                        slot: 'printdate',
                        width:120,
                        resizable:true
                    },
                    {
                        title: '制造日期',
                        slot: 'madedate',
                        width:120,
                        resizable:true
                    },
                    {
                        title: '标准',
                        slot: 'standard',
                        width:80,
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 200,
                        align: 'center',
                        fixed: 'right'
                    }
                ],
                tbldata: [],
                CertificateTemplateList:[],
                //显示在下拉框里面的模板数据
                CertificateTemplateShowList:null,
                //中文型号分类列表
                CnModelList:null,
                selectedTempIndex:0,
                ccclist:null,
                postquerydata:null,
                synctext:'同步数据',
                syncdisabled:false,
                syncdisabledsecondleft:30,
                tblselection:null,
                //上传窗口是否显示
                showuploadwin:false,
                showuploadbtn:false,
                uploadlinkaddr:null,
                orgportlist:[],
                orgportqueryover:false
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            //this.handleQuery('vehicleQueryForm')
            console.log('departid:'+this.departid)
            this.makeTemplateList(true)
            console.log(this.CertificateTemplateList)
            console.log(typeof(this.CertificateTemplateList))
            if(this.CertificateTemplateList.length >0){
                //同步合格证数据后会自动同步ccc数据，所以if里面不需要同步ccc操作
                this.ccclist = this._self.$root.$store.state.CccList
                if(this.ccclist ==null){
                    this.refreshCcclist()
                }
            }


        },
        methods: {
            handleQuery(name) {
                this.postquerydata={
                        companyid:this.formInline.companyid,
                        certificateno:this.formInline.certificateno,
                        motorno:this.formInline.motorno,
                        vehicleno:this.formInline.vehicleno,
                        status:this.formInline.status,
                        exportstatus:this.formInline.exportstatus,
                        pageNum:this.tblpageinfo.currentpage,
                        pageSize:this.tblpageinfo.pagesize
                    }
                console.log(this.formInline.printdate)
                console.log(this.formInline.vehicleno)
                console.log(this.formInline.cerindex+'--->'+this.formInline.certificateno)
                if(this.formInline.vehicleno !=null && this.formInline.vehicleno!=''){

                }
                else if(this.formInline.cerindex ==0 || this.formInline.cerindex == -1){
                    this.$Message.error({content:'请选择合格证',duration:3})
                    return
                }
                else{
                    console.log(this.CertificateTemplateList)
                    this.postquerydata.certificateno = this.CertificateTemplateList[this.formInline.cerindex -1].cerfulldata.certificateno
                    this.formInline.certificateno = this.postquerydata.certificateno
                    console.log(this.formInline.cerindex+'--->'+this.formInline.certificateno)
                }

                if(this.formInline.printdate !=null){
                    if(this.formInline.printdate[0] !=''){
                        this.postquerydata.printdatestart = this.formInline.printdate[0]
                    }
                    if(this.formInline.printdate[1] !=''){
                        this.postquerydata.printdateend = this.formInline.printdate[1]
                    }
                }

                if(this.formInline.madedate !=null){
                    if(this.formInline.madedate[0] !=''){
                        this.postquerydata.madedatestart = this.formInline.madedate[0]
                    }
                    if(this.formInline.madedate[1] !=''){
                        this.postquerydata.madedateend = this.formInline.madedate[1]
                    }
                }

                if(this.departid == '101' || this.departid == '200'){
                    //车间部门，固定库存状态
                    delete this.postquerydata.status
                }
                else if(this.departid == '102'){
                    //仓库部门
                    this.postquerydata.exportstatus=null
                }

                for(var p in this.postquerydata){
                    if(this.postquerydata[p] == null){
                        delete this.postquerydata[p]
                    }
                }
                console.log(this.postquerydata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/query",
                    data:this.postquerydata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        this.tbldata = res.data.data.data
                        this.tblpageinfo.total = res.data.data.totalnumber
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            refreshCerlist(){
                console.log(this._self.$root.$store.state.LogonInfo)
                      this.syncdisabled = true
                this.syncdisabledsecondleft = 30
                var countDown =setInterval(() =>{
                    if(this.syncdisabledsecondleft < 1){
                        this.syncdisabled = false
                        this.synctext = '同步数据'
                        clearInterval(countDown);
                    }else{
                        this.syncdisabled = true
                        this.synctext = '同步数据'+this.syncdisabledsecondleft+"s"
                        this.syncdisabledsecondleft = this.syncdisabledsecondleft -1
                    }
                },1000);
                console.log(this.syncdisabled)
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/query",
                    data:{
                        companyid:this._self.$root.$store.state.LogonInfo.companyid,
                        pageNum:1,
                        pageSize:200
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(rspdata)
                        this.CertificateTemplateList=[]
                        for(var item=0;item<rspdata.length;item++){
                            var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

                            cer.value=item+1
                            cer.certificateid=rspdata[item].id
                            cer.label=rspdata[item].certificatealias
                            cer.certificateno=rspdata[item].certificateno
                            cer.standard= ( rspdata[item].standardtype == 2 ? '企标':(rspdata[item].standardtype == 1 ? '国标21版' : '国标23版'))
                            this.CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
                        }
                        this.$store.commit('changeCertificateList',this.CertificateTemplateList)
                        this.$Message.success({content:'合格证数据同步完成', duration:3})
                        this.formInline.cerindex =0
                        console.log(this.CertificateTemplateList)
                        this.makeTemplateList(false)
                        this.refreshCcclist()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            makeTemplateList(ifemptythenquery){
                this.CertificateTemplateList =this._self.$root.$store.state.CertificateList
                console.log('makeTemplateList:ifemptythenquery='+ifemptythenquery+'  this.CertificateTemplateList='+this.CertificateTemplateList)
                if(this.CertificateTemplateList ==null ||this.CertificateTemplateList =='' || this.CertificateTemplateList =='null'){
                    if(ifemptythenquery){
                        this.CertificateTemplateList ==[]
                        this.refreshCerlist()
                        return
                    }
                    else{
                        this.CertificateTemplateList ==[]
                    }
                }


                this.CnModelList=[]
                console.log(this.CertificateTemplateList)
                console.log(typeof(this.CertificateTemplateList))
                this.CertificateTemplateList.forEach(element => {
                    if(this.CnModelList.indexOf(element.cerfulldata.cnmodelno)<=-1){
                        //不存在
                        this.CnModelList.push(element.cerfulldata.cnmodelno)
                    }
                });

                this.filterTemplateList()
            },
            onTmpSelect(item){
                console.log(item)
                this.showuploadbtn = false
                if(item==null ||item==''||item==0){
                    return
                }

                if(item==-1){
                    this.refreshCerlist()
                    this.formInline.cerindex = 0
                }

                this.computeorgport(this.CertificateTemplateList[this.formInline.cerindex -1].cerfulldata.id)
            },
            computeorgport(cerid){
                if(this.orgportlist ==null || this.orgportlist.length ==0){
                    if(this.orgportqueryover == false){
                        this.queryorgport()
                        this.orgportqueryover = true
                    }
                }
                else{
                    for(var i=0;i<this.orgportlist.length;i++){
                        if(this.orgportlist[i].cerid == cerid){
                            if(this.orgportlist[i].orgportlinkaddr !=null){
                                this.showuploadbtn = true
                                this.uploadlinkaddr = this.orgportlist[i].orgportlinkaddr
                                console.log(this.uploadlinkaddr)
                                return
                            }
                        }
                    }
                }
            },
            onCnModelSelect(v){
                this.filterTemplateList()
            },
            onselectexportstatus (v){
                if(v == null || v=='null' || v==''){
                    this.formInline.exportstatus = null
                }
                else{
                    this.formInline.exportstatus = v
                }
            },
            filterTemplateList(){
                console.log(this.formInline.cnmodelno)
                if(this.formInline.cnmodelno==''){
                    this.CertificateTemplateShowList = this.CertificateTemplateList
                }
                else{
                    this.CertificateTemplateShowList =[]
                    this.CertificateTemplateList.forEach(element=>{
                        if(element.cerfulldata.cnmodelno == this.formInline.cnmodelno){
                            this.CertificateTemplateShowList.push(element)
                        }
                    })
                }
            },
            refreshCcclist(){
                this.$axios({
                    method:'post',
                    url:"/api/ccc/query",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.ccclist =rspdata
                        this.$store.commit('changeCccList',this.ccclist)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.handleQuery('vehicleQueryForm')
            },
            onstatusselect(status){
                console.log(status)
                if(status == 2){
                    this.formInline.status =[2]
                }
                else if(status == 3){
                    this.formInline.status =[3]
                }
                else{
                    this.formInline.status =[2,3]
                }
            },
            onselectionchange(s){
                this.tblselection = s
                console.log(this.tblselection)
            },
            exportVerhicles (type) {
                console.log(type)
                //导出车辆列表
                if(this.tblpageinfo.total == 0){
                    this.$Message.error({content:'没有数据可供导出！',duration:3})
                    return
                }

                if(this.CertificateTemplateList[this.formInline.cerindex-1].cerdisplay.standard == '企标'){
                    this.$Message.error({content:'企标数据暂不支持导出！',duration:3})
                    return
                }

                if(this.CertificateTemplateList[this.formInline.cerindex -1].cerfulldata.cccindex == 0){
                    this.$Message.error({content:'该合格证没有配置认证机构，不能导出数据！请先在合格证模板里配置认证机构！',duration:3})
                    return
                }
                let exportqdata
                let exportqurl
                let ids=[]
                if(type == 'selected'){
                    //导出选中数据
                    for(var i=0;i<this.tblselection.length;i++){
                        console.log(this.tblselection[i])
                        ids.push(this.tblselection[i].id);
                    }
                    exportqdata ={
                        ids: ids,
                        companyid:this._self.$root.$store.state.LogonInfo.companyid
                    }
                    exportqurl = "/api/vehicle/export"
                }
                else if(type == 'unselected'){
                    //导出未选中数据

                    for(var i=0;i<this.tbldata.length;i++){
                        ids.push(this.tbldata[i].id)
                    }
                    for(var i=0;i<this.tblselection.length;i++){
                        var tmp=this.tblselection[i]
                        ids.forEach(function(item, index, arr) {
                            if(item == tmp.id) {
                                arr.splice(index, 1);
                            }
                        })
                    }
                    exportqdata ={
                        ids: ids,
                        companyid:this._self.$root.$store.state.LogonInfo.companyid
                    }
                    exportqurl = "/api/vehicle/export"
                }
                else{
                    //导出全部数据
                    url:"/api/vehicle/export",
                    exportqdata =this.postquerydata
                    exportqurl = "/api/vehicle/exportall"
                }
                console.log(exportqdata)
                console.log(exportqurl)
                this.$axios({
                    method:'post',
                    url: exportqurl,
                    data: exportqdata,
                    responseType: 'blob',
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    const link = document.createElement('a')
                    let blob = new Blob([res.data],{type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    let num = ''
                    for(let i=0;i < 10;i++){
                        num += Math.ceil(Math.random() * 10)
                    }

                    console.log(this.CertificateTemplateList)
                    console.log(this.formInline.cerindex)
                    link.setAttribute('download', this.getcccname(this.CertificateTemplateList[this.formInline.cerindex-1].cerfulldata.cccindex) + "_"+this.postquerydata.certificateno + '.xls')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.handleQuery('vehicleQueryForm')
                }.bind(this));

            },
            exportsingle (index){
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/exportsingle",
                    data:{id: this.tbldata[index].id},
                    responseType: 'blob',
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    const link = document.createElement('a')
                    let blob = new Blob([res.data],{type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    let num = ''
                    for(let i=0;i < 10;i++){
                        num += Math.ceil(Math.random() * 10)
                    }

                    console.log(this.CertificateTemplateList)
                    console.log(this.formInline.cerindex)
                    link.setAttribute('download',this.tbldata[index].vehicleno + '.xls')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    this.handleQuery('vehicleQueryForm')
                }.bind(this));
            },
            queryorgport (){
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/cerorgport/query",
                    data:{companyid: this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.orgportlist = res.data.data
                        console.log(this.orgportlist)
                        this.computeorgport(this.CertificateTemplateList[this.formInline.cerindex -1].cerfulldata.id)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            view (index){

            },
            remove (index) {
                this.$Modal.confirm({
                    content:'确定要删除该车辆数据吗？',
                    okText:'确定删除',
                    cancelText:'不删了',
                    onOk:()=>{
                        this.$axios({
                            method:'post',
                            url:"/api/vehicle/delete",
                            data:{id:this.tbldata[index].id},
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            console.log(res.data)
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})

                                this.handleQuery('vehicleQueryForm')
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));
                    },
                })

            },
            getcccname(id){
                console.log(id)
                console.log(this.ccclist)
                for(var i=0;i<this.ccclist.length;i++){
                    if(this.ccclist[i].id == id){
                        return this.ccclist[i].ccccode
                    }
                }
                return 'normal'
            },
            getstatustext(status){
                for(var item in statusdata){
                    if(statusdata[item].status == status){
                        return statusdata[item].text
                    }
                }
            },
            getstandardtext(standard){
                // if(standard==1)
                //     return '国标'
                // else
                //     return '企标'
                if(standard==2){
                  return '企标'
                }else if (standard==1){
                  return '国标21版'
                }else{
                  return '国标23版'
                }
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''
            }
        }
}
</script>
