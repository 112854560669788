<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem prop="vehicleno" label="整车编码:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.vehicleno">
                            </i-input>
                        </FormItem>
                    </td>
                    <td width="23%">
                        <FormItem prop="cnmodelno" label="中文型号" style="margin-bottom:5px;margin-top:10px">
                            <Select ref="cnmodelselect" v-model="formInline.cnmodelno" @on-change="onCnModelSelect">
                                <Option value='' :key=-1>请选择</Option>
                                <Option v-for="(item,index) in CnModelList" :value="item" :key="index">{{ item }}</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td align="left" width="23%">
                        <Row  index="">
                          <i-col span="18">
                            <FormItem prop="certificateno" label="合格证:" style="margin-bottom:5px;margin-top:10px">
    <!--                             <i-input type="text" v-model="formInline.certificateno">
                                </i-input> -->
                                <Select  v-model="formInline.cerindex" @on-change="onTmpSelect">
                                    <Option :value=0 :key=0>请选择</Option>
                                    <Option v-for="item in CertificateTemplateShowList" :value="item.cerdisplay.value" :key="item.cerdisplay.value">{{item.cerdisplay.label+"("+item.cerdisplay.standard+")" }}</Option>
                                </Select>
                            </FormItem>
                          </i-col>
                          <i-col span="6" style="padding-left:5px;padding-top:18px">
                              <Button type="success" v-bind:disabled="syncdisabled" size="small" @click="refreshCerlist" >{{synctext}}</Button>
                          </i-col>
                        </Row>
                    </td>

                    <td rowspan="2" colspan="2" align="right">
                        <div>
                            <Button type="primary" @click="handleQuery('vQueryForm')" >导入车辆查询</Button>
                            <Button  @click="handleReset('vQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:50px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">导入车辆列表</label>
            <Upload
                ref="uploadref"
                action="/api/vehicle/import/"
                :max-size= "51200"
                :data="{
                    companyid: this.formInline.companyid
                }"
                :format="['xls']"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :show-upload-list=false
                style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%"
                >
                <Button type="success" >导入车辆数据</Button>
            </Upload>
            <a style="margin-left: 20px;padding-top:24px;height:90%;line-height: 90%;float: left;" href="http://ddc.2kj.cn/ycxt/download/vinexceltemplate/vin_import.xls">excel导入模板下载</a>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>

        <!-- 整车编码列表-->
        <div style="clear: both;">
            <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" >
            <template slot-scope="{ row }" slot="vehicleno">
                <strong>{{ row.vehicleno }}</strong>
            </template>
            <template slot-scope="{ row }" slot="standard">
                {{ getstandardtext(row.standard) }}
            </template>
            <template slot-scope="{ row }" slot="madedate">
                {{ formatDate(row.madedate) }}
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <Button type="error" size="small" @click="remove(index)">删除</Button>
            </template>
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>

        </div>
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'ImportVehicle',
    props:['departid'],
    data () {
            return {
                formInline: {
                    companyid:'',
                    certificateno: '',
                    cnmodelno:'',
                    cerindex:0,
                    motorno: '',
                    vehicleno: '',
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:100
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '整车编码',
                        slot: 'vehicleno',
                    },
                    {
                        title: '合格证编号',
                        key: 'certificateno',
                    },
                    {
                        title: '电动机编号',
                        key: 'motorno',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '制造日期',
                        slot: 'madedate',
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right'
                    }
                ],
                tbldata: [],
                CertificateTemplateList:[],
                //显示在下拉框里面的模板数据
                CertificateTemplateShowList:null,
                //中文型号分类列表
                CnModelList:null,
                selectedTempIndex:0,
                ccclist:null,
                postquerydata:null,
                synctext:'同步数据',
                syncdisabled:false,
                syncdisabledsecondleft:30,
                tblselection:null,
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            //this.handleQuery('vQueryForm')
            console.log('departid:'+this.departid)
            this.makeTemplateList(true)
            console.log(this.CertificateTemplateList)
            console.log(typeof(this.CertificateTemplateList))
            if(this.CertificateTemplateList.length >0){
                //同步合格证数据后会自动同步ccc数据，所以if里面不需要同步ccc操作
                this.ccclist = this._self.$root.$store.state.CccList
                if(this.ccclist ==null){
                    this.refreshCcclist()
                }
            }


        },
        methods: {
            handleQuery(name) {
                this.postquerydata={
                        companyid:this.formInline.companyid,
                        certificateno:this.formInline.certificateno,
                        vehicleno:this.formInline.vehicleno,
                        pageNum:this.tblpageinfo.currentpage,
                        pageSize:this.tblpageinfo.pagesize
                    }
                console.log(this.formInline.vehicleno)
                console.log(this.formInline.cerindex+'--->'+this.formInline.certificateno)
                if(this.formInline.vehicleno !=null && this.formInline.vehicleno!=''){

                }
                else if(this.formInline.cerindex ==0 || this.formInline.cerindex == -1){
                    this.formInline.certificateno = null
                }
                else{
                    console.log(this.CertificateTemplateList)
                    this.postquerydata.certificateno = this.CertificateTemplateList[this.formInline.cerindex -1].cerfulldata.certificateno
                    this.formInline.certificateno = this.postquerydata.certificateno
                    console.log(this.formInline.cerindex+'--->'+this.formInline.certificateno)
                }


                for(var p in this.postquerydata){
                    if(this.postquerydata[p] == null){
                        delete this.postquerydata[p]
                    }
                }
                console.log(this.postquerydata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/queryimport",
                    data:this.postquerydata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        this.tbldata = res.data.data.data
                        this.tblpageinfo.total = res.data.data.totalnumber
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            refreshCerlist(){
                console.log(this._self.$root.$store.state.LogonInfo)
                      this.syncdisabled = true
                this.syncdisabledsecondleft = 30
                var countDown =setInterval(() =>{
                    if(this.syncdisabledsecondleft < 1){
                        this.syncdisabled = false
                        this.synctext = '同步数据'
                        clearInterval(countDown);
                    }else{
                        this.syncdisabled = true
                        this.synctext = '同步数据'+this.syncdisabledsecondleft+"s"
                        this.syncdisabledsecondleft = this.syncdisabledsecondleft -1
                    }
                },1000);
                console.log(this.syncdisabled)
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/query",
                    data:{
                        companyid:this._self.$root.$store.state.LogonInfo.companyid,
                        pageNum:1,
                        pageSize:200
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(rspdata)
                        this.CertificateTemplateList=[]
                        for(var item=0;item<rspdata.length;item++){
                            var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

                            cer.value=item+1
                            cer.certificateid=rspdata[item].id
                            cer.label=rspdata[item].certificatealias
                            cer.certificateno=rspdata[item].certificateno
                            cer.standard= (rspdata[item].standardtype == 1 ? '国标':'企标')
                            this.CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
                        }
                        this.$store.commit('changeCertificateList',this.CertificateTemplateList)
                        this.$Message.success({content:'合格证数据同步完成', duration:3})
                        this.formInline.cerindex =0
                        console.log(this.CertificateTemplateList)
                        this.makeTemplateList(false)
                        this.refreshCcclist()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            makeTemplateList(ifemptythenquery){
                this.CertificateTemplateList =this._self.$root.$store.state.CertificateList
                console.log('makeTemplateList:ifemptythenquery='+ifemptythenquery+'  this.CertificateTemplateList='+this.CertificateTemplateList)
                if(this.CertificateTemplateList ==null ||this.CertificateTemplateList =='' || this.CertificateTemplateList =='null'){
                    if(ifemptythenquery){
                        this.CertificateTemplateList ==[]
                        this.refreshCerlist()
                        return
                    }
                    else{
                        this.CertificateTemplateList ==[]
                    }
                }


                this.CnModelList=[]
                console.log(this.CertificateTemplateList)
                console.log(typeof(this.CertificateTemplateList))
                this.CertificateTemplateList.forEach(element => {
                    if(this.CnModelList.indexOf(element.cerfulldata.cnmodelno)<=-1){
                        //不存在
                        this.CnModelList.push(element.cerfulldata.cnmodelno)
                    }
                });

                this.filterTemplateList()
            },
            onTmpSelect(item){
                console.log(item)
                if(item==null ||item==''||item==0){
                    this.formInline.cerindex = 0
                    this.formInline.certificateno =null
                    return
                }

                if(item==-1){
                    this.refreshCerlist()
                    this.formInline.cerindex = 0
                }
            },
            onCnModelSelect(v){
                this.filterTemplateList()
            },
            filterTemplateList(){
                console.log(this.formInline.cnmodelno)
                if(this.formInline.cnmodelno==''){
                    this.CertificateTemplateShowList = this.CertificateTemplateList
                }
                else{
                    this.CertificateTemplateShowList =[]
                    this.CertificateTemplateList.forEach(element=>{
                        if(element.cerfulldata.cnmodelno == this.formInline.cnmodelno){
                            this.CertificateTemplateShowList.push(element)
                        }
                    })
                }
            },
            refreshCcclist(){
                this.$axios({
                    method:'post',
                    url:"/api/ccc/query",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.ccclist =rspdata
                        this.$store.commit('changeCccList',this.ccclist)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vQueryForm')
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.handleQuery('vQueryForm')
            },
            onselectionchange(s){
                this.tblselection = s
                console.log(this.tblselection)
            },
            view (index){

            },
            remove (index) {
                this.$Modal.confirm({
                    content:'确定要删除该车辆数据吗？',
                    okText:'确定删除',
                    cancelText:'不删了',
                    onOk:()=>{
                        this.$axios({
                            method:'post',
                            url:"/api/vehicle/delete",
                            data:{id:this.tbldata[index].id},
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            console.log(res.data)
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})

                                this.handleQuery('vQueryForm')
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));
                    },
                })

            },
            getcccname(id){
                console.log(id)
                console.log(this.ccclist)
                for(var i=0;i<this.ccclist.length;i++){
                    if(this.ccclist[i].id == id){
                        return this.ccclist[i].ccccode
                    }
                }
                return 'normal'
            },
            getstatustext(status){
                for(var item in statusdata){
                    if(statusdata[item].status == status){
                        return statusdata[item].text
                    }
                }
            },
            getstandardtext(standard){
                if(standard==1)
                    return '国标'
                else
                    return '企标'
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''
            },
            handleSuccess(response, file, fileList){
                console.log(response)
                if(response.resultCode ==0){
                    this.$Message.success({content:response.msg, duration:3})
                }
                else{
                    this.$Modal.error({
                        title:'导入车辆数据错误',
                        content:response.msg,
                        okText:'我知道了',
                        })
                }
                this.$Loading.finish();
            },
            handleFormatError(response, file, fileList){
                this.$Message.error({content:'文件格式错误，请重新选择文件!', duration:3})
                this.$Loading.error();
            },
            handleMaxSize(file, fileList){
                this.$Message.error({content:"文件过大，单个文件不能超过50M!", duration:3})
                this.$Loading.error();
            },
            beforeUpload(){
                this.$Loading.start();
                return true
            },
        }
}
</script>
