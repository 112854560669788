<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:blue;text-align:left">
            <span>{{title}}</span>
        </p>
        <div style="margin:0 auto;width:100%;display:flex;justify-content:center;align-items:center;" >
            <Form ref="FinanceClassifyForm" :model="formInline"   label-position="right" :rules="ruleInline" style="width:60%;">
                <FormItem prop="classify" label="账目类型:">
                    <Select v-model="formInline.type" >
                        <Option value=0 :key=0>请选择</Option> 
                        <Option value=1 :key=1>出账</Option> 
                        <Option value=2 :key=2>入账</Option>  
                    </Select>
                </FormItem>
                <FormItem prop="classify" label="账目分类:">
                    <i-input type="text" v-model="formInline.customercontact" :maxlength=30 show-word-limit></i-input>
                </FormItem>
            </Form>
        </div>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('FinanceClassifyForm')" >提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'FinanceClassifyEdit',
        props:['FinanceClassifydata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    type: this.FinanceClassifydata==null?'':this.FinanceClassifydata.type,
                    classify: this.FinanceClassifydata==null?'':this.FinanceClassifydata.classify,
                },
                ruleInline: {
                    FinanceClassifyname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    FinanceClassifyaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
            }
        },
        mounted(){
            console.log(this.FinanceClassifydata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.FinanceClassifydata ==null){
                            url = "/api/FinanceClassify/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/FinanceClassify/update"
                            postdata = this.extend({},this.formInline,{id:this.FinanceClassifydata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                               
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.type= this.FinanceClassifydata==null?'':this.FinanceClassifydata.type
                    this.formInline.classify= this.FinanceClassifydata==null?'':this.FinanceClassifydata.classify
                    
                    if(this.FinanceClassifydata ==null){
                        this.title ='录入新订单'
                    }
                    else{
                        this.title ='修改订单'
                    }
                }
            },
            oncancel(){
                this.$emit('CloseFinanceClassify','')
            },
            handleView () {
              console.log('handleView')
            }
        },
        components:{
        }
    }
</script>