<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline"  :rules="ruleInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="25%">
                        <FormItem  label="选择物料:" :label-width="120" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.materialtypeId">
                                <Option v-for="(item,index) in masterdata.materialTypes" :value="item.id" :key="index">{{item.materialtype}}</Option>
                              </Select>
                        </FormItem>
                    </td>
                    <td align="left" style="vertical-align: middle;">
                        <FormItem  label="是否已定价:" :label-width="120" prop="priceConfirmed" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.priceConfirmed">
                              <Option value="0" key="1">未定价</Option>
                              <Option value="1" key="2">已定价</Option>
                            </Select>
                        </FormItem>
                    </td> 
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="display: inline-flex;margin-top: 20px;">
            <div style="width:100%">
                <div style="height:40px;width:100%;">
                    <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">物料价格明细结果<span style="margin-left: 400px;">共计： <span style="color: red;">{{tblpageinfo.total}} </span> 条 <span style="color: red;">物料价格</span> 记录</span></label>  
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
                </div>
                
                <!-- 整车编码列表-->
                <div style="clear: both;">
                    <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" no-data-text="">
                    <template  slot-scope="{ row,index }" slot="price">
                        <div v-if="index == updatedata.activeindex">
                            <i-input type="text" v-model="updatedata.formInline.price" @on-blur="onbasepricekeyup"/>
                        </div>
                        <div v-else>
                            {{row.price==null? '-':row.price}}
                        </div>
                    </template>
                    <template  slot-scope="{ row,index }" slot="baseprice">
                        <div v-if="index == updatedata.activeindex">
                            <i-input type="text" v-model="updatedata.formInline.baseprice" @on-blur="onbasepricekeyup"/>
                        </div>
                        <div v-else>
                            {{row.baseprice==null? '-':row.baseprice}}
                        </div>
                    </template>   
                    <template slot-scope="{ row, index }" slot="action">
                        <div  class="opbar">
                            <Button type="info" v-if="updatedata.activeindex != index " @click="clickmodifyprice(index)">
                                修改价格
                            </Button>
                            <Button type="success" v-else  @click="clickmodifyprice(index)">
                                保存修改
                            </Button>
                            <Button v-if="updatedata.activeindex !=null && updatedata.activeindex==index" type="error" @click="updatedata.activeindex =null">放弃修改</Button>
                        </div>
                    </template>            
                    </Table>
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>
        
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

export default {
    name:'PartPrice',
    props:['departid'],
    data () {
            return {
                formInline: {
                    companyid:'',
                    materialtypeId:null,
                    priceConfirmed:null
                },
                updatedata:{
                    activeindex:null,
                    pricedata:null,
                    edittext:'修改价格',
                    formInline:{
                        baseprice:null,
                        price:null
                    }
                },
                ruleInline: {
                    modelids: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ]
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },
                    {
                        title: '供应商',
                        key: 'supplier',
                        resizable:true
                    },                 
                    {
                        title: '原价(元)',
                        slot: 'baseprice',
                        resizable:true
                    },
                    {
                        title: '采购价(元)',
                        slot: 'price',
                        resizable:true
                    },
                    {
                        title: '库存数量',
                        key: 'stock_num',
                        resizable:true
                    },
                    {
                        title: '采购中数量',
                        key: 'stock_num_inbuying',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        resizable:true
                    },
                ],
                tbldata: [],
                postquerydata:null,
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.getMaterialMasterData()    
        },
        methods: {
            handleQuery(name) {  

                console.log("零配件价格查询表单数据",this.formInline)
                this.postquerydata={
                    companyid:this.formInline.companyid,
                    materialtypeId: this.formInline.materialtypeId,
                    priceConfirmed: this.formInline.priceConfirmed,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                }  


                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log("零配件价格查询查询表单数据",this.postquerydata)
                this.$axios.post("/api/bomMaterialPrice/price/query",this.postquerydata)
                .then(function(res){
                    console.log("零配件价格查询查询结果",res.data.data)
                    if(res.data.resultCode ==0){
                        //let summary = res.data.data.summary
                        let detail = res.data.data

                        this.tbldata = detail.data
                        this.tblpageinfo.total = detail.totalnumber

                        this.usedwatertype = this.formInline.eventType
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.formInline.material =[]
                this.formInline.priceConfirmed = null
                this.tbldata=[]
                this.tblpageinfo.total = 0
            },
            getMaterialMasterData(){
                this.$axios.post('/api/material/masterdata/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                        .then(function(res){
                        if(res.data.resultCode ==0){
                            //this.$Message.success(res.data.msg)
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = res.data.data
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = i
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+') '+ mdata.materialInfos[j].supplier
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
            onbasepricekeyup(){
                if(this.updatedata.formInline.baseprice !=null){
                    this.updatedata.formInline.baseprice=this.updatedata.formInline.baseprice.replace(/[^\d\.]/g,'')
                }
                if(this.updatedata.formInline.price !=null){
                    this.updatedata.formInline.price=this.updatedata.formInline.price.replace(/[^\d\.]/g,'')
                }
            },
            clickmodifyprice(index){
                if(this.updatedata.activeindex == null){
                    
                    this.updatedata.pricedata = this.tbldata[index]
                    console.log("准备修改物料价格数据",this.updatedata.pricedata)
                    this.updatedata.formInline.price = this.updatedata.pricedata.price
                    this.updatedata.formInline.baseprice = this.updatedata.pricedata.baseprice
                    this.updatedata.activeindex = index
                }
                else{
                    this.updatedata.pricedata.price =this.updatedata.formInline.price
                    this.updatedata.pricedata.baseprice =this.updatedata.formInline.baseprice

                    let postdata = JSON.parse(JSON.stringify(this.updatedata.pricedata))
                    postdata = this.removeEmptyChilds(postdata)
                    this.$axios.post("/api/bomMaterialPrice/price/update",postdata)
                    .then(function(res){
                        console.log("零配件价格更新结果",res.data.data)
                        if(res.data.resultCode ==0){
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.updatedata.activeindex = null
                            this.handleQuery('vehicleQueryForm')
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this)); 
                }
            }
        },
        computed:{

        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>