<template>
    <Modal ref="accountModal" :value="show" width="30%" @on-cancel="oncancel" :mask-closable=false>
        <p slot="header" style="color:#f60;text-align:left">
            <span >创建账号</span>
        </p>
        <Form ref="SubAccountForm" :model="formInline" :label-width="120" label-position="right" :rules="ruleInline">
            <Row>
              <i-col span="24">
                <FormItem  prop="username" label="账号名称:">
                    <i-input v-if="userid>=0" type="text" v-model="username"  :disabled=true  :maxlength="30" show-word-limit ></i-input>
                    <i-input v-else type="text" v-model="formInline.username"  :maxlength="30" show-word-limit :autofocus=true></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="password" label="账号密码">
                    <i-input type="password" v-model="formInline.password" password></i-input>
                </FormItem>                  
              </i-col>
            </Row>
            <Row>                
                <i-col span="24">
                    <FormItem  prop="contact" label="归属人:">
                        <i-input v-if="userid>=0" type="text" v-model="contact"  :disabled=true  :maxlength="30" show-word-limit ></i-input>
                        <i-input v-else type="text" v-model="formInline.contact"  :maxlength="30" show-word-limit :autofocus=true></i-input>
                    </FormItem>
                </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('SubAccountForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'recharge',
        props:['show','userid','companyid','username','deptcode'],
        data () {
            const validatedept = (rule, value, callback) => {
                if (value == '') {
                    return callback(new Error('请选择部门'))
                } else {
                    callback()
                }
            }
            return {
                formInline: {
                    username: this.username,
                    password:'',
                    contact: null
                },
                ruleInline: {
                    username: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { pattern: /^[a-zA-Z][a-zA-Z0-9]{4,15}$/, message: '需以字母开头的4-16位英文字母和数字组合', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
                    ]
                },
                departmentList:null
            }
        },
        mounted (){         
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid) =>{
                    if(valid){
                        var posturl=''
                        var postdata=null

                        posturl ="/api/adminuser/createSubAdminAccount"
                        postdata=this.formInline

                        this.$axios({
                            method:'post',
                            url:posturl,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success({content:res.data.msg, duration:3})
                                this.formInline.password=''
                                this.formInline.deptid = ''
                                this.$refs['accountModal'].close()
                            }
                            else{
                                this.$Message.error({content:res.data.msg, duration:3})
                            }
                        }.bind(this));   
                    }
                    else{
                        this.$Message.error("请输入正确的内容")
                    }
                })
            },
            oncancel(){
                this.$emit('CloseAccountEdit','')
            }
        }
    }
</script>
