<!-- 整车出入库功能-->
<template>
    <div class="StockInOut">
        <Row style="background:#eee;padding:20px">
            <i-col span="11">
                <Card :bordered="false" style="height:860px">
                    <p slot="title">整车编码列表</p>
                    <i-input type="textarea" v-model="vehicleNoList" :maxlength="2000" :rows="27" 
                    placeholder="
                    批量车架号录入，以分号(;)或换行进行分割，
                    比如：
                    687345909087880;687345909087881;687345909087882 
                    或 
                    687345909087880
                    687345909087881
                    687345909087882"/>
                </Card>
            </i-col>
            <i-col span="12" offset="1">
                <div  style="height:800px;background: white;padding:10px;">
                    <Card shadow style="height:360px;background: rgb(253, 248, 224);">
                        <p slot="title" style="text-align: left;color: rgb(73, 53, 253);">
                                <strong>第一步：选择销售订单</strong>
                        </p>
                        <a href="#" slot="extra" @click.prevent="querySaleOrder">
                            刷新订单
                        </a>
                        <div style="background: white;overflow:auto;display:block">
                            <Table :columns="saleorder.tblcolumns" :data="saleorder.tbldata" size="small" stripe border height="280" >
                                <template slot-scope="{ row,index }" slot="head">
                                    <div v-if="index == saleorder.selectedindex">
                                       <span style="color: red;"> <strong>√ {{index+1}}</strong></span>
                                    </div>
                                    <div v-else>
                                        {{index+1}}
                                    </div>
                                </template>
                                <template slot-scope="{ row }" slot="ordercode">
                                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                                </template>
                                <template slot-scope="{ row, index }" slot="action" >
                                    <div  class="opbar">
                                        <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>             
                                        <Button type="info" size="small" ghost @click="setorderselected(index)" >选择发货</Button>    
                                    </div>
                                </template>
                                <template slot-scope="{ row,index }" slot="status">
                                        <p style="font-size:12px">
                                            <span>{{getmstatustext(row.mstatus)}}</span> | 
                                            <span style="background: rgb(252, 2, 2);" v-if="row.fstatus == 0 || row.fstatus ==null">未付款</span>
                                            <span style="background: rgb(243, 247, 3);" v-if="row.fstatus == 1">已付定</span>
                                            <span style="background: rgb(185, 248, 243);" v-if="row.fstatus == 2">已全款</span>
                                        </p>
                                </template>
                                <template slot-scope="{ row }" slot="expectdate">
                                        <p style="font-size:12px">{{new Date(row.expectdate).Format("yyyy-MM-dd")}}</p>
                                </template>
                            </Table>
                        </div>
                    </Card>
                    <div id="orderinfodiv" style="margin: 20px 0px;height:150px;border: 1px solid rgb(235, 239, 248);background: rgb(235, 239, 248);">
                        <div style="padding:10px 0px;width: 100%;text-align: left;"><strong>订单相关信息:</strong></div>
                        <table align="left" width="100%" border="0px solid black" style="text-align: left;height: 65%;background: white;" v-if="saleorder.selectedindex !=null">
                            <tr style="padding: 20px 0px;height: 33%;">
                                <td style="text-align: right;"><strong>收货单位:</strong></td>
                                <td style="text-align: left;padding-left: 10px;">{{saleorder.selectedOrderinfo.customer}}</td>
                                <td style="text-align: right;"><strong>联系人及电话:</strong></td>
                                <td style="text-align: left;padding-left: 10px;">{{saleorder.selectedOrderinfo.contact+'  '+saleorder.selectedOrderinfo.phone}}</td>
                            </tr>
                            <tr style="padding: 20px 0px;height: 33%;">
                                <td style="text-align: right;"><strong>地址:</strong></td>
                                <td style="text-align: left;padding-left: 10px;" colspan="3">{{saleorder.selectedOrderinfo.city+'  '+saleorder.selectedOrderinfo.address}}</td>
                            </tr>
                            <tr style="padding: 20px 0px;height: 33%;">
                                <td style="text-align: right;"><strong>下单日期:</strong></td>
                                <td style="text-align: left;padding-left: 10px;">{{saleorder.selectedOrderinfo.createtime}}</td>
                                <td style="text-align: right;"><strong>交付日期:</strong></td>
                                <td style="text-align: left;padding-left: 10px;">{{saleorder.selectedOrderinfo.expectdate}}</td>
                            </tr>
                        </table>
                    </div>
                    <Card shadow style="margin-top: 20px;height:220px;background: rgb(253, 248, 224);">
                        <p slot="title" style="text-align: left;color: rgb(73, 53, 253);">
                            <strong>第二步：执行出库</strong>
                        </p>
                        <div style="position:absolute;bottom:30px;width:100%;" align="left">
                            <div style="width:35%;height:130px;float:left;">
                                <Button type="warning" @click="handleOut()" style="height:80px;width:100%">发货出库</Button>
                                <Button type="info" ghost @click="handlePrint()" style="height:40px;;width:100%;margin-top:10px">打印出库贴</Button>
                            </div>
                            <div style="float:right;width:63%;margin-top:5px">
                                <RadioGroup v-model="city" size="small">
                                    <Radio label="上海区域" border></Radio>
                                    <Radio label="全国其它区域" border></Radio>
                                </RadioGroup>           
                                <i-input v-model="address" placeholder="详细地址" style="margin-top:10px;width:90%"></i-input>
                            </div>
                        </div>
                    </Card>
                </div>
            </i-col>
        </Row>
        <Modal v-model="showoutconfirm" title="确定要出库吗？" @on-ok="confirmout">共计{{postvinlist.length}}个车辆编码即将发货出库</Modal>
        <Modal v-model="showprint" title="出库贴打印" width="800px" :mask-closable="false">
          <stockprint />
        </Modal>  
        <Modal v-model="showresult" width="600px" height="90%" scrollable>
            <p slot="header" style="color:#f60;text-align:left">
                <span >库存操作结果</span>
            </p>
            <div style="display:block;  height:450px;" v-if="showresult">
                <div v-if="stockresult.failednum >0" style="margin:10px 0px">
                    <span style="color: red;">出库失败!</span>
                </div>
                <table  border="1px solid black" cellspacing="0" style="margin-top: 20px;width: 100%;text-align: left;">
                    <tr>
                        <th style="width: 40%;">整车编码</th>
                        <th style="width: 40%;">结果</th>
                    </tr>
                    <tr v-for="(item,key) in stockresult.failedList" style="background: rgb(240, 203, 203);">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                    <tr v-if="stockresult.failednum ==0" v-for="(item,key) in stockresult.successList">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                </table>
            </div>
            
        </Modal>
        <Modal v-model="saleorder.showdetail" title="销售订单详情" width="90%">
            <Table :columns="sdetail.tblcolumns" :data="sdetail.tbldata" v-if="saleorder.readfinished"></Table>
            <div v-else>
                正在读取数据...
            </div>
        </Modal>
    </div>
</template>

<script>
//import cityList from '@/components/zsjs/citylist.js'
import StockOutPrint from './StockOutPrint.vue'
export default {
    name:'StockOut',
    data() {
        return {
            vehicleNoList: '',
            postvinlist:[],
            city: '全国其它区域',
            address:'',
            stockresult:null,
            showresult:false,
            showinconfirm:false,
            showoutconfirm:false,
            showprint:false,
            saleorder:{
                showdetail:false,
                readfinished:false,
                selectedindex:null,
                selectedOrderinfo:null,
                tblcolumns: [
                    {
                        title: '序号',
                        width: 60,
                        slot: "head",
                        align: 'center'
                    },
                    {
                        title: '订单号',
                        slot: 'ordercode',
                        resizable:true
                    },
                    {
                        title: '订单状态',
                        slot: 'status',
                        resizable:true
                    },        
                    {
                        title: '预交付日期',
                        slot: 'expectdate',
                        resizable:true
                    },                                        
                    {
                        title: '客户单位',
                        key: 'customer',
                        resizable:true
                    },                  
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        resizable:true
                    }
                    
                ],
                tbldata: [],
            },
            sdetail:{
                tbldata:[],
                tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '产品型号',
                        key: 'productname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                        resizable:true
                    },    
                    {
                        title: '数量',
                        key: 'productcount',
                        resizable:true
                    },      
                    {
                        title: '已发货数量',
                        key: 'productcountFinished',
                        resizable:true
                    },  
                ]
            }
        }
    },
    mounted(){
        this.querySaleOrder()
    },
    methods:{
        formatdata(){
            var inputdata=this.vehicleNoList.replace(new RegExp("\n","gm"),';').replace(new RegExp("；","gm"),';').replace(new RegExp(";;","gm"),';').replace(new RegExp(";;;","gm"),';')
            var arr=inputdata.split(";")
            Array.prototype.remove = function(val) {
                var index = this.indexOf(val);
                if (index > -1) {
                    this.splice(index, 1);
                }
            }
            arr.remove("")    
            this.postvinlist =[]
            for(let item=0;item<arr.length;item++){

                var itemdata=arr[item]
                if(itemdata.indexOf("，") != -1){
                    var no= (arr[item].split("，"))[1]
                    this.postvinlist.push(no)
                }
                else{
                    this.postvinlist.push(arr[item])
                }
            }
        },
        setorderselected(index){
            this.saleorder.selectedindex = -1
            
            //this.saleorder.tbldata = JSON.parse(JSON.stringify(this.saleorder.tbldata))
            this.saleorder.selectedindex = index
            this.saleorder.selectedOrderinfo = JSON.parse(JSON.stringify(this.saleorder.tbldata[index]))
        },
        handleOut(){
            if(this.vehicleNoList==null || this.vehicleNoList.length==0){
                this.$Message.error('请输入整车编码')
                return
            }
            this.formatdata()
            console.log(this.postvinlist)
            this.showoutconfirm = true
        },
        confirmout(){
            if(this.saleorder.selectedindex ==null){
                this.$Modal.error({
                    content:'请先选择销售订单！'
                })
                return
            }
            var postdata= {
                stocktype:100,
                orderid: this.saleorder.tbldata[this.saleorder.selectedindex].id,
                ordercode: this.saleorder.tbldata[this.saleorder.selectedindex].ordercode,
                vehiclenos:this.postvinlist,
                receiverarea:this.city,
                receiveraddress:this.address
            }

            this.$axios.post("/api/vehicleIn/stockout",postdata)
            .then(function(res){
                if(res.data.resultCode ==0){
                    this.stockresult = res.data.data
                    console.log("库存操作结果",this.stockresult)
                    if(this.stockresult.failednum ==0){
                        this.$Message.success({content:res.data.msg, duration:3, closable:true})
                        this.querySaleOrder()
                    }

                    this.showresult = true
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));            
            //this.$Message.success('入库成功')
        },
        handlePrint(){
            this.showprint = true
        }  ,
        querySaleOrder(){
            let postdata={
                //只显示已审核订单
                mstatus:1,
                companyid: this._self.$root.$store.state.LogonInfo.companyid
            }
            this.$axios.post("/api/sale/order/queryall",postdata,)
            .then(function(res){
                if(res.data.resultCode ==0){
                    var rspdata = res.data.data
                    console.log("销售订单查询结果",res.data.data)
                    if(rspdata == null){
                        this.saleorder.tbldata=[]
                    }
                    else{
                        this.saleorder.tbldata =rspdata
                    }
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));
        },
        queryOrderDetail(index) {
            this.$axios.post(
            "/api/sale/orderdetail/query",{
                orderid: this.saleorder.tbldata[index].id
            })
            .then(function(res){
                if(res.data.resultCode ==0){
                    var rspdata = res.data.data.data
                    if(rspdata != null){
                        var tmptbldata = JSON.parse(JSON.stringify(this.saleorder.tbldata))
                        tmptbldata[index].products =rspdata
                        this.saleorder.tbldata = JSON.parse(JSON.stringify(tmptbldata))

                        var tmptbldata = JSON.parse(JSON.stringify(this.saleorder.tbldata))
                        this.saleorder.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                        this.sdetail.tbldata = this.saleorder.tbldata[index].products
                        this.saleorder.readfinished = true
                    }
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        viewdetail(index){
            this.saleorder.readfinished = false
            this.saleorder.showdetail = true

            if(this.saleorder.tbldata[index].products == null || this.saleorder.tbldata[index].products.length==0){
                this.queryOrderDetail(index)
            }
            else{
                var tmptbldata = JSON.parse(JSON.stringify(this.saleorder.tbldata))
                this.saleorder.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                this.sdetail.tbldata = this.saleorder.tbldata[index].products
                this.saleorder.readfinished = true
            }
        },
        getmstatustext(mstatus){
            //订单状态 0-待审核  1-已审核  2-已驳回  3-已发货  4-已完成 
            if(mstatus == 0 || mstatus==null){
                return '待审核'
            }
            else if(mstatus == 1){
                return '已审核'
            }
            else if(mstatus == 2){
                return '已驳回'
            }
            else if(mstatus == 3){
                return '已发货'
            }
            else if(mstatus == 4){
                return '已完成'
            }
        },
        getfstatustext(fstatus){
            //财务状态：0-未付定金  1-已付定金 2- 已付全款
            if(fstatus == 0 || fstatus == null){
                return '未付定'
            }
            else if(fstatus == 1){
                return '付定待确认'
            }
            else if(fstatus == 2){
                return '全款待确认'
            }
        },
        rowClassName (row, index) {
            if (index == this.saleorder.selectedindex) {
                return 'demo-table-warning-row';
            }
            return '';
        }
    },
    components: {
        stockprint:StockOutPrint,
    },
}
</script>

<style scoped>
    .StockInOut{

    }
    .ivu-card-body .ivu-card-head{
        background: lightsalmon;
    }

    .ivu-table .demo-table-warning-row td{
        background-color: lightblue;
        color: #fff;
    }
</style>