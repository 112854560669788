<template>
    <Modal :value="show" width="60%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="customerForm" :model="formInline" :label-width="140" label-position="right" :rules="ruleInline" :disabled="action=='view'">
            <Row  :gutter="20" type="flex" justify="start" align="middle">
                <i-col span="7">
                    <FormItem prop="customerName" label="公司名称:">
                        <i-input type="text" v-model="formInline.customerName" :maxlength=30 show-word-limit :disabled="action!='add'"></i-input>
                    </FormItem>
                </i-col>
                <i-col span="7" >
                    <FormItem prop="customerContact" label="联系人:">
                        <i-input type="text" v-model="formInline.customerContact" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                <i-col span="7" >
                    <FormItem prop="customerPhone" label="联系人电话:">
                        <i-input type="text" v-model="formInline.customerPhone" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>              
                </Row>
                <Row :gutter="20" type="flex" justify="start" align="middle">
                    <i-col span="7" >
                        <FormItem  label="收货省份:">
                            <Select v-model="formInline.customerProvince" @on-change="onprovinceselect">
                                <Option v-for="(item,index) in allcitylist" :value="item.provice" :key="index">{{item.provice}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="7" >
                        <FormItem  label="收货城市:">
                            <Select v-model="formInline.customerCity" >
                                <Option v-for="(item,index) in showcitylist" :value="item" :key="index">{{item}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="10">
                        <FormItem  label="详细地址:">
                            <i-input type="text" v-model="formInline.customerAddr" :maxlength=30   placeholder='详细地址'></i-input>
                        </FormItem>
                    </i-col>
                </Row>
                <Row :gutter="20" type="flex" justify="start" align="middle">
                    <i-col span="7" >
                        <FormItem  label="客户星级:">
                            <Select v-model="formInline.customerStar" >
                                <Option value="1" key="1">☆</Option>
                                <Option value="2" key="2">☆☆</Option>
                                <Option value="3" key="3">☆☆☆</Option>
                                <Option value="4" key="4">☆☆☆☆</Option>
                                <Option value="5" key="5">☆☆☆☆☆</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="10">
                        <FormItem  label="备注:">
                            <i-input type="text" v-model="formInline.note" :maxlength=200   placeholder='备注'></i-input>
                        </FormItem>
                    </i-col>
                </Row>
                <div v-if="showsubaddredit==false && action!='view'" style="padding: 15px 0px;">
                    <Button type="success" size="large"  @click="showsubaddredit=true">添加备用地址</Button>
                </div>
                <Row :gutter="20" v-if="showsubaddredit"  style="border: 1px solid lightblue;padding: 15px 0px;" type="flex" justify="start" align="middle">
                    <i-col span="7"  style="padding-top: 23px;">
                        <FormItem  label="收货省份:">
                            <Select v-model="subFormInline.customerProvince" @on-change="onsubprovinceselect">
                                <Option v-for="(item,index) in allcitylist" :value="item.provice" :key="index">{{item.provice}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="7"  style="padding-top: 23px;">
                        <FormItem  label="收货城市:">
                            <Select v-model="subFormInline.customerCity" >
                                <Option v-for="(item,index) in subshowcitylist" :value="item" :key="index">{{item}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="7" style="padding-top: 23px;">
                        <FormItem  label="详细地址:">
                            <i-input type="text" v-model="subFormInline.customerAddr" :maxlength=30   placeholder='详细地址'></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="3">
                        <Button type="primary" @click="saveSubAddr">保存</Button>
                    </i-col>
                </Row>
                <div>
                    <h3>备用地址:</h3>
                </div>
                <Table :row-class-name="rowClassName" :columns="tblcolumns" :data="tbldata" border size="small" width="100%" height="300">
                    <template slot-scope="{ row }" slot="materialname">
                        <strong>{{ row.materialname }}</strong>
                    </template>
                    <template slot-scope="{ row, index }" slot="action" >
                        <Button type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                        <Poptip
                        confirm
                        title="确定要删除该备选地址吗?"
                        width="250px"
                        @on-ok="tbldata.splice(index,1)"
                        placement="left"
                        style="float:right"
                        >
                        <Button type="error" size="small" ghost  >删除</Button>   
                    </Poptip>
                    </template>                            
                </Table>  
        </Form>
        <div slot="footer">
            <Button type="error" v-if="action=='view'" size="large" long  @click="oncancel">关闭</Button>
            <Button type="error" v-else size="large" long  @click="handleSubmit('customerForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    import {getcitylist} from '@/zsjs/citylist.js'
    export default {
        name:'CustomerEdit',
        props:['customerdata','show','action'],
        data () {
            return {
                title:'',
                allcitylist: [],
                showcitylist:[],
                subshowcitylist:[],
                showsubaddredit:false,
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    customerName: this.customerdata==null?'':this.customerdata.customerName,
                    customerProvince: this.customerdata==null?'':this.customerdata.customerProvince,
                    customerCity: this.customerdata==null?'':this.customerdata.customerCity,
                    customerAddr: this.customerdata==null?'':this.customerdata.customerAddr,
                    customerContact: this.customerdata==null?'':this.customerdata.customerContact,
                    customerPhone: this.customerdata==null?'':this.customerdata.customerPhone,
                    note: this.customerdata==null?'':this.customerdata.note,
                    customerStar: this.customerdata==null?'3':this.customerdata.customerStar,
                    customerOtherAddrs:this.customerdata==null?'':this.customerdata.customerOtherAddrs,
                },
                subFormInline:{
                    customerProvince: this.customerdata==null?'':this.customerdata.customerProvince,
                    customerCity: this.customerdata==null?'':this.customerdata.customerCity,
                    customerAddr: this.customerdata==null?'':this.customerdata.customerAddr,
                },
                ruleInline: {
                    customerName: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerProvince: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerCity: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerAddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerContact: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerPhone: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    customerStar: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '省份',
                        key: 'customerProvince',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '城市',
                        key: 'customerCity',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '地址',
                        key: 'customerAddr',   
                        width:160,
                        resizable:true         
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width:150
                    }
                ],
                tbldata:[],
            }
        },
        mounted(){
            this.allcitylist = getcitylist()
            console.log("接收传递的销售客户数据",this.customerdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.tbldata.length >0){
                            this.formInline.customerOtherAddrs = JSON.stringify(this.tbldata)
                        }
                        if(this.customerdata ==null){
                            url = "/api/sale/customer/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/sale/customer/update"
                            postdata = this.extend({},this.formInline,{id:this.customerdata.id})
                        }

                        this.$axios.post(url,postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                                eventBus.$emit('customerupdate',null)
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.customerName= this.customerdata==null?'':this.customerdata.customerName
                    this.formInline.customerProvince = this.customerdata==null?'':this.customerdata.customerProvince
                    //this.formInline.customerCity = this.customerdata==null?'':this.customerdata.customerCity
                    this.formInline.customerAddr= this.customerdata==null?'':this.customerdata.customerAddr
                    this.formInline.customerContact = this.customerdata==null?'':this.customerdata.customerContact
                    this.formInline.customerPhone = this.customerdata==null?'':this.customerdata.customerPhone
                    this.formInline.note = this.customerdata==null?'':this.customerdata.note
                    this.formInline.customerStar = this.customerdata==null?'3': this.customerdata.customerStar+''
                    this.formInline.customerOtherAddrs =this.customerdata==null?'':this.customerdata.customerOtherAddrs
                    console.log("编辑客户信息页面接收数据",this.customerdata)
                    console.log("编辑客户信息页面数据",this.formInline)
                    if(this.formInline.customerOtherAddrs != ''){
                        this.tbldata = JSON.parse(this.formInline.customerOtherAddrs)
                    }
                    else{
                        this.tbldata = []
                    }

                    if(this.formInline.customerProvince !=''){
                        this.onprovinceselect(this.formInline.customerProvince)
                        this.formInline.customerCity = this.customerdata==null?'':this.customerdata.customerCity
                    }

                    if(this.action =='add'){
                        this.title ='新增客户信息'
                    }
                    else if(this.action =='modify'){
                        this.title ='修改客户信息'
                    }
                    else{
                        this.title ='查看客户信息'
                    }
                }
            },
            oncancel(){
                this.$emit('Closecustomer','')
            },
            onprovinceselect(province){
                console.log(province)
                if(province ==''){
                    this.showcitylist =[]
                    return
                }
                for(var i=0;i<this.allcitylist.length;i++){
                    if(this.allcitylist[i].provice == province){
                        this.showcitylist= this.allcitylist[i].city
                        return
                    }
                }
            },
            onsubprovinceselect(province){
                console.log(province)
                if(province ==''){
                    this.subshowcitylist =[]
                    return
                }
                for(var i=0;i<this.allcitylist.length;i++){
                    if(this.allcitylist[i].provice == province){
                        this.subshowcitylist= this.allcitylist[i].city
                        return
                    }
                }
            },
            modify(index){
                this.subFormInline = this.tbldata[index]
                this.tbldata.splice(index,1)
                this.showsubaddredit = true
            },
            saveSubAddr(){
                this.tbldata.push(this.subFormInline)
                this.showsubaddredit = false
            },
            rowClassName (row, index) {
                if (index == this.itemmodifyindex && this.isitemmodify) {
                    return 'demo-table-warning-row';
                }
                return '';
            },
        }
    }
</script>
<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #b6f7b3;
        color: rgb(54, 12, 240);
    }
</style>