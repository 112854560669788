import ApiService from '@/config/apiservice'

/**
 * 记录访问信息
 * @param {*} data
 */
//export const addCertificateTemplate= data =>fetch('/certificatetemplate/addnew',data,'POST')
//export const updateCertificateTemplate= data =>fetch('/certificatetemplate/update',data,'POST')
export const certificateService = {
	create(data) {
		return ApiService.post("/api/certificatetemplate/addnew", data);
	},
	update(data) {
		return ApiService.post("/api/certificatetemplate/update", data);
	},
	getpic(picclass,data){
		return ApiService.post(`/api/${picclass}/update`, data);
	},
	getwatermarkpic(data){
		return ApiService.post(`/api/watermark/query`, data);
	},
	getsealpic(data){
		return ApiService.post(`/api/seal/query`, data);
	},
	grab(data){
		return ApiService.post(`/api/certificatetemplate/grab`, data);
	},
};
