 <template>
    <div style="display:none">
        <p>this is store component</p>
    </div>
 </template>
<script>
export default {
  name: 'store',
  data () {
    return {
      age: 20,
      sex: 'male'
    }
  },
  mounted () {
    this.onmonitor()
  },
  methods: {
    onmonitor: function () {
      this.$on('update', (res) => {
        this.updatedata()
        console.log(this.age)
      })
    },
    updatedata: function () {
      this.age++
    }
  }
}
</script>
