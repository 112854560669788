<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="sealForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="sealname" label="印章名称:">
                    <i-input type="text" v-model="formInline.sealname" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="sealaddr" label="印章图片">
                    <div class="demo-upload-list" >
                      <template >
                          <img ref="shimg" :src="'/api/files/get?imgpath='+this.formInline.sealaddr">
                          <div class="demo-upload-list-cover">
                              <Icon type="ios-eye-outline" @click.native="handleView()"></Icon>
                              <Icon type="ios-trash-outline" @click.native="handleRemove()"></Icon>
                          </div>
                      </template>
                    </div>
                    <Upload
                      ref="uploadshw"
                      :show-upload-list="false"
                      :data="{
                        companyid: this.formInline.companyid
                      }"
                      :on-success="onuploadsuccesssh"
                      :on-error="onuploaderrorsh"
                      :format="['png']"
                      :max-size="200"
                      :on-exceeded-size="onpicexceed"
                      :on-format-error="onpicformaterror"
                      type="drag"
                      action="/api/files/seal/upload"
                      style="display: inline-block;width:58px;">
                      <div style="width: 58px;height:58px;line-height: 58px;">
                          <Icon type="ios-camera" size="20"></Icon>
                      </div>
                    </Upload>
                    <P style="color:red">合格证检验印章png透明背景图片(大小不超过200k)</P>                    
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('sealForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'sealEdit',
        props:['sealdata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    sealname: this.sealdata==null?'':this.sealdata.sealname,
                    sealaddr: this.sealdata==null?'':this.sealdata.sealaddr,
                },
                ruleInline: {
                    sealname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    sealaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.sealdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.sealdata ==null){
                            url = "/api/seal/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/seal/update"
                            postdata = this.extend({},this.formInline,{id:this.sealdata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                               
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.sealname= this.sealdata==null?'':this.sealdata.sealname
                    this.formInline.sealaddr= this.sealdata==null?'':this.sealdata.sealaddr
                    
                    if(this.sealdata ==null){
                        this.title ='新增水印数据'
                    }
                    else{
                        this.title ='修改水印数据'
                    }
                }
            },
            oncancel(){
                this.$emit('Closeseal','')
            },
            onuploadsuccesssh(response, file){
              console.log(response)
              console.log(file)
              this.formInline.sealaddr = response.data.filepath
              this.$refs['shimg'].src ="/api/files/get?imgpath="+this.formInline.sealaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorsh(response, file){
              console.log(response)
              console.log(file)
            },
            onpicexceed(f){
              this.$Message.error({content:'上传失败，文件超出200k！',duration:3})
            },
            onpicformaterror(f){
              this.$Message.error({content:'上传失败，文件格式需png透明背景图片！',duration:3})
            },
            handleView () {
              console.log('handleView')

              console.log(this.formInline.sealaddr)
              this.viewpicurl = this.formInline.sealaddr
              this.showimg = true
            },
            handleRemove () {
              this.$axios({
                method:'post',
                url:'/api/files/delete',
                data:{path:this.formInline.sealaddr},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
              }).then(function(res){
                if(res.data.resultCode ==0){
                  this.$Message.success({content:res.data.msg, duration:3, closable:true})
                  this.formInline.sealaddr =null
                  this.$refs['shimg'].src =null
                }
                else{
                  this.$Message.error({content:res.data.msg, duration:3})
                }
              }.bind(this));
            },
        }
    }
</script>
<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }