import { baseUrl } from './env'
import Vue from "vue";
//import Vue from "@/utils/install"
import store from '@/store'
import '@/plugins/axios'
import { Modal,Message } from "view-design";
import axios from 'axios'

const ApiService = {
  init() {
  axios.defaults.baseURL = baseUrl;
  Vue.axios.defaults.baseURL = baseUrl;

  Vue.axios.defaults.timeout = 90000;   // 超时时间，90秒
  Vue.axios.defaults.headers ={'Content-Type': 'application/x-www-form-urlencoded'};
    Vue.axios.defaults.transformRequest =function(obj) {
    var str = [];
    for(var p in obj){
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
}

	Vue.axios.interceptors.request.use(function (config) {
		　　//这里经常搭配token使用，将token值配置到tokenkey中，将tokenkey放在请求头中
    　　config.headers['Authorization'] = store.state.AccessToken;
			  config.headers['clientInfo'] = JSON.stringify({ip:sessionStorage.getItem('ip'),agent:navigator.userAgent.toLocaleLowerCase(),resolution:window.screen.width+'x'+window.screen.height})
		　　return config;
		}, function (error) {
		  // Do something with request error
		  console.log("request error拦截器")
		  return Promise.reject(error);
		});

		// 添加一个响应拦截器
		axios.interceptors.response.use(function (response) {
		  // Do something with response data
		  //console.log(response)
		  switch (response.data.resultCode)
		  {
			case 401:
			case 205:
			  {
				router.replace({path:"/"})
				break;
				//router.replace(path: 'login',
				//query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面)
			  }
		  }
		  return response;
		}, function (error) {
		  // Do something with response error
		  console.log(error)
      Message.error({content:'网络异常!!!!',duration:6})
		  return Promise.reject(error);
		});
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const TagsService = {
  get() {
    return ApiService.get("tags");
  }
};

export const ArticlesService = {
  query(type, params) {
    return ApiService.query("articles" + (type === "feed" ? "/feed" : ""), {
      params: params
    });
  },
  get(slug) {
    return ApiService.get("articles", slug);
  },
  create(params) {
    return ApiService.post("articles", { article: params });
  },
  update(slug, params) {
    return ApiService.update("articles", slug, { article: params });
  },
  destroy(slug) {
    return ApiService.delete(`articles/${slug}`);
  }
};

export const CommentsService = {
  get(slug) {
    if (typeof slug !== "string") {
      throw new Error(
        "[RWV] CommentsService.get() article slug required to fetch comments"
      );
    }
    return ApiService.get("articles", `${slug}/comments`);
  },

  post(slug, payload) {
    return ApiService.post(`articles/${slug}/comments`, {
      comment: { body: payload }
    });
  },

  destroy(slug, commentId) {
    return ApiService.delete(`articles/${slug}/comments/${commentId}`);
  }
};

export const FavoriteService = {
  add(slug) {
    return ApiService.post(`articles/${slug}/favorite`);
  },
  remove(slug) {
    return ApiService.delete(`articles/${slug}/favorite`);
  }
};
