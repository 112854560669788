<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="watermarkForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="watermarkname" label="水印名称:">
                    <i-input type="text" v-model="formInline.watermarkname" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="watermarkaddr" label="水印图片">
                    <div class="demo-upload-list" >
                      <template >
                          <img ref="shimg" :src="'/api/files/get?imgpath='+this.formInline.watermarkaddr">
                          <div class="demo-upload-list-cover">
                              <Icon type="ios-eye-outline" @click.native="handleView()"></Icon>
                              <Icon type="ios-trash-outline" @click.native="handleRemove()"></Icon>
                          </div>
                      </template>
                    </div>
                    <Upload
                      ref="uploadshw"
                      :show-upload-list="false"
                      :data="{
                        companyid: this.formInline.companyid
                      }"
                      :on-success="onuploadsuccesssh"
                      :on-error="onuploaderrorsh"
                      :format="['jpg','jpeg','png']"
                      :max-size="200"
                      :on-exceeded-size="onpicexceed"
                      type="drag"
                      action="/api/files/watermark/upload"
                      style="display: inline-block;width:58px;">
                      <div style="width: 58px;height:58px;line-height: 58px;">
                          <Icon type="ios-camera" size="20"></Icon>
                      </div>
                    </Upload>
                    <P style="color:red">合格证背景水印图片(大小不超过200k)</P>                    
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('watermarkForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'WatermarkEdit',
        props:['watermarkdata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    watermarkname: this.watermarkdata==null?'':this.watermarkdata.watermarkname,
                    watermarkaddr: this.watermarkdata==null?'':this.watermarkdata.watermarkaddr,
                },
                ruleInline: {
                    watermarkname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    watermarkaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.watermarkdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.watermarkdata ==null){
                            url = "/api/watermark/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/watermark/update"
                            postdata = this.extend({},this.formInline,{id:this.watermarkdata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                               
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.watermarkname= this.watermarkdata==null?'':this.watermarkdata.watermarkname
                    this.formInline.watermarkaddr= this.watermarkdata==null?'':this.watermarkdata.watermarkaddr
                    
                    if(this.watermarkdata ==null){
                        this.title ='新增水印数据'
                    }
                    else{
                        this.title ='修改水印数据'
                    }
                }
            },
            oncancel(){
                this.$emit('CloseWatermark','')
            },
            onuploadsuccesssh(response, file){
              console.log(response)
              console.log(file)
              this.formInline.watermarkaddr = response.data.filepath
              this.$refs['shimg'].src ="/api/files/get?imgpath="+this.formInline.watermarkaddr
              this.$Message.success('上传成功')
            },
            onuploaderrorsh(response, file){
              console.log(response)
              console.log(file)
            },
            onpicexceed(f){
              this.$Message.error({content:'上传失败，文件超出200k！',duration:3})
            },
            handleView () {
              console.log('handleView')

              console.log(this.formInline.watermarkaddr)
              this.viewpicurl = this.formInline.watermarkaddr
              this.showimg = true
            },
            handleRemove () {
              this.$axios({
                method:'post',
                url:'/api/files/delete',
                data:{path:this.formInline.watermarkaddr},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
              }).then(function(res){
                if(res.data.resultCode ==0){
                  this.$Message.success({content:res.data.msg, duration:3, closable:true})
                  this.formInline.watermarkaddr =null
                  this.$refs['shimg'].src =null
                }
                else{
                  this.$Message.error({content:res.data.msg, duration:3})
                }
              }.bind(this));
            },
        }
    }
</script>
<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }