<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:90%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">车间部门工作台系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;" >
                <TabPane v-if="checkpermission('workshop:printcer')" label="车辆合格证打印" key="downline" >
                    <vehicledownline v-if="currenttab='downline'"></vehicledownline>
                </TabPane>
                <TabPane v-if="checkpermission('workshop:export')" label="车辆数据维护(导出及上传机构)" key="export">
                    <stocksummary :departid='101' v-if="currenttab='export'"></stocksummary>
                </TabPane>
                <TabPane v-if="checkpermission('workshop:summary')" label="本账号打印车辆数据统计" key="summary">
                    <printsummary ref="printsummaryref" :liushuixianid="userid" v-if="currenttab='summary'"></printsummary>
                </TabPane>
                <TabPane v-if="checkpermission('workshop:summary:all')" label="全部流水线打印车辆数据统计" key="summaryall">
                    <printsummary ref="printsummaryref2" v-if="currenttab='summaryall'"></printsummary>
                </TabPane>
                <TabPane v-if="checkpermission('workshop:import')" label="导入车辆数据" key="import" >
                    <importvehicle ref="importtabref" v-if="currenttab='import'"></importvehicle>
                </TabPane>
            </Tabs>
            <div style="margin-top:18px;" v-if="!isV2">
                <a style="font-size:10px"  href="http://ddc.2kj.cn/Lodop6.226_Clodop3.093.zip">打印插件下载</a>
            </div>
        </div>
    </div>
</template>

<script>
import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
import VehicleDownline from '@/views/business/workshop/VehicleDownline.vue'
import StockSummary from '@/views/business/workshop/StockSummary.vue'
import PrintSummary from '@/views/business/workshop/factory/PrintSummary'
import ImportVehicle from '@/views/business/workshop/importvehicledata/ImportVehicle'
export default {
    name:'WorkShop',
    data(){
        return {
            userid: this._self.$root.$store.state.LogonInfo.userid,
            currenttab:'downline',
            CertificateTemplateList:[]
        }
    },
    components:{
        'vehicledownline': VehicleDownline,
        'stocksummary':StockSummary,
        'printsummary':PrintSummary,
        'importvehicle': ImportVehicle,
        loginfobar:UserInfoTitleBar,
    },
    computed:{
        isV2() {
            let path = this.$route.path
            if(path.indexOf("/business/workhome")!=-1){
                return true
            }
            return false
        }
    },
    created(){
        //this.refreshCerlist()
    },
    methods:{
        ontabclick(key){
            console.log('ontabclick:::key='+key)
            if(key == 2){
                this.$refs['printsummaryref'].reload()
            }
        },
        refreshCerlist(){
            this.$axios.post("/api/certificatetemplate/query",{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    pageNum:1,
                    pageSize:200
                })
                .then(function(res){
                if(res.data.resultCode ==0){
                    var rspdata = res.data.data.data
                    console.log("生产模块工作台读取合格证数据结果",rspdata)
                    this.CertificateTemplateList=[]
                    for(var item=0;item<rspdata.length;item++){
                        var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

                        cer.value=item+1
                        cer.certificateid=rspdata[item].id
                        cer.label=rspdata[item].certificatealias
                        cer.certificateno=rspdata[item].certificateno
                        cer.standard= (rspdata[item].standardtype == 1 ? '国标':'企标')
                        this.CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
                    }
                    this.$store.commit('changeCertificateList',this.CertificateTemplateList)
                    this.$Message.success({content:'合格证数据同步完成', duration:3})

                    console.log("生产模块工作台合格证数据列表",this.CertificateTemplateList)
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));     
        },
    }
}
</script>