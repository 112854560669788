<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:blue;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="FinanceAccountForm" :model="formInline" :label-width="150"  label-position="right" >
            <FormItem prop="sOrdercode"  label="*订单编号:">
                <i-input type="text" v-model="formInline.sOrdercode" :maxlength=10 :disabled="getordermode"></i-input>
            </FormItem>
            <FormItem prop="aClassifyId" label="*类别:">
                <Select v-model="formInline.aClassifyId" style="padding-left:5px" @on-change="onclassifyselect" label-in-value>
                    <Option value=0 :key=0>请选择</Option> 
                    <Option value=1 :key=1>定金</Option> 
                    <Option value=2 :key=2>尾款</Option> 
                    <Option value=3 :key=3>全款</Option>
                </Select>
            </FormItem>
            <FormItem prop="payMoney"  label="*发生金额(单位:元):">
                <i-input type="number" v-model="formInline.payMoney" :maxlength=20></i-input>
            </FormItem>
            <FormItem prop="paySn"  label="*付款流水号:">
                <i-input type="text" v-model="formInline.paySn" :maxlength=30></i-input>
            </FormItem>
            <FormItem prop="happendDate"  label="*账目日期:">
                <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.happendDate"  @on-change="formInline.happendDate=$event"></DatePicker>
            </FormItem>
            <FormItem  label="备注:">
                <i-input type="textarea" v-model="formInline.note" :maxlength=100></i-input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('FinanceAccountForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'SaleOrderAccountEdit',
        props:['ordercode','accountdata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    createuser:this._self.$root.$store.state.LogonInfo.userid,
                    createusername:this._self.$root.$store.state.LogonInfo.fullname,
                    modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                    modifyusername:this._self.$root.$store.state.LogonInfo.fullname,
                    happendDate:null,
                    sOrdercode: this.ordercode,
                    aType: '100',
                    aClassifyId: null,
                    note:null,
                    payMoney:0,
                    paySn:null
                },
                ruleInline: {
                    sOrdercode: [
                        {type: 'string', required: true, message: '不能为空'}
                    ],
                    paySn: [
                        {type: 'string', required: true, message: '不能为空',triger:'blur'}
                    ],
                    aType: [
                        {required: true, message: '不能为空',triger:'blur'}
                    ],
                    aClassifyId: [
                        {required: true, message: '不能为空',triger:'blur'}
                    ],
                    payMoney: [
                        {required: true, message: '不能为空',triger:'blur'}
                    ],
                    happendDate: [
                        {required: true, message: '不能为空', triger:'change'}
                    ],
                },
            }
        },
        mounted(){
            console.log(this.ordercode)
        },
        computed:{
            getordermode(){
                if(this.ordercode !=null){
                    return true
                }
                if(this.accountdata !=null){
                    return true
                }

                return false
            }
        },
        methods: {
            handleSubmit (name) {
                console.log(this.formInline)
                console.log(this.$refs[name])

                if(this.formInline.sOrdercode==null  || this.formInline.aClassifyId==null
                   || this.formInline.payMoney ==null || this.formInline.paySn == null || this.formInline.happendDate == null){
                    this.$Message.error('请填写完整')
                    return
                }

                var url
                var postdata = JSON.parse(JSON.stringify(this.formInline))
                if(this.accountdata ==null){
                    url = "/api/fin/account/addnew"
                }
                else{
                    url = "/api/fin/account/update"
                }

                console.log(postdata)
                delete postdata.createtime
                delete postdata.createuser
                delete postdata.createusername
                delete postdata.modifytime

                for(var p in postdata){
                    console.log(p)
                    if(postdata[p] == null){
                        console.log('-------------')
                        delete postdata[p]
                    }
                }
                
                postdata.happendDate = new Date(postdata.happendDate).Format("yyyy-MM-dd")
                this.$axios({
                    method:'post',
                    url:url,
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success(res.data.msg)
                        this.$emit('OnSaveOver','')
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                    
                }.bind(this))
            },
            queryOrder(){
                this.$axios({
                    method:'post',
                    url:"/api/sale/order/querybycode",
                    data:{ordercode:this.ordercode},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data.data)
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                    
                }.bind(this))                
            },
            onshow(v){
                if(v){
                    
                    if(this.accountdata ==null){
                        this.title ='录入新账目'
                        this.formInline= {
                            companyid:this._self.$root.$store.state.LogonInfo.companyid,
                            createuser:this._self.$root.$store.state.LogonInfo.userid,
                            createusername:this._self.$root.$store.state.LogonInfo.fullname,
                            modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                            modifyusername:this._self.$root.$store.state.LogonInfo.fullname,
                            happendDate:null,
                            sOrdercode: this.ordercode,
                            aType: '100',
                            aClassifyId: null,
                            note:null,
                            payMoney:0,
                            paySn:null
                        }                    
                    }
                    else{
                        this.title ='修改账目'
                        this.formInline = JSON.parse(JSON.stringify(this.accountdata)) 
                        this.formInline.modifyuser =this._self.$root.$store.state.LogonInfo.userid
                        this.formInline.modifyusername = this._self.$root.$store.state.LogonInfo.fullname
                        this.formInline.payMoney = this.accountdata.payMoney
                        this.formInline.aClassifyId = parseInt(this.formInline.aClassifyId)
                        this.formInline.aType = parseInt(this.formInline.aType)
                        console.log(this.formInline)
                        //this.queryOrder()
                    }

                }
            },
            onatypeselect(v){
                this.formInline.note = v.label
            },
            onclassifyselect(v){
                this.formInline.aClassifyName = v.label
            },
            oncancel(){
                this.$emit('CloseFinanceAccount','')
                this.$refs['FinanceAccountForm'].resetFields()
            },
        },
        components:{
        }
    }
</script>