<template>
    <div style="width: 100%;padding:10px 50px">
        <div style="width: 90%;background: white;padding: 10px 20px;margin: 10px auto;">

            <div style="height: 30px;">
                <img src="@/assets/icon/back.png" height="100%" style="float: left; cursor: pointer;" @click="handleClose"/>
                <p style="color:#f60;text-align:left;font-size: 24px;padding: 0px;float: left;margin-left: 15px;">
                    <span>{{title}} - 出库单信息</span>
                </p>                
            </div>
            <Form ref="PurchaseEditForm" :model="formInline"   label-position="right" :rules="ruleInline" style="clear: both;margin-top: 20px;">
                <FormItem prop="requestUsername"  label="领料人姓名:" style="width: 20%;">
                    <i-input type="text" v-model="formInline.requestUsername" :maxlength=30   placeholder='领料人姓名' style="width: 400px;"></i-input>
                </FormItem>
                <FormItem  label="备注:" style="width: 20%;">
                    <i-input type="textarea" v-model="formInline.note" :maxlength=200   placeholder='备注信息' style="width: 400px;"></i-input>
                </FormItem>
                <Row :gutter="20" index="" style="width: 80%;">
                    <i-col span="12">
                        <FormItem  label="选择物料:">
                            <Cascader v-model="detailFormInline.material"  :data="materialTypelist" @on-change="onMaterialChange"></Cascader>
                        </FormItem>
                    </i-col>
                    <i-col span="4" style="padding-left:15px">
                        <FormItem   label="库存数量:">
                            <i-input disabled v-model="kucunnum"/>
                        </FormItem>
                    </i-col>                       
                    <i-col span="4" style="padding-left:5px">
                        <FormItem   label="出库数量:">
                            <i-input type="number" v-model="detailFormInline.total" :maxlength=10 placeholder="数量">
                                <span slot="append">{{detailFormInline.unit}}</span>
                            </i-input>
                        </FormItem>
                    </i-col>                
                    <i-col span="4" style="padding-left:5px;">
                        <Button type="success" style="font-size:10px;margin-top:33px" icon="md-add" @click="addproduct">添加到出库单</Button>
                    </i-col>             
                </Row>    
                <div style="margin-left:10px;width:100%;text-align:left">出库物料列表：</div>
                <Table :columns="tblcolumns" :data="tbldata" border size="small"  height="200">
                    <template slot-scope="{ row }" slot="materialname">
                        <strong>{{ row.materialname }}</strong>
                    </template>
                    <template slot-scope="{ row, index }" slot="action" >
                        <Button type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                        <Button type="error" size="small" ghost @click="removeproduct(index)" style="float:right">删除</Button>        
                    </template>                            
                </Table>  
            </Form>

        </div>
        
        <div slot="footer" style="margin: 25px; display: inline-flex;align-items: center;">
            <Button type="error" size="large"   @click="handleClose" style="margin: 0px 20px;width: 150px;">取消</Button>
            <Button type="primary" size="large"   @click="handleSubmit('PurchaseEditForm')" style="margin: 0px 20px;width: 150px;">提交</Button>
        </div>
    </div>
</template>
<script>
    import {getModels,getModelvers} from "@/api/masterdata"
    import axios from "axios"
    export default {
        name:'PartStockOutEdit',
        props:['show'],
        data () {
            return {
                title:'',
                MasterModelDatalist:[],
                kucunnum:null,
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    requestUsername:null,
                    note:null,
                },
                detailFormInline:{
                    materialtypeindex:null,
                    materialinfoindex:0,
                    material:[],
                    unit:null,
                    total:0
                },
                ruleInline: {
                    requestUsername:[
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '物料名',
                        slot: 'materialname',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec'
                    },
                    {
                        title: '物料编码',
                        key: 'mcode'
                    },
                    {
                        title: '供应商',
                        key: 'supplier'
                    },
                    {
                        title: '数量',
                        key: 'total',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '单位',
                        key: 'unit',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width:130
                    }
                ],
                tbldata:[],
                dateoption:{
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now()-86400000;
                    }
                },
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                //供应商匹配列表
                suppliersearchlist:[],
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            console.log('出库单编辑页面挂载=============')
            this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList
            this.title ='新建出库单'
            this.getMaterialMasterData()
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        let url = "/api/partRequest/lsck"
                        console.log("提交零散出库单",this.formInline)
                        let postdata = this.extend({},this.formInline,{content:JSON.stringify(this.tbldata)})
                        
                        console.log(postdata)
                        this.$axios.post(url,postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('closeedit','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            getMaterialMasterData(){
                this.$axios.post('/api/material/masterdata/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                .then(function(res){
                        if(res.data.resultCode ==0){
                            //this.$Message.success(res.data.msg)
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = this.processmaterialshowlist(res.data.data)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = i
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+')'
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
            getunitbymaterialid(id){
                for(let i=0;i< this.masterdata.materialInfos.length;i++){
                    if(this.masterdata.materialInfos[i].id == id){
                        return this.masterdata.materialInfos[i].unit
                    }
                }

                return ''
            },
            searchSupplier(value){
                if(value ==null){
                    this.suppliersearchlist =[]
                    return
                }
                var supplierlist =this.masterdata.supplierInfos
                this.suppliersearchlist=[]
                for(var i=0;supplierlist !=null && i<supplierlist.length;i++){
                    if(supplierlist[i].suppliername.indexOf(value) !=-1){
                        this.suppliersearchlist.push(supplierlist[i].suppliername)
                    }
                }
                console.log(this.suppliersearchlist)
            },
            addproduct(){
                if(this.detailFormInline.total  <=0){
                    this.$Message.error({content:'物料数量不能小于1',duration:3})
                    return
                }
                console.log(this.detailFormInline)
                this.detailFormInline.materialinfoindex = this.detailFormInline.material[1]
                this.detailFormInline.materialtypeindex = this.detailFormInline.material[0]
                this.detailFormInline.companyid = this._self.$root.$store.state.LogonInfo.companyid
                console.log(this.masterdata)
                this.detailFormInline.materialname = this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].materialname
                this.detailFormInline.materialspec = this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].spec
                this.detailFormInline.materialtype = this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].materialtype

                this.tbldata.push({
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    materialid: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].id,
                    materialname: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].materialname,
                    materialspec: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].spec,
                    mcode:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].mcode,
                    materialtype: this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].materialtype,  
                    supplier: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].supplier,  
                    cache:[this.detailFormInline.materialtypeindex,this.detailFormInline.materialinfoindex],
                    unit: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].unit,            
                    total: this.detailFormInline.total                 
                })

                this.detailFormInline ={
                    materialtypeindex:null,
                    materialinfoindex:0,
                    material:[],
                    unit:null,
                    total:0
                }
            },
            addbommaterials(){
                if(this.bom.num <=0){
                    this.$Message.error({content:'数量不能小于1',duration:3})
                    return
                }
                this.bom.bomdata = null
                this.bom.materials = []
                axios.post('/api/bom/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid,modelverid:this.bom.modelverid})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        if(res.data.data == null){
                            this.$Message.error({content:'没有该车型BOM数据',duration:5})
                        }
                        else{
                            this.bom.bomdata = res.data.data
                            console.log("BOM数据",this.bom.bomdata)
                            this.bom.materials = JSON.parse(res.data.data.content)
                            this.formInline.note = this.bom.modelvername
                            this.bom.materials.forEach(element => {
                                console.log("物料数据",element)
                                if(element.unit == undefined || element.unit == null){
                                    element.unit = this.getunitbymaterialid(element.materialid)
                                }
                                this.tbldata.push({
                                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                                    materialid: element.materialid,
                                    materialname: element.materialname,
                                    materialspec: element.materialspec,
                                    materialtype: element.materialtype,  
                                    supplier: element.supplier,
                                    cache:[this.detailFormInline.materialtypeindex,this.detailFormInline.materialinfoindex],
                                    unit: element.unit,            
                                    amount: this.bom.num                 
                                })
                            });
                        }
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                        
                }.bind(this))   
            },
            getbomdata(cid,mid){
                axios.post('/api/bom/query',{companyid: cid,modelverid:mid})
                .then(function(res){
                        if(res.data.resultCode ==0){
                            if(res.data.data == null){
                                this.$Message.error({content:'没有该车型BOM数据',duration:5})
                            }
                            else{
                                this.bom.bomdata = res.data.data
                                console.log("BOM数据",this.bom.bomdata)
                                this.bom.materials = JSON.parse(res.data.data.content)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))                
            },
            getkucundata(mid){
                this.postquerydata={
                    materialId: mid,
                    pageNum:1,
                    pageSize:10
                }  
                this.$axios.post("/api/partMaterialStock/query",this.postquerydata)
                .then(function(res){
                    console.log("零部件库存查询结果",res.data.data)
                    if(res.data.resultCode ==0){
                        //let summary = res.data.data.summary
                        let result = res.data.data
                        let totalnumber = result.totalnumber
                        if(totalnumber > 0){
                            this.kucunnum = result.data[0].num
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));             
            },
            onmodelchange(e,selectedData){
              console.log(e)
              console.log(selectedData)
              if(selectedData.length >1){
                this.bom.modelverid = selectedData[1].value
                this.bom.modelvername =selectedData[0].label+"/"+ selectedData[1].label
              }
            },
            onMaterialChange(e){
                console.log(e)
                this.kucunnum = 0
                if(e.length>1){
                    this.detailFormInline.unit = this.masterdata.materialInfos[e[1]].unit
                    this.getkucundata(this.masterdata.materialInfos[e[1]].id)
                }
                
            },
            removeproduct(index) {
                console.log(index)
                this.tbldata.splice(index,1)
            },
            modify (index){
                this.detailFormInline.material = JSON.parse(JSON.stringify(this.tbldata[index].cache))
                this.detailFormInline.total = this.tbldata[index].total
                this.detailFormInline.unit = this.tbldata[index].unit
                this.removeproduct(index)
            },
            handleClose(){
                this.$emit('closeedit','')
            },
            
        },
        components:{

        }
    }
</script>