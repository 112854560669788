<template>
    <Modal :value="show" width="85%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:blue;text-align:left">
            <span>{{title}}</span>
        </p>

        <p style="color:#f60;text-align:left">
            <span>订单信息</span>
        </p>
        <Card :bordered="true" style="height:550px;overflow:scroll">
            <Form ref="SellorderForm" :model="formInline"   label-position="left" :rules="ruleInline" style="background: lightsteelblue;">
            <div style="background: white;">
                <Row  :gutter="20">
                    <i-col span="12">
                        <FormItem  label="选择订单客户:">
                            <Select @on-change="onselectCustomer">
                              <Option v-for="(item,index) in customers"  :value="index" :key="item.id"> {{item.customerName}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>           
                </Row>
                <Row  :gutter="20">
                    <i-col span="4">
                        <FormItem prop="customer" label="公司名称:">
                            <i-input type="text" v-model="formInline.customer" :maxlength=30 show-word-limit disabled></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="4" >
                        <FormItem prop="contact" label="联系人:">
                            <i-input type="text" v-model="formInline.contact" :maxlength=30 show-word-limit disabled></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="4" >
                        <FormItem prop="phone" label="联系人电话:">
                            <i-input type="text" v-model="formInline.phone" :maxlength=30 show-word-limit disabled></i-input>
                        </FormItem>
                    </i-col>              
                </Row>
                <Row :gutter="20">
                    <i-col span="4" >
                        <FormItem  label="收货省份:">
                            <i-input type="text" v-model="formInline.province" :maxlength=30 show-word-limit disabled></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="4" >
                        <FormItem  label="收货城市:">
                            <i-input type="text" v-model="formInline.city" :maxlength=30 show-word-limit disabled></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="5">
                        <FormItem  label="详细地址:">
                            <i-input type="text" v-model="formInline.address" :maxlength=30   placeholder='详细地址' disabled></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="4" >
                        <Button type="error" style="margin-top: 35px;" v-if="showsubaddrlist" @click="showsubaddrwin=true">选择备用收货地址</Button>
                    </i-col>
                </Row>  
                <Row :gutter="20">
                    <i-col span="4">
                        <FormItem  label="订单类型:">
                            <Select v-model="formInline.todept">
                                <Option value="1" :key="1">计划生产订单</Option>
                                <Option value="2" :key="2">库房现货订单</Option>
                            </Select>
                        </FormItem>
                    </i-col>        
                    <i-col span="4" >
                            <FormItem  label="付款状态:">
                                <Select v-model="formInline.fstatus" >
                                    <Option value="0" :key="0">未付定金</Option>
                                    <Option value="1" :key="1">已付定金</Option>
                                    <Option value="2" :key="2">已付全款</Option>
                                </Select>
                            </FormItem>
                        </i-col>                     
                    <i-col span="4">
                        <FormItem  label="预计交货日期:">
                            <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.expectdate"  @on-change="formInline.expectdate=$event" :options="dateoption"></DatePicker>
                        </FormItem>
                    </i-col>

                </Row> 
            </div>
            <div style="margin-top: 0px;background: white;">
                <Row ::gutter="20" index="">
                    <i-col span="5">
                        <FormItem  label="产品型号:">
                            <Select v-model="detailFormInline.modelverid" @on-change="onModelSelect">
                                <Option v-for="(item,index) in modeldatalist()" :value="item.id" :key="index">{{item.modelVer}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="3" style="padding-left:5px">
                        <FormItem  label="颜色:">
                            <Select v-model="detailFormInline.productcolor" >
                                <Option v-for="(item,index) in colorlist" :value="item" :key="index">{{item}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>     
                    <i-col span="2" style="padding-left:5px">
                        <FormItem  label="数量:">
                            <i-input type="number" v-model="detailFormInline.productcount" :maxlength=10 placeholder="数量"></i-input>
                        </FormItem>
                    </i-col>           
                    <i-col span="2" style="padding-left:5px">
                        <FormItem  label="单价:">
                            <i-input type="number" v-model="detailFormInline.priceper" :maxlength=10 placeholder="单价"></i-input>
                        </FormItem>
                    </i-col>       
                    <i-col span="3" style="padding-left:5px;">
                        <Button type="success" style="font-size:10px;margin-top:33px" icon="md-add" @click="addproduct">添加</Button>
                    </i-col>    
                    <!--
                    <i-col span="3" style="padding-left:5px;">
                        <Button type="warning" style="font-size:10px;margin-top:33px" icon="ios-search" @click="showsummarytotal=true">查看现有库存</Button>
                    </i-col>  
                    -->  
        
                </Row>  
                <div style="margin-left:10px;width:100%;text-align:left">选购产品列表：</div>
                <Table :row-class-name="rowClassName" :columns="tblcolumns" :data="tbldata" border size="small"  height="200">
                    <template slot-scope="{ row }" slot="productname">
                        <strong>{{ row.productname }}</strong>
                    </template>
                    <template slot-scope="{ row }" slot="priceper">
                        <p ><span style="font-size:12px">{{ row.priceper }}</span> (<span style="font-size:10px;text-decoration:line-through"> {{row.oriunitprice}} </span>) <span v-if="row.priceper<row.oriunitprice" style="color:red">↓</span> <span v-if="row.priceper>row.oriunitprice" style="color:green">↑</span></p>
                    </template>
                    <template slot-scope="{ row, index }" slot="action" >
                        <Button type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                        <Button type="error" size="small" ghost @click="removeproduct(index)" style="float:right">删除</Button>        
                    </template>                            
                </Table>    
                <div>
                    <p style="float:left">合计: {{countcompute}}辆</p>
                    <p style="float:right">共计: {{pricecompute}}元</p>
                </div>
            </div>
            </Form>
        </Card>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('SellorderForm')">提交</Button>
        </div>
        <Modal v-model="showsummarytotal">
            <viewsummary v-if="showsummarytotal"/>
        </Modal>
        <Modal v-model="showsubaddrwin" >
            <p slot="header" style="color:blue;text-align:left">
                <span>选择备用地址</span>
            </p>
            <div v-if="showsubaddrlist">
                <Select @on-change="onselectsubaddr">
                  <Option v-for="(item,index) in subaddrlist" :value="index" :key="index">{{item.customerAddr}}</Option>
                </Select>
            </div>
        </Modal>
    </Modal>
</template>
<script>
    import {getcitylist} from '@/zsjs/citylist.js'
    import VehicleQuerySummary from '../../../common/VehicleQuerySummary.vue'
    import axios from '@/config/apibatch'
    export default {
        name:'SellorderEdit',
        props:['Sellorderdata','show'],
        data () {
            return {
                title:'',
                showsummarytotal:false,
                showsubaddrlist:false,//是否显示备选地址
                showsubaddrwin:false,
                subaddrlist:[],
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    salerid: this._self.$root.$store.state.LogonInfo.userid,
                    salername: this._self.$root.$store.state.LogonInfo.fullname,
                    customer: this.Sellorderdata==null?'':this.Sellorderdata.customer,
                    contact: this.Sellorderdata==null?'':this.Sellorderdata.contact,
                    phone:this.Sellorderdata==null?'':this.Sellorderdata.phone,
                    province:this.Sellorderdata==null?'':this.Sellorderdata.province,
                    city:this.Sellorderdata==null?'':this.Sellorderdata.city,
                    address:this.Sellorderdata==null?'':this.Sellorderdata.address,
                    expectdate:this.Sellorderdata==null?'':this.Sellorderdata.expectdate,
                    fstatus:this.Sellorderdata==null?'':this.Sellorderdata.fstatus,
                    todept:this.Sellorderdata==null?'':this.Sellorderdata.todept,
                    pricetotal:0,
                    counttotal:0
                    //prodlist:this.Sellorderdata==null?'':this.Sellorderdata.prodlist,
                },
                detailFormInline:{
                    modelverid:null,
                    productname:null,
                    productcolor:null,
                    productcount:0,
                    priceper:0,
                    pricetotal:0,
                    cerindex:0
                },
                ruleInline: {
                    Sellordername: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    Sellorderaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
                allcitylist:getcitylist(),
                showcitylist:[],
                cerlist:[],
                CertificateTemplateShowList:[],
                cnmodellist:[],
                colorlist:[],
                pricelist:[],
                customers:this._self.$root.$store.state.MasterCustomers == null ? []:this._self.$root.$store.state.MasterCustomers,
                syncprice:false,
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '型号',
                        slot: 'productname'
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                    },
                    {
                        title: '单价',
                        slot: 'priceper',           
                    },
                    {
                        title: '数量',
                        key: 'productcount',          
                    },
                    {
                        title: '当前库存',
                        key: 'nowamount',           
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        fixed:'right',
                        width:130
                    }
                ],
                tbldata:[],
                dateoption:{
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now()-86400000;
                    }
                }
            }
        },
        mounted(){
            console.log("接收到传递的销售订单数据",this.Sellorderdata)
            //this.getMasterData()
        },
        computed:{
            pricecompute() {
                var pricetotal =0
                for(let i=0;i< this.tbldata.length;i++){
                    pricetotal = pricetotal +parseInt(this.tbldata[i].pricetotal) 
                }
                
                this.formInline.pricetotal = pricetotal
                return pricetotal;
            },
            countcompute() {
                var counttotal =0
                for(let i=0;i< this.tbldata.length;i++){
                    counttotal = counttotal + parseInt(this.tbldata[i].productcount) 
                }
                
                this.formInline.counttotal = counttotal
                return counttotal;
            }
        },
        methods: {
            modeldatalist(){
                return this._self.$root.$store.state.MasterModelVerList
            },
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.Sellorderdata ==null){
                            url = "/api/sale/order/addnew"
                            postdata ={
                                sOrder:JSON.stringify(this.formInline),
                                sOrderdetails:JSON.stringify(this.tbldata)
                            }
                            
                        }
                        else{
                            url = "/api/sale/order/update"
                            postdata = this.extend({},this.formInline,{id:this.Sellorderdata.id})
                        }
                        console.log("销售订单提交表单数据",postdata)
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                                this.formInline
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                this.customers = this._self.$root.$store.state.MasterCustomers == null ? []:this._self.$root.$store.state.MasterCustomers
                if(v){
                
                    if(this.Sellorderdata ==null){
                        this.title ='录入新订单'

                        this.formInline= {
                            companyid:this._self.$root.$store.state.LogonInfo.companyid,
                            salerid: this._self.$root.$store.state.LogonInfo.userid,
                            salername: this._self.$root.$store.state.LogonInfo.fullname,
                            customer: null,
                            contact: null,
                            phone:null,
                            province:'',
                            city:'',
                            address:null,
                            expectdate:null,
                            fstatus:'',
                            todept:'',
                            pricetotal:0,
                            counttotal:0
                        }

                        this.detailFormInline={
                            modelverid:null,
                            productname:null,
                            productcolor:null,
                            productcount:0,
                            priceper:0,
                            pricetotal:0,
                            cerindex:0
                        }
                    }
                    else{
                        this.title ='修改订单'

                        this.formInline= {
                            companyid:this._self.$root.$store.state.LogonInfo.companyid,
                            salerid: this._self.$root.$store.state.LogonInfo.userid,
                            salername: this._self.$root.$store.state.LogonInfo.fullname,
                            customer: this.Sellorderdata==null?'':this.Sellorderdata.customer,
                            contact: this.Sellorderdata==null?'':this.Sellorderdata.contact,
                            phone:this.Sellorderdata==null?'':this.Sellorderdata.phone,
                            province:this.Sellorderdata==null?'':this.Sellorderdata.province,
                            city:this.Sellorderdata==null?'':this.Sellorderdata.city,
                            address:this.Sellorderdata==null?'':this.Sellorderdata.address,
                            expectdate:this.Sellorderdata==null?'':this.Sellorderdata.expectdate,
                            fstatus:this.Sellorderdata==null?'':this.Sellorderdata.fstatus,
                            todept:this.Sellorderdata==null?'':this.Sellorderdata.todept,
                            pricetotal:0,
                            counttotal:0
                        }
                    }
                }


            },
            onprovinceselect(province){
                console.log(province)
                if(province ==''){
                    this.showcitylist =[]
                    return
                }
                for(var i=0;i<this.allcitylist.length;i++){
                    if(this.allcitylist[i].provice == province){
                        this.showcitylist= this.allcitylist[i].city
                        return
                    }
                }
            },
            checkamount(){
                var postdata={
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    modelverid:this.detailFormInline.modelverid,
                    color:this.detailFormInline.productcolor.trim(),
                    expectdate:this.formInline.expectdate
                }
                this.$axios.post("/api/vehicleIn/stock/modelver/color/amount/query", postdata)
                .then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                       
                        this.tbldata.push({
                            productname:this.detailFormInline.productname,
                            modelverid:this.detailFormInline.modelverid,
                            productcolor:this.detailFormInline.productcolor,
                            productcount:this.detailFormInline.productcount,
                            priceper:this.detailFormInline.priceper,
                            pricetotal: this.detailFormInline.productcount * this.detailFormInline.priceper,
                            oriunitprice:this.getmodelverbyid(this.detailFormInline.modelverid).sugSalePrice,           
                            nowamount:rspdata.nowamount     
                        })

                        console.log(this.tbldata)
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            addproduct(){
                if(this.formInline.expectdate == null || this.formInline.expectdate ==''){
                    this.$Message.error('请先填写预计交货日期')
                    return
                }

                if(this.detailFormInline.productcolor==null ||this.detailFormInline.productcolor==''){
                    this.$Message.error('必须填写车辆颜色')
                    return
                }
                this.detailFormInline.productcolor = this.detailFormInline.productcolor.trim()
                console.log(this.detailFormInline.cerindex)

                this.detailFormInline.productname = this.getmodelverbyid(this.detailFormInline.modelverid).modelVer
                
                this.checkamount()
            },
            removeproduct(index) {
                console.log(index)
                this.tbldata.splice(index,1)
            },
            modify(index){
                this.detailFormInline.modelverid = this.tbldata[index].modelverid
                this.detailFormInline.productname = this.tbldata[index].productname
                this.detailFormInline.productcolor = this.tbldata[index].productcolor
                this.detailFormInline.productcount = this.tbldata[index].productcount

                this.removeproduct(index)
            },
            oncancel(){
                this.$emit('CloseSellorder','')
            },
            handleView () {
              console.log('handleView')
            },
            getmodelverbyid(id){
                let models = this.modeldatalist()
                for(let i=0;i<models.length;i++){
                    if(models[i].id == id){
                        return models[i]
                    }
                }
            },
            onModelSelect(v){
                console.log(v)
                this.colorlist = []
                let models = this.modeldatalist()
                for(let i=0;i<models.length;i++){
                    if(models[i].id == v){
                        if(models[i].colors==null ||models[i].colors=='' ){
                            break 
                        }
                        this.colorlist=models[i].colors.split(";"); //字符分割
                        break
                    }
                }
                console.log(this.colorlist)
            },
            onselectCustomer(item){
                console.log(item)
                this.formInline.customer = this.customers[item].customerName
                this.formInline.contact = this.customers[item].customerContact
                this.formInline.phone = this.customers[item].customerPhone
                this.formInline.province = this.customers[item].customerProvince
                this.formInline.city = this.customers[item].customerCity
                this.formInline.address = this.customers[item].customerAddr

                if(this.customers[item].customerOtherAddrs !=''){
                    this.subaddrlist = JSON.parse(this.customers[item].customerOtherAddrs)
                    this.showsubaddrlist = true
                }
            },
            onselectsubaddr(index){
                this.formInline.province = this.subaddrlist[index].customerProvince
                this.formInline.city = this.subaddrlist[index].customerCity
                this.formInline.address = this.subaddrlist[index].customerAddr
                this.showsubaddrwin = false
            },
            rowClassName (row, index) {
                if (row.planamount + row.nowamount < row.productcount) {
                    return 'demo-table-warning-row';
                }
                return '';
            }
        },
        components:{
            viewsummary:VehicleQuerySummary
        }
    }
</script>

<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #ffe7e7;
        color: black;
    }
</style>