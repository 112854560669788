import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 存储token
    AccessToken: localStorage.getItem('AccessToken') ? localStorage.getItem('AccessToken') : '',
    AccessTokenExpiryTime: localStorage.getItem('AccessTokenExpiryTime') ? localStorage.getItem('AccessTokenExpiryTime') : '',

    //合格证临时数据保存
    CertificateEditData:localStorage.getItem('certificateEditData') ? JSON.parse(localStorage.getItem('certificateEditData')) : '',

    //账号登录信息
    /**
     *     
     *  String accessToken;
     *  Long userid;
     *  String username;
     *  String password;
     *  String fullname;
     *  Long companyid;
     *  String companyname;
     *  Long departmentid;
     *  Date expiryTime;
     * 
     * //用户类型
     * private String userCategory;
     * 
     * //一级用户权限级别,区分运维管理员\CCC用户\企业主账号
     * private int level;
     */
    LogonInfo:localStorage.getItem('logonInfo') ? JSON.parse(localStorage.getItem('logonInfo')):null,

    //合格证列表数据缓存
    /**
     * 数组每个item的属性对象1：cerdisplay  合格证选择列表显示用
     * --value
     * --certificateid
     * --label
     * --certificateno
     * --standard
     * 数组每个item的属性对象2：cerfulldata  合格证全部数据
     * 
     */
    CertificateList:localStorage.getItem('certificateList') ? JSON.parse(localStorage.getItem('certificateList')) : null,    

    //本地打印机配置信息
    PrinterInfo:localStorage.getItem('printerInfo') ? JSON.parse(localStorage.getItem('printerInfo')):null,

    //常用车辆颜色
    VehicleCommonColors:localStorage.getItem('commoncolors') ? JSON.parse(localStorage.getItem('commoncolors')):null,

    //认证中心数据缓存
    CccList:localStorage.getItem('ccclist') ? JSON.parse(localStorage.getItem('ccclist')):null,

    //印章缓存
    SealList:localStorage.getItem('seallist') ? JSON.parse(localStorage.getItem('seallist')):null,
    
    //水印缓存
    WatermarkList:localStorage.getItem('watermarklist') ? JSON.parse(localStorage.getItem('watermarklist')):null,    

    //打印自删位数
    /**
     * vindelnum
     * motordelnum
     */
    VehicleAddConfig:localStorage.getItem('vehicleAddConfig') ? JSON.parse(localStorage.getItem('vehicleAddConfig')):null,  

    //页面底部热线、公司名称、超链等信息
    SupportInfo:localStorage.getItem('supportinfo') ? JSON.parse(localStorage.getItem('supportinfo')):null, 

    /**
     * 物料类型数据
     * id
     * materialtype
     */
    MaterialType:localStorage.getItem('materialtype') ? JSON.parse(localStorage.getItem('materialtype')):null, 

    /**
     * 技术部门车型主数据
     */
    MasterModelList: localStorage.getItem('mastermodellist') ? JSON.parse(localStorage.getItem('mastermodellist')):null, 

    /**
     * 技术部门车型版本主数据
     */
    MasterModelVerList: localStorage.getItem('mastermodelverlist') ? JSON.parse(localStorage.getItem('mastermodelverlist')):null, 

      /**
     * 技术部门供应商主数据
     */
    MasterSuppliers: localStorage.getItem('mastersuppliers') ? JSON.parse(localStorage.getItem('mastersuppliers')):null, 

    /**
     * 提供给各模块做车型筛选的二级列表数据
     */
    MasterModelChooseList:localStorage.getItem('mastermodelchooselist') ? JSON.parse(localStorage.getItem('mastermodelchooselist')):null, 

    /**
     * 销售部门客户主数据
     */
    MasterCustomers: localStorage.getItem('mastercustomers') ? JSON.parse(localStorage.getItem('mastercustomers')):null, 

    /**
     * ERP配置
     */
    ErpConfig: localStorage.getItem('erpconfig') ? JSON.parse(localStorage.getItem('erpconfig')):null, 

    /**
     * 验厂系统车辆型号列表
     */
    ModelListInfo: localStorage.getItem('modellistinfo') ? JSON.parse(localStorage.getItem('modellistinfo')):null, 

    /**
     * 车型参数配置
     */
    ModelCfg: localStorage.getItem('modelcfg') ? JSON.parse(localStorage.getItem('modelcfg')):null, 

  },
  mutations: {
    // 修改token，并将token存入localStorage
    changeLogin (state, user) {
      state.LogonInfo = user.logonInfo
      localStorage.setItem('logonInfo', JSON.stringify(user.logonInfo));

      state.AccessToken = user.accessToken;
      localStorage.setItem('AccessToken', user.accessToken);
      
    },

    changeCommonColors (state,colors){
      state.VehicleCommonColors = colors;  
      localStorage.setItem('commoncolors', JSON.stringify(colors));
    },

    changeToken (state, token){
      state.AccessToken = token;
      localStorage.setItem('AccessToken', token);      
    },
    changeLogoninfo (state, logoninfo){
      state.LogonInfo = logoninfo
      localStorage.setItem('logonInfo', JSON.stringify(logoninfo));
    },
    changeCertificateList(state,cerlist){
      state.CertificateList = cerlist
      localStorage.setItem('certificateList',JSON.stringify(cerlist))          
    },
    changeCertificateData(state,cerdata){
      state.CertificateEditData = cerdata
      localStorage.setItem('certificateEditData',JSON.stringify(cerdata))      
    },

    changePrinterData(state,printerdata){
      state.PrinterInfo = printerdata
      localStorage.setItem('printerInfo',JSON.stringify(printerdata))      
    },

    changeCccList(state,cccdata){
      state.CccList = cccdata
      localStorage.setItem('ccclist',JSON.stringify(cccdata))      
    },

    changeSealList(state,sealdata){
      state.SealList = sealdata
      localStorage.setItem('seallist',JSON.stringify(sealdata))      
    },

    changeWatermarkList(state,watermarkdata){
      state.WatermarkList = watermarkdata
      localStorage.setItem('watermarklist',JSON.stringify(watermarkdata))      
    },

    changeVehicleConfig(state,data){
      state.VehicleAddConfig = data
      localStorage.setItem('vehicleAddConfig',JSON.stringify(data))   
    },

    changeSupportInfo(state,data){
      state.SupportInfo = data
      localStorage.setItem('supportinfo',JSON.stringify(data))   
    },

    changeMaterialType(state, data){
      state.MaterialType = data
      localStorage.setItem('materialtype',JSON.stringify(data))   
    },

    changeMasterModelList(state, data){
      state.MasterModelList = data
      localStorage.setItem('mastermodellist',JSON.stringify(data))   
    },

    changeMasterModelVerList(state, data){
      state.MasterModelVerList = data
      localStorage.setItem('mastermodelverlist',JSON.stringify(data))   
    },

    changeMasterSuppliers(state, data){
      state.MasterSuppliers = data
      localStorage.setItem('mastersuppliers',JSON.stringify(data))   
    },

    changeModelListInfo(state,data){
      state.ModelListInfo = data
      localStorage.setItem('modellistinfo',JSON.stringify(data))
    },

    changeModelChooseListInfo(state,data){
      state.MasterModelChooseList = data
      localStorage.setItem('mastermodelchooselist',JSON.stringify(data))
    },

    changeMasterCustomers(state,data){
      state.MasterCustomers = data
      localStorage.setItem('mastercustomers',JSON.stringify(data))
    },

    changeErpConfig(state,data){
      state.ErpConfig = data
      localStorage.setItem('erpconfig',JSON.stringify(data))
    },

    changeModelCfg(state,data){
      state.ModelCfg = data
      localStorage.setItem('modelcfg',JSON.stringify(data))
    }
  },
  actions: {
  },
  modules: {
  }
})
