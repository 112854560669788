<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false>
        <p slot="header" style="color:#f60;text-align:left">
            <span>添加车辆数据</span>
        </p>
        <Form ref="vehicleEditForm" :model="formInline" :label-width="100" label-position="right" :rules="ruleInline">
            <Row :gutter="20" >
              <i-col span="12">
                <FormItem prop="vehicleno" label="整车编码">
                    <i-input type="text" v-model="formInline.vehicleno" />
                </FormItem>
              </i-col>
              <i-col span="12">
                <FormItem prop="certificateno" label="合格证编码">
                    <i-input type="text" v-model="formInline.certificateno" />
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="12">
                <FormItem prop="motorno" label="电动机编码">
                    <i-input type="text" v-model="formInline.motorno"/>
                </FormItem>
              </i-col>
              <i-col span="12">  
                <FormItem prop="vehiclecolor" label="车身颜色">
                    <i-input type="text" v-model="formInline.vehiclecolor" />
                </FormItem>                  
              </i-col>
            </Row>
            <Row>  
              <i-col span="12">
                <FormItem prop="madedate" label="制造日期">
                    <i-input type="text" v-model="formInline.madedate" />
                </FormItem>
              </i-col>
              <i-col span="12">  
                <FormItem prop="note" label="备注">
                    <i-input type="text" v-model="formInline.note" />
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('vehicleEditForm')">添加</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'VehicleEdit',
        props:['show'],
        data () {
            return {
                formInline: {
                    vehicleno: '',
                    certificateno: '',
                    motorno:'',
                    vehiclecolor: '',
                    madedate: '',
                    note: ''
                },
                ruleInline: {
                    verhicleno: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    certificateno: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    motorno: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    vehiclecolor: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ],
                    madedate: [
                        { required: true, message: '不能为空', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid) =>{
                    if(valid){
                        this.$axios({
                        method:'post',
                        url:"/api/certificatetemplate/check",
                        data:this.formInline,
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3, closable:true})
                        }
                        else{
                        this.$Message.error({content:res.data.msg, duration:3})
                        }
                    }.bind(this));
                    }
                })
            },
            oncancel(){
                this.$emit('CloseVerhicleEdit','')
            }
        }
    }
</script>
