<template>
    <table style="width:100%;height:50px;background:#7093db">
        <tr >
<!--
            <td valign="center" align="left" width="150px" height="50px" style="padding-top:8px;padding-bottom:5px">
                <img  src="@/assets/ever.png" width="150px" height="50px" style="margin-left:8px;"/>
            </td>
            <td valign="center" align="left"><h3 >恒大新能源汽车集团</h3></td>
             -->

            <td valign="center" align="left" width="119px"  height="70px" style="padding-top:8px;padding-bottom:5px">
                <img  src="@/assets/ddc.png"  height="70px" style="margin-left:8px;"/>
            </td>
            <!--
            <td valign="center" align="left"><h3 >天津牛商企业服务有限公司</h3></td>
            -->

           
            <td valign="center" align="right"><div>{{logoninfo.companyname}} - {{logoninfo.departmentname}} - {{logoninfo.username}},欢迎您！</div></td>
            <td valign="center" align="right" width="100px" ><Button type="warning" ghost @click="logoff" style="margin-right:8px">退出登陆</Button></td>
        </tr>
    </table>

</template>

<script>
export default {
    name:'UserInfoTitleBar',
    props:['userinfo'],
    data(){
        return {
            logoninfo:this._self.$root.$store.state.LogonInfo
        }
    },
    mounted(){
    },
    methods:{
        logoff(){
            this.$store.commit('changeToken',null)
            this.$router.push({ path: '/' })
        }
    }
}
</script>