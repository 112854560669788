<template>
    <div>
        <planedit v-if="showeditPlan" @closeedit="onPlanEditWinClose"/>
    
        <div id="planlistdiv" v-show="showeditPlan==false">
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>                 
                        <td align="left">
                            <FormItem  label="计划制定日期:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.createtime"  @on-change="formInline.createtime=$event" @on-clear="formInline.createtime=null"></DatePicker>
                            </FormItem>  
                        </td>                    
                        <td align="left">
                            <FormItem  label="计划月度:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
                            </FormItem>  
                        </td>

                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('vehicleQueryForm')" >销售计划单查询</Button>    
                                <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">销售预测计划列表</label>  
                <Button v-hasPermission="'saleplanorder:add'" type="primary" v-if="departid=='107'"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="showeditPlan=true">新增销售预测计划单</Button>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border>
                <template slot-scope="{ row }" slot="ordercode">
                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                </template>
                <template slot-scope="{ row }" slot="progress">
                    {{row.count_purchased+' / '+row.count_total}}
                </template>
                <template slot-scope="{ row }" slot="scprogress">
                    {{row.count_purchased+' / '+row.count_total}}
                </template>
                <template slot-scope="{ row }" slot="expectdate">
                    {{new Date(row.expectdate).Format("yyyy-MM-dd")}}
                </template>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row,index }" slot="products">
                        <table style="font-size:12px;float:left;width:90%" border="0px" rules="rows">
                            <tr style="font-weight:bold;height:15px" align="center">
                                <th style="padding-left:5px;width:60%">产品型号</th>
                                <th style="padding-left:5px;width:20%">颜色</th>
                                <th style="padding-left:5px;width:20%">数量</th>
                            </tr>
                            <tbody  v-show="row.openprod">
                                <tr v-for="(item,idx) in row.products"  :key='idx' align="left">
                                    <td style="padding-left:5px;">{{(idx+1)+'. '+item.productname}}</td>
                                    <td style="padding-left:5px;">{{item.productcolor}}</td>
                                    <td style="padding-left:5px;">{{item.productcount}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="float:right;margin-top:15px">
                            <Icon  type="md-arrow-dropdown" v-show="!row.openprod" style="margin:0 auto;margin-left:50%" size="20" @click="clickopenprod(index)" />
                            <Icon  type="md-arrow-dropup" v-show="row.openprod" style="margin:0 auto;margin-left:50%" size="20" @click="clickopenprod(index)"/>
                        </div>
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <!-- 107 销售部门-->
                    <div  class="opbar" v-if="departid == '107'">
                        <Button    type="success" size="small" @click="viewdetail(index)" >查看/修改</Button>  
                        <Button :disabled="row.status!=0 && row.status != 2" v-hasPermission="'saleplanorder:delete'"  type="error" size="small" @click="remove(index)" >删除</Button>              
                    </div>
                    <!-- 104 财务部门-->
                    <div  class="opbar" v-if="departid == '104'">
                        <Button    type="success" size="small" @click="viewdetail(index)" >查看</Button>  
                        <Button v-if="erpconfig.finCkSaleplan && row.status == 0" v-hasPermission="'finacial:manage'"  type="info" ghost size="small" @click="passed(index)" >审核通过</Button>  
                        <Button v-if="erpconfig.finCkSaleplan && row.status == 0" v-hasPermission="'finacial:manage'"  type="error" ghost size="small" @click="reject(index)" >驳回</Button>             
                    </div>
                    <!-- 105 计划部门-->
                    <div  class="opbar" v-if="departid == '105'">
                        <Button    type="success" size="small" @click="viewdetail(index)" >查看</Button>  
                        <Button :disabled="row.status!=0 && row.status!=1" v-hasPermission="'plan:manage'"  type="error" ghost size="small" @click="reject(index)" >驳回</Button> 
                        <Tooltip content="创建采购单后，销售计划单不可再修改">
                        <Button  :disabled="!(((row.status==0 && !erpconfig.finCkSaleplan) ||(row.status!=0 && erpconfig.finCkSaleplan) || row.status ==1 || row.status == 2) && row.count_total - row.count_purchased >0)" v-hasPermission="'plan:manage'"  type="success" ghost size="small" @click="beforecreatepurchaseorder(index)" >生成采购单</Button>  
                        </Tooltip>
                        <Tooltip content="确认后的销售计划可列入生产计划">
                        <Button  :disabled="row.status!=2" v-hasPermission="'plan:manage'"   type="warning" ghost size="small" @click="beforecreatescorder(index)" >生成生产计划</Button>  
                        </Tooltip>  
                        <Button :disabled="row.status!=2"   type="error" ghost size="small" @click="viewmaterial(index)" >物料清单</Button>           
                    </div>
                    <!-- 106 采购部门-->
                    <div  class="opbar" v-if="departid == '106'">
                        <Button   type="success" size="small" @click="viewdetail(index)" >查看</Button>            
                    </div>
                </template>                
                </Table>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>
        </div>
        <Modal v-model="showdeleteconfirm" title="作废计划单" @on-ok="confirmDelete">确定要作废吗？</Modal>
        <Modal v-model="showdetail" title="销售预测计划清单" width="90%">
            <Table :columns="spdetail.tblcolumns" :data="spdetail.tbldata" v-if="readfinished">
                <template slot-scope="{ row,index }" slot="productcount">
                    <div style="display: inline-flex;width: 100%;" @mouseover="mouseOver($event,index)" @mouseleave="mouseLeave($event,index)">
                        <div v-if="!(((spdetail.orderinfo.status==0 && !erpconfig.finCkSaleplan) || ((spdetail.orderinfo.status==0 ||spdetail.orderinfo.status==1) && erpconfig.finCkSaleplan))) || !spdetail.showinput || (spdetail.showinput && index != spdetail.activeindex)"  style="padding: 3px 0px;">
                            {{row.productcount}}
                        </div>
                        <div v-else>
                            <i-input  type="number"  v-model="spdetail.activecount" ></i-input>
                        </div>
                    </div>
                </template>
            </Table>
            <div v-else>
                正在读取数据...
            </div>
            <div slot="footer" v-if="readfinished">
                <Button size="large" @click="showdetail=false">取消</Button>
                <Button v-if="((spdetail.orderinfo.status==0 && !erpconfig.finCkSaleplan) || ((spdetail.orderinfo.status==0 ||spdetail.orderinfo.status==1) && erpconfig.finCkSaleplan))" type="warning" size="large" @click="detailmodify">{{spdetail.edittext}}</Button>
                <Button v-if="!(((spdetail.orderinfo.status==0 || spdetail.orderinfo.status==1) && erpconfig.finCkSaleplan) || (spdetail.orderinfo.status==0 && !erpconfig.finCkSaleplan))" type="primary" size="large" @click="showdetail=false">确定</Button>
            </div>
        </Modal>
        <Modal v-model="mdetail.showmaterialviewwin" title="查看物料需求清单" width="80%">
            <Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata" v-if="mdetail.readfinished"></Table>
            <div v-else>
                正在读取数据...
            </div>
        </Modal>
        <Modal v-model="showrejectdlg" title="驳回计划单" ok-text="确认驳回" @on-ok="confirmReject">
            <i-input v-model="rejectreason" placeholder="备注驳回原因"></i-input>
        </Modal>
        <Modal v-model="purchaseOrder.showCreate" title="生成采购单" ok-text="保存" >
            <Form ref="orderCreateForm" :model="purchaseOrder.formInline" label-position="right" :label-width="100" >
                <FormItem  label="批次分解:" style="margin-bottom:5px;margin-top:10px">
                    <Select v-model="purchaseOrder.formInline.part" @on-change="partChange">
                      <Option value="0" key="0">全部生成</Option>
                      <Option value="1" key="1">部分生成</Option>
                    </Select>
                </FormItem>
                <FormItem  label="生成数量:" style="margin-bottom:5px;margin-top:10px">
                    <i-input  type="number"  v-model="purchaseOrder.formInline.count" placeholder="请输入本次采购的数量"></i-input>
                </FormItem>  
                <FormItem prop="expectdate"  label="采购到货日期:" >
                    <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="purchaseOrder.formInline.expectdate"  @on-change="purchaseOrder.formInline.expectdate=$event" :options="purchaseOrder.dateoption"></DatePicker>
                </FormItem>
                <FormItem  label="备注:" >
                    <i-input type="textarea" v-model="purchaseOrder.formInline.note" :maxlength=200   placeholder='备注信息' style="width: 400px;"></i-input>
                </FormItem>
            </Form>

            <div slot="footer">
                <Button  type="primary" size="large" long @click="createPurchaseorder">保存</Button>
            </div>
        </Modal>
        <Modal v-model="scOrder.showCreate" title="生成生产计划单" ok-text="保存" >
            <Form ref="orderCreateForm" :model="scOrder.formInline" label-position="right" :label-width="100" v-if="scOrder.readfinished">
                <FormItem  label="车型:" style="margin-bottom:5px;margin-top:10px">
                   <div> {{scOrder.sporderdetail[0].productname}} </div>
                </FormItem>
                <FormItem  label="车辆颜色:" style="margin-bottom:5px;margin-top:10px">
                    <Select v-model="scOrder.formInline.productcolor" @on-change="onsccolorchange">
                        <Option v-for="(item,index) in scOrder.sporderdetail" :value="item.productcolor" :key="index">{{item.productcolor}}</Option>
                    </Select>
                </FormItem>
                <FormItem  label="生成数量:" style="margin-bottom:5px;margin-top:10px">
                    <i-input  type="number"  v-model="scOrder.formInline.count" placeholder="请输入本次生产数量"></i-input>
                </FormItem>  
                <FormItem  label="备注:" >
                    <i-input type="textarea" v-model="scOrder.formInline.note" :maxlength=200   placeholder='备注信息' style="width: 400px;"></i-input>
                </FormItem>
            </Form>
            <div v-else>
                正在读取数据...
            </div>            
            <div slot="footer">
                <Button  type="primary" size="large" long @click="createScorder">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import SPlanEdit from '@/views/business/workshop/sell/saleplan/SPlanEdit'    

export default {
    name:'SPlanOrder',
    props:['departid'],
    data () {
            return {
                erpconfig:this._self.$root.$store.state.ErpConfig,
                showeditPlan:false,
                editSellorderdata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                showrejectdlg:false,
                rejectIndex:null,
                rejectreason:null,
                showdetail:false,
                readfinished:false,
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    status:null,
                    createtime:null,
                    expectdate: sysTool.getNowFormatDate(new Date()),
                    pageSize:20,
                    pageNum:0
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '计划单号',
                        slot: 'ordercode',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '计划车型',
                        key: 'productname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '计划单状态',
                        slot: 'status',
                        width:160,
                        resizable:true
                    },     
                    {
                        title: '采购进度',
                        slot: 'progress',
                        tooltip:true,
                        resizable:true
                    },   
/*
    
                    {
                        title: '计划单产品',
                        slot: 'products',
                        width:300,
                        resizable:true
                    },    
                */
                    {
                        title: '交货日期',
                        slot: 'expectdate',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '下单时间',
                        key: 'createtime',
                        tooltip:true,
                        resizable:true
                    },                                                                 
                    {
                        title: '制定人',
                        key: 'createusername',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        width:480,
                        resizable:true
                    }
                ],
                tbldata: [],
                spdetail:{
                    editmode:false,
                    edittext:'修改',
                    activeindex:null,
                    activecount:0,
                    showinput:false,
                    orderinfo:null,
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '产品型号',
                        key: 'productname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                        resizable:true
                    },    
                    {
                        title: '数量',
                        slot: 'productcount',
                        resizable:true
                    },     
                    {
                        title: '已排产',
                        key: 'productcount_planed',
                        resizable:true
                    },  
                    ]
                },
                scOrder:{
                    readfinished:false,
                    sporderdetail:null,
                    showCreate:false,
                    formInline:{
                        productcolor:0,
                        note:null,
                        orderid:null,
                        count:0
                    },
                    dateoption:{
                        disabledDate (date) {
                            return date && date.valueOf() < Date.now()-86400000;
                        }
                    },
                },
                purchaseOrder:{
                    sporderinfo:null,
                    showCreate:false,
                    formInline:{
                        //1-部分生成、 0-全部生成
                        part:0,
                        expectdate:null,
                        note:null,
                        orderid:null,
                        count:0
                    },
                    dateoption:{
                        disabledDate (date) {
                            return date && date.valueOf() < Date.now()-86400000;
                        }
                    },
                },
                mdetail:{
                    showmaterialviewwin:false,
                    readfinished:false,
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'amount',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                }
            }
        },
        mounted (){

            //计划部门
            if(this.departid == '105'){
                this.tblcolumns.splice(5,2)
                this.tblcolumns.splice(4,0,
                {
                    title: '生产进度',
                    slot: 'scprogress',
                    tooltip:true,
                    resizable:true
                },   
                )
            }

            setTimeout(()=>{
                this.handleQuery()
            },2000)
            
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
  
                if(this.formInline.createtime !=null){
                    if(this.formInline.createtime[0] !=''){
                        postdata.createtimestart = this.formInline.createtime[0]
                    }
                    if(this.formInline.createtime[1] !=''){
                        postdata.createtimeend = this.formInline.createtime[1]
                    }
                }

                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }

                if(postdata.ordercode !=null){
                    postdata.ordercode = this.formInline.ordercode.trim()
                }

                for(var p in postdata){
                    if(postdata[p] == null){
                        delete postdata[p]
                    }
                }

                console.log("销售预测订单查询表单数据",postdata)
                axios.post("/api/saleplan/order/query",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("销售预测订单查询结果",res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios.post(
                    "/api/saleplan/orderdetail/query",
                    {
                        orderid: this.tbldata[index].id
                    },
                ).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("销售预测订单详情查询结果",res.data.data)
                        if(rspdata != null){
                            var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                            tmptbldata[index].products =rspdata
                            this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                            callback(index)
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onSubmmitOver(){
                this.showeditSellorder =false
                this.handleQuery()
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            activedetailitem(index){
                this.spdetail.activecount = this.spdetail.tbldata[index].productcount
                this.spdetail.showinput = true
            },
            detailmodify(){
                if(this.spdetail.editmode){
                    this.spdetail.editmode = false
                    this.spdetail.edittext = '修改'
                    console.log("pnumber0",this.$refs['pnumber0'])
                    console.log("修改后的销售计划详情数据",this.spdetail.tbldata)
                    let postdata = {
                        plandetail: JSON.stringify(this.spdetail.tbldata)
                    }
                    this.$axios.post("/api/saleplan/orderdetail/update",postdata).then(function(res){
                        if(res.data.resultCode ==0){
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.handleQuery()
                            this.showdetail = false
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:5})
                        }
                    }.bind(this));  
                }
                else{
                    this.spdetail.editmode = true
                    this.spdetail.edittext = '保存修改'
                }
            },
            confirmReject (){
                let postdata={
                    orderid : this.tbldata[this.rejectIndex].id,
                    ordercode: this.tbldata[this.rejectIndex].ordercode,
                    note: this.rejectreason,
                    status : 3
                }

                if(this.rejectreason ==null){
                    postdata.note = "驳回"
                }

                this.$axios.post("/api/saleplan/order/updatemstatus",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:'已驳回', duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            reject (index){
                this.showrejectdlg = true
                this.rejectIndex = index
            },
            passed(index){
                let postdata={
                    orderid : this.tbldata[index].id,
                    ordercode: this.tbldata[index].ordercode,
                    status : 1
                }

                this.$axios.post("/api/saleplan/order/updatemstatus",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:'已审核通过', duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            viewdetail(index){
                this.readfinished = false
                this.showdetail = true

                if(this.tbldata[index].products == null || this.tbldata[index].products.length==0){
                    this.queryOrderDetail(index,(index)=>{
                        this.viewdetail(index)
                    })
                }
                else{
                    var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                    this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                    this.spdetail.orderinfo = this.tbldata[index]
                    this.spdetail.tbldata = JSON.parse(JSON.stringify(this.tbldata[index].products))
                    this.readfinished = true
                    console.log("查看销售计划单详情",this.spdetail)
                }
            },
            viewmaterial(index){
                this.mdetail.readfinished = false
                this.mdetail.showmaterialviewwin = true
                this.$axios.post(
                    "/api/plan/materialorder/querybysaleplan",
                    {
                        saleplanordercode: this.tbldata[index].ordercode
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.mdetail.tbldata = JSON.parse(res.data.data.content)
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                        this.mdetail.tbldata = []
                    }

                    this.mdetail.readfinished = true

                }.bind(this))
            },
            beforecreatescorder(index){
                this.scOrder.sporderdetail=[]
                this.scOrder.readfinished = false
                this.scOrder.showCreate = true

                this.$axios.post(
                    "/api/saleplan/orderdetail/query",
                    {
                        orderid: this.tbldata[index].id
                    },
                ).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("销售预测订单详情查询结果",res.data.data)
                        if(rspdata != null){
                            this.scOrder.sporderdetail = rspdata
                            this.scOrder.formInline.orderid = this.tbldata[index].id
                            this.scOrder.formInline.ordercode = this.tbldata[index].ordercode
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                    this.scOrder.readfinished = true
                }.bind(this));   
            },
            createScorder(){
                if(this.scOrder.formInline.productcolor == null || this.scOrder.formInline.productcolor ==''){
                    this.$Message.error({content:'请选择车辆颜色',duration:5})
                    return
                }
                if(this.scOrder.formInline.count == null || this.scOrder.formInline.count ==''){
                    this.$Message.error({content:'请输入车辆数量',duration:5})
                    return
                }

                let details = this.scOrder.sporderdetail
                for(let i=0;i<details.length;i++){
                    if(details[i].productcolor == this.scOrder.formInline.productcolor){
                        let maxcount = details[i].productcount - details[i].productcount_planed
                        if(this.scOrder.formInline.count>maxcount){
                            this.$Message.error({content:'数量超出超出限制',duration:5})
                            return
                        }
                    }
                }

                this.$axios.post("/api/plan/order/createfromsaleplan",
                    this.scOrder.formInline
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.scOrder.showCreate = false
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            },
            beforecreatepurchaseorder(index){
                this.purchaseOrder.sporderinfo = this.tbldata[index]
                this.purchaseOrder.formInline.orderid = this.tbldata[index].id
                this.purchaseOrder.formInline.ordercode = this.tbldata[index].ordercode
                this.purchaseOrder.showCreate = true
            },
            createPurchaseorder(){
                this.$axios.post("/api/saleplan/order/createpurchaseorder",
                this.purchaseOrder.formInline
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.purchaseOrder.showCreate = false
                        this.createMaterialPlan(this.purchaseOrder.sporderinfo.id)
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            },
            createMaterialPlan(saleplanid){
                this.$axios.post("/api/plan/materialorder/createfromsaleplan",
                    {
                        'saleplanid': saleplanid
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        //this.$Message.success({content:res.data.msg, duration:3})
                        //this.handleQuery()
                    }
                    else{
                        //this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            },
            confirmDelete(){
                this.$axios.post("/api/saleplan/order/delete",{id:this.tbldata[this.deleteIndex].id})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            onOrderStatusSelect(status){
                if (status == null || status == 'null'){
                    this.formInline.status = null
                }
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            onpagechange(v){
                this.formInline.pageNum = v
                this.handleQuery()
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].status
                if(status ==0){
                    return '未确认'
                }
                else if(status ==1){
                    return '已审核'
                }
                else if(status ==2){
                    return '已确认'
                }
                else if(status ==3){
                    return '已驳回'
                }
            },
            clickopenprod(index){
                console.log(index)
                if(this.tbldata[index].products == null || this.tbldata[index].products.length==0){
                    this.queryOrderDetail(index,(index)=>{
                        this.clickopenprod(index)        
                    })
                }
                else{

                    var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                    tmptbldata[index].openprod = !tmptbldata[index].openprod
                    this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                }
            },
            onPlanEditWinClose(){
                this.showeditPlan=false
                this.handleQuery()
            },
            partChange(v){
                if(v=='0'){
                    console.log(this.purchaseOrder.sporderinfo)
                    this.purchaseOrder.formInline.count = this.purchaseOrder.sporderinfo.count_total - this.purchaseOrder.sporderinfo.count_purchased
                }
            },
            onsccolorchange(v){
                let details = this.scOrder.sporderdetail
                for(let i=0;i<details.length;i++){
                    if(details[i].productcolor == v){
                        this.scOrder.formInline.count = details[i].productcount - details[i].productcount_planed
                        return
                    }
                }

                this.scOrder.formInline.count = 0
            },
            mouseOver(event,index){
                console.log("mouseOver")
                if(this.spdetail.editmode){
                    if(this.spdetail.activeindex == index && this.spdetail.showinput){
                        //重复激发mouseover事件
                        return
                    }
                    this.spdetail.activeindex = index
                    this.spdetail.activecount = this.spdetail.tbldata[index].productcount
                    this.spdetail.showinput = true
                }
            },
            mouseLeave(e,index){
                if(!this.spdetail.editmode){
                    return
                }
                console.log("mouseLeave",index)
                console.log("mouseLeave",this.spdetail.activeindex)
                if(index == this.spdetail.activeindex && this.spdetail.showinput){
                    if(this.spdetail.activecount <0){
                        this.spdetail.activecount =0
                    }
                    this.spdetail.tbldata[index].productcount=this.spdetail.activecount
                }
                console.log("mouseLeave",this.spdetail.tbldata)
                this.spdetail.showinput = false
            }
        },
        watch:{
            'showdetail':{
                handler: function(newV, oldV) {
                    if(!newV){
                        this.spdetail.editmode = false
                        this.spdetail.edittext = '修改'
                        this.spdetail.activeindex = null
                    }
                }
            },
            'purchaseOrder.showCreate':{
                deep:true,
                handler: function(newV, oldV) {
                    if(!newV){
                        this.purchaseOrder.sporderinfo = null
                        this.purchaseOrder.formInline.count =0
                        this.purchaseOrder.formInline.note =null
                        this.purchaseOrder.formInline.part =null
                        this.purchaseOrder.formInline.expectdate =null
                    }
                }
            }
        },
        components:{
            'planedit':SPlanEdit
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>