// get brower
export function GetCurrentBrowser () {
  let ua = navigator.userAgent.toLocaleLowerCase()
  let browserType = null
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = 'IE'
  } else if (ua.match(/firefox/) != null) {
    browserType = 'firefox'
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = 'UC'
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = 'opera'
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = 'baidu'
  } else if (ua.match(/metasr/) != null) {
    browserType = 'sougou'
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = 'QQ'
  } else if (ua.match(/maxthon/) != null) {
    browserType = 'maxthon'
  } else if (ua.match(/chrome/) != null) {
    var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    if (is360) {
      browserType = '360'
    } else {
      browserType = 'chrome'
    }
  } else if (ua.match(/safari/) != null) {
    browserType = 'Safari'
  } else {
    browserType = 'others'
  }
  return browserType
}

function _mime (option, value) {
  var mimeTypes = navigator.mimeTypes
  for (var mt in mimeTypes) {
    if (mimeTypes[mt][option] === value) {
      return true
    }
  }
  return false
}

// get os
export function GetOs () {
  let sUserAgent = navigator.userAgent.toLocaleLowerCase()
  let isWin = (navigator.platform === 'win32') || (navigator.platform === 'windows')
  let isMac = (navigator.platform === 'mac68k') || (navigator.platform === 'macppc') || (navigator.platform === 'macintosh') || (navigator.platform === 'macintel')
  if (isMac) return 'Mac'
  var isUnix = (navigator.platform === 'x11') && !isWin && !isMac
  if (isUnix) return 'Unix'
  var isLinux = (String(navigator.platform).indexOf('linux') > -1)
  if (isLinux) return 'Linux'
  if (isWin) {
    var isWin2K = sUserAgent.indexOf('windows nt 5.0') > -1 || sUserAgent.indexOf('windows 2000') > -1
    if (isWin2K) return 'Win2000'
    var isWinXP = sUserAgent.indexOf('windows nt 5.1') > -1 || sUserAgent.indexOf('windows xp') > -1
    if (isWinXP) return 'WinXP'
    var isWin2003 = sUserAgent.indexOf('windows nt 5.2') > -1 || sUserAgent.indexOf('windows 2003') > -1
    if (isWin2003) return 'Win2003'
    var isWinVista = sUserAgent.indexOf('windows nt 6.0') > -1 || sUserAgent.indexOf('windows vista') > -1
    if (isWinVista) return 'WinVista'
    var isWin7 = sUserAgent.indexOf('windows nt 6.1') > -1 || sUserAgent.indexOf('windows 7') > -1
    if (isWin7) return 'Win7'
  }
  if (sUserAgent.indexOf('android') > -1) return 'Android'
  if (sUserAgent.indexOf('iphone') > -1) return 'iPhone'
  if (sUserAgent.indexOf('symbianos') > -1) return 'SymbianOS'
  if (sUserAgent.indexOf('windows phone') > -1) return 'Windows Phone'
  if (sUserAgent.indexOf('ipad') > -1) return 'iPad'
  if (sUserAgent.indexOf('ipod') > -1) return 'iPod'
  return 'others'
}

// getAddress
// {/*<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>*/}
// {/*export function GetAddress () {*/}
//   {/*return returnCitySN*/}
// {/*}*/}

export function strtodate(datestr){
  return new Date(datestr)
}

/**
 * 
 * @param {*} startdate date
 * @param {*} enddate date
 */
export function datediff(startdate,enddate){
  var endTime = enddate.getTime() / 1000 - parseInt(startdate.getTime() / 1000);
  var timeDay = parseInt(endTime / 60 / 60 / 24);        //相差天数
  return timeDay
}

/**
 * 
 * @param {*} expirydate expirydate为yyyy-MM-dd字符串类型
 */
export function nowdatediff(expirydate){
  var nowdate = new Date();
  return datediff(nowdate,strtodate(expirydate))
}

export function getNowFormatDate(date) {
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
      month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

    //获取与毫秒数的转化比例（相差天数：1，相差小时数：2，相差分钟数：3，相差秒数：4）
    var getDifferScale = function (value) {
      var format;
      //获取转化比（天数跟毫秒数的比例）
      if (value == 1) {
          format = parseFloat(24 * 60 * 60 * 1000);
      }
          //获取转化比（小时数跟毫秒数的比例）
      else if (value == 2) {
          format = parseFloat(60 * 60 * 1000);
      }
          //获取转化比（分钟数跟毫秒数的比例）
      else if (value == 3) {
          format = 1000;
      }
          //获取转化比（秒数跟毫秒数的比例）
      else if (value == 4) {
          format = parseFloat(1000);
      }
      return format;
  }
  
  //获取两个日期的相差日期数(differ 相差天数：1、相差小时数：2、相差分钟数：3、相差秒数：4)
  export function getDifferDate(firstDate, secondDate, differ) {
    //1)将两个日期字符串转化为日期对象
    var startDate = new Date(firstDate);
    var endDate = new Date(secondDate);
    //2)计算两个日期相差的毫秒数
    var msecNum = endDate.getTime() - startDate.getTime();
    //3)计算两个日期相差的天数
    var dayNum = Math.floor(msecNum /getDifferScale(differ));
    return dayNum;
}

// date 代表指定的日期，格式：2018-09-27
// day 传-1表始前一天，传1表始后一天
// JS获取指定日期的前一天，后一天
export function getNextDate(date, day) { 
  　　var dd = new Date(date);
  　　dd.setDate(dd.getDate() + day);
  　　var y = dd.getFullYear();
  　　var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  　　var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  　　return y + "-" + m + "-" + d;
  };
