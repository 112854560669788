import axios from 'axios'
import { Modal,Message } from "view-design";
import store from '@/store'

axios.defaults.timeout = 90000;   // 超时时间，90秒
axios.defaults.headers ={'Content-Type': 'application/x-www-form-urlencoded'};
axios.defaults.transformRequest =function(obj) {
    var str = [];
    for(var p in obj){
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
}

axios.interceptors.request.use(function (config) {
　　//这里经常搭配token使用，将token值配置到tokenkey中，将tokenkey放在请求头中
　　config.headers['Authorization'] = store.state.AccessToken;
    config.headers['clientInfo'] = JSON.stringify({ip:sessionStorage.getItem('ip'),agent:navigator.userAgent.toLocaleLowerCase(),resolution:window.screen.width+'x'+window.screen.height})
　　return config;
}, function (error) {
    // Do something with request error
    console.log("request error拦截器")
    return Promise.reject(error);
});
  
// 添加一个响应拦截器
axios.interceptors.response.use(function (response) {
    // Do something with response data
    //console.log(response)
    switch (response.data.resultCode)
    {
    case 401:
    case 205:
        {
        router.replace({path:"/"})
        break;
        //router.replace(path: 'login',
        //query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面)
        }
    }
    return response;
}, function (error) {
    // Do something with response error
    console.log(error)
    Message.error({content:'网络异常!!!!',duration:6})
    return Promise.reject(error);
});

export default axios