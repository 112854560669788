<template>
    <div>
        设置
    </div>
</template>

<script>
export default {
    name:'AdminSettings'
}
</script>