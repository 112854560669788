<template>
<div >
        <div style="height:40px;width:100%;" v-if="caller!='viewer'">
            <Button v-hasPermission="'model:add'" type="primary"  style="margin-top:3px;float:left" @click="addmastermodel">添加车型数据</Button>
        </div>
        <!-- 车型数据列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="modelVer">
                <strong>{{ row.modelVer }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="bom" >
                <div class="opbar">
                    <Button type="warning" ghost size="small" @click="viewbom(index)" >查看</Button>       
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div class="opbar">
                    <Button type="success" size="small" @click="view(index)" >查看</Button>
                    <Button v-hasPermission="'model:delete'" type="error" size="small" @click="remove(index)" v-if="caller!='viewer'">停用</Button>
                    <Button v-hasPermission="'model:update'" type="info" size="small" ghost @click="modify(index)" v-if="caller!='viewer'">修改</Button>         
                </div>
            </template>
            </Table>
        </div>
        <mastermodelEdit v-bind:show="showeditmastermodel" v-bind:action="mastermodelaction" v-bind:mastermodeldata="editmastermodeldata" v-bind:departid="departid" @Closemastermodel="onEditClose" @SaveOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="停用车型" @on-ok="confirmDelete">确定要停用吗？停用车型后，将不可恢复</Modal>
        <Modal v-model="showbom" title="查看车型BOM" width="80%">
            <bomview v-if="showbom" v-bind:modeldata="bom_modeldata"  />
        </Modal>
    </div>
</template>

<script>
import MasterModelEdit from '@/views/business/workshop/technology/MasterModelEdit'
import BomView from '@/views/business/workshop/technology/BomView'
import axios from 'axios'
export default {
    name:'mastermodel',
    props:['caller','departid'],//viewer: 其它模块调用查看 owner：技术模块管理
    data () {
            return {
                showeditmastermodel:false,
                editmastermodeldata:null,
                mastermodelaction:'edit',
                showdeleteconfirm:false,
                showbom:false,
                bom_modeldata:null,
                deleteIndex:null,
                withstockinfo:false,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '车型版本',
                        slot: 'modelVer',
                    },
                    {
                        title: '车型名称',
                        key: 'modelAlias',
                    },
                    {
                        title: '配置',
                        key: 'modelConfig',
                    },
                    {
                        title: '成本价(元)',
                        key: 'costPrice',
                    },   
                    {
                        title: '备注',
                        key: 'note',
                    },      
                    {
                        title: 'bom操作',
                        slot: 'bom',
                        align: 'center'
                    }, 
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.withstockinfo = false
            if(this.departid == '104'){
                this.tblcolumns.splice(5,0,                    
                    {
                        title: '建议销售价(元)',
                        key: 'sugSalePrice',
                    },
                    {
                        title: '库存数量(台)',
                        key: 'stock_num',
                    }
                )

                this.withstockinfo = true
            }
            this.queryModelData()
        },
        methods: {
            queryModelData(){
                axios.all([
                    axios.post("/api/tech/master/modelver/query",{companyId:this._self.$root.$store.state.LogonInfo.companyid,withstockinfo: this.withstockinfo}),
                    axios.post('/api/tech/master/model/query',{companyId:this._self.$root.$store.state.LogonInfo.companyid}),
                    axios.post("/api/tech/master/modelver/getmodellist",{companyId:this._self.$root.$store.state.LogonInfo.companyid})
                ])
                .then(axios.spread(function (modelVerRsp, modelRsp, modellistRsp) {
                    // 上面两个请求都完成后，才执行这个回调方法
                    console.log('modelVer数据', modelVerRsp);
                    console.log('model数据', modelRsp);
                    if(modelVerRsp.data.resultCode ==0){
                        this.tbldata =modelVerRsp.data.data
                        this.$store.commit('changeMasterModelVerList',modelVerRsp.data.data)
                        //this.$Message.success({content:modelVerRsp.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }

                    if(modelRsp.data.resultCode ==0){
                        this.$store.commit('changeMasterModelList',modelRsp.data.data)
                    }
                    else{
                        this.$Message.error({content:modelRsp.data.msg, duration:3})
                    }

                    if(modellistRsp.data.resultCode ==0){
                        console.log("更新车型数据后读取车型选择列表",modellistRsp.data.data)
                        var MasterModelDatalist = modellistRsp.data.data
                        this.$store.commit("changeModelChooseListInfo",MasterModelDatalist)
                    }
                    else{
                        this.$Message.error({content:modelRsp.data.msg, duration:3})
                    }
                }.bind(this)));
            },
            queryModelList(){
                axios.post('/api/tech/master/model/query',{companyId:this._self.$root.$store.state.LogonInfo.companyid})
                .then(function (modelRsp) {
                    console.log('model数据', modelRsp);
                    if(modelRsp.data.resultCode ==0){
                        this.$store.commit('changeMasterModelList',modelRsp.data.data)
                    }
                    else{
                        this.$Message.error({content:modelRsp.data.msg, duration:3})
                    }
                }.bind(this));
            },
            handleQuery() {
                axios.post("/api/tech/master/modelver/query",{companyId:this._self.$root.$store.state.LogonInfo.companyid})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addmastermodel(){
                this.editmastermodeldata = null
                this.mastermodelaction = 'add'
                this.showeditmastermodel = true                
            },
            modify (index) {
                this.editmastermodeldata = this.tbldata[index]
                this.mastermodelaction = 'edit'
                this.showeditmastermodel = true
            },
            view (index){
                this.editmastermodeldata = this.tbldata[index]
                this.mastermodelaction = 'view'
                this.showeditmastermodel = true
            },
            viewbom (index){
                this.bom_modeldata = this.tbldata[index]
                this.showbom = true
            },
            confirmDelete(){
                axios.post("/api/tech/master/modelver/delete",{id:this.tbldata[this.deleteIndex].id})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditmastermodel =false
            },
            onSubmmitOver(){
                this.showeditmastermodel =false
                this.queryModelData()
            },
        },
        components:{
            mastermodelEdit:MasterModelEdit,
            bomview: BomView
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>