<template>
<div >
    <Form ref="accountQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
        <table width="100%">
            <tr>
                <td align="right" width="23%">
                    <FormItem prop="aType" label="账目类型" style="margin-bottom:5px;margin-top:10px">
                        <Select v-model="formInline.aType" >
                            <Option value=0 :key=0>请选择</Option> 
                            <Option value=1 :key=1>采购出账</Option> 
                            <Option value=2 :key=2>委外出账</Option> 
                            <Option value=100 :key=100>销售入账</Option>  
                        </Select>
                    </FormItem>
                </td>
                <td width="23%">
                    <FormItem prop="aClassifyId" label="账目分类" style="margin-bottom:5px;margin-top:10px">
                        <Select v-model="formInline.aClassifyId">
                            <Option value=0 :key=0>请选择</Option> 
                            <Option value=1 :key=1>定金</Option> 
                            <Option value=2 :key=2>尾款</Option> 
                            <Option value=3 :key=3>全款</Option>   
                        </Select>
                    </FormItem>
                </td>
                <td align="left" width="23%">
                    <FormItem  label="账目日期:" style="margin-top:5px;margin-bottom:5px;">
                        <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.happendDate"  @on-change="onmonthselect"" @on-clear="formInline.happendDate=null"></DatePicker>
                    </FormItem>  
                </td>
                <td rowspan="2" colspan="2" align="right">
                    <div>
                        <Button type="primary" @click="handleQuery('accountQueryForm')" >账目查询</Button>    
                        <Button  @click="handleReset('accountQueryForm')" style="margin-left:10px">重置</Button>
                    </div>
                </td>
            </tr>
        </table>
    </Form>    
    <div style="height:40px;width:100%;">
        <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">出入帐列表</label>  
        <Button v-hasPermission="'finacial:manage'" type="primary"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="addSOrderAccount">录入销售账目</Button>
        <Button v-hasPermission="'finacial:manage'" type="primary"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="addPOrderAccount">录入采购账目</Button>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>        
    <!-- 订单列表-->
    <div style="padding-top:10px">
        <Table :columns="tblcolumns" :data="tbldata" stripe border>
            <template slot-scope="{ row }" slot="ordercode">
                <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
            </template>
            <template slot-scope="{ row }" slot="atype">
                <div v-if="row.aType == 1">
                    采购出账
                </div>
                <div v-if="row.aType == 2">
                    委外出账
                </div>
                <div v-if="row.aType == 100">
                    销售入账
                </div>
            </template>
            <template slot-scope="{ row }" slot="intoAmount">
                {{row.aType==2? (row.payMoney):'/'}}
            </template>
            <template slot-scope="{ row }" slot="outAmount">
                {{row.aType==1? (row.payMoney):'/'}}
            </template>
            <template slot-scope="{ row,index }" slot="aClassifyName">
                    {{getorderinfo(index)}}
            </template>
            <template slot-scope="{ row }" slot="happendDate">
                    {{new Date(row.happendDate).Format("yyyy-MM-dd")}}
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div  class="opbar">
                <Button v-hasPermission="'finacial:manage'" type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                <Button v-hasPermission="'finacial:manage'" type="error" size="small" ghost @click="remove(index)" style="float:right">删除</Button>  
                </div>      
            </template>
            <template slot-scope="{ row }" slot="totalprice">
                <p style="font-size:12px">￥{{row.totalprice/10000}}万</p>
            </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>
    <!--
    <FinanceAccountEdit v-bind:show="showeditFinanceAccount" v-bind:FinanceAccountdata="editFinanceAccountdata" @CloseFinanceAccount="onEditClose" @SubmmitOver="onSubmmitOver"/>
    -->
    <saccountedit  v-bind:show="showeditSOrderAccount" v-bind:ordercode="accountordercode" v-bind:accountdata="editFinanceAccountdata" @CloseFinanceAccount="onEditSAccountClose" @OnSaveOver="onSaveOver"/>
    <paccountedit id="paccountedit"  v-bind:show="showeditPOrderAccount" v-bind:ordercode="accountordercode" v-bind:accountdata="editFinanceAccountdata" @CloseFinanceAccount="onEditPAccountClose" @OnSaveOver="onPAccountSaveOver"/>
    <Modal v-model="showdeleteconfirm" title="删除账目" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import FinanceAccountEdit from './FinanceAccountEdit.vue'
import SaleOrderAccountEdit from '@/views/business/workshop/finance/SaleOrderAccountEdit'
import PurchaseOrderAccountEdit from '@/views/business/workshop/finance/PurchaseOrderAccountEdit'
export default {
    name:'FinanceAccount',
    data () {
            return {
                showeditFinanceAccount:false,
                showeditSOrderAccount:false,
                showeditPOrderAccount:false,
                editFinanceAccountdata:null,
                accountordercode:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                formInline:{
                    aType:null,
                    aClassifyId:null,
                    happendDate:sysTool.getNowFormatDate(new Date())
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '类型',
                        slot: 'atype',
                        resizable:true
                    },
                    {
                        title: '账目日期',
                        slot: 'happendDate',  
                        resizable:true             
                    },
                    {
                        title: '支出(单位:元)',
                        slot: 'outAmount',   
                        resizable:true         
                    },
                    {
                        title: '收入(单位:元)',
                        slot: 'intoAmount',  
                        resizable:true             
                    },
                    {
                        title: '分类',
                        slot: 'aClassifyName',
                        resizable:true,
                        tooltip:true
                    },
                    {
                        title: '备注',
                        key: 'note',
                        resizable:true,
                    },
                    {
                        title: '操作',
                        slot: 'action',
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleReset (name) {
                this.formInline.happendDate = null
                this.$refs[name].resetFields();
                this.handleQuery()
            },
            handleQuery() {
                var postdata ={
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize                    
                }

                if(this.formInline.aType !=null){
                    postdata.aType = this.formInline.aType
                }
                if(this.formInline.aClassifyId !=null){
                    postdata.aClassifyId = this.formInline.aClassifyId
                }

                /*
                if(this.formInline.happendDate !=null && this.formInline.happendDate.length>0){
                    if(this.formInline.happendDate[0] !=''){
                        postdata.happenddatestart = this.formInline.happendDate[0]
                    }
                    if(this.formInline.happendDate[1] !=''){
                        postdata.happenddateend = this.formInline.happendDate[1]
                    }
                }*/

                if(this.formInline.happendDate !=null && this.formInline.happendDate!=''){
                    if(typeof(this.formInline.happendDate) == 'object'){
                        postdata.happendDate = sysTool.getNowFormatDate(this.formInline.happendDate)
                    }
                    else{
                        postdata.happendDate = this.formInline.happendDate
                    }
                    
                }

                this.$axios({
                    method:'post',
                    url:"/api/fin/account/query",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        this.tbldata =rspdata
                        console.log(rspdata)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addFinanceAccount(){
                this.editFinanceAccountdata = null
                this.showeditFinanceAccount = true                
            },
            addSOrderAccount(){
                this.editFinanceAccountdata = null
                this.showeditSOrderAccount = true
            },
            addPOrderAccount(){
                this.editFinanceAccountdata = null
                this.showeditPOrderAccount = true
            },
            modify (index) {
                
                if(this.tbldata[index].sOrdercode !=null){
                    this.showeditSOrderAccount = true
                }
                else if(this.tbldata[index].pOrdercode !=null){
                    this.showeditPOrderAccount = true
                }
                this.editFinanceAccountdata = this.tbldata[index]
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/FinanceAccount/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            getorderinfo (index){
                let orderinfo=null
                let classify = this.tbldata[index].aClassifyName
                if(classify ==null || classify == 'null'){
                    classify = ''
                }
                if(this.tbldata[index].pOrdercode !=null){
                    return '采购订单:'+this.tbldata[index].pOrdercode+" "+ classify
                }
                if(this.tbldata[index].sOrdercode !=null){
                    return '销售订单:'+this.tbldata[index].sOrdercode+" "+ classify
                }

                return classify
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditFinanceAccount =false
            },
            onEditSAccountClose(){
                this.showeditSOrderAccount = false
            },
            onEditPAccountClose(){
                this.showeditPOrderAccount = false
                console.log('onEditPAccountClose')
            },
            onPAccountSaveOver(){
                this.showeditPOrderAccount = false
                this.handleQuery()
            },
            onSaveOver(){
                this.showeditSOrderAccount = false
                this.handleQuery()
            },
            onSubmmitOver(){
                this.showeditFinanceAccount =false
                this.handleQuery()
            },
            onOrderStatusSelect(status){

            },
            onmonthselect(e){
                if(e == null || e==''){
                    this.formInline.happendDate =null
                }
                else{
                    this.formInline.happendDate=e+'-01'
                }
            },
            onpagechange(){

            },
            clickopenprod(index){
                console.log(index)
                this.tbldata[index].openprod = !this.tbldata[index].openprod
            }
        },
        components:{
            FinanceAccountEdit:FinanceAccountEdit,
            'saccountedit':SaleOrderAccountEdit,
            'paccountedit':PurchaseOrderAccountEdit
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>