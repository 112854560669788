<template>
<div >
    <div id="printdiv" style="display: none;" >
        <sellprint ref="sellprint" />
    </div>
    <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
        <table width="100%">
            <tr>
                <td align="right" width="23%">
                    <FormItem  label="订单编号:" style="margin-bottom:5px;margin-top:10px">
                        <i-input type="text" v-model="formInline.ordercode">
                        </i-input>
                    </FormItem>
                </td>
                <td width="23%">
                    <FormItem prop="mstatus" label="订单状态" style="margin-bottom:5px;margin-top:10px">
                        <Select v-model="formInline.mstatus" @on-change="onmstatuschange">
                            <Option value=null key=100>全部</Option> 
                            <Option value=0 key=0>待审核</Option> 
                            <Option value=1 key=1>已审核</Option> 
                            <Option value=2 key=2>已驳回</Option> 
                            <Option value=3 key=3>已发货</Option>   
                            <Option value=4 key=4>已完成</Option>    
                        </Select>
                    </FormItem>
                </td>
                <td align="left" width="23%">
                    <FormItem prop="fstatus" label="付款状态" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.fstatus" @on-change="onfstatuschange">
                                <Option value=null key=100>全部</Option> 
                                <Option value=0 key=0>未付款</Option> 
                                <Option value=1 key=1>已付定金</Option> 
                                <Option value=2 key=2>已付全款</Option>   
                            </Select>
                    </FormItem>
                </td>

                <td rowspan="2" colspan="2" align="right">
                    <div>
                        <Button type="primary" @click="handleQuery('vehicleQueryForm')" >订单查询</Button>    
                        <Button  @click="handleReset('orderQueryForm')" style="margin-left:10px">重置</Button>
                    </div>
                </td>
            </tr>
            <tr>
                <td align="left">
                    <FormItem prop="customer" label="客户公司名称:" style="margin-bottom:5px;margin-top:10px">
                        <i-input type="text" v-model="formInline.customer">
                        </i-input>                   
                    </FormItem>  
                </td>            
                <td align="right">
                        <FormItem  label="下单日期:" style="margin-bottom:5px;">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.createtime"  @on-change="formInline.createtime=$event" @on-clear="ondateclear"></DatePicker>
                        </FormItem>  
                </td>
                <td align="left" width="23%">
                        <FormItem  label="交付月份:" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
                        </FormItem>  
                    <!--
                        <FormItem prop="phone" label="客户电话:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.phone">
                            </i-input>                   
                        </FormItem>  
                    -->
 
                </td>        
            </tr>
        </table>
    </Form>    
    <div style="height:40px;width:100%;">
        <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">我的订单列表</label>  
        <Button v-hasPermission="'saleorder:manage'" type="primary" v-if="departid=='107'"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="addSellorder">添加新订单</Button>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pageSize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>        
    <!-- 订单列表-->
    <div style="padding-top:10px">
        <Table :columns="tblcolumns" :data="tbldata" stripe border>
        <template slot-scope="{ row }" slot="ordercode">
            <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
        </template>
        <template slot-scope="{ row,index }" slot="products">
                <table style="font-size:10px;float:left;width: 90%;" border="0px" rules="rows">
                    <tr style="font-weight:bold;height:15px" align="center">
                        <th style="padding-left:5px">产品型号</th>
                        <th style="padding-left:5px">颜色</th>
                        <th style="padding-left:5px">数量</th>
                        <th style="padding-left:5px">销售单价</th>
                        <th style="padding-left:5px">原单价</th>
                        <th style="padding-left:5px">合计</th>
                    </tr>
                    <tbody  v-show="row.openprod">
                        <tr v-for="(item,idx) in row.products"  :key='idx' align="left">
                            <td style="padding-left:5px;">{{(idx+1)+'. '+item.productname}}</td>
                            <td style="padding-left:5px;">{{item.productcolor}}</td>
                            <td style="padding-left:5px;">{{item.productcount}}</td>
                            <td style="padding-left:5px;">￥{{item.priceper}}</td>
                            <td style="padding-left:5px;text-decoration:line-through">￥{{getprice(item.cerid)}}</td>
                            <td style="padding-left:5px;">￥{{item.productcount * item.priceper}}</td>
                        </tr>
                    </tbody>
                </table>
                <div style="float:right;margin-top:15px">
                    <Icon size="20"  type="md-arrow-dropdown" v-show="!row.openprod" style="margin:0 auto;margin-left:50%" @click="clickopenprod(index)" />
                    <Icon size="20"  type="md-arrow-dropup" v-show="row.openprod" style="margin:0 auto;margin-left:50%" @click="clickopenprod(index)"/>
                </div>
        </template>
        <template slot-scope="{ row, index }" slot="action" >
            <!-- 102 成品库部门-->
            <div  class="opbar" v-if="departid == '102'">
                <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>      
                <Button type="info" size="small" @click="viewhistory(row.ordercode)" >历史</Button>  
                <Button type="success" ghost size="small" @click="print(index)" >打印清单</Button>    
            </div>
            <!-- 104 财务部门-->
            <div  class="opbar" v-if="departid == '104'">
                <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>      
                <Button type="info" size="small" @click="viewhistory(row.ordercode)" >历史</Button>   
                <Button :disabled="row.mstatus!=0" v-hasPermission="'finacial:manage'" type="info" size="small" ghost @click="updateorder(index)" >审核</Button>             
                <Button v-hasPermission="'finacial:manage'" type="error" size="small" ghost @click="viewhistory(row.ordercode)" v-if="row.unconfirm">收款确认</Button>  
                <Button type="success" ghost size="small" @click="print(index)" >打印清单</Button>          
            </div>
            <!-- 107 销售部门-->
            <div  class="opbar" v-if="departid == '107'">
                <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>  
                <Button type="info" size="small" @click="viewhistory(row.ordercode)">历史</Button>  
                <Button v-if="row.mstatus ==0 || row.mstatus ==2" v-hasPermission="'saleorder:delete'" type="error" size="small" @click="remove(index)">作废</Button>               
                <Button v-hasPermission="'saleorder:manage'" type="info" size="small" ghost @click="updateorder(index)" >发货</Button>
                <Button v-hasPermission="'saleorder:manage'" type="error" size="small" ghost @click="updateorder(index)" >结束订单</Button>
                <Button v-hasPermission="'saleorder:manage'" type="success" size="small" ghost @click="doaccount(row.ordercode)">收款</Button>      
                <Button type="success" ghost size="small" @click="print(index)" >打印清单</Button> 
            </div>
        </template>
        <template slot-scope="{ row,index }" slot="status">
                <p style="font-size:12px">
                    <span>{{getmstatustext(row.mstatus)}}</span> | 
                    <span style="background: rgb(252, 2, 2);" v-if="row.fstatus == 0 || row.fstatus ==null">未付款</span>
                    <span style="background: rgb(243, 247, 3);" v-if="row.fstatus == 1">已付定</span>
                    <span style="background: rgb(185, 248, 243);" v-if="row.fstatus == 2">已全款</span>
                </p>
        </template>
        <template slot-scope="{ row }" slot="pricetotal">
            <p style="font-size:12px">￥{{row.pricetotal/10000}}万</p>
        </template>
        <template slot-scope="{ row }" slot="expectdate">
                <p style="font-size:12px">{{new Date(row.expectdate).Format("yyyy-MM-dd")}}</p>
        </template>
        <template slot-scope="{ row }" slot="createtime">
                <p style="font-size:12px">{{new Date(row.createtime).Format("yyyy-MM-dd")}}</p>
        </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pageSize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>
    <SellorderEdit v-bind:show="showeditSellorder" v-bind:Sellorderdata="editSellorderdata" @CloseSellorder="onEditClose" @SubmmitOver="onSubmmitOver"/>
    <accountedit v-bind:show="showeditAccount" v-bind:ordercode="accountordercode" @CloseFinanceAccount="onEditAccountClose" @OnSaveOver="onSaveOver"/>
    <accountconfirm v-bind:show="showAccountConfirm" v-bind:ordercode="accountordercode" @CloseFinanceAccount="onAccountConfirmClose" @OnSaveOver="onAccountConfirmSaveOver"/>
    <sorderhistory v-bind:show="showhistory" v-bind:ordercode="historyordercode" @OnCloseOrderHistory="onHistoryClose"/>
    <Modal v-model="showdeleteconfirm" title="作废订单" @on-ok="confirmDelete">确定要作废吗？</Modal>
    <Modal v-model="showaddplanconfirm" title="订单处理" @on-ok="confirmAddplan">确定已经加入采购生产计划了吗？</Modal>
    <Modal v-model="showupdatewin" title="订单操作" @on-ok="confirmUpdate" v-if="updateIndex !=null" ok-text="提交更新">
        <Form ref="orderUpdateForm" :model="formInline" label-position="right" :label-width="100" >
            <FormItem  label="订单编号:" style="margin-bottom:5px;margin-top:10px">
                <p>{{tbldata[updateIndex].ordercode}}</p>
            </FormItem>
            <FormItem  label="客户公司名称:" style="margin-bottom:5px;margin-top:10px">
                <p>{{tbldata[updateIndex].customer}}</p>                  
            </FormItem>             
            <FormItem  label="订单状态:" style="margin-bottom:5px;margin-top:10px">
                <Select v-model="updateFormInline.mstatus" @on-change="onselectoperation" label-in-value>
                    <Option value=0 key=0>待审核</Option> 
                    <Option value=1 key=1 v-if="departid=='104'">审核通过</Option> 
                    <Option value=2 key=2 v-if="departid=='104'">驳回</Option> 
                    <Option value=3 key=3 v-if="departid=='102'">已发货</Option>   
                    <Option value=4 key=4 v-if="departid=='102'">已完成</Option>    
                </Select>
            </FormItem>
            <FormItem  label="备注:" style="margin-bottom:5px;margin-top:10px">
                <i-input  v-model="updateFormInline.note" maxlength="100" show-word-limit type="textarea">
                </i-input>                   
            </FormItem>  

        </Form>
        </Modal>
        <Modal v-model="showdetail" title="销售订单" width="90%">
            <Table :columns="sdetail.tblcolumns" :data="sdetail.tbldata" v-if="readfinished"></Table>
            <div v-else>
                正在读取数据...
            </div>
        </Modal>
        <loading ref="loading" msg='正在读取后台数据...'/>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import SellorderEdit from './SellorderEdit.vue'
import SaleOrderAccountConfirm from '@/views/business/workshop/finance/SaleOrderAccountConfirm'
import SaleOrderAccountEdit from '@/views/business/workshop/finance/SaleOrderAccountEdit'
import SellOrderHistory from '@/views/business/workshop/sell/SellOrderHistory'
import SellPrint from '@/views/business/workshop/sell/SellPrint'
import Loading from '@/components/Loading'

export default {
    name:'Sellorder',
    props:['departid'],
    data () {
            return {
                showeditSellorder:false,
                showeditAccount:false,
                showAccountConfirm:false,
                showhistory:false,
                accountordercode:null,
                historyordercode:null,
                editSellorderdata:null,
                showdeleteconfirm:false,
                showaddplanconfirm:false,
                showupdatewin:false,
                deleteIndex:null,
                updateIndex:null,
                showdetail:false,
                readfinished:false,
                addplanIndex:null,
                pricelist:[],
                formInline:{
                    ordercode:null,
                    fstatus:null,
                    mstatus:null,
                    phone:null,
                    expectdate: sysTool.getNowFormatDate(new Date()),
                    customer:null,
                    createtime:null,
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                },
                updateFormInline:{
                    mstatus:null,
                    note:null
                },
                tblpageinfo:{
                    total:0,
                    pageNum:1,
                    pageSize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '订单号',
                        slot: 'ordercode',
                        resizable:true
                    },
                    {
                        title: '订单状态',
                        slot: 'status',
                        resizable:true
                    },       
                    /*{
                        title: '订单产品',
                        slot: 'products',
                        width:350,
                        resizable:true
                    },  */
                    {
                        title: '订单总价',
                        slot: 'pricetotal',
                        resizable:true
                    }, 
                    {
                        title: '下单时间',
                        slot: 'createtime',
                        resizable:true
                    },      
                    {
                        title: '预交付日期',
                        slot: 'expectdate',
                        resizable:true
                    },                                        
                    {
                        title: '客户单位',
                        key: 'customer',
                        resizable:true
                    },     
                    {
                        title: '客户联系人',
                        key: 'contact',
                        resizable:true
                    },     
                    {
                        title: '客户电话',
                        key: 'phone',
                        resizable:true
                    },         
                    {
                        title: '销售经理',
                        key: 'salername',
                        resizable:true
                    },             
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        width:460,
                        resizable:true
                    }
                    
                ],
                tbldata: [
                   /* {
                        ordercode:'5746354344466',
                        status:'待审核',
                        products:[
                            {
                                productname:'金鹰电动1号',
                                productcolor:'白',
                                productcount:100,
                                priceper:1000,
                                oripriceper:1050,
                            },
                            {
                                productname:'金鹰电动1号升级款',
                                productcolor:'红',
                                productcount:200,
                                priceper:1000,
                                oripriceper:1050,
                            },
                            {
                                productname:'金鹰电动1号',
                                productcolor:'白',
                                productcount:150,
                                priceper:1000,
                                oripriceper:1050,
                            },
                            {
                                productname:'金鹰电动1号',
                                productcolor:'红',
                                productcount:250,
                                priceper:1000,
                                oripriceper:1050,
                            },                            
                        ],
                        pricetotal:245310,
                        createtime:'2020-01-10 10:12:30',
                        expectdate:'2020-06-10',
                        customer:'河北省唐山市飞鹰电动专卖店',
                        contact:'王占',
                        phone:'15809897777',
                        salername:'王凯',
                        openprod:false           
                    },  */         
                ],
                sdetail:{
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '产品型号',
                        key: 'productname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                        resizable:true
                    },    
                    {
                        title: '数量',
                        key: 'productcount',
                        resizable:true
                    },    
                    {
                        title: '已发货数量',
                        key: 'productcountFinished',
                        resizable:true
                    },   
                    ]
                }
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleReset (name) {
                this.formInline.createtime = null
                this.formInline.createtimestart = null
                this.formInline.createtimeend = null
                this.$refs[name].resetFields();
                console.log(this.formInline.createtime)
                this.handleQuery()
            },
            handleQuery() {
                this.$refs['loading'].startloading()
                this.formInline.createtimestart = null
                this.formInline.createtimeend = null    
                if(this.formInline.createtime !=null){
                    if(this.formInline.createtime[0] !=''){
                        this.formInline.createtimestart = this.formInline.createtime[0]
                    }
                    if(this.formInline.createtime[1] !=''){
                        this.formInline.createtimeend = this.formInline.createtime[1]
                    }
                }

                if(this.formInline.ordercode !=null){
                    this.formInline.ordercode = this.formInline.ordercode.trim()
                }

                let postdata = JSON.parse(JSON.stringify(this.formInline))

                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }

                /*
                if(this.departid == '105'){
                    //计划部门
                    postdata.todept = 1
                }
                else if(this.departid == '102'){
                    //仓库部门
                    postdata.todept = 2
                }
                else if(this.departid == '106'){
                    //采购部门
                    postdata.todept = 3
                }*/

                postdata = this.extend({},postdata,this.tblpageinfo)
                postdata = this.removeEmptyChilds(postdata)
                this.$axios.post("/api/sale/order/query",postdata,)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("销售订单查询结果",res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }

                    this.$refs['loading'].stoploading()
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios.post(
                "/api/sale/orderdetail/query",{
                    orderid: this.tbldata[index].id
                })
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata != null){
                            callback(index,rspdata)
                        }
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            viewdetail(index){
                this.readfinished = false
                this.showdetail = true

                if(this.tbldata[index].products == null || this.tbldata[index].products.length==0){
                    this.queryOrderDetail(index,(index,rspdata)=>{
                        var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                        tmptbldata[index].products =rspdata
                        this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                        console.log(this.tbldata)
                    })
                }
                else{
                    var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                    this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                    this.sdetail.tbldata = this.tbldata[index].products
                    this.readfinished = true
                }
            },
            print(index){
                this.queryOrderDetail(index,(idx,rspdata)=>{
                    this.$refs['sellprint'].printdata(this.tbldata[idx],rspdata,"xsqd")
                })
            },
            getmstatustext(mstatus){
                //订单状态 0-待审核  1-已审核  2-已驳回  3-已发货  4-已完成 
                if(mstatus == 0 || mstatus==null){
                    return '待审核'
                }
                else if(mstatus == 1){
                    return '已审核'
                }
                else if(mstatus == 2){
                    return '已驳回'
                }
                else if(mstatus == 3){
                    return '已发货'
                }
                else if(mstatus == 4){
                    return '已完成'
                }
            },
            getfstatustext(fstatus){
                //财务状态：0-未付定金  1-已付定金 2- 已付全款
                if(fstatus == 0 || fstatus == null){
                    return '未付定'
                }
                else if(fstatus == 1){
                    return '付定待确认'
                }
                else if(fstatus == 2){
                    return '全款待确认'
                }
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已取消'
                }
                let statustext = this.getmstatustext(this.tbldata[index].mstatus) + ' | ' + this.getfstatustext(this.tbldata[index].fstatus)
                return statustext
            },
            addSellorder(){
                this.editSellorderdata = null
                this.showeditSellorder = true                
            },
            modify (index) {
                this.editSellorderdata = this.tbldata[index]
                this.showeditSellorder = true
            },
            confirmUpdate(){
                let postdata = JSON.parse(JSON.stringify(this.tbldata[this.updateIndex]))
                delete postdata.products
                postdata.mstatus = this.updateFormInline.mstatus
                postdata.orderid = postdata.id
                postdata.note = this.updateFormInline.note
                this.$axios({
                    method:'post',
                    url:"/api/sale/order/updatemstatus",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/sale/order/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            confirmAddplan(){
                this.$axios({
                    method:'post',
                    url:"/api/sale/order/addtoplan",
                    data:{id:this.tbldata[this.addplanIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            addtoplan (index) {
                this.showaddplanconfirm = true
                this.addplanIndex = index
            },
            updateorder (index) {
                this.updateIndex = index
                this.showupdatewin = true
            },
            doaccount (ordercode) {
                this.accountordercode = ordercode
                this.showeditAccount = true
            },
            confirmaccount (ordercode){
                this.accountordercode = ordercode
                this.showAccountConfirm = true
            },
            viewhistory (ordercode){
                this.historyordercode = ordercode
                this.showhistory = true
            },
            ondateclear(){
                this.formInline.createtime=null
                console.log(this.formInline)
            },
            onEditClose(){
                this.showeditSellorder =false
            },
            onEditAccountClose(){
                this.showeditAccount = false
            },
            onAccountConfirmClose(){
                this.showAccountConfirm = false
            },
            onHistoryClose(){
                this.showhistory = false
                this.handleQuery()
            },
            onSubmmitOver(){
                this.showeditSellorder =false
                this.handleQuery()
            },
            onAccountConfirmSaveOver(){
                this.showAccountConfirm = false
                this.handleQuery()
            },
            onSaveOver(){
                this.showeditAccount = false
                this.handleQuery()
            },
            onfstatuschange(status){
                console.log(status)
                if (status == null || status == 'null'){
                    this.formInline.fstatus = null
                }

                console.log(this.formInline)
            },
            onmstatuschange(status){
                if (status == null  || status == 'null'){
                    this.formInline.mstatus = null
                }
            },
            onselectoperation(v){
                console.log(v)
                this.updateFormInline.note = v.label
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            onpagechange(num){
                this.tblpageinfo.pageNum = num
                this.handleQuery()
            },
            clickopenprod(index){
                console.log(index)
                console.log(this.tbldata[index].openprod)
                if(this.tbldata[index].products == null || this.tbldata[index].products.length==0){
                    this.queryOrderDetail(index,(index)=>{
                        this.clickopenprod(index)        
                    })
                }
                else{
                    //this.tbldata[index].openprod = !this.tbldata[index].openprod

                    var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                    tmptbldata[index].openprod = !tmptbldata[index].openprod
                    this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                }

                console.log(this.tbldata)
            },
            refreshCerlist(){
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/query",
                    data:{
                        companyid:this._self.$root.$store.state.LogonInfo.companyid,
                        pageNum:1,
                        pageSize:200
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(rspdata)
                        var CertificateTemplateList=[]
                        for(var item=0;item<rspdata.length;item++){
                            var cer={value:null,certificateid:null,label:null,certificateno:null,standard:null}

                            cer.value=item
                            cer.certificateid=rspdata[item].id
                            cer.label=rspdata[item].certificatealias
                            cer.certificateno=rspdata[item].certificateno
                            cer.standard= (rspdata[item].standardtype == 1 ? '国标':'企标')
                            CertificateTemplateList.push({cerdisplay:cer,cerfulldata:rspdata[item]})
                        }
                        this.$store.commit('changeCertificateList',CertificateTemplateList)
                        //this.$Message.success({content:'合格证数据同步完成', duration:3})

                        console.log(CertificateTemplateList)
                        //this.makeTemplateList(false)

                        this.queryprice()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));     
            },
            queryprice (){
                this.$axios({
                    method:'post',
                    url:"/api/sale/price/query",
                    data:{companyid: this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.pricelist = res.data.data
                        console.log(this.pricelist)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            getprice(cerid){
                if(this.pricelist == null || this.pricelist.length ==0){
                    return 0
                }
                
                for(var i=0;i<this.pricelist.length;i++){
                    if(this.pricelist[i].cerid == cerid){
                        if(this.pricelist[i].guidprice !=null && this.pricelist[i].guidprice !=''){
                            return this.pricelist[i].guidprice
                        }
                    }
                }

                return 0
            },
        },
        components:{
            SellorderEdit:SellorderEdit,
            'accountedit':SaleOrderAccountEdit,
            'accountconfirm':SaleOrderAccountConfirm,
            'sorderhistory':SellOrderHistory,
            'sellprint':SellPrint,
            'loading':Loading
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: left;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>