<template>
    <div >
        <Form ref="customerQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <Row :gutter="20" style="margin-top: 15px;">
                <i-col span="4" >
                    <FormItem  label="省份:">
                        <Select v-model="formInline.customerProvince" @on-change="onprovinceselect">
                            <Option v-for="(item,index) in allcitylist" :value="item.provice" :key="index">{{item.provice}}</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="4" >
                    <FormItem  label="城市:">
                        <Select v-model="formInline.customerCity" >
                            <Option v-for="(item,index) in showcitylist" :value="item" :key="index">{{item}}</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="4" >
                    <FormItem  label="公司名称:">
                        <i-input v-model="formInline.customerName"  placeholder="客户公司名字" :maxlength=40 ></i-input>
                    </FormItem>
                </i-col>
                <i-col span="7" >
                    <div>
                        <Button type="primary" @click="handleQuery('customerQueryForm')" >查询</Button>    
                        <Button  @click="handleReset('customerQueryForm')" style="margin-left:10px">重置</Button>
                    </div>
                </i-col>                
            </Row>

        </Form>
        <div style="height:40px;width:100%;">
            <Button v-hasPermission="'sale:customer:add'" type="primary"  style="margin-top:3px;float:left" @click="addcustomer">添加客户信息</Button>
            <Page :total="formInline.total" :page-size="formInline.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>
        <!-- 账号列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="customerName">
                <strong>{{ row.customerName }}</strong>
            </template>
            <template slot-scope="{ row }" slot="customerArea">
                <strong>{{ row.customerProvince + row.customerCity }}</strong>
            </template>
            <template slot-scope="{ row }" slot="customerStar">
                <span v-for="item in (1,row.customerStar)">☆</span>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div class="opbar">
                    <Button v-hasPermission="'sale:customer:view'" type="success" size="small" @click="view(index)">查看</Button>
                    <Button v-hasPermission="'sale:customer:delete'" type="error" size="small" @click="remove(index)">删除</Button>
                    <Button v-hasPermission="'sale:customer:modify'" type="info" size="small" ghost @click="modify(index)">修改</Button>
                </div>
            </template>
            </Table>
        </div>
        <customerEdit v-bind:show="showeditcustomer" v-bind:action="customeraction" v-bind:customerdata="editcustomerdata" @Closecustomer="onEditClose" @SubmmitOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="删除客户数据" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
import CustomerEdit from './CustomerEdit.vue'
import {getcitylist} from '@/zsjs/citylist.js'
export default {
    name:'customer',
    data () {
            return {
                showeditcustomer:false,
                editcustomerdata:null,
                customeraction:'add',
                showdeleteconfirm:false,
                deleteIndex:null,
                allcitylist: [],
                showcitylist:[],
                formInline:{
                    customerProvince:[],
                    customerCity:[],
                    customerName:null,
                    deleted:false,

                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '省/市',
                        slot: 'customerArea',
                    },
                    {
                        title: '客户信息名称',
                        slot: 'customerName',
                    },
                    {
                        title: '客户星级',
                        slot: 'customerStar',
                    },
                    {
                        title: '联系人',
                        key: 'customerContact',
                    },        
                    {
                        title: '联系电话',
                        key: 'customerPhone',
                    },           
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.allcitylist = getcitylist()
            console.log("城市列表",this.allcitylist)
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                let postdata = this.removeEmptyChilds(this.formInline)
                this.$axios({
                    method:'post',
                    url:"/api/sale/customer/query",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log("销售客户查询结果",res.data.data)
                        var rspdata = res.data.data.data
                        this.tbldata =rspdata
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addcustomer(){
                this.editcustomerdata = null
                this.customeraction = 'add'
                this.showeditcustomer = true                
            },
            modify (index) {
                this.editcustomerdata = this.tbldata[index]
                this.customeraction = 'modify'
                this.showeditcustomer = true
            },
            view (index) {
                this.editcustomerdata = this.tbldata[index]
                this.customeraction = 'view'
                this.showeditcustomer = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/sale/customer/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditcustomer =false
            },
            onSubmmitOver(){
                this.showeditcustomer =false
                this.handleQuery()
            },
            onprovinceselect(province){
                console.log(province)
                if(province ==''){
                    this.showcitylist =[]
                    return
                }
                for(var i=0;i<this.allcitylist.length;i++){
                    if(this.allcitylist[i].provice == province){
                        this.showcitylist= this.allcitylist[i].city
                        return
                    }
                }
            },
            onpagechange(num){
                this.formInline.currentpage = num
                this.handleQuery()
            },
        },
        components:{
            customerEdit:CustomerEdit
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>