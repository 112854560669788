import { render, staticRenderFns } from "./PartRequirement.vue?vue&type=template&id=4d5fc9dd"
import script from "./PartRequirement.vue?vue&type=script&lang=js"
export * from "./PartRequirement.vue?vue&type=script&lang=js"
import style0 from "./PartRequirement.vue?vue&type=style&index=0&id=4d5fc9dd&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports