<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:90%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">零部件管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane label="采购入库">
                    <purchaseorder departid="108"/>
                </TabPane>
                <TabPane label="领料出库">
                    <partrequirement departid="108"/>
                </TabPane>
                <TabPane label="委外加工">
                    <partoutsourcing departid="108"/>
                </TabPane>                
                <TabPane label="库存查询">
                    <partstockinfo departid="108"/>
                </TabPane>
                <TabPane label="流水查询">
                    <partreport departid="108"/>
                </TabPane>
                
                <!--
                <TabPane label="领料出库">
                    <planorder departid="108"/>
                </TabPane>
                -->

            </Tabs>
            <div v-if="!isV2" style="margin-top:18px;">
                <a style="font-size:10px"  href="http://www.c-lodop.com/download/Lodop6.226_Clodop3.093.zip">打印插件下载</a>
            </div>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import PurchaseOrder from '@/views/business/workshop/purchase/PurchaseOrder'
    import PlanOrder from '@/views/business/workshop/plan/PlanOrder'
    import PartRequirement from '@/views/business/workshop/partstock/PartRequirement'
    import PartStockReport from '@/views/business/workshop/partstock/PartStockReport'
    import PartStockInfo from '@/views/business/workshop/partstock/PartStockInfo'
    import PartOutSourcing from '@/views/business/workshop/partstock/PartOutSourcing'

    export default {
        name:'WareHouse',
        components:{
            'purchaseorder':PurchaseOrder,
            'planorder':PlanOrder,
            'partreport':PartStockReport,
            'partrequirement':PartRequirement,
            'partstockinfo':PartStockInfo,
            'partoutsourcing':PartOutSourcing,
            loginfobar:UserInfoTitleBar,
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },  
    }
</script>
