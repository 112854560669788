<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false>
        <p slot="header" style="color:#f60;text-align:left">
            <span>商家费用充值</span>
        </p>
        <Form ref="rechargeForm" :model="formInline" :label-width="100" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem  label="充值对象:">
                    <p>{{companyname}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
              <i-col span="24">
                <FormItem  label="当前有效期:">
                    <p>{{expiryTime==null?'无':expiryTime}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="7">
                <FormItem prop="expiryDate" label="增加效期">
                    <Select  @on-change="onSelect">
                        <Option v-for="item in [1,2,3,4,5,6,7,8,9,10]" :value="item" :key="item">{{item}}年</Option>
                    </Select>
                </FormItem>
              </i-col>  
            </Row>              
            <Row>  
              <i-col span="24">  
                <FormItem prop="expiryDate" label="新有效期">
                    <DatePicker type="date" format="yyyy-MM-dd" v-model="formInline.expiryDate" placeholder="选择日期" ></DatePicker>
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('rechargeForm')">充值</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'recharge',
        props:['show','companyid','companyname','expiryTime'],
        data () {
            return {
                formInline: {
                    expiryDate: ''
                },
                ruleInline: {
                    expireDate: [
                        { required: true, message: '不能为空', trigger: 'blur',pattern: /.+/ }
                    ]
                }
            }
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var postdata ={
                            accountid:this.companyid,
                            accountexpirytime:new Date(this.formInline.expiryDate).Format('yyyy-MM-dd'),
                            actionuserid:this._self.$root.$store.state.LogonInfo.userid
                        }
                        console.log(postdata)
                        this.$axios({
                            method:'post',
                            url:"/api/adminuser/recharge",
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('CloseRecharge','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入新的有效期')
                    }
                })
            },
            oncancel(){
                this.$emit('CloseRecharge','')
            },
            onSelect(v){
                console.log(v)
                var nowedate = this.expiryTime == null? new Date() :new Date(this.expiryTime)
                nowedate.setFullYear(nowedate.getFullYear()+v);
                this.formInline.expiryDate  = new Date(nowedate).Format('yyyy-MM-dd')
                
                console.log(this.formInline)
                
            }
        }
    }
</script>
