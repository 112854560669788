<template>
<div >
    <div style="height:40px;width:100%;">
        <h1>{{companyname}}</h1>
        <h2>部门账号管理</h2>
    </div>
    
    <!-- 部门账号列表-->
    <div>
        <div style="height:40px;width:100%;">
            <Button type="warning" icon="ios-add-circle-outline" style="margin-top:6px;float:left" @click="addSubAccount">添加部门账号</Button>
        </div>        
        <Table border :columns="tblcolumns" :data="tbldata">
        <template slot-scope="{ row }" slot="deptname">
            <strong>{{ row.deptname }}</strong>
        </template>
        <template slot-scope="{ row, index }" slot="action">
            <div class="opbar">
                <Button type="primary" size="small"  @click="modify(index)">修改</Button>
                <Button v-if="row.deptcode!='150'" type="warning" size="small"  @click="grant(index)">权限分配</Button>
                <Button type="error" size="small" @click="remove(index)">删除</Button>
            </div>
        </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right" @on-change="onpagechange"/>
        <accountedit v-bind:show="showAccountEdit" v-bind:userid="editUserId" v-bind:companyid="editAccountId" v-bind:username="editUserName" v-bind:deptcode="editUserDeptcode" v-bind:fullname="editUserFullname" @CloseAccountEdit="onAccountEditClose"/>
        <permissionedit v-bind:show="showpermissionedit" v-bind:userinfo="tbldata[grantUserIdx]" @ClosePermissionEdit="onPermissionEditClose"/>
        <Modal v-model="showDeleteDialog" title="确定要删除吗?" @on-ok="confirmDelete">
          <p>删除账号{{editUserName}}</p>
        </Modal>
    </div>

    </div>
</template>

<script>
import SubAccountEdit from './SubAccountEdit'
import SubAccountPermissionEdit from '@/views/admin/SubAccountPermissionEdit'
export default {
    name:'SubAccount',
    data () {
        return {
            showAccountEdit:false,
            editAccountId: this._self.$root.$store.state.LogonInfo.companyid,
            showpermissionedit:false,
            grantUserIdx:null,
            editUserId:null,
            editUserName:null,
            editUserDeptcode:null,
            editUserFullname:null,
            showDeleteDialog:false,
            companyname:this._self.$root.$store.state.LogonInfo.companyname,
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:20
            },
            tblcolumns: [
                {
                    title: '部门名称',
                    slot: 'deptname',
                },
                {
                    title: '账号',
                    key: 'username',         
                },
                {
                    title: '开通日期',
                    key: 'createTime'
                },
                {
                    title: '归属人',
                    key: 'fullname'
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center'
                }
                
            ],
            tbldata: []
        }
    },
    mounted (){
        this.editAccountId =this._self.$root.$store.state.LogonInfo.companyid
        this.refreshslaveusers()
    },
    methods: {
        addSubAccount(){
            this.editUserId = -1
            this.showAccountEdit = true
            this.editUserName =''
            this.editUserDeptcode =''
            this.editUserFullname = ''
        },
        modify (index) {
            this.editUserId = this.tbldata[index].userid
            this.editUserName =this.tbldata[index].username
            this.editUserDeptcode =this.tbldata[index].deptcode
            this.editUserFullname = this.tbldata[index].fullname
            this.showAccountEdit = true
        },
        remove (index) {
            //this.tbldata.splice(index, 1);
            this.editUserId = this.tbldata[index].userid
            this.editUserName =this.tbldata[index].username
            this.showDeleteDialog = true
        },
        grant (index){
            this.showpermissionedit = true
            this.grantUserIdx  = index
        },
        confirmDelete(){
            this.$axios({
                method:'post',
                url:"/api/slaveuser/users/delete",
                data:{
                    userid:this.editUserId,
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.refreshslaveusers()
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));               
        },
        onAccountEditClose (){
            this.showAccountEdit = false
            this.refreshslaveusers()
        },
        onPermissionEditClose(){
            this.showpermissionedit = false
        },
        refreshslaveusers(){
            console.log(this.editAccountId)
            console.log(this._self.$root.$store.state.LogonInfo.companyid)
            this.$axios({
                method:'post',
                url:"/api/slaveuser/users/query",
                data:{
                    companyid:this.editAccountId,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.tbldata = res.data.data.data
                    this.tblpageinfo.total = res.data.data.totalnumber
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));   
        },
        onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.refreshslaveusers()
        }
    },
    components:{
        'accountedit': SubAccountEdit,
        'permissionedit':SubAccountPermissionEdit
    },
    
}
</script>
<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>