<template>
  <div id="app">
    <div class="box">
      <router-view />
    </div>
    <pagefooter id="pagefooter"/>
  </div>
</template>

<script>
import PageFooterBar from './views/common/PageFooterBar.vue'
import * as sysTool from '@/zsjs/systemTool.js'
export default {
  name:'app',
  components:{
    pagefooter:PageFooterBar
  },
  data () {
    return {
        ip: '1.1.1.1',
        area: '北京市',
        brower: 'chrome',
        os: 'windows7',
        agent:'',
        resolution:''
    }
  },
  mounted(){

    this.ip = sessionStorage.getItem('ip')
    this.area = sessionStorage.getItem('area')
    this.brower = sysTool.GetCurrentBrowser()
    this.os = sysTool.GetOs()
    this.agent = navigator.userAgent.toLocaleLowerCase()
    this.resolution = window.screen.width+'x'+window.screen.height
    console.log('ip，地区，浏览器，操作系统，：'+this.ip+this.area+this.brower+this.os)
    console.log(this.agent)
  }
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;

}

.box {
    top:0px;
    width:100%;
    margin:auto
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#pagefooter {
  width:100%;
  margin-top:30px;
  padding-top:5px;
  padding-bottom:10px
}
</style>
