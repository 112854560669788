<template>
    <div>
        <div id="planlistdiv" >
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>                                  
                        <td align="left">
                            <FormItem  label="计划月度:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
                            </FormItem>  
                        </td>
                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('orderQueryForm')" >计划单查询</Button>    
                                <Button  @click="handleReset('orderQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">物料计划列表</label>  
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border>
                <template slot-scope="{ row }" slot="ordercode">
                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                </template>
                <template slot-scope="{ row }" slot="expectdate">
                    {{new Date(row.expectdate).Format("yyyy-MM-dd")}}
                </template>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <div  class="opbar">
                        <Button  type="success" size="small" @click="view(index)" >查看</Button>   
                        <Button  type="warning" size="small" @click="dopurchase(index)" >生成采购计划</Button>    
                    </div>  
                </template>                
                </Table>
            </div>
        </div>
        <Modal v-model="showviewwin" title="查看物料需求清单" width="80%">
            <Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata"></Table>
        </Modal>
        <Modal v-model="showeditwin" title="编辑计划采购" width="80%">
            <div v-if="showeditwin">
                <Form ref="peditForm" :model="peditformInline" :rules="ruleInline">
                    <FormItem prop="expectdate"  label="采购完成日期:" style="width: 20%;">
                        <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="peditformInline.expectdate"  @on-change="peditformInline.expectdate=$event" :options="dateoption"></DatePicker>
                    </FormItem>
                    <FormItem  label="备注:" style="width: 20%;">
                        <i-input type="textarea" v-model="peditformInline.note" :maxlength=200   placeholder='备注信息' style="width: 400px;"></i-input>
                    </FormItem>
                    <FormItem  label="预计总价:" style="width: 20%;" prop="pricetotal">
                        <i-input type="text" v-model="peditformInline.pricetotal" :maxlength=200   placeholder='预计价格' style="width: 400px;">
                            <span slot="append">元</span>
                        </i-input>
                    </FormItem>
                </Form>
                <Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata"></Table>
            </div>
            <div slot="footer">
                <Button type="error" size="large" long  @click="handleSave('peditForm')" >提交</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'   

export default {
    name:'MPlanPurchase',
    props:['departid'],
    data () {
            return {
                showviewwin:false,
                showeditwin:false,
                editmdata:null,
                peditformInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    expectdate:this.Purchasedata==null?'':this.Purchasedata.expectdate,
                    note:this.Purchasedata==null?'':this.Purchasedata.note,
                    pricetotal:0
                },
                ruleInline: {
                    expectdate: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    pricetotal: [
                        { required: true, message: '请填写正确的金额',pattern: /^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/}
                    ],
                },
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    status:null,
                    expectdate: sysTool.getNowFormatDate(new Date()),
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:7
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '计划单号',
                        slot: 'ordercode',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '计划单状态',
                        slot: 'status',
                        resizable:true
                    },          
                    {
                        title: '交货日期',
                        slot: 'expectdate',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '下单时间',
                        key: 'createtime',
                        tooltip:true,
                        resizable:true
                    },                                                                 
                    {
                        title: '计划员',
                        key: 'createusername',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        resizable:true
                    }
                ],
                tbldata: [],
                activeindex:0,
                mdetail:{
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'amount',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                },
                dateoption:{
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now()-86400000;
                    }
                },
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
  
                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }

                for(var p in postdata){
                    if(postdata[p] == null){
                        delete postdata[p]
                    }
                }
                postdata.status =0
                
                console.log(postdata)
                
                this.$axios.post("/api/plan/materialorder/query",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios({
                    method:'post',
                    url:"/api/plan/orderdetail/query",
                    data:{
                        orderid: this.tbldata[index].id
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata != null){
                            var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                            tmptbldata[index].products =rspdata
                            this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                            console.log(this.tbldata)
                            callback(index)
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onSubmmitOver(){
                this.showeditSellorder =false
                this.handleQuery()
            },
            view (index) {
                this.mdetail.tbldata = JSON.parse(this.tbldata[index].content)
                this.showviewwin = true
            },
            dopurchase(index){
                this.activeindex = index
                this.mdetail.tbldata = JSON.parse(this.tbldata[index].content)
                this.showeditwin = true
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].status
                if(status ==0){
                    return '未确认'
                }
                else if(status ==1){
                    return '已确认'
                }
                else if(status ==2){
                    return '已完成'
                }
            },
            handleSave(name){
                console.log("提交计划采购表单")
                this.$refs[name].validate((valid)=>{
                    console.log("提交计划采购表单1111111 "+valid)
                    if(valid){
                        var url = "/api/purchase/order/createfrommplan"
                        var postdata ={
                            mplan:JSON.stringify(this.tbldata[this.activeindex]),
                            purchaseinfo:JSON.stringify(this.peditformInline),
                            purchasedetail:JSON.stringify(this.mdetail.tbldata)
                        }

                        console.log(postdata)
                        this.$axios.post(url,postdata).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.handleQuery()
                                this.showeditwin = false
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            }
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>