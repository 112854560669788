<template>
    <div>
        <!--采购订单-->
        <div id="cgdd" style="width:100%" v-if="printid=='cgdd'">
            <table  border="1px solid black"  cellspacing="0" cellpadding="0" width="100%">
                <tr style="font-size: 16px;padding: 5px 1px;">
                    <th colspan="9"><span style="font-size: 24px;">{{userinfo.companyname}}</span></th>
                </tr>
                <tr style="font-size: 22px;padding: 5px 1px;">
                    <th colspan="9">原物料采购订单</th>
                </tr>
                <tr>
                    <td colspan="9" style="text-align: right;">订单日期：{{(new Date(porderinfo.createtime)).Format('yyyy年MM月dd日')}}</td>
                </tr>
                <tr >
                    <td colspan="9">
                        <span>采购联系人：{{porderinfo.contact}}</span>  
                        <span style="margin-left: 15px;"> 联系电话：{{porderinfo.supplierphone}} </span>
                        <span style="margin-left: 15px;"> 微信号：{{porderinfo.supplierweixin}}</span>
                    </td>
                </tr>
                <tr style="font-size: 16px;">
                    <td colspan="9">公司地址：{{porderinfo.supplieraddr}}</td>
                </tr>
                <tr  >
                    <td style="width: 8%;">序号</td>
                    <td style="width: 12%;">原物料编码</td>
                    <td style="width: 10%;">车型名称</td>
                    <td style="width: 10%;">原物料名称</td>
                    <td style="width: 30%;">规格型号及特性描述</td>
                    <td style="width: 6%;">单位</td>
                    <td style="width: 6%;">数量</td>
                    <td style="width: 10%;">交货日期</td>
                    <td style="width: 8%;">备注</td>
                </tr>
                <tr v-for="(item,index) in porderdetail" >
                    <td >{{index+1}}</td>
                    <td >{{item.mcode}}</td>
                    <td >{{item.modelver}}</td>
                    <td >{{item.materialname}}</td>
                    <td >{{item.materialspec}}</td>
                    <td >{{item.unit}}</td>
                    <td >{{item.amount}}</td>
                    <td >{{porderinfo.expectdate}}</td>
                    <td >{{item.note}}</td>
                </tr>
            </table>
        </div>

        <div id="rkd" v-if="printid=='rkd'">
            <!--原材料入库单，打印时必须传入table上一级div的id，传table的id直接打印会有问题-->
            <table  border="1px solid black" width="100%"  cellspacing="0" cellpadding="0" style="margin: 0 auto;">
                <tr>
                    <th colspan="10">{{userinfo.companyname}}</th>
                </tr>
                <tr>
                    <th colspan="10">原物料入库单</th>
                </tr>
                <!--
                <tr>
                    <td colspan="10">单据号：</td>
                </tr>
                -->
                <tr>
                    <td colspan="5" style="border-bottom: 1px solid black;border-left: 1px solid black;border-top: 1px solid black;;border-right: 0px solid black;text-align: left;">供应商名称:{{porderinfo.supplier}}</td>
                    <td colspan="5" style="border-bottom: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;border-left: 0px solid black; text-align: right;">入库日期：{{(new Date(porderinfo.expectdate)).Format('yyyy年MM月dd日')}}</td>
                </tr>
                <tr  >
                    <td style="width: 8%;">序号</td>
                    <td style="width: 10%;">原物料编码</td>
                    <td style="width: 10%;">车型名称</td>
                    <td style="width: 10%;">原物料名称</td>
                    <td style="width: 30%;">规格型号</td>
                    <td style="width: 5%;">单位</td>
                    <td style="width: 1%;">入库数量</td>
                    <td style="width: 5%;">单价/元</td>
                    <td style="width: 8%;">金额</td>
                    <td style="width: 8%;">备注</td>
                </tr>
                <tr v-for="(item,index) in porderdetail">
                    <td >{{index+1}}</td>
                    <td >{{item.mcode}}</td>
                    <td >{{item.modelver}}</td>
                    <td >{{item.materialname}}</td>
                    <td >{{item.materialspec}}</td>
                    <td >{{item.unit}}</td>
                    <td >{{item.amount}}</td>
                    <td >{{item.price==null? '-':item.price}}</td>
                    <td >{{item.price==null? '-':item.price * item.amount}}</td>
                    <td >{{item.note}}</td>
                </tr>
                <tr >
                    <td >注明</td>
                    <td colspan="9" >
                        <p>1、本入库单一式四份：①白联/存根；②粉联/财务；③黄联/供应商；④篮联/仓库 。</p>
                        <p>2、需双方签字齐全并在当日下班前交由财务（物流、自提采购办理入库供方单据交由财务保管）。</p>
                        <p>3、黄联作为供应商与我司对账、付款的有效依据。本单签字盖章有效，涂改无效。</p>
                        <p>4、订单号来源为供应商送货单单号（物流以物流单号为依据）。</p>
                    </td>
                </tr>
            </table>
        </div>
</div>
</template>

<script>
// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

import {getLodop} from '@/zsjs/LodopFuncs.js'
import {getPrinters,public_piaoju,public_print_with_dir} from '@/zsjs/zskjprinter.js'
export default {
    name:'PurchasePrint',
    //props:['porderinfo','porderdetail','printid'],
    data () {
        return {
            userinfo:this._self.$root.$store.state.LogonInfo,
            doprint:false,
            porderinfo:null,
            porderdetail:null,
            printid:null
        }
    },
    methods:{
        print(div){
            LODOP=getLodop();  	
            var style = "<style>table td{padding:5px 3px;font-size:14px}</style>"
            var strFormHtml=style +document.getElementById(div).innerHTML;

            LODOP.PRINT_INIT("原材料入库单采购单");	
            LODOP.SET_PRINTER_INDEX(-1);

            //设置打印方向、尺寸
            LODOP.SET_PRINT_PAGESIZE(1, 0,0,"A4")
            LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Width");

            LODOP.ADD_PRINT_HTM("5mm", "12mm", "RightMargin:12mm","BottomMargin:5mm", strFormHtml);
            LODOP.PRINT();
        },
        printdata(orderinfo,orderdetail,printdiv){
            this.porderinfo = orderinfo
            this.porderdetail = orderdetail

            console.log("打印用采购单数据",this.porderinfo)
            console.log("打印用采购单详情数据",this.porderdetail)

            if(printdiv == 'cgdd'){
                this.printid = 'cgdd'
            }
            else if(printdiv == 'rkd'){
                this.printid = 'rkd'
            }

            this.$nextTick(() => {
                this.print(this.printid)
                //public_piaoju(this.printid,1)
            })
        },
        startprint(){
            public_piaoju(this.printid,1)
        }
    }
}
</script>