<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="mastermodelForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline" :disabled="action=='view'">
            <Row  >
              <i-col span="24">
                <FormItem prop="modelAlias" label="车型名称或品牌名称:">
                    <i-input type="text" v-model="formInline.modelAlias" :maxlength=50 placeholder="一级分类" :disabled="action!='add'"></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row  >
                <i-col span="24">
                  <FormItem prop="modelConfig" label="车型配置:">
                    <i-input type="text" v-model="formInline.modelConfig" :maxlength=30 ></i-input>
                  </FormItem>
                </i-col>
            </Row>
            <Row  >
                <i-col span="24">
                  <FormItem prop="modelVer" label="车型配置别名:">
                    <i-input type="text" v-model="formInline.modelVer" :maxlength=30 placeholder="二级分类" :disabled="action!='add'"></i-input>
                  </FormItem>
                </i-col>
            </Row>
            <Row>
                <i-col span="24">  
                    <FormItem prop="costPrice" label="成本价(元):">
                        <i-input type="text" v-model="formInline.costPrice" :maxlength=100></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row v-if="departid == '104'">
                <i-col span="24">  
                    <FormItem prop="sugSalePrice" label="建议销售价(元):">
                        <i-input type="text" v-model="formInline.sugSalePrice" :maxlength=30></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="colors" label="颜色:">
                        <div v-if="action!='view'" style="border: 1px solid black;background: lightseagreen;text-align: center;" @click="formInline.colors.push('')">+添加颜色</div>
                        <div v-for="(item,index) in formInline.colors">
                            <i-input type="text" size="small" v-model="formInline.colors[index]" :maxlength=30 ></i-input>
                        </div>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="note" label="备注:">
                        <i-input type="textarea" v-model="formInline.note" :maxlength=200  placeholder="备注"></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
        </Form>
        <div slot="footer" v-if="action=='view'">
            <Button type="error" size="large" long  @click="oncancel">关闭</Button>
        </div>
        <div slot="footer" v-else>
            <Button type="error" size="large" long  @click="handleSubmit('mastermodelForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'mastermodelEdit',
        props:['mastermodeldata','show','action','departid'],
        data () {
            return {
                title:'',
                ModelList:this._self.$root.$store.state.MasterModelList,
                formInline: {
                    companyId:this._self.$root.$store.state.LogonInfo.companyid,
                    modelVer: this.mastermodeldata==null?'':this.mastermodeldata.modelVer,
                    modelConfig: this.mastermodeldata==null?'':this.mastermodeldata.modelConfig,
                    modelAlias: this.mastermodeldata==null?'':this.mastermodeldata.modelAlias,
                    sugSalePrice: this.mastermodeldata==null?'':this.mastermodeldata.sugSalePrice,
                    costPrice: this.mastermodeldata==null?'':this.mastermodeldata.costPrice,
                    colors: this.mastermodeldata==null? ['']: this.strtoarray(this.mastermodeldata.colors,';'),
                    note: this.mastermodeldata==null?'':this.mastermodeldata.note,
                },
                ruleInline: {
                    modelVer: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    modelConfig: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    modelAlias: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.mastermodeldata)
        },
        computed: {
			getModellist() {
				return this._self.$root.$store.state.MasterModelList
			}
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata = JSON.parse(JSON.stringify(this.formInline))
                        console.log("车型表单数据",this.formInline)
                        postdata = this.removeEmptyChilds(postdata)
                        if(this.mastermodeldata ==null){
                            url = "/api/tech/master/modelver/addnew"
                        }
                        else{
                            url = "/api/tech/master/modelver/update"
                            postdata = this.extend({},postdata,{id:this.mastermodeldata.id})
                        }
                        axios.post(url,postdata).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SaveOver','')
                            }
                            else{
                                this.$Message.error({content: res.data.msg, duration:5})
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onModelSelect(v){
                console.log(v)
                console.log(this.formInline.modelNo)
                this.formInline.modelNo = v
            },
            onshow(v){
                if(v){
                    this.formInline.modelVer= this.mastermodeldata==null?'':this.mastermodeldata.modelVer
                    this.formInline.modelConfig= this.mastermodeldata==null?'':this.mastermodeldata.modelConfig
                    this.formInline.modelAlias= this.mastermodeldata==null?'':this.mastermodeldata.modelAlias,
                    this.formInline.sugSalePrice= this.mastermodeldata==null?'':this.mastermodeldata.sugSalePrice
                    this.formInline.costPrice= this.mastermodeldata==null?'':this.mastermodeldata.costPrice
                    this.formInline.colors= this.mastermodeldata==null? ['']: this.strtoarray(this.mastermodeldata.colors,';')
                    this.formInline.note= this.mastermodeldata==null?'':this.mastermodeldata.note
                    if(this.action == 'view'){
                        this.title ='查看车型数据详情'
                    }
                    else if(this.mastermodeldata ==null){
                        this.title ='新增车型数据'
                    }
                    else{
                        this.title ='修改车型数据'
                    }
                }
            },
            oncancel(){
                this.$emit('Closemastermodel','')
            },
            strtoarray(str,delimiter){
                var strs= new Array(); //定义一数组
                strs=str.split(delimiter); //字符分割
                
                return strs
            }
        }
    }
</script>