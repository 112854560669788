<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="23%">
                        <FormItem  label="选择车型:" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Cascader v-model="formInline.modelids"  :data="MasterModelDatalist" @on-change="onmodelchange"></Cascader>
                        </FormItem>
                    </td>
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >库存统计查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">库存统计结果</label>  
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>
        
        <!-- 整车编码列表-->
        <div style="clear: both;">
            <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" no-data-text="" show-summary :summary-method="handleSummary">
            <template slot-scope="{ row }" slot="vehicleno">
                <strong>{{ row.vehicleno }}</strong>
            </template>   
            <template  slot-scope="{ row }" slot="count">
                {{row.num}}
            </template>        
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small"  style="margin-right: 5px" ">导出</Button>
            </template>            
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>

        </div>
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'StockInfo',
    props:['departid'],
    data () {
            return {
                MasterModelDatalist:[],
                MasterModelVers:null,
                Colorlist:[],
                formInline: {
                    companyid:'',
                    modelids:[],
                    modelverid:null
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:100
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center'
                    },
                    {
                        title: '车型配置别名',
                        key: 'certificatealias',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '库存数量',
                        key: 'num'       ,           
                    }          
                ],
                tbldata: [],
                postquerydata:null,
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList
            this.MasterModelVers = this._self.$root.$store.state.MasterModelVerList
        },
        methods: {
            handleQuery(name) {  
                /*
                if(this.formInline.modelverid ==0 || this.formInline.modelverid == null){
                    this.$Message.error({content:'请选择车型查询',duration:3})
                    return
                }*/

                this.postquerydata={
                    companyid:this.formInline.companyid,
                    modelverid:this.formInline.modelverid,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                }  

                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log("库存统计查询条件数据",this.postquerydata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicleIn/stockinfo/query",
                    data:this.postquerydata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log("查询库存统计信息结果",res.data)
                    if(res.data.resultCode ==0){
                        this.tbldata = res.data.data.data
                        this.tblpageinfo.total = res.data.data.totalnumber
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            handleReset (name) {
                this.formInline.modelverid = null
                this.$refs[name].resetFields();
                this.tbldata=[]
                this.tblpageinfo.total = 0
                this.tblpageinfo.currentpage = 1
                this.handleQuery('vehicleQueryForm')
            },
            onmodelchange(e,selectedData){
              if(selectedData.length >1){
                this.formInline.modelverid = selectedData[1].value
              }
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            handleSummary ({ columns, data }) {
                const sums = {};
                columns.forEach((column, index) => {
                    const key = column.key;
                    if (index === 0) {
                        sums[key] = {
                            key,
                            value: '合计'
                        };
                        return;
                    }
                     if(index !== 3){
                        sums[key] = {
                            key,
                            value: 'N/A'
                        };
                        return;
                     }
                    const values = data.map(item => Number(item[key]));
                    if (!values.every(value => isNaN(value))) {
                        const v = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[key] = {
                            key,
                            value: v + ' 辆'
                        };
                    } else {
                        sums[key] = {
                            key,
                            value: 'N/A'
                        };
                    }
                });

                return sums;
            }
        }
}
</script>