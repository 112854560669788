<template>
  <div>
    <h1 style="padding-top:30px">电动车企业管理系统</h1>
    <h2 style="padding-top:20px">用户注册</h2>
  
    <Form ref="formInline" :model="formInline" :rules="ruleInline" @on-validate="onValidate" label-position="right" :label-width="120" style="width:35%;margin:auto;padding-top:15px">
        <Row :gutter="10" >
          <i-col span="18">
            <FormItem label="账号：" prop="username" ref="username" >
              <i-input type="text" v-model="formInline.username"  :autofocus="true">
                    <Icon type="ios-person-outline" slot="prepend"></Icon>
              </i-input>
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem :label-width="0">
              <Button type="warning" v-if="false" @click="checkaccount">检测</Button>
              <p style="color:blue" v-if="accountCanUsed == 0">需具备唯一性</p> 
              <p style="color:green" v-if="accountCanUsed == 1">√ 账号名可用</p>  
              <p style="color:red" v-if="accountCanUsed == 2">× 账号名已存在</p>  
            </FormItem>
          </i-col>
        </Row>
        <FormItem label="密码：" prop="password" style="padding-top:20px;padding-bottom:10px">
            <i-input type="password" v-model="formInline.password" password placeholder="输入密码">
            </i-input>
        </FormItem>
         <FormItem label="密码确认：" prop="repassword" style="padding-top:10px;padding-bottom:10px">
            <i-input type="password" v-model="formInline.repassword" placeholder="再次输入密码" password>
            </i-input>
        </FormItem>
        <FormItem label="公司名称：" prop="companyname" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.companyname" placeholder="建议公司全称">
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <!--
        <Row :gutter="10" style="padding-top:10px;padding-bottom:10px">
          <i-col span="18">
            <FormItem label="公司编码：" prop="companycode" ref="companycode" >
                <i-input type="text" v-model="formInline.companycode" placeholder="建议公司名称拼音首字母大写">
                    <Icon type="ios-keypad-outline" slot="prepend"></Icon>
                </i-input>
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem :label-width="0">
              <Button type="warning" v-if="false" @click="checkcompany">检测</Button>
              <p style="color:blue" v-if="companycodeCanUsed == 0">需具备唯一性</p> 
              <p style="color:green" v-if="companycodeCanUsed == 1">√ 公司编码可用</p>  
              <p style="color:red" v-if="companycodeCanUsed == 2">× 公司编码已存在</p>  
            </FormItem>
          </i-col>
        </Row>
        -->
        <FormItem label="法人姓名：" prop="legalperson" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.legalperson" maxlength="20" show-word-limit>
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>     
        <FormItem label="法人电话：" prop="legalphonenumber" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.legalphonenumber"  maxlength="20" show-word-limit>
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem label="联系人名称：" prop="contact" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.contact" maxlength="20" show-word-limit>
                <Icon type="ios-crop" slot="prepend"></Icon>
            </i-input>
        </FormItem>                   
        <FormItem label="联系人电话：" prop="phonenumber" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.phonenumber" maxlength="20" show-word-limit>
                <Icon type="ios-phone-portrait" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem label="Email地址：" prop="emailaddr" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.emailaddr" placeholder="邮箱地址">
                <Icon type="ios-mail-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>        
        <FormItem label="社会信用代码：" prop="societycode" style="padding-top:10px;padding-bottom:10px">
            <i-input type="text" v-model="formInline.societycode" placeholder="社会信用代码">
                <Icon type="ios-medal-outline" slot="prepend"></Icon>
            </i-input>
        </FormItem>
        <FormItem prop="yzm" style="padding-top:10px;padding-bottom:10px">
          <Row type="flex" align="middle" justify="center">
            <i-col span="8">
              <i-input type="text" v-model="formInline.yzm" placeholder="验证码" @on-enter="handleSubmit('formInline')">
                  <Icon type="md-aperture" />
              </i-input>
            </i-col>
            <i-col span="1">  </i-col>
            <i-col span="8" ><yzm :identifyCode="identifyCode" v-on:onrefresh="refreshcode"></yzm></i-col>
          </Row>
        </FormItem>
        <FormItem style="padding-top:10px;padding-bottom:10px">
            <Button type="primary" v-bind:disabled="!accountCanUsed" @click="handleSubmit('formInline')" style="width:100%">注册</Button>
        </FormItem>
        <FormItem >
          <router-link to="/adminlogin">已有账号，开始登录</router-link>
        </FormItem>
    </Form>
  </div>
</template>
<script>
import SIdentify from '@/components/SIdentify.vue' //* *引入验证码组件**
export default {
  name: 'Register',
  data () {
    const validateyzm = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入验证码'))
      } else if (value != this.identifyCode) {
        return callback(new Error('验证码输入错误'))
      } else {
        callback()
      }
    }
    const validatepwd = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入密码'))
      } else if (value != this.formInline.password) {
        return callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      formInline: {
        username: '',
        password: '',
        repassword: '',
        companyname: '',
        companycode: '',
        phonenumber: '',
        emailaddr:'',
        societycode: '',
        legalperson:'',
        legalphonenumber:'',
        contact:'',
        yzm: ''
      },
      identifyCodes: '1234567890',
      identifyCode: '12354',
      accountCanUsed:0,//1--can be used   2--can not be used
      companycodeCanUsed:0,//1--can be used   2--can not be used
      submmitDisabled:true, 
      ruleInline: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { pattern: /^[A-Z][A-Z]{3,15}$/, message: '企业简称全拼,必须大写4-16位', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
        ],
        repassword: [
          { validator: validatepwd, required: true, trigger: 'blur' }
        ],
        companyname: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { type: 'string', min: 4, message: '公司名称不能低于4位', trigger: 'blur' }
        ],
        /*
        companycode: [
          { required: true, message: '请输入公司编码', trigger: 'blur' },
          { pattern: /^[A-Z0-9]{4,15}$/, message: '4-16位大写英文字母和数字组合', trigger: 'blur' }
        ],*/
        legalperson: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],
        legalphonenumber: [
          { required: true, message: '请输入法人电话', trigger: 'blur' },
          { type: 'string', min: 7, message: '法人电话不能低于7位', trigger: 'blur' }
        ],     
        contact: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],   
        phonenumber: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { type: 'string', min: 8, message: '电话号码不能低于7位', trigger: 'blur' }
        ],
        emailaddr: [
          { required: true, message: '请输入Email', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]{2,4})$/, message: '请输入合法Email', trigger: 'blur' }
          
        ],
        yzm: [
          { validator: validateyzm, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    'yzm': SIdentify
  },
  mounted () {
    this.refreshcode()
  },
  watch:{
    'formInline.username':{
      deep:true,
      handler: function(newV, oldV) {
        if(this.accountCanUsed !=0){
          this.accountCanUsed =0
        }
      }
    },
    'formInline.companycode': {
      deep:true,
      handler: function(newV, oldV) {
        if(this.companycodeCanUsed !=0){
          this.companycodeCanUsed =0
        }
      }      
    },
    /*accountCanUsed: function(newV,oldV){
      if(newV == 1){
        // can be used
        this.submmitDisabled = this.companycodeCanUsed == 1 ? false : true
      }
    },
    companycodeCanUsed: function(newV,oldV){
      if(newV == 1){
        // can be used
        this.submmitDisabled = this.accountCanUsed == 1 ? false : true
      }
    }*/
  },
  methods: {
    onValidate (prop, status, error){
      switch(prop){
        case 'username':
          if(status){
            //如果没有检测过唯一性
            //如果this.accountCanUsed !=0代表已经检测过唯一性,现在是重复validate,不需要再进行唯一性检测
            if(this.accountCanUsed ==0){
              this.checkaccount()
            }
          }
          else{
            this.accountCanUsed =0
          }
          break;
          /*
        case 'companycode':
          if(status){
            if(this.companycodeCanUsed ==0){
              this.checkcompany()
            }
          }
          else{
            this.companycodeCanUsed =0
          }
          break;  */
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          axios.post("/api/adminuser/users/register",this.formInline)
          .then(function(res){
            if(res.data.resultCode ==0){
              this.$Message.success({content:'注册成功', duration:3, closable:true, onClose:this.notifyOver})
            }
            else{
              this.$Message.error({content:res.data.msg, duration:3})
            }
          }.bind(this));
        } else {
          this.$Message.error('请输入正确的内容')
          this.refreshcode()
        }
      })
    },
    checkaccount() {
      console.log("send account post request")
      axios.post("/api/adminuser/users/check/username",{username:this.formInline.username})
      .then(function(res){
        console.log(res.data)
        if(res.data.resultCode ==0){
          this.accountCanUsed = 2
        }
        else{
          this.accountCanUsed = 1
        }
      }.bind(this));
    },
    checkcompany (){
      console.log("send company post request")
      axios.post("/api/adminuser/users/check/companycode",{companycode:this.formInline.companycode})
      .then(function(res){
        if(res.data.resultCode ==0){
          this.companycodeCanUsed = 2
        }
        else{
          this.companycodeCanUsed = 1
        }
      }.bind(this));
    },
    notifyOver (){
      console.log(this)
      this.$router.push('adminlogin')
    },
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
      console.log(this.identifyCode)
    },
    refreshcode: function () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    }
  }
}
</script>

<style>
    .center {
        position: fixed;
        top: 50%;
        left: 50%;
        text-align: center;
        background-color: #ffffcc;
        width:70%;
        height: 70%;
        -webkit-transform: translateX(-50%) translateY(-50%);
    }
</style>
