<template>
    <Modal :value="showloading" 
    :closable="false"
    :footer-hide="true"
    :mask-closable="false"
    >
       <div style="display: inline-block;
               width: 100%;
               height: 100px;
               position: relative;
               border: 1px solid #eee;">
           <Spin fix>
               <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
               <div>{{msg}}</div>
           </Spin>
       </div>
   </Modal>
</template>

<script>
export default {
    name:'Loading',
    props:['msg'],
    data(){
        return {
            showloading:false
        }
    },
    methods:{
        startloading(){
            this.showloading = true
        },
        stoploading(){
            this.showloading = false
        },
    }
}
</script>

<style>
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
</style>