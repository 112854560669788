<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:blue;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="FinanceAccountForm" :model="formInline" :label-width="100"  label-position="right" :rules="ruleInline">
            <Row>
                <i-col span="8" >
                    <FormItem  label="账目类型:">
                        <Select v-model="formInline.type" @on-change="onprovinceselect">
                            <Option value=0 :key=0>请选择</Option> 
                            <Option value=1 :key=1>出账</Option> 
                            <Option value=2 :key=2>入账</Option>  
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="16" style="padding-left:10px">
                    <FormItem  label="类别:">
                        <Select v-model="formInline.classify" style="padding-left:5px">
                            <Option value=0 :key=0>请选择</Option> 
                            <Option value=1 :key=1>整车销售收入</Option> 
                            <Option value=2 :key=2>采购支出</Option> 
                            <Option value=3 :key=3>工资支出</Option> 
                            <Option value=4 :key=4>物业支出</Option>   
                            <Option value=5 :key=5>索赔支出</Option>  
                        </Select>
                    </FormItem>
                </i-col>
            </Row>   
            <FormItem  label="发生金额(单位:元):">
                <i-input type="text" v-model="formInline.amount" :maxlength=10></i-input>
            </FormItem>
            <FormItem  label="账目日期:">
                <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.happendate"  @on-change="formInline.happendate=$event"></DatePicker>
            </FormItem>
            <FormItem  label="备注:">
                <i-input type="text" v-model="formInline.note" :maxlength=100></i-input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('FinanceAccountForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    import {getcitylist} from '@/zsjs/citylist.js'
    import VehicleQuerySummary from '../../../common/VehicleQuerySummary.vue'
    export default {
        name:'FinanceAccountEdit',
        props:['FinanceAccountdata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    type: this.FinanceAccountdata==null?'':this.FinanceAccountdata.type,
                    classify: this.FinanceAccountdata==null?'':this.FinanceAccountdata.classify,
                    amount:this.FinanceAccountdata==null?'':this.FinanceAccountdata.amount,
                    note:this.FinanceAccountdata==null?'':this.FinanceAccountdata.note,

                    cnmodel:null,
                    certificate:null,
                    unitprice:null,
                    amount:0,
                },
                ruleInline: {
                    FinanceAccountname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    FinanceAccountaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
            }
        },
        mounted(){
            console.log(this.FinanceAccountdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.FinanceAccountdata ==null){
                            url = "/api/FinanceAccount/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/FinanceAccount/update"
                            postdata = this.extend({},this.formInline,{id:this.FinanceAccountdata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                               
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.FinanceAccountname= this.FinanceAccountdata==null?'':this.FinanceAccountdata.FinanceAccountname
                    this.formInline.FinanceAccountaddr= this.FinanceAccountdata==null?'':this.FinanceAccountdata.FinanceAccountaddr
                    
                    if(this.FinanceAccountdata ==null){
                        this.title ='录入新账目'
                    }
                    else{
                        this.title ='修改账目'
                    }
                }
            },
            onprovinceselect(province){

            },
            oncancel(){
                this.$emit('CloseFinanceAccount','')
            },
            handleView () {
              console.log('handleView')
            }
        },
        components:{
            viewsummary:VehicleQuerySummary
        }
    }
</script>