<template>
  <table v-if="cerdata !=null" width="1180" border="0px solid black" cellspacing="0"  style="margin:0 auto">
    <tr>
      <td align="center" valign="bottom">
        <div style="margin:0 auto">
          <h2>电动车自行车产品合格证</h2>
          <h3>第一部分车辆总体信息</h3>
        </div>
        <table  width="590"  cellspacing="0" border="1px solid #FFF" style="font-size:12px;" >
          <tr >
            <td width="50%" style="padding-top:10px;padding-bottom:10px">0.0 整车编码：{{cerdata.vehicleNo}}</td>
            <!--                        <td width="50%">{{cerdata.vehicleNo}}</td>-->
          </tr>
          <tr >
            <td style="padding-top:10px;padding-bottom:10px">0.1 车辆制造商：{{cerdata.manufacturer}}</td>
            <!--                        <td >{{cerdata.manufacturer}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.2 生产企业名称：{{cerdata.producer}}</td>
            <!--                        <td >{{cerdata.producer}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.3 生产企业地址：{{cerdata.produceraddr}}</td>
            <!--                        <td >{{cerdata.produceraddr}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.4 车辆中文商标：{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>
            <!--                        <td >{{(cerdata.cnbrand =='null' ||cerdata.cnbrand ==null)?'':cerdata.cnbrand}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.5 车辆英文商标：{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>
            <!--                        <td>{{(cerdata.enbrand =='null' ||cerdata.enbrand ==null)?'':cerdata.enbrand}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.6 产品型号：{{cerdata.modelno}}</td>
            <!--                        <td>{{cerdata.modelno}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.7 驱动方式：{{cerdata.drivetype}}</td>
            <!--                        <td>{{cerdata.drivetype}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.8 长*宽*高(mm)：{{cerdata.bodysize}}</td>
            <!--                        <td>{{cerdata.bodysize}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.9 前后轮中心距(mm)：{{cerdata.wheeltrack}}</td>
            <!--                        <td>{{cerdata.wheeltrack}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.10 装配完整的电动自行车的整车质量(kg)：{{cerdata.totalweight}}</td>
            <!--                        <td>{{cerdata.totalweight}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.11 最高设计车速(km/h)：{{cerdata.maxspeed}}</td>
            <!--                        <td>{{cerdata.maxspeed}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.12 铭牌固定位置：{{cerdata.cardposition}}</td>
            <!--                        <td>{{cerdata.cardposition}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.13 车架上车辆编码的位置：{{cerdata.codeposition}}</td>
            <!--                        <td>{{cerdata.codeposition}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.14 电动机编码：{{cerdata.motorNo}}</td>
            <!--                        <td>{{cerdata.motorNo}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.15 续行里程(km)：{{cerdata.driverange}}</td>
            <!--                        <td>{{cerdata.driverange}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.16 百公里电耗(kw.h/100km)：{{cerdata.powerconsumption}}</td>
            <!--                        <td>{{cerdata.powerconsumption}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.17.1 CCC证书编号：{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>
            <!--                        <td>{{(cerdata.cccno =='null' ||cerdata.cccno ==null)?'':cerdata.cccno}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.17.2 CCC证书版本号：{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>
            <!--                        <td>{{ (cerdata.cccver =='null' ||cerdata.cccver ==null)?'':cerdata.cccver}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.17.3 CCC证书发证日期：{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>
            <!--                        <td>{{cerdata.cccdate==null?'':cerdata.cccdate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
          </tr>
          <tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.18 车辆制造日期：{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>
            <!--                      <td>{{cerdata.madeDate==null?'':cerdata.madeDate.replace(/\s[\x00-\xff]*/g,'')}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.19 产品名称：{{cerdata.productname}}</td>
            <!--                      <td>{{cerdata.productname}}</td>-->
          </tr>
          <tr>
            <td style="padding-top:10px;padding-bottom:10px">0.20 车身颜色：{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>
            <!--                      <td>{{cerdata.vehicleColor==null?'':cerdata.vehicleColor}}</td>-->
          </tr>
        </table>
      </td>
      <td align="center" valign="bottom" style="padding-left:20px">
        <table  width="590" border="1px solid black" cellspacing="0" style="font-size:11px;">
          <tr>
            <td colspan="4" align="left" style="padding-top:10px;padding-bottom:10px">产品合格证编号：{{cerdata.certificateno}}</td>
          </tr>
          <tr>
            <td colspan="4" align="center" style="padding-top:10px;padding-bottom:10px"><h2>第二部分产品合格证参数</h2></td>
          </tr>
          <tr>
            <td colspan="4" align="center">
              <img ref="shimgref" width="400px" height="280px"  :src="bikeimgurl" />
            </td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">1.1 电动机生产企业</td>
            <td width="31%">{{cerdata.motorproducer}}</td>
            <td width="19%">1.2 电动机型号</td>
            <td width="31%">{{cerdata.motormodel}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">1.3 电动机型式</td>
            <td width="31%">{{cerdata.motortype}}</td>
            <td width="19%">1.4 额定转速(r/min)</td>
            <td width="31%">{{cerdata.rotationspeed}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">1.5 额定连续输出功率(W)</td>
            <td width="31%">{{cerdata.outputpower}}</td>
            <td width="19%">1.6 额定电压(V)</td>
            <td width="31%">{{cerdata.voltage}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">2.1 控制器生产企业</td>
            <td width="31%">{{cerdata.controllerproducer}}</td>
            <td width="19%">2.2 控制器型号</td>
            <td width="31%">{{cerdata.controllerno}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">2.3 欠压保护值(V)</td>
            <td width="31%">{{cerdata.undervoltage}}</td>
            <td width="19%">2.4 过流保护值(A)</td>
            <td width="31%">{{cerdata.overcurrent}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">3.1 蓄电池类型</td>
            <td width="31%">{{cerdata.celltype}}</td>
            <td width="19%">3.2 蓄电池生产企业</td>
            <td width="31%">{{cerdata.cellproducer}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">3.3 蓄电池容量(Ah)</td>
            <td width="31%">{{cerdata.cellvolume}}</td>
            <td width="19%">3.4 蓄电池型号</td>
            <td width="31%">{{cerdata.cellno}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">4.1充电器生产企业</td>
            <td width="31%">{{cerdata.chargerProducer}}</td>
            <td width="19%">4.2 充电器型号</td>
            <td width="31%">{{cerdata.chargerspecs}}</td>
          </tr>

          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">4.3充电方式</td>
            <td width="31%">{{cerdata.chargerMethod}}</td>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">5.1 前轮轮胎型号</td>
            <td width="31%">{{cerdata.frontwheel}}</td>
          </tr>
          <tr>
            <td width="19%" style="padding-top:10px;padding-bottom:10px">5.2 后轮轮胎型号</td>
            <td width="31%">{{cerdata.backwheel}}</td>
            <td width="19%" rowspan="2">
              <vue-qr v-if="showccc" :text="cccUrl" :correctLevel=3  :size="100" :margin="1" :callback="test1" qid="cid1" style="margin-left:10px;float:left;width:95px;height:95px"></vue-qr>
              <vue-qr v-if="showself" :text="selfUrl" :correctLevel=2 :callback="test2" :size="100" :margin="1" qid="cid2" style="margin-right:10px;float:right;width:95px;height:95px"></vue-qr>
            </td>
            <td width="31%" rowspan="2"></td>
          </tr>
          <tr  height="145px">
            <td width="19%" style="padding-top:10px;padding-bottom:10px">6 备注</td>
            <td width="31%">{{(cerdata.note =='null' ||cerdata.note ==null)?'':cerdata.note}}</td>
          </tr>
          <tr>
            <!--                    <td colspan="4" >-->
            <!--                      本产品经过检验，符合-->
            <!--                      <label v-for="(item,key) in standardnameList">-->
            <!--                        <input type="checkbox" > {{item.label}}-->
            <!--                      </label>-->
            <!--                      的要求，特此证明。-->
            <!--                    </td>-->
            <td colspan="4" >
              <span>本产品经过检验，符合</span>
              <CheckboxGroup v-model="cerdata.standardname2" class="checkbox">
                <Checkbox label="GB17761-2018《电动自行车安全技术规范》" style="display: inline"></Checkbox>
                <Checkbox label="GB42295-2022《电动自行车电气安全要求》" style="display: inline"></Checkbox>
                <Checkbox label="GB42296-2022《电动自行车用充电器安全技术要求》" style="display: inline"></Checkbox>
              </CheckboxGroup>
              <span>的要求,特此证明。</span>
            </td>
          </tr>
        </table>
                         <img border="0" v-if="cerdata.printseal=='true'" width="150px" height="150px" transcolor="#FFFFFF" :src="'/api/files/get/seal?id='+cerdata.sealaddrid" style="z-index: -1; position: absolute; left:650px; top:470px;" />
      </td>
    </tr>
  </table>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  name: 'cptableV3',
  components: {
    vueQr
  },
  props: {
    cerdata: {
      type: Object,      //类型
      default: {}     //默认值
    },
    bikeimgurl:{
      type:String,
      default:''

    },
    showccc:{
      type:Boolean,
      default:false
    },
    showself:{
      type:Boolean,
      default:false
    },
    cccUrl:{
      type:String,
      default:''
    },
    selfUrl:{
      type:String,
      default:''
    },
    cccqrcodeFinish:{
      type:Boolean,
      default:false
    },
    selfqrcodeFinish:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
    }

  },
  methods:{
    test1(dataUrl,id){
      console.log(id+" ::::-> "+dataUrl)
      this.cccqrcodeFinish = true
      this.checkqrcodefinish()
    },
    test2(dataUrl,id){
      console.log(id+" ::::-> "+dataUrl)
      this.selfqrcodeFinish = true
      this.checkqrcodefinish()
    },
    checkqrcodefinish(){
      if((this.showccc == true && this.cccqrcodeFinish == true && this.showself == false) ||
          (this.showself == true && this.selfqrcodeFinish == true && this.showccc == false) ||
          (this.showccc == true && this.cccqrcodeFinish == true && this.showself == true && this.selfqrcodeFinish == true)
      ){
        console.log('qrcode generate finished')
        // this.$emit('qrcodesfinished','')
      }

    }
  }


}
</script>

<style scoped lang="scss">
.checkbox{
  display: inline;
  :deep(.ivu-checkbox-wrapper) {
    font-size: 10px
    !important;
  }
}

</style>
