<template>
    <div>
        <planedit v-if="showeditPlan" @closeedit="onPlanEditWinClose"/>
    
        <div id="planlistdiv" v-show="showeditPlan==false">
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>
                        <td align="left">
                            <FormItem prop="status" label="计划单状态" style="margin-bottom:5px;margin-top:10px">
                                <Select v-model="formInline.status" @on-change="onOrderStatusSelect">
                                    <Option value=null key=100>请选择</Option> 
                                    <Option value=0 key=0>未接单</Option> 
                                    <Option value=1 key=1>领料中</Option> 
                                    <Option value=2 key=2>排产中</Option> 
                                    <Option value=3 key=3>已完成</Option> 
                                </Select>
                            </FormItem>
                        </td>                       
                        <td align="left">
                            <FormItem  label="下单日期:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.createtime"  @on-change="formInline.createtime=$event" @on-clear="formInline.createtime=null"></DatePicker>
                            </FormItem>  
                        </td>                
                        <!--
                        <td align="left">
                            <FormItem  label="计划月度:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
                            </FormItem>  
                        </td>
                        -->    

                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('vehicleQueryForm')" >计划单查询</Button>    
                                <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">计划列表</label>  
                <!--
                <Button v-hasPermission="'plan:manage'" type="primary" v-if="departid=='105'"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="showeditPlan=true">新增计划单</Button>
                -->

                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border>
                <template slot-scope="{ row }" slot="ordercode">
                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                </template>
                <template slot-scope="{ row }" slot="expectdate">
                    {{new Date(row.expectdate).Format("yyyy-MM-dd")}}
                </template>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row,index }" slot="progress">
                    {{row.finishedcount+'/'+row.productcount}}
                </template>
                <template slot-scope="{ row,index }" slot="plantype">
                    {{row.plantype == 0 ? '计划内排产':'零散排产'}}
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <div v-if="departid=='105'" class="opbar">
                        <Button  v-hasPermission="'plan:manage'" type="info" size="small" @click="viewmaterial(index)" style="float:left">查看物料</Button>
                        <Button v-if="row.status == 0" v-hasPermission="'plan:manage'" type="warning" size="small" @click="checkrequirepart(index)" style="float:left">申请领料</Button>
                        <!--

                        
                        <Button v-hasPermission="'plan:manage'" type="error" size="small" @click="remove(index)" style="float:left">作废</Button>  
                        -->           
                    </div>
                    <div v-if="departid=='108'" class="opbar">
                        <Button v-hasPermission="'partwarehouse:manage'" type="error" size="small" @click="remove(index)" style="float:left">出库</Button>             
                    </div>
                </template>                
                </Table>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>
        </div>
        <Modal v-model="showdeleteconfirm" title="作废计划单" @on-ok="confirmDelete">确定要作废吗？</Modal>
        <Modal v-model="mdetail.showmaterialviewwin" title="查看物料需求清单" width="80%">
            <div  v-if="mdetail.readfinished">
                <div v-if="mdetail.orderinfo.status==0" style="color: red;">还未申请领料</div>
                <div v-else>已经申请领料</div>
                <Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata"></Table>
            </div>
            <div v-else>
                正在读取数据...
            </div>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import PlanEdit from '@/views/business/workshop/plan/PlanEdit'    

export default {
    name:'PlanOrder',
    props:['departid'],
    data () {
            return {
                showeditPlan:false,
                editSellorderdata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    status:null,
                    createtime:null,
                    //expectdate: sysTool.getNowFormatDate(new Date()),
                    pageSize:20,
                    pageNum:0
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '计划单号',
                        slot: 'ordercode',
                        tooltip:true,
                        width:200,
                        resizable:true
                    },
                    {
                        title: '类型',
                        slot: 'plantype',
                        resizable:true
                    }, 
                    {
                        title: '计划单状态',
                        slot: 'status',
                        resizable:true
                    },       
                    {
                        title: '生产进度',
                        slot: 'progress',
                        resizable:true
                    },      
                    {
                        title: '车型',
                        key: 'productname',
                        resizable:true
                    },     
                    {
                        title: '颜色',
                        key: 'productcolor',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '数量',
                        key: 'productcount',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '下单时间',
                        key: 'createtime',
                        tooltip:true,
                        resizable:true
                    },                                                                 
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        width:180,
                        resizable:true
                    }
                ],
                tbldata: [],
                mdetail:{
                    showmaterialviewwin:false,
                    readfinished:false,
                    orderinfo:null,
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'total',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                }
            }
        },
        mounted (){
            if(this.departid == '105'){
                this.formInline.status=null
            }
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
  
                if(this.formInline.createtime !=null){
                    if(this.formInline.createtime[0] !=''){
                        postdata.createtimestart = this.formInline.createtime[0]
                    }
                    if(this.formInline.createtime[1] !=''){
                        postdata.createtimeend = this.formInline.createtime[1]
                    }
                }
                /*
                if(this.formInline.expectdate !=null){
                    if(this.formInline.expectdate[0] !=''){
                        postdata.expectdatestart = this.formInline.expectdate[0]
                    }
                    if(this.formInline.expectdate[1] !=''){
                        postdata.expectdateend = this.formInline.expectdate[1]
                    }
                }*/
/*
                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }*/

                if(postdata.ordercode !=null){
                    postdata.ordercode = this.formInline.ordercode.trim()
                }

                for(var p in postdata){
                    if(postdata[p] == null){
                        delete postdata[p]
                    }
                }

                console.log("查询计划订单的表单数据",postdata)
                
                this.$axios.post("/api/plan/order/query",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("查询计划订单结果",res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios.post("/api/plan/orderdetail/query",
                    {
                        orderid: this.tbldata[index].id
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("查询计划订单详情数据",res.data.data)
                        if(rspdata != null){
                            var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                            tmptbldata[index].products =rspdata
                            this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                            callback(index)
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onSubmmitOver(){
                this.showeditSellorder =false
                this.handleQuery()
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            confirmDelete(){
                this.$axios.post(
                    "/api/plan/order/delete",
                    {id:this.tbldata[this.deleteIndex].id})
                    .then(function(res){
                        if(res.data.resultCode ==0){
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.handleQuery()
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                }.bind(this));      
            },
            onOrderStatusSelect(status){
                if (status == null || status == 'null'){
                    this.formInline.status = null
                }
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            onpagechange(v){
                this.formInline.pageNum = v
                this.handleQuery()
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].status
                if(status ==0 || status == null){
                    return '未接单'
                }
                else if(status ==1){
                    return '领料中'
                }
                else if(status ==2){
                    return '排产中'
                }
                else if(status ==3){
                    return '已完成'
                }
            },
            onPlanEditWinClose(){
                this.showeditPlan=false
                this.handleQuery()
            },
            viewmaterial(index){
                this.mdetail.orderinfo = this.tbldata[index]
                this.mdetail.readfinished = false
                this.mdetail.showmaterialviewwin = true
                this.$axios.post(
                    "/api/partRequest/querymaterialneededfrommodel",
                    {
                        modelverid: this.tbldata[index].modelverid,
                        requestnum: this.tbldata[index].productcount
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        console.log("物料信息",res.data.data)
                        this.mdetail.tbldata = res.data.data
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                        this.mdetail.tbldata = []
                    }

                    this.mdetail.readfinished = true

                }.bind(this))
            },
            checkrequirepart(index){
                this.$axios.post(
                    "/api/partRequest/queryrequirementstock",
                    {
                        modelverid: this.tbldata[index].modelverid,
                        requestnum: this.tbldata[index].productcount
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        //this.$Message.success({content:res.data.msg, duration:3})
                        console.log("物料及库存信息",res.data.data)
                        let mdata = res.data.data
                        if(mdata == null || mdata.length ==0){
                            this.$Message.error({content:'没有查询到零部件信息', duration:3})
                        }
                        else{
                            let hungry=[]
                            let hms
                            for(let i=0;i<mdata.length;i++){
                                if(mdata[i].stock_num < mdata[i].total){
                                    hungry.push(mdata[i])
                                    let tmpstr ="<br>" + mdata[i].materialname+"("+mdata[i].materialtype+") "
                                    hms = hms==null? tmpstr : hms +tmpstr
                                }
                            }

                            

                            if(hungry.length>0){
                                this.$Modal.confirm({
                                    content:'部分零部件 '+hms+' 库存不足，确定申领吗？',
                                    okText:'确定申领',
                                    cancelText:'先不领了',
                                    onOk:()=>{
                                        this.requirepart(index)
                                    },
                                })
                            }
                            else{
                                this.requirepart(index)
                            }
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            },
            requirepart(index){
                this.$axios.post(
                    "/api/partRequest/addnew",
                    {
                        modelVerId: this.tbldata[index].modelverid,
                        modelVerName:this.tbldata[index].productname,
                        fromPlanOrderId: this.tbldata[index].id,
                        fromPlanOrderCode :this.tbldata[index].ordercode,
                        requestType:0
                    }
                )
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        console.log("物料信息",res.data.data)
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            }
        },
        components:{
            'planedit':PlanEdit
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>