<!-- 箱贴打印功能-->
<template>
    <div style="margin:0 auto;float:top;margin-top:1px;">
        <table v-if="vehicledata!=null" border="4px solid black" cellspacing="0"  style="margin:0 auto;height:800px" width="1000px" >
            <tr style="font-size:60px;font-weight:bold;height:19%;">
                <td align="left" style="border: 4px solid black;">商品名</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.cer.boxalias}}
                </td>
            </tr>
            <tr style="font-size:40px;font-weight:bold;height:15%;">
                <td align="left" style="border: 4px solid black;">型号：</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.cer.modelno}}
                </td>

            </tr>
            <tr style="font-size:55px;font-weight:bold;height:16%;" >
                <td align="left" style="border: 4px solid black;">颜色：</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.vehicle.vehicleColor}}
                </td>
            </tr>           
            <tr style="font-size:34px;font-weight:bold;height:12%;">
                <td align="left" style="border: 4px solid black;">整车编码：</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.vehicle.vehicleNo}}
                </td>
            </tr>
            <tr style="font-size:34px;font-weight:bold;height:12%;">
                <td align="left" style="border: 4px solid black;">电机编码：</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.vehicle.motorNo}}
                </td>
            </tr>
            <tr style="font-size:34px;font-weight:bold;height:12%;">
                <td align="left" style="border: 4px solid black;">批次日期：</td>
                <td align="center" valign="center" style="border: 4px solid black;">
                    {{vehicledata.vehicle.madeDate}}
                </td>
            </tr>
             <tr style="height:12%;">
                <td colspan="2" style="border: 4px solid black;">
                    <div>
                        <img id="barcode" style="margin:0 auto;display:flex;justify-content:center;align-items:center;height:60%;width:100%"/>
                        <p style="margin:0 auto;font-size:40px;display:flex;justify-content:center;align-items:center;">{{vehicledata.vehicle.vehicleNo}}</p>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<!-- style="margin:0 auto;width:100%;display:flex;justify-content:center;align-items:center;"
            <tr>
                <img id="barcode" style="margin-top:5px"/>
            </tr>     
 -->
 <script>
    import JsBarcode from 'jsbarcode'
    export default {
        name:'CodePrint',
        props:['vehicledata'],
        watch:{
            vehicledata:function (v){
                if(v!=null){
                    this.$nextTick(function(){
                        this.barcode()
                    })
                }
            }
        },
        methods:{
            barcode() {
                JsBarcode("#barcode", this.vehicledata.vehicle.vehicleNo, {
                    format: "code128",  //条形码的格式
                    lineColor: "#000",  //线条颜色
                    width:2, //线宽
                    height:25,  //条码高度
                    fontSize:10,
                    marginTop:0,
                    marginBottom:0,
                    //fontOptions:"bold",//使文字加粗体或变斜体
                    font:"fantasy",//设置文本的字体
                    displayValue: false //是否显示文字信息
                });
            }
        },
        mounted(){
            //this.barcode()
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

.barcode{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
}

</style>