<template>
    <Modal ref="accountPermissionModal" :value="show" width="30%" scrollable @on-cancel="oncancel" :mask-closable=false @on-visible-change="onvisit">
        <p slot="header" style="color:#f60;text-align:left">
            <span v-if="userinfo!=null"> 账号( <span  style="color: rgb(58, 6, 247);">{{this.userinfo.username}}</span> )权限配置</span>
        </p>
        <Form v-if="userinfo!=null" ref="PermissionForm" :model="formInline" :label-width="120" label-position="top" >
            <FormItem label="所属部门：">
                <label>{{userinfo.deptname}}</label>
            </FormItem>
            <FormItem label="选择权限：">
                <Tree ref="menutree" :data="menudata" show-checkbox></Tree>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('PermissionForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    import qs from "qs"
    export default {
        name:'permissionEdit',
        props:['show','userinfo'],
        data () {
            const validatedept = (rule, value, callback) => {
                if (value == '') {
                    return callback(new Error('请选择部门'))
                } else {
                    callback()
                }
            }
            return {
                formInline: {

                },
                menudata:[
                        {
                            title: '技术模块',
                            expand: true,
                            checked:false,
                            key:11,
                            children: [
                                {
                                    title: '车型管理',
                                    checked:true,
                                    key:110
                                },
                                {
                                    title: 'BOM管理',
                                    checked:false,
                                    key:111
                                }
                            ]
                        },
                        {
                            title: '采购模块',
                            expand: true,
                            key:12,
                            children: [
                                {
                                    title: '采购管理',
                                    key:121,
                                },
                                {
                                    title: '采购查看',
                                    key:122
                                }
                            ]
                        }
                    ]
            }
        },
        mounted (){
            this.$axios({
                method:'post',
                url:"/api/slaveuser/department/query",
                data:{companyid:this.companyid},
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.departmentList = res.data.data
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));            
        },
        methods: {
            handleSubmit (name) {
                let ckdata = this.$refs["menutree"].getCheckedAndIndeterminateNodes()
                console.log("选中权限",ckdata)
                let menuids=[];
                let postdata={
                    userid:this.userinfo.userid,
                    menuids:"",
                    halfids:""
                }
                ckdata.forEach(element => {
                    if(element.indeterminate==true){
                        postdata.halfids = postdata.halfids+element.key+","
                    }
                    else{
                        postdata.menuids = postdata.menuids + element.key+","
                    }
                });

                
                console.log(postdata)
                axios.post("/api/slaveuser/menu/update",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this))
            },
            oncancel(){
                this.$emit('ClosePermissionEdit','')
            },
            onvisit(visit){
                console.log(visit)
                if(visit==true){
                    axios.post("/api/slaveuser/menu/get",{userid:this.userinfo.userid})
                    .then(function(res){
                        if(res.data.resultCode ==0){
                            console.log(res.data.data)
                            this.menudata = res.data.data.children
                        }
                        else{
                            this.$Message.error({content:res.data.msg, duration:3})
                        }
                    }.bind(this)); 
                }
            }
        }
    }
</script>
