<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="supplierForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="suppliername" label="供应商名称:">
                    <i-input :disabled="supplierdata !=null" type="text" v-model="formInline.suppliername" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="scop" label="产品范围:">
                        <i-input type="text" v-model="formInline.scop" :maxlength=100 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="suppliercontact" label="联系人:">
                        <i-input type="text" v-model="formInline.suppliercontact" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="supplierphone" label="联系电话:">
                        <i-input type="tel" v-model="formInline.supplierphone" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="supplierweixin" label="联系微信:">
                        <i-input type="text" v-model="formInline.supplierweixin" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="supplieraddr" label="联系地址:">
                        <i-input type="text" v-model="formInline.supplieraddr" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row v-if="departid=='104'">   
                <i-col span="9">  
                    <FormItem prop="paytype" label="结算方式:">
                        <Select v-model="formInline.paytype">
                          <Option value="0" key="0">现结</Option>
                          <Option value="1" key="1">月结</Option>
                          <Option value="2" key="2">年结</Option>
                        </Select>
                    </FormItem>                         
                </i-col>
                <i-col span="6" v-if="formInline.paytype == '2'">  
                    <FormItem prop="paymonth" label="结算月:" :label-width="65">
                        <i-input type="number" v-model="formInline.paymonth" />
                    </FormItem>                         
                </i-col>
                <i-col span="6" v-if="formInline.paytype == '1' || formInline.paytype == '2'">  
                    <FormItem prop="payday" label="结算日:" :label-width="65">
                        <i-input type="number" v-model="formInline.payday" >
                            <span slot="append">日</span>
                        </i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="note" label="备注:">
                        <i-input type="textarea" v-model="formInline.note" :maxlength=100 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('supplierForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'supplierEdit',
        props:['supplierdata','show','departid'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                    modifyusername:this._self.$root.$store.state.LogonInfo.fullname,
                    suppliername: this.supplierdata==null?'':this.supplierdata.suppliername,
                    suppliercontact: this.supplierdata==null?'':this.supplierdata.suppliercontact,
                    supplierphone: this.supplierdata==null?'':this.supplierdata.supplierphone,
                    supplierweixin: this.supplierdata==null?'':this.supplierdata.supplierweixin,
                    supplieraddr: this.supplierdata==null?'':this.supplierdata.supplieraddr,
                    paytype: this.supplierdata==null?'':this.supplierdata.paytype,
                    paymonth: this.supplierdata==null?'':this.supplierdata.paymonth,
                    payday: this.supplierdata==null?'':this.supplierdata.payday,
                    level: this.supplierdata==null?'':this.supplierdata.level,
                    scop: this.supplierdata==null?'':this.supplierdata.scop,
                    note: this.supplierdata==null?'':this.supplierdata.note,
                },
                ruleInline: {
                    suppliername: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    supplieraddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.supplierdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.supplierdata ==null){
                            url = "/api/supplier/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/supplier/update"
                            postdata = this.extend({},this.formInline,{id:this.supplierdata.id})
                        }
                        console.log("修改供应商表单数据",postdata)
                        postdata = this.removeEmptyChilds(postdata)
                        console.log("修改供应商表单数据去除空数据",postdata)
                        this.$axios.post(url,postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SaveOver','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.suppliername= this.supplierdata==null?'':this.supplierdata.suppliername
                    this.formInline.note= this.supplierdata==null?'':this.supplierdata.note
                    this.formInline.scop= this.supplierdata==null?'':this.supplierdata.scop
                    this.formInline.level= this.supplierdata==null?'':this.supplierdata.level
                    this.formInline.suppliercontact= this.supplierdata==null?'':this.supplierdata.suppliercontact
                    this.formInline.supplierphone= this.supplierdata==null?'':this.supplierdata.supplierphone
                    this.formInline.supplierweixin= this.supplierdata==null?'':this.supplierdata.supplierweixin,
                    this.formInline.supplieraddr= this.supplierdata==null?'':this.supplierdata.supplieraddr,
                    
                    this.formInline.paytype= this.supplierdata==null?'':(this.supplierdata.paytype == null ? null : this.supplierdata.paytype+'')
                    this.formInline.paymonth= this.supplierdata==null?'':this.supplierdata.paymonth
                    this.formInline.payday= this.supplierdata==null?'':this.supplierdata.payday
                    if(this.supplierdata ==null){
                        this.title ='新增供应商'
                    }
                    else{
                        this.title ='修改供应商'
                    }
                }
            },
            oncancel(){
                this.$emit('Closesupplier','')
            },

        }
    }
</script>