<!-- 整车库存查询功能-->
<template>
    <div >
        <!-- 整车编码列表-->
        <div>
            <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="certificatealias">
                <strong>{{ row.certificatealias }}</strong>
            </template>              
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small"  style="padding-right:5px;font-size:12px" @click="viewdetail(index)">详情</Button>
            </template>            
            </Table>
        </div>
        <Modal v-model="showdetail" title="库存详情" >
            <div style="margin:0 auto;width:100%;display:flex;justify-content:center;align-items:center;">
                <table border="1px solid black" cellspacing="0" width="70%" v-if="tbldata.length>0">
                <tr>
                    <th colspan="2" style="font-size:14px">{{tbldata.length>0? tbldata[currentindex].certificatealias :''}}</th>
                </tr>
                <tr>
                    <th>颜色</th>
                    <th>库存数量</th>
                </tr>
                <tr v-for="(item,index) in tbldata[currentindex].colorRecordList">
                    <td>{{item.color}}</td>
                    <td>{{item.count}}</td>
                </tr>       
                </table>
            </div>
          
        </Modal>
    </div>
</template>

<script>
export default {
    name:'VehicleQuerySummary',
    data () {
            return {
                showdetail:false,
                currentindex:0,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 50,
                        align: 'center'
                    },
                    {
                        title: '中文型号',
                        key: 'cnmodel',
                        resizable:true
                    },
                    {
                        title: '合格证',
                        slot: 'certificatealias',
                        resizable:true
                    },
                    {
                        title: '库存',
                        key: 'count',
                        width:80,
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 70,
                        align: 'center',
                    }                    
                ],
                tbldata: [],
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.handleQuery()
        },
        methods: {
            viewdetail (index){
                this.currentindex = index
                this.showdetail = true
            },
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/vehicle/stocksummary/query",
                    data:{
                        companyid: this._self.$root.$store.state.LogonInfo.companyid
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tbldata =rspdata
                        }
                        
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
        }
}
</script>