<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="cccForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="cccname" label="认证中心名称:">
                    <i-input type="text" v-model="formInline.cccname" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
              <i-col span="24">
                <FormItem prop="ccccode" label="认证中心简称:">
                    <i-input type="text" v-model="formInline.ccccode" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="prefix" label="二维码地址前缀">
                    <i-input type="text" v-model="formInline.prefix" :maxlength=200  placeholder="url车架号之前的部分"></i-input>
                </FormItem>                  
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="suffix" label="二维码地址后缀">
                    <i-input type="text" v-model="formInline.suffix" :maxlength=200 show-word-limit placeholder="url车架号之后的部分"></i-input>
                </FormItem>                  
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('cccForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'CccEdit',
        props:['cccdata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    cccname: this.cccdata==null?'':this.cccdata.cccname,
                    ccccode: this.cccdata==null?'':this.cccdata.ccccode,
                    prefix: this.cccdata==null?'':this.cccdata.prefix,
                    suffix: this.cccdata==null?'':this.cccdata.suffix
                },
                ruleInline: {
                    cccname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    ccccode: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    prefix: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    suffix: [
                        { required: false, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.cccdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.cccdata ==null){
                            url = "/api/ccc/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/ccc/update"
                            postdata = this.extend({},this.formInline,{id:this.cccdata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.cccname= this.cccdata==null?'':this.cccdata.cccname
                    this.formInline.ccccode= this.cccdata==null?'':this.cccdata.ccccode
                    this.formInline.prefix= this.cccdata==null?'':this.cccdata.prefix
                    this.formInline.suffix= this.cccdata==null?'':this.cccdata.suffix
                    
                    if(this.cccdata ==null){
                        this.title ='新增认证中心数据'
                    }
                    else{
                        this.title ='修改认证中心数据'
                    }
                }
            },
            oncancel(){
                this.$emit('CloseCcc','')
            }
        }
    }
</script>
