<template>
    <div style="margin:0 auto">
        <h1 style="margin-top:120px;padding-left:300px" align="left">欢迎进入电动车管理系统</h1>
        <h5 style="margin-top:20px;padding-left:350px" align="left">账号有效期至：<span style="color:red"> {{logoninfo.expiryTime}}</span></h5>
    </div>
</template>

<script>
export default {
    name:'AdminSettings',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo
        }
    }
}
</script>
 