<template>
<div >
    <div style="height:40px;width:100%;">
        <h2>账号管理</h2>
    </div>
    
    <!-- 部门账号列表-->
    <div>
        <div style="height:40px;width:100%;">
            <Button type="warning" icon="ios-add-circle-outline" style="margin-top:6px;float:left" @click="addSubAccount">添加账号</Button>
        </div>        
        <Table border :columns="tblcolumns" :data="tbldata">
        <template slot-scope="{ row, index }" slot="contact">
                <p v-if="row.contact !='null'">{{row.contact}}
                <p v-else>-</p>    
        </template>    
        <template slot-scope="{ row, index }" slot="action">
            <Button type="error" size="small" @click="remove(index)">删除</Button>
        </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right" @on-change="onpagechange"/>
        <accountedit v-bind:show="showAccountEdit" v-bind:userid="editUserId" v-bind:companyid="editAccountId" v-bind:username="editUserName" v-bind:deptcode="editUserDeptcode" @CloseAccountEdit="onAccountEditClose"/>
        <Modal v-model="showDeleteDialog" title="确定要删除吗?" @on-ok="confirmDelete">
          <p>删除账号{{editUserName}}</p>
        </Modal>
    </div>

    </div>
</template>

<script>
import SubAdminAccountEdit from '@/views/admin/systemowner/SubAdminAccountEdit'
export default {
    name:'SubAccount',
    data () {
        return {
            showAccountEdit:false,
            editAccountId: this._self.$root.$store.state.LogonInfo.companyid,
            editUserId:null,
            showDeleteDialog:false,
            companyname:this._self.$root.$store.state.LogonInfo.companyname,
            tblpageinfo:{
                total:0,
                currentpage:1,
                pagesize:5
            },
            tblcolumns: [
                {
                    title: '账号',
                    key: 'username',         
                },
                {
                    title: '姓名',
                    slot: 'contact',
                },
                {
                    title: '开通日期',
                    key: 'createtime'
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center'
                }
                
            ],
            tbldata: []
        }
    },
    mounted (){
        this.editAccountId =this._self.$root.$store.state.LogonInfo.companyid
        this.refreshslaveusers()
    },
    methods: {
        addSubAccount(){
            this.editUserId = -1
            this.showAccountEdit = true
            this.editUserName =''
            this.editUserDeptcode =''
        },
        modify (index) {
            this.editUserId = this.tbldata[index].id
            this.editUserName =this.tbldata[index].username
            this.editUserDeptcode =this.tbldata[index].deptcode
            this.showAccountEdit = true
        },
        remove (index) {
            //this.tbldata.splice(index, 1);
            this.editUserId = this.tbldata[index].id
            //this.editUserName =this.tbldata[index].username
            this.showDeleteDialog = true
        },
        confirmDelete(){
            this.$axios({
                method:'post',
                url:"/api/adminuser/subadmin/delete",
                data:{
                    userid:this.editUserId,
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Message.success({content:res.data.msg, duration:3})
                    this.refreshslaveusers()
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this));               
        },
        onAccountEditClose (){
            this.showAccountEdit = false
            this.refreshslaveusers()
        },
        refreshslaveusers(){
            console.log(this.editAccountId)
            console.log(this._self.$root.$store.state.LogonInfo.companyid)
            this.$axios({
                method:'post',
                url:"/api/adminuser/querySubAdminAccount",
                data:{
                    companyid:this.editAccountId,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    this.tbldata = res.data.data.data
                    this.tblpageinfo.total = res.data.data.totalnumber
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));   
        },
        onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.refreshslaveusers()
        }
    },
    components:{
        'accountedit': SubAdminAccountEdit
    },
    
}
</script>