<template>
    <div style="width: 100%;padding:10px 50px">
        <div style="width: 90%;background: white;padding: 10px 20px;margin: 10px auto;">
            <div style="height: 30px;">
                <img src="@/assets/icon/back.png" height="100%" style="float: left; cursor: pointer;" @click="handleClose"/>
                <p style="color:#f60;text-align:left;font-size: 24px;padding: 0px;float: left;margin-left: 15px;">
                    <span>{{title}} - 计划单信息</span>
                </p>                
            </div>
            <Form ref="PlanEditForm" :model="formInline"   label-position="right" :rules="ruleInline" >
                <FormItem  label="预计交货日期:" style="width: 20%;">
                    <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.expectdate"  @on-change="formInline.expectdate=$event" :options="dateoption"></DatePicker>
                </FormItem>
                <FormItem  label="备注:">
                    <i-input type="textarea" v-model="formInline.note" :maxlength=200   placeholder='备注信息'></i-input>
                </FormItem>
                <Row :gutter="20" index="">
                    <i-col span="5">
                        <FormItem  label="产品型号:">
                            <Select v-model="detailFormInline.modelverid" @on-change="onModelSelect">
                                <Option v-for="(item,index) in modeldatalist()" :value="item.id" :key="index">{{item.modelVer}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>
                    <i-col span="3" style="padding-left:5px">
                        <FormItem  label="颜色:">
                            <Select v-model="detailFormInline.productcolor" >
                                <Option v-for="(item,index) in colorlist" :value="item" :key="index">{{item}}</Option>
                            </Select>
                        </FormItem>
                    </i-col>     
                    <i-col span="3" style="padding-left:5px">
                        <FormItem  label="数量:">
                            <i-input type="number" v-model="detailFormInline.productcount" :maxlength=10 placeholder="数量"></i-input>
                        </FormItem>
                    </i-col>                
                    <i-col span="4" style="padding-left:5px;">
                        <Button type="success" style="font-size:10px;margin-top:33px" icon="md-add" @click="addproduct">添加</Button>
                    </i-col>             
                </Row>  
                <div style="margin-left:10px;width:100%;text-align:left">计划产品列表：</div>
                <Table :columns="tblcolumns" :data="tbldata" border size="small"  height="200">
                    <template slot-scope="{ row }" slot="productname">
                        <strong>{{ row.productname }}</strong>
                    </template>
                    <template slot-scope="{ row, index }" slot="action" >
                        <Button type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                        <Button type="error" size="small" ghost @click="removeproduct(index)" style="float:right">删除</Button>        
                    </template>                            
                </Table>    
            </Form>
            <div style="margin: 15px;padding: 10px;">
                    <p style="float:left">合计: {{countcompute}}辆</p>
            </div>
        </div>
        
        <div slot="footer" style="margin: 25px; display: inline-flex;align-items: center;">
            <Button type="error" size="large"   @click="handleClose" style="margin: 0px 20px;width: 150px;">取消</Button>
            <Button type="primary" size="large"   @click="handleSubmit('PlanEditForm')" style="margin: 0px 20px;width: 150px;">提交</Button>
        </div>
    </div>
</template>
<script>
    export default {
        name:'SPlanEdit',
        props:['Plandata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    createuserid:this._self.$root.$store.state.LogonInfo.userid,
                    createusername: this._self.$root.$store.state.LogonInfo.fullname,
                    expectdate:this.Plandata==null?'':this.Plandata.expectdate,
                    note:this.Plandata==null?'':this.Plandata.note,
                },
                detailFormInline:{
                    modelverid:null,
                    productname:null,
                    productcolor:null,
                    productcount:0
                },
                ruleInline: {
                    Planordername: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ]
                },
                colorlist:[],
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '型号',
                        slot: 'productname',
                        resizable:true
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                        width:70
                    },
                    {
                        title: '数量',
                        key: 'productcount',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width:130
                    }
                ],
                tbldata:[],
                dateoption:{
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now()-86400000;
                    }
                }
            }
        },
        mounted(){
            console.log('planedit mounted=============')
            console.log(this.Plandata)
            if(this.Plandata ==null){
                this.title ='新建计划单'
            }
            else{
                this.title ='修改计划单'
            }
        },
        computed:{
            countcompute() {
                var counttotal =0
                console.log(this.tbldata)
                for(let i=0;i< this.tbldata.length;i++){
                    counttotal = counttotal + parseInt(this.tbldata[i].productcount) 
                }
                
                this.formInline.counttotal = counttotal
                return counttotal;
            }
        },
        methods: {
            modeldatalist(){
                return this._self.$root.$store.state.MasterModelVerList
            },
            handleSubmit (name) {
                if(this.formInline.expectdate==null || this.formInline.expectdate == ''){
                    this.$Message.error({content:'预计交货日期不能为空',duration:3})
                    return
                }
                this.$refs[name].validate((valid)=>{

                    if(valid){
                        var url
                        var postdata
                        if(this.Plandata ==null){
                            url = "/api/plan/order/addnew"
                            postdata ={
                                planinfo:JSON.stringify(this.formInline),
                                plandetail:JSON.stringify(this.tbldata)
                            }
                            
                        }
                        else{
                            url = "/api/plan/order/update"
                            postdata = this.extend({},this.formInline,{id:this.Sellorderdata.id})
                        }
                        console.log(postdata)
                        axios.post(url,postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('closeedit','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            addproduct(){
                this.detailFormInline.productcolor = this.detailFormInline.productcolor.trim()
                this.detailFormInline.productname = this.getmodelverbyid(this.detailFormInline.modelverid)
                this.tbldata.push({
                    productname:this.detailFormInline.productname,
                    modelverid:this.detailFormInline.modelverid,
                    productcolor:this.detailFormInline.productcolor,
                    productcount:this.detailFormInline.productcount                 
                })
            },
            removeproduct(index) {
                console.log(index)
                this.tbldata.splice(index,1)
            },
            oncancel(){
                this.$emit('CloseSellorder','')
            },
            handleView () {
              console.log('handleView')
            },
            handleClose(){
                this.$emit('closeedit','')
            },
            getmodelverbyid(id){
                let models = this.modeldatalist()
                for(let i=0;i<models.length;i++){
                    if(models[i].id == id){
                        return models[i].modelVer
                    }
                }
            },
            onModelSelect(v){
                console.log(v)
                this.colorlist = []
                let models = this.modeldatalist()
                for(let i=0;i<models.length;i++){
                    if(models[i].id == v){
                        if(models[i].colors==null ||models[i].colors=='' ){
                            break 
                        }
                        this.colorlist=models[i].colors.split(";"); //字符分割
                        break
                    }
                }
                console.log(this.colorlist)
            },
        },
        components:{

        }
    }
</script>