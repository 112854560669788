<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline"  :rules="ruleInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="25%">
                        <FormItem  label="选择车型:" :label-width="120" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Cascader v-model="formInline.modelids"  :data="MasterModelDatalist" @on-change="onmodelchange"></Cascader>
                        </FormItem>
                    </td>
                    <td width="20%">
                        <FormItem  label="流水类型:" :label-width="120" prop="watertype" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.watertype" >
                                <Option value="into" key="1">入库</Option>
                                <Option value="out" key="2">出库</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td align="left" width="25%">
                        <FormItem  label="日期范围:" :label-width="120" style="margin-bottom:5px;margin-top:10px">
                            <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.opdate"  @on-change="formInline.opdate=$event" @on-clear="formInline.opdate=null"></DatePicker>
                        </FormItem>
                    </td>
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >查询</Button>
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="display: inline-flex;margin-top: 20px;">
            <div style="width:65%">
                <div style="height:40px;width:100%;">
                    <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">{{usedwatertype == 'into'? '入库':'出库'}}流水明细结果<span style="margin-left: 400px;">共计： <span style="color: red;">{{tblpageinfo.total}} </span> 条 <span style="color: red;">{{usedwatertype=='into'? '入库':'出库'}}</span> 记录</span></label>
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
                </div>

                <!-- 整车编码列表-->
                <div style="clear: both;">
                    <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" no-data-text="">
                    <template slot-scope="{ row }" slot="vehicleno">
                        <strong>{{ row.vehicleno }}</strong>
                    </template>
                    <template  slot-scope="{ row }" slot="count">
                        {{row.num}}
                    </template>
                    <template  slot-scope="{ row }" slot="opdate">
                        {{usedwatertype == 'into'? row.intodate : row.outdate}}
                    </template>
                    <template slot-scope="{ row, index }" slot="opuser">
                        {{usedwatertype == 'into'? row.intobyusername : row.outbyusername}}
                    </template>
                      <template slot-scope="{ row, index }" slot="recvaddr" v-if="usedwatertype == 'out'">
                        {{row.recvaddr}}
                      </template>
                    </Table>
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>

                </div>
            </div>
            <div style="width:35%;padding: 5px 20px;">
                <Card style="background: lightgrey;">
                    <p slot="title" align="center" >
                        <strong>{{usedwatertype == 'into'? '入库':'出库'}}流水信息统计 (<span style="color: red;">{{getdaterangetext()}}</span>)</strong>
                    </p></span>
                    <div>
                        <Table ref="summarytable" size="small" border :columns="summary.tblcolumns" :data="summary.tbldata" no-data-text="">
                        </Table>
                    </div>
                </Card>
            </div>
        </div>

    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'StockReport',
    props:['departid'],
    data () {
            return {
                MasterModelDatalist:[],
                MasterModelVers:null,
                Colorlist:[],
                usedwatertype:'',
                formInline: {
                    companyid:'',
                    modelids:[],
                    modelverid:null,
                    watertype:'into',
                    opdate:[]
                },
                ruleInline: {
                    modelids: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    watertype: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center'
                    },
                    {
                        title: '整车编码',
                        key: 'vehicleno',
                    },
                    {
                        title: '车型配置别名',
                        key: 'certificatealias',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '日期',
                        slot: 'opdate'       ,
                    },
                    {
                        title: '操作人',
                        slot: 'opuser'       ,
                    },
                    {
                      title: '详细地址',
                      slot: 'recvaddr',

                    },
                ],
                tbldata: [],
                summary:{
                    tblcolumns: [
                    {
                        title: '车型配置别名',
                        key: 'certificatealias',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '流水数量',
                        key: 'num'       ,
                    }
                ],
                tbldata: [],
                },
                postquerydata:null,
            }
        },
        mounted(){
          // console.log(this.usedwatertype)
          // if(this.usedwatertype != 'out'){
          //   this.tblcolumns.splice(this.tblcolumns.lenth-1,1);
          // }
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList
            this.MasterModelVers = this._self.$root.$store.state.MasterModelVerList
        },
        methods: {
            handleQuery(name) {
                if(this.formInline.modelverid ==0 || this.formInline.modelverid == null){
                    this.$Message.error({content:'请选择车型查询',duration:3})
                    return
                }

                console.log("库存报表表单数据",this.formInline)
                this.postquerydata={
                    companyid:this.formInline.companyid,
                    modelverid:this.formInline.modelverid,
                    watertype:this.formInline.watertype,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                }

                if(this.formInline.opdate !=null){
                    if(this.formInline.opdate[0] !=''){
                        this.postquerydata.opDateStart = this.formInline.opdate[0]
                    }
                    if(this.formInline.opdate[1] !=''){
                        this.postquerydata.opDateEnd = this.formInline.opdate[1]
                    }
                }

                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log("出入库流水查询表单数据",this.postquerydata)
                this.$axios.post("/api/vehicleIn/stockwater/query",this.postquerydata)
                .then(function(res){
                    console.log("出入库流水查询结果",res.data)
                    if(res.data.resultCode ==0){
                        let summary = res.data.data.summary
                        let detail = res.data.data.detail

                        this.tbldata = detail.data
                        this.tblpageinfo.total = detail.totalnumber

                        this.summary.tbldata = summary

                        this.usedwatertype = this.formInline.watertype
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.formInline.modelverid = null
                this.formInline.opdate = []
                this.tbldata=[]
                this.tblpageinfo.total = 0
            },
            onmodelchange(e,selectedData){
              console.log(e)
              console.log(selectedData)
              if(selectedData.length >1){
                this.formInline.modelverid = selectedData[1].value
              }
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''
            },
            getdaterangetext(){
                //console.log("getdaterangetext",this.formInline.opdate)
                if(this.formInline.opdate !=null && this.formInline.opdate.length >0){
                    let dstr =''
                    if(this.formInline.opdate[0] !=''){
                        dstr = this.formInline.opdate[0] +' 至 '
                    }
                    else{
                        return '全部'
                    }

                    if(this.formInline.opdate[1] !=''){
                        dstr = dstr + this.formInline.opdate[1]
                    }
                    else{
                        dstr = dstr + "当前"
                    }

                    return dstr
                }
                else{
                    return "全部"
                }
            }
        }
}
</script>
