<template>
      <div style="height:700px">
        <h1 style="padding-top:30px">电动车管理系统</h1>
        <h2 style="padding-top:30px">用户登录</h2>
        <Form ref="adminloginForm" :model="formInline" :rules="ruleInline" style="width:20%;margin:auto;padding-top:40px">
          <FormItem prop="username">
              <i-input type="text" v-model="formInline.username" placeholder="账号" :autofocus="true">
                  <Icon type="ios-person-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="password" style="padding-top:30px;">
              <i-input type="password" v-model="formInline.password" placeholder="密码">
                  <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </i-input>
          </FormItem>
          <FormItem prop="yzm" style="padding-top:30px;">
            <Row type="flex" align="middle" justify="center">
              <i-col span="8">
                <i-input type="text" v-model="formInline.yzm" placeholder="验证码" @on-enter="handleSubmit('adminloginForm')">
                    <Icon type="md-aperture" />
                </i-input>
              </i-col>
              <i-col span="1">  </i-col>
              <i-col span="8" ><yzm :identifyCode="identifyCode" v-on:onrefresh="refreshcode"></yzm></i-col>
            </Row>
          </FormItem>
          <FormItem style="padding-top:30px;padding-bottom:10px">
              <Button type="primary" @click="handleSubmit('adminloginForm')" style="width:100%">登录</Button>
          </FormItem>
          <FormItem >
            <router-link to="/register">注册新用户</router-link>
          </FormItem>
      </Form>
      <Modal v-model="recharge.showrechargealert" title="续费提醒" ok-text="现在去通知续费" cancel-text="我知道了，稍后再去通知"  @on-cancel="rechargecancel" @on-ok="rechargeOk">
        <p>{{recharge.rechargealerttext}}</p>
        <p>请及时通知公司管理人员进行续费！</p>
      </Modal>
    </div>
</template>
<script>
import {nowdatediff} from '@/zsjs/systemTool.js'
import SIdentify from '@/components/SIdentify.vue' //* *引入验证码组件**
export default {
  name: 'AdminLogin',
  data () {
    const validateyzm = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入验证码'))
      } else if (value != this.identifyCode) {
        return callback(new Error('验证码输入错误'))
      } else {
        callback()
      }
    }
    return {
      formInline: {
        username: '',
        password: '',
        yzm: ''
      },
      recharge:{
        showrechargealert:false,
        rechargealerttext:'',
        afterlogonpath:'',
        //alerttype 1--即将过期   2--已过期  3--未激活
        alerttype: 1
      },      
      identifyCodes: '1234567890',
      identifyCode: '12354',
      haveError: 1,
      errorMsg: '密码错误',
      ruleInline: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
        ],
        yzm: [
          { validator: validateyzm, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    'yzm': SIdentify
  },
  mounted () {
    this.refreshcode()
  },
  methods: {
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          axios.post("/api/adminuser/users/login",this.formInline)
          .then(function(res){
            if(res.data.resultCode ==0){
              this.$Message.success({content:res.data.msg, duration:3, closable:true})
              this.$store.commit('changeLogin',res.data.data)

              console.log(res.data.data)
              var expiryTime = res.data.data.logonInfo.expiryTime
              var newpath = '/workdesk'
              //this.$router.push({ path:newpath})
              if(res.data.data.logonInfo.level==100){
                this.recharge.afterlogonpath = newpath
                if(expiryTime == null || expiryTime == ''){
                  this.$Message.error({content:'账号已于'+expiryTime+'过期，请联系公司管理人员先续费！！', duration:15})
                  this.recharge.alerttype = 3
                  this.recharge.rechargealerttext =   '您公司的账号还未激活'
                  this.recharge.showrechargealert = true
                }
                else{
                  var datediff = nowdatediff(expiryTime)
                  console.log(datediff)
                  if(datediff >0 && datediff<30){
                    this.recharge.alerttype = 1
                    this.recharge.rechargealerttext =   '您公司的账号即将于【'+expiryTime+'】过期'
                    this.recharge.showrechargealert = true
                  }
                  else if(datediff <=0){
                    this.recharge.alerttype = 2
                    this.recharge.rechargealerttext =   '您公司的账号已于【'+expiryTime+'】过期'
                    this.recharge.showrechargealert = true   
                  }
                  else{
                    this.$router.push({ path:newpath})
                  }
                }
              }
              else{
                this.$router.push({ path:newpath})
              }
            }
            else{
              this.$Message.error({content:res.data.msg, duration:3})
            }
          }.bind(this));
          
        } else {
          this.$Message.error('请输入正确的内容')
        }
      })
    },
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
      console.log(this.identifyCode)
    },
    refreshcode: function () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    rechargecancel(){
      this.recharge.showrechargealert = false
      setTimeout(()=>{
        this.$router.push({ path: this.recharge.afterlogonpath })
      },1000)
    },
    rechargeOk(){
      this.recharge.showrechargealert = false
      //this.$router.push({ path: this.recharge.afterlogonpath })
    }
  }
}
</script>

<style>
    .center {
        position: fixed;
        top: 50%;
        left: 50%;
        text-align: center;
        background-color: #ffffcc;
        width:70%;
        height: 70%;
        -webkit-transform: translateX(-50%) translateY(-50%);
    }
</style>
