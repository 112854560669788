import {getLodop} from '@/zsjs/LodopFuncs.js'

//
var LODOP_CER=null;
var LODOP_CODE=null;

var CER_PRINTER_NAME=null;
var CODE_PRINTER_NAME=null;

var LODOP_PUBLIC;
var LODOP_PIAOJU=null;

function getLodopbyname(name){
    if(name=="cer"){
        if(LODOP_CER ==null){
            printerInit(name)
        }
        return LODOP_CER
    }
    else if(name=="code"){
        if(LODOP_CODE ==null){
            printerInit(name)
        }
        return LODOP_CODE
    }
}

function printerInit(name){
    if(name == "cer"){
        console.log("--------INIT cer-------");
        LODOP_CER = getLodop();
        LODOP_CER.PRINT_INITA(0,0,"279mm","210mm","cer");
    }
    else if(name == "code"){
        console.log("--------INIT code-------");
        LODOP_CODE = getLodop();
        LODOP_CODE.PRINT_INIT("code");
    }
}

export function getPrinters() {
    if(LODOP_CER ==null){
        printerInit("cer")
    }

    var printernum =LODOP_CER.GET_PRINTER_COUNT();
    console.log(printernum);
    var i=0;
    console.log("---------------");
    console.log(LODOP_CER);
    var printerlist=[];
    for(i=0;i<printernum;i++){
      printerlist.push({name: LODOP_CER.GET_PRINTER_NAME(i)})
    }
    return printerlist
}

export function setCerPrinterName(name){
    CER_PRINTER_NAME = name
    console.log("setCerPrinterName:"+CER_PRINTER_NAME)
}

export function setCodePrinterName(name){
    CODE_PRINTER_NAME = name
}

export function printSetup(name){
    var lodop=getLodopbyname(name);
    
    return lodop.PRINT_SETUP()   
}

export function printDesign(name){
    var lodop=getLodopbyname(name);
    
    return lodop.PRINT_DESIGN()   
}

export function printData(name,data){

}

function beforeprintcer(data){
//if(LODOP_CER ==null){
    printerInit("cer");
    //}
    //设置打印方向、尺寸
    LODOP_CER.SET_PRINT_PAGESIZE(2, 0,0,"A4")

    //指定使用的打印机名称
    LODOP_CER.SET_PRINTER_INDEX(CER_PRINTER_NAME)

    LODOP_CER.SET_PRINT_STYLE("FontSize", 12);
    LODOP_CER.SET_PRINT_STYLE("Bold", 1);

    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */
    LODOP_CER.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Full-Page");
    //LODOP_CER.SET_PRINT_MODE("PRINT_PAGE_PERCENT","100%");
    LODOP_CER.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    LODOP_CER.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);    

    var printpagesize=JSON.parse(localStorage.getItem("printpagesize"))

    console.log(printpagesize)
    if(printpagesize == null){
        printpagesize={w:"92%", h:"95%"}
        localStorage.setItem("printpagesize",JSON.stringify(printpagesize))
    }

    //LODOP_CER.ADD_PRINT_IMAGE("8mm", "5mm", printpagesize.w,printpagesize.h, document.getElementById(data).innerHTML);
	//LODOP_CER.SET_PRINT_STYLEA(0,"Stretch",1);
    LODOP_CER.ADD_PRINT_HTM("8mm", "5mm", printpagesize.w,printpagesize.h, document.getElementById(data).innerHTML);
}

function beforeprintcerV2(data){
    //if(LODOP_CER ==null){
    printerInit("cer");
    //}
    //设置打印方向、尺寸
    LODOP_CER.SET_PRINT_PAGESIZE(2, 0,0,"A4")

    //指定使用的打印机名称
    LODOP_CER.SET_PRINTER_INDEX(CER_PRINTER_NAME)

    LODOP_CER.SET_PRINT_STYLE("FontSize", 12);
    LODOP_CER.SET_PRINT_STYLE("Bold", 1);

    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */
    //LODOP_CER.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Full-Page");
    LODOP_CER.SET_PRINT_MODE("PRINT_PAGE_PERCENT","100%");
    LODOP_CER.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    LODOP_CER.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);    

    LODOP_CER.ADD_PRINT_HTM("8mm", "5mm", "92%","95%", document.getElementById(data).innerHTML);
}

function addCerWatermark(waterdata){
    LODOP_CER.ADD_PRINT_SETUP_BKIMG(waterdata);
    LODOP_CER.SET_SHOW_MODE("BKIMG_PRINT",1);
}

function addCerQrCode(direct, value){
    if(value == null){
        return
    }

    if(direct == "left"){
        var leftqr_pos=JSON.parse(localStorage.getItem("leftqr_pos"))

        console.log("打印左侧二维码",value)
        if(leftqr_pos == null){
            //leftqr_pos ={x:"176mm", y:"208mm"}
            leftqr_pos ={x:"942", y:"800"}
            localStorage.setItem("leftqr_pos",JSON.stringify(leftqr_pos))
        }

        leftqr_pos ={x:"942", y:"800"}
        localStorage.setItem("leftqr_pos",JSON.stringify(leftqr_pos))
        console.log(leftqr_pos.x)
        console.log(leftqr_pos.y)
        LODOP_CER.ADD_PRINT_BARCODE(leftqr_pos.x,leftqr_pos.y,122,122,"QRCode",value);
    }
    
    if(direct == "right"){
        var rightqr_pos=JSON.parse(localStorage.getItem("rightqr_pos"))

        console.log("打印右侧二维码",value)
        if(rightqr_pos == null){
            //rightqr_pos={x:"176mm", y:"240mm"}
            rightqr_pos={x:"1076", y:"800"}
            localStorage.setItem("rightqr_pos",JSON.stringify(rightqr_pos))
        }
        
        rightqr_pos={x:"1076", y:"800"}
        localStorage.setItem("rightqr_pos",JSON.stringify(rightqr_pos))
        LODOP_CER.ADD_PRINT_BARCODE(rightqr_pos.x,rightqr_pos.y,122,122,"QRCode",value);
    }

    LODOP_CER.SET_PRINT_STYLEA(0,"QRCodeVersion",10);
    LODOP_CER.SET_PRINT_STYLEA(0,"QRCodeErrorLevel","H");//
    LODOP_CER.SET_PRINT_STYLEA(0,"DataCharset","UTF-8");
}

function addShowNum(shownum){
    //Top,Left,Width,Height,strContent
    LODOP_CER.ADD_PRINT_TEXT(300,200,200,200,shownum)
}

/**
 * 
 * @param {*} data 
 * @param {*} watermark 水印
 * @param {*} num 本次打印份数
 * @param {*} shownum 显示当日累计打印份数
 */
export function printCerWithNum(data,watermark,num,shownum){
    beforeprintcer(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    if (shownum>0){
        addShowNum(shownum)
    }

    LODOP_CER.SET_PRINT_COPIES(num)
    var result=LODOP_CER.PRINT();
    console.log("printcer result="+result)
    return result;
}

export function printCer(data,watermark,num){
    
    beforeprintcer(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }
    LODOP_CER.SET_PRINT_COPIES(num)
    var result=LODOP_CER.PRINT();
    console.log("printcer result="+result)
    return result;
}

export function printCerV2(data,watermark,num){
    
    beforeprintcerV2(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }
    LODOP_CER.SET_PRINT_COPIES(num)
    var result=LODOP_CER.PRINT();
    console.log("printcer result="+result)
    return result;
}

export function printCerWithQrcode(data,watermark,num,leftqrcode,rightqrcode){
    
    beforeprintcerV2(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    addCerQrCode("left",leftqrcode)
    addCerQrCode("right",rightqrcode)

    LODOP_CER.SET_PRINT_COPIES(num)
    var result=LODOP_CER.PRINT();
    console.log("printcer result="+result)
    return result;
}

export function cerPreview(data,watermark){
    beforeprintcer(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    addCerQrCode()
    //LODOP_CER.PRINT_DESIGN()
    LODOP_CER.PREVIEW()
}

export function cerPreviewV2(data,watermark){
    beforeprintcerV2(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    //addCerQrCode("left","http://ccc.niuccc.cn/public/#/certificate/showdetail/?vinCode=876234567")
    //addCerQrCode("right","https://3c.cccap.org.cn/do/chn_car/coc/ins_detail/?vinCode=271722105013557")
    //LODOP_CER.PRINT_DESIGN()
    LODOP_CER.PREVIEW()
}

export function cerPreviewWithQrcode(data,watermark,leftqr,rightqr){
    beforeprintcerV2(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    console.log("左侧二维码",leftqr)
    console.log("右侧二维码",rightqr)
    addCerQrCode("left",leftqr)
    addCerQrCode("right",rightqr)
    LODOP_CER.PRINT_SETUP()
    //LODOP_CER.PRINT_DESIGN()
    //LODOP_CER.PREVIEW()
}

export function cerPreviewWithNum(data,watermark,shownum){
    beforeprintcer(data)
    if(watermark != null){
        addCerWatermark(watermark)
    }

    if (shownum>0){
        addShowNum(shownum)
    }

    LODOP_CER.PREVIEW()
}

function beforeprintcodeext(data,width,height){
    printerInit("code");

    //设置打印方向、尺寸（宽、高）
    //LODOP_CODE.SET_PRINT_PAGESIZE(1, width+"mm",height+"mm","")

    //指定使用的打印机名称
    LODOP_CODE.SET_PRINTER_INDEX(CODE_PRINTER_NAME)

    //ADD_PRINT_LINE(Top1,Left1, Top2, Left2,intLineStyle, intLineWidth)
    /*      intLineStyle：
            线条类型，数字型，0--实线 1--破折线 2--点线 3--点划线 4--双点划线
            缺省线条是实线。
            intLineWidth：
            线条宽，整数型，单位是(打印)像素，缺省值是1，非实线的线条宽也是0。
    */
    LODOP.ADD_PRINT_LINE("2%","3%","2%","97%",0,1);
    LODOP.ADD_PRINT_LINE("15%","3%","15%","97%",0,1);
    LODOP.ADD_PRINT_LINE("30%","3%","30%","97%",0,1);
    LODOP.ADD_PRINT_LINE("40%","3%","40%","97%",0,1);
    LODOP.ADD_PRINT_LINE("50%","3%","50%","97%",0,1);
    LODOP.ADD_PRINT_LINE("60%","3%","60%","97%",0,1);
    LODOP.ADD_PRINT_LINE("70%","3%","70%","97%",0,1);


    LODOP.ADD_PRINT_LINE("2%","3%","70%","3%",0,1);
    LODOP.ADD_PRINT_LINE("2%","30%","70%","30%",0,1);
    LODOP.ADD_PRINT_LINE("2%","97%","70%","97%",0,1);

    LODOP.ADD_PRINT_TEXT("7%","4%","30%","12%","商品名");
}

function beforeprintcode(data){
    printerInit("code");


    //设置打印方向、尺寸（宽、高）
    //LODOP_CODE.SET_PRINT_PAGESIZE(1, width+"mm",height+"mm","")

    //指定使用的打印机名称
    LODOP_CODE.SET_PRINTER_INDEX(CODE_PRINTER_NAME)

    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */
    LODOP_CODE.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Full-Page");

    LODOP_CODE.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    LODOP_CODE.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    
    console.log('start print code')
    //ADD_PRINT_HTM(Top,Left,Width,Height,strHtmlContent)
    LODOP_CODE.ADD_PRINT_HTM("4mm", "4mm", "RightMargin:4mm","BottomMargin:4mm", document.getElementById(data).innerHTML);
}

export function printCode(data,num){
    for(var i=0;i<num;i++){
        beforeprintcode(data)
        LODOP_CODE.PRINT();
    }
}

export function codePreview(data){
    beforeprintcode(data)
    LODOP_CODE.PREVIEW()
}

function public_preview_with_dir_on_one_page_init(data,num,direction){
    LODOP_PUBLIC = getLodop();
    LODOP_PUBLIC.PRINT_INIT("public");
    LODOP_PUBLIC.SET_PRINTER_INDEX(-1);
    //设置打印方向、尺寸
    LODOP_PUBLIC.SET_PRINT_PAGESIZE(direction, 0,0,"A4")

    LODOP_PUBLIC.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    LODOP_PUBLIC.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */
    LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Page");
    LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "5mm", "RightMargin:5mm","BottomMargin:5mm", document.getElementById(data).innerHTML);
    
    LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PRINTER",true);
    LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_ORIENT",true);
    LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PAGESIZE",true);
    LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_COPIES",true);
    
    LODOP_PUBLIC.SET_PRINT_COPIES(num)
}

function public_preview_with_dir_init(data,num,direction){
    LODOP_PUBLIC = getLodop();
    LODOP_PUBLIC.PRINT_INIT("public");
    LODOP_PUBLIC.SET_PRINTER_INDEX(-1);
    //设置打印方向、尺寸
    LODOP_PUBLIC.SET_PRINT_PAGESIZE(direction, 0,0,"A4")

    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */

     if(direction == 1){
        LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Width");
        LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "12mm", "RightMargin:12mm","BottomMargin:5mm", document.getElementById(data).innerHTML);
     }
     else{
        console.log("print full page mode")
        LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Height");
        LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "3mm", "RightMargin:3mm","BottomMargin:5mm", document.getElementById(data).innerHTML);
     }
    
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PRINTER",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_ORIENT",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PAGESIZE",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_COPIES",true);

    LODOP_PUBLIC.SET_PRINT_COPIES(num)
}

export function public_preview_with_dir_on_one_page(data,num,direction){
    public_preview_with_dir_on_one_page_init(data,num,direction)
    LODOP_PUBLIC.PREVIEW()
}

export function public_preview_with_dir(data,num,direction) {
    public_preview_with_dir_init(data,num,direction)
    LODOP_PUBLIC.PREVIEW()
    //LODOP_PUBLIC.PRINT_DESIGN()
}

export function public_print_with_dir_on_one_page(data,num,direction){
    public_preview_with_dir_on_one_page_init(data,num,direction)
    LODOP_PUBLIC.PRINT()
}

export function public_print_with_dir(data,num,direction) {
    public_preview_with_dir_init(data,num,direction)
    LODOP_PUBLIC.PRINT()
}

export function public_printInnerHtml_with_dir(data,num,direction) {
    LODOP_PUBLIC = getLodop();
    LODOP_PUBLIC.PRINT_INIT("public");
    LODOP_PUBLIC.SET_PRINTER_INDEX(-1);
    //设置打印方向、尺寸
    LODOP_PUBLIC.SET_PRINT_PAGESIZE(direction, 0,0,"A4")

    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */

     if(direction == 1){
        LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Width");
        LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "5mm", "RightMargin:5mm","BottomMargin:5mm", data);
     }
     else{
        console.log("print full page mode")
        LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Height");
        LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "3mm", "RightMargin:3mm","BottomMargin:5mm", data);
     }
    
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PRINTER",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_ORIENT",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PAGESIZE",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_COPIES",true);

    LODOP_PUBLIC.SET_PRINT_COPIES(num)
    LODOP_PUBLIC.PRINT()
}

export function public_piaoju(data,num) {
    LODOP_PUBLIC = getLodop();
    LODOP_PUBLIC.PRINT_INIT("public");
    LODOP_PUBLIC.SET_PRINTER_INDEX(-1);
    //设置打印方向、尺寸
    LODOP_PUBLIC.SET_PRINT_PAGESIZE(1, 0,0,"A4")

    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */


    LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT"," Full-Width");
    LODOP_PUBLIC.ADD_PRINT_HTM("5mm", "5mm", "RightMargin:5mm","BottomMargin:5mm", document.getElementById(data).innerHTML);
    
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PRINTER",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_ORIENT",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_PAGESIZE",true);
     LODOP_PUBLIC.SET_PRINT_MODE("RESELECT_COPIES",true);

    LODOP_PUBLIC.SET_PRINT_COPIES(num)
    LODOP_PUBLIC.PREVIEW()
}

export function public_print(data,num) {		
    LODOP_PUBLIC = getLodop();
    LODOP_PUBLIC.PRINT_INIT("public");
    LODOP_PUBLIC.SET_PRINTER_INDEX(-1);
    //设置打印方向、尺寸
    LODOP_PUBLIC.SET_PRINT_PAGESIZE(1, 0,0,"A4")

    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW",true);
    //LODOP_PUBLIC.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);
    /**
     * 设置缩放
     * Full-Width	按整宽，即宽度缩放到与纸宽相当，高度不变，缩放后会变形。
     * Full-Height	按整高，即高度缩放到与纸高相当，宽度不变，缩放后会变形
     * Full-Page	按整页，既按整宽又按整高，缩放后会变形。
     * Auto-Width	按整宽且不变形，即按整宽的同时，高度与之同比。
     * Auto-Height	按整高且不变形，即按整高的同时，宽度与之同比。
     */
    //LODOP_PUBLIC.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Auto-Height");

    LODOP_PUBLIC.ADD_PRINT_HTM("4mm", "4mm", "RightMargin:4mm","BottomMargin:4mm", document.getElementById(data).innerHTML);
    LODOP_PUBLIC.SET_PRINT_COPIES(num)
    //LODOP_PUBLIC.PRINT_DESIGN()
    LODOP_PUBLIC.PREVIEW()
};	