<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem prop="vehicleno" label="整车编码:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="formInline.vehicleno">
                            </i-input>
                        </FormItem>
                    </td>
                    <td width="23%">
                        <FormItem  label="选择车型:" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Cascader v-model="formInline.modelids"  :data="MasterModelDatalist" @on-change="onmodelchange"></Cascader>
                        </FormItem>
                    </td>
                    <td width="23%">
                        <FormItem prop="color"  label="选择颜色:" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.color" >
                              <Option v-for="(item,index) in Colorlist" :value="item" :key="index">{{item}}</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >库存查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">库存车辆列表 ( <span style="font-weight: bold;">库存数量：</span> <span style="color: red;font-size: large;margin:0px 15px;">{{tblpageinfo.total}}</span> )</label>  
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>
        
        <!-- 整车编码列表-->
        <div style="clear: both;">
            <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" no-data-text="">
            <template slot-scope="{ row }" slot="vehicleno">
                <strong>{{ row.vehicleno }}</strong>
            </template>
            <template  slot-scope="{ row }" slot="status">
                {{getstatustext(row.status)}}
            </template>      
            <template  slot-scope="{ row }" slot="intoDate">
                {{new Date(row.intodate).Format("yyyy-MM-dd HH:mm:ss")}}
            </template>        
            <template slot-scope="{ row, index }" slot="action">
                <Button type="primary" size="small"  style="margin-right: 5px" ">导出</Button>
            </template>            
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>

        </div>
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'StockDetail',
    props:['departid'],
    data () {
            return {
                MasterModelDatalist:[],
                MasterModelVers:null,
                Colorlist:[],
                formInline: {
                    companyid:'',
                    modelids:[],
                    modelverid:null,
                    color:null,
                    vehicleno: '',
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:100
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center'
                    },
                    {
                        title: '整车编码',
                        slot: 'vehicleno'
                    },
                    {
                        title: '车型配置别名',
                        key: 'certificatealias',
                    },
                    {
                        title: '颜色',
                        key: 'vehiclecolor',
                    },
                    {
                        title: '状态',
                        slot: 'status'       ,           
                    },
                    {
                        title: '入库时间',
                        slot: 'intoDate',
                        resizable:true
                    }            
                ],
                tbldata: [],
                postquerydata:null,
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList
            this.MasterModelVers = this._self.$root.$store.state.MasterModelVerList
        },
        methods: {
            handleQuery(name) {  
                if(this.formInline.vehicleno !=null && this.formInline.vehicleno!=''){
                    this.postquerydata={
                        companyid:this.formInline.companyid,
                        vehicleno:this.formInline.vehicleno,
                        status:2,
                        pageNum:this.tblpageinfo.currentpage,
                        pageSize:this.tblpageinfo.pagesize
                    }  
                }
                else if(this.formInline.modelverid ==0 || this.formInline.modelverid == null){
                    this.$Message.error({content:'请选择车型查询',duration:3})
                    return
                }
                else{
                    this.postquerydata={
                        companyid:this.formInline.companyid,
                        modelverid:this.formInline.modelverid,
                        vehiclecolor:this.formInline.color,
                        status:2,
                        pageNum:this.tblpageinfo.currentpage,
                        pageSize:this.tblpageinfo.pagesize
                    }  
                }

                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log(this.postquerydata)
                this.$axios({
                    method:'post',
                    url:"/api/vehicleIn/stockinQuery",
                    data:this.postquerydata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        this.tbldata = res.data.data.data
                        this.tblpageinfo.total = res.data.data.totalnumber
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            onmodelchange(e,selectedData){
              console.log(e)
              console.log(selectedData)
              if(selectedData.length >1){
                this.formInline.modelverid = selectedData[1].value
                this.Colorlist = this.getcolorsbymodelverid(this.formInline.modelverid)
                console.log("读取颜色列表",this.Colorlist)
              }
            },
            getcolorsbymodelverid(id){
                for(let i =0;i<this.MasterModelVers.length;i++){
                    if(this.MasterModelVers[i].id == id){
                       return this.MasterModelVers[i].colors.split(";")
                    }
                }
                return []
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.tbldata=[]
                this.tblpageinfo.total = 0
                //this.handleQuery('vehicleQueryForm')
            },
            getstatustext(status){
                for(var item in statusdata){
                    if(statusdata[item].status == status){
                        return statusdata[item].text
                    }
                }
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            }
        }
}
</script>