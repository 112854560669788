<template>
<div >
    <div style="height:40px;width:100%;">
        <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">账目分类列表</label>  
        <Button v-hasPermission="'finacial:manage'" type="primary"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="addFinanceClassify">添加账目分类</Button>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>        
    <!-- 分类列表-->
    <div style="padding-top:10px">
        <Table :columns="tblcolumns" :data="tbldata" stripe border>
            <template slot-scope="{ row }" slot="type">
                <p style="font-size:10px"><strong>{{ row.type }}</strong></p>
            </template>
            <template slot-scope="{ row }" slot="classify">
                <p style="font-size:10px"><strong>{{ row.classify }}</strong></p>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button v-hasPermission="'finacial:manage'" type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                <Button v-hasPermission="'finacial:manage'" type="error" size="small" ghost @click="remove(index)" style="float:right">删除</Button>        
            </template>
        </Table>
        <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
    </div>
    <FinanceClassifyEdit v-bind:show="showeditFinanceClassify" v-bind:FinanceClassifydata="editFinanceClassifydata" @CloseFinanceClassify="onEditClose" @SubmmitOver="onSubmmitOver"/>
    <Modal v-model="showdeleteconfirm" title="删除账目分类" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
import FinanceClassifyEdit from './FinanceClassifyEdit.vue'
export default {
    name:'FinanceClassify',
    data () {
            return {
                showeditFinanceClassify:false,
                editFinanceClassifydata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                formInline:{
                    ordercode:null,
                    status:null,
                    customernumber:null,
                    customercompany:null,
                    ordertime:null
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:7
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '类别',
                        key: 'type',
                        resizable:true
                    },
                    {
                        title: '分类名称',
                        key: 'classify',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width:130
                    }
                    
                ],
                tbldata: [
                    {
                        type:'入账',
                        classify:'整车销售收入',                     
                    },
                    {
                        type:'入账',
                        classify:'配件销售收入',                     
                    },
                    {
                        type:'出账',
                        classify:'采购支出',                     
                    },
                    {
                        type:'出账',
                        classify:'索赔支出',                     
                    },
                    {
                        type:'出账',
                        classify:'工资支出',                     
                    },
                ]
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/FinanceClassify/query",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addFinanceClassify(){
                this.editFinanceClassifydata = null
                this.showeditFinanceClassify = true                
            },
            modify (index) {
                this.editFinanceClassifydata = this.tbldata[index]
                this.showeditFinanceClassify = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/FinanceClassify/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditFinanceClassify =false
            },
            onSubmmitOver(){
                this.showeditFinanceClassify =false
                this.handleQuery()
            },
            onOrderStatusSelect(status){

            },
            onpagechange(){

            },
            clickopenprod(index){
                console.log(index)
                this.tbldata[index].openprod = !this.tbldata[index].openprod
            }
        },
        components:{
            FinanceClassifyEdit:FinanceClassifyEdit
        }
}
</script>