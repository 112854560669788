<template>
    <div style="background: #ffffff;height:90%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">财务部门账务管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane label="资金管理">
                    <faccount />
                </TabPane>
                <!--
                <TabPane label="账目分类维护">
                    <fclassify />
                </TabPane>
                -->
                <TabPane label="销售订单">
                    <sellorder departid="104"/>
                </TabPane>
                <TabPane label="采购订单">
                    <purchaseorder departid="104"/>
                </TabPane>
                <TabPane label="委外单">
                    <partoutsourcing departid="104"/>
                </TabPane>
                <TabPane  label="销售计划">
                    <splanorder departid="104"/>
                </TabPane>
                <TabPane  label="车型定价">
                    <mastermodel departid="104"/>
                </TabPane>
                <!--
                <TabPane label="车型定价">
                    <modelprice />
                -->
                </TabPane>
                <TabPane label="物料定价">
                    <partprice />
                </TabPane>
                <TabPane v-if="checkpermission('supplier:view')" label="供应商结算方式">
                    <supplier departid="104"/>
                </TabPane>                
                <TabPane label="财务报表">
                    <freport />
                </TabPane>                
                <TabPane label="整车库存查询" v-if="checkpermission('productwarehouse:view')">
                    <p style="color:#f60;text-align:left">
                        <span>库存车辆统计信息</span>
                    </p>
                    <stockinfo />
                </TabPane>
            </Tabs>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import SellOrder from '../sell/SellOrder.vue'
    import SalePlanOrder from '@/views/business/workshop/sell/saleplan/SalePlanOrder'
    import VehicleQuerySummary from '@/views/common/VehicleQuerySummary.vue'
    import FinanceAccount from './FinanceAccount.vue'
    import FinanceClassify from './FinanceClassify'
    import FinanceReport from './FinanceReport.vue'
    import PurchaseOrder from '@/views/business/workshop/purchase/PurchaseOrder'
    import ModelPrice from '@/views/business/workshop/finance/ModelPrice'
    import MasterModel from '@/views/business/workshop/technology/MasterModel'
    import PartPrice from '@/views/business/workshop/finance/PartPrice'
    import StockInfo from '@/views/business/workshop/stock/StockInfo'
    import Supplier from '@/views/business/workshop/technology/Supplier'
    import PartOutSourcing from '@/views/business/workshop/partstock/PartOutSourcing'

    export default {
        name:'FinanceDesktop',
        components:{
            'sellorder': SellOrder,
            'purchaseorder':PurchaseOrder,
            'splanorder':SalePlanOrder,
            'viewsummary':VehicleQuerySummary,
            'stockinfo':StockInfo,
            'faccount':FinanceAccount,
            'fclassify':FinanceClassify,
            'freport':FinanceReport,
            'supplier':Supplier,
            'modelprice':ModelPrice,
            'mastermodel':MasterModel,
            'partprice':PartPrice,
            'partoutsourcing':PartOutSourcing,
            'loginfobar':UserInfoTitleBar,
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },        
    }
</script>
