<template>
<div >
    <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
        <FormItem  label="月度查询:" style="margin-bottom:5px;margin-top:10px;width:300px">
            <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
        </FormItem>  
    </Form>
    <!-- 账号列表-->
    <div style="padding-top:10px">
        <Table stripe :columns="tblcolumns" :data="tbldata">
        <template slot-scope="{ row }" slot="productname">
            <strong>{{ row.productname }}</strong>
        </template>
        </Table>
    </div>
</div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
export default {
    name:'UnplanedSummary',
    data () {
            return {
                formInline:{
                    expectdate: sysTool.getNowFormatDate(new Date())
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '合格证别名',
                        slot: 'productname',
                    },
                    {
                        title: '颜色',
                        key: 'productcolor',
                    },     
                    {
                        title: '总数量(单位:辆)',
                        key: 'total',
                    }
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata={
                    companyid:this._self.$root.$store.state.LogonInfo.companyid
                }
                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                }
                else{
                    this.$Message.error("请选择月份进行查询！")
                }

                this.$axios({
                    method:'post',
                    url:"/api/plan/unplansummary/query",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                    this.handleQuery()
                }

            },
        }
}
</script>