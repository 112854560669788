<template>
    <div style="background: #ffffff;height:90%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">计划部门管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane label="生产计划">
                    <planorder departid="105"/>
                </TabPane>
                <TabPane label="销售计划">
                    <splanorder departid="105"/>
                </TabPane>
                <!--
                <TabPane label="物料需求计划">
                    <mplanorder departid="105"/>
                </TabPane>
                -->
            </Tabs>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import PlanOrder from './PlanOrder.vue'
    import VehicleQuerySummary from '@/views/common/VehicleQuerySummary.vue'
    import SellOrder from '../sell/SellOrder.vue'
    import UnplanedSummary from '@/views/business/workshop/plan/UnplanedSummary'
    import SalePlanOrder from '@/views/business/workshop/sell/saleplan/SalePlanOrder'
    import MaterialPlanOrder from '@/views/business/workshop/plan/materialplan/MaterialPlanOrder'

    export default {
        name:'FinanceDesktop',
        components:{
            'sellorder': SellOrder,
            'splanorder':SalePlanOrder,
            'mplanorder':MaterialPlanOrder,
            'planorder': PlanOrder,
            'loginfobar':UserInfoTitleBar,
            'unplansummary':UnplanedSummary
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },        
    }
</script>
