<style scoped>
    .time{
        font-size: 14px;
        font-weight: bold;
    }
    .content{
        padding-left: 5px;
    }
</style>
<template>
    <Modal :value="show" width="60%" @on-cancel="oncancel" @on-ok="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:blue;text-align:left">
            <span>订单编号：{{ordercode}}</span>
        </p>
        <Row :gutter="20" index="" align="middle" justify="center" v-if="statusdata !=null">
          <i-col span="12">
            <h3>订单状态：</h3>
            <Timeline style="margin-top: 15px;">
                <TimelineItem color="blue">
                        <p class="time"></p>
                        <p class="content">开始</p>
                </TimelineItem>
                <TimelineItem color="red" v-for="(item,index) in statusdata.mstatusList" :key="index">
                    <p class="time">{{item.happentime}}</p>
                    <p class="content">{{item.mstatustext}} | {{item.operatorname}}  {{item.note}}</p>
                </TimelineItem>
            </Timeline>            
          </i-col>
          <i-col span="12">
            <h3>付款状态：</h3>
            <Timeline style="margin-top: 15px;">
                <TimelineItem color="blue">
                        <p class="time"></p>
                        <p class="content">开始</p>
                </TimelineItem>
                <TimelineItem color="green"  v-for="(item,index) in statusdata.fstatusList" :key="index">
                    <p class="time">{{new Date(item.createtime).Format("yyyy-MM-dd")}}</p>
                    <p class="content"><span style="color: fuchsia;">{{item.payMoney}}元</span> | {{new Date(item.happendDate).Format("yyyy-MM-dd")}} | {{item.note}} | {{item.modifyusername}}
                    </p>
                </TimelineItem>
            </Timeline>               
          </i-col>
        </Row>
    </Modal>

</template>
<script>
    export default {
        name:'PurchaseOrderHistory',
        props:['ordercode','show'],
        data(){
            return {
                statusdata:null,
                deptid:this._self.$root.$store.state.LogonInfo.departmentid
            }
        },
        methods:{
            onshow(v){
                if(v){
                    this.deptid = this._self.$root.$store.state.LogonInfo.departmentid
                    this.statusdata =null
                    this.queryOrderStatus()
                }
            },
            oncancel(){
                this.$emit('OnCloseOrderHistory','')
            },
            queryOrderStatus(){
                this.$axios({
                    method:'post',
                    url:"/api/purchase/order/querystatus",
                    data:{
                        ordercode:this.ordercode
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data.data)
                        this.statusdata = res.data.data
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
        }
    }
</script>
