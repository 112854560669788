import ApiService from '@/config/apiservice'

/**
 * 记录访问信息
 * @param {*} data 
 */
//export const addCertificateTemplate= data =>fetch('/certificatetemplate/addnew',data,'POST')
//export const updateCertificateTemplate= data =>fetch('/certificatetemplate/update',data,'POST')
export function getModels(cid){
	return axios.post('/api/tech/master/model/query',{companyId: cid})
}
export function getModelvers(cid) {
	return axios.post("/api/tech/master/modelver/query",{companyId: cid}) 
}
export const masterDataService = {
	
};