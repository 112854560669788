<template>
<div >
        <div style="height:40px;width:100%;">
            <Button type="primary"  style="margin-top:3px;float:left" @click="addWatermark">添加水印图片</Button>
        </div>
        <!-- 账号列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="watermarkname">
                <strong>{{ row.watermarkname }}</strong>
            </template>
            <template slot-scope="{ row }" slot="watermarkaddr">
                <img :src="row.watermarkaddr" style="width:100px;height:100px"/>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button type="error" size="small" @click="remove(index)" style="margin-right: 5px">删除</Button>
                <Button type="info" size="small" ghost @click="modify(index)">修改</Button>
            </template>
            </Table>
        </div>
        <watermarkEdit v-bind:show="showeditwatermark" v-bind:watermarkdata="editwatermarkdata" @CloseWatermark="onEditClose" @SubmmitOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="删除水印数据" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
import WatermarkEdit from './WatermarkEdit.vue'
export default {
    name:'WaterMark',
    data () {
            return {
                showeditwatermark:false,
                editwatermarkdata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '水印图片名称',
                        slot: 'watermarkname',
                    },
                    {
                        title: '图片',
                        slot: 'watermarkaddr',
                    },               
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/watermark/query",
                    data:{companyid:this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addWatermark(){
                this.editwatermarkdata = null
                this.showeditwatermark = true                
            },
            modify (index) {
                this.editwatermarkdata = this.tbldata[index]
                this.showeditwatermark = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/watermark/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditwatermark =false
            },
            onSubmmitOver(){
                this.showeditwatermark =false
                this.handleQuery()
            },
        },
        components:{
            watermarkEdit:WatermarkEdit
        }
}
</script>