<template>
<div >
        <div style="padding-top:10px;width:50%;margin:auto 0">
            <Form ref="supportForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline" >
                <Row  >
                <i-col span="24">
                    <FormItem prop="companyname" label="公司名称:">
                        <i-input type="text" :disabled="!startmodify" v-model="formInline.companyname" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                </Row>
                <Row>                
                <i-col span="24">
                    <FormItem prop="linkaddr" label="公司超链地址:">
                        <i-input :disabled="!startmodify" type="text" v-model="formInline.linkaddr" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                </Row>
                <Row>  
                <i-col span="24">  
                    <FormItem prop="servicenumber" label="服务热线电话：">
                        <i-input :disabled="!startmodify" type="text" v-model="formInline.servicenumber" :maxlength=20  placeholder="服务热线电话"></i-input>
                    </FormItem>                  
                </i-col>
                </Row>
                <Row>  
                <i-col span="24">  
                    <FormItem prop="supportnumber" label="售后技术支持电话：">
                        <i-input :disabled="!startmodify" type="text" v-model="formInline.supportnumber" :maxlength=20  placeholder="售后技术支持电话"></i-input>
                    </FormItem>                  
                </i-col>
                </Row>
                <Row>
                    <i-col span="24">  
                        <FormItem prop="qrcodedomain" label="合格证厂家二维码地址域名：">
                            <i-input :disabled="!startmodify" type="text" v-model="formInline.qrcodedomain" :maxlength=30  placeholder="二维码地址域名"></i-input>
                        </FormItem>                  
                    </i-col>
                </Row>                
                <Button type="info" @click="onclick('supportForm')">{{formInline.buttontext}} </Button>
            </Form>
        </div>
    </div>
</template>

<script>
export default {
    name:'SupportSetting',
    data () {
            return {
                formInline:{
                    id:null,
                    companyname:null,
                    linkaddr:null,
                    servicenumber:null,
                    supportnumber:null,
                    qrcodedomain:null,
                    buttontext:'点击修改',
                },
                startmodify:false,
                ruleInline: {
                    companyname: [{ required: true, message: '请输入公司名称', trigger: 'blur' },],
                    qrcodedomain: [{ required: true, message: '请输入合格证二维码域名', trigger: 'blur' },],
                }
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/support/query",
                    data:null,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        console.log(rspdata)
                        if(rspdata !=null){
                            this.formInline.id = rspdata[0].id
                            this.formInline.companyname = rspdata[0].companyname
                            this.formInline.linkaddr = rspdata[0].linkaddr
                            this.formInline.servicenumber = rspdata[0].servicenumber
                            this.formInline.supportnumber = rspdata[0].supportnumber
                            this.formInline.qrcodedomain = rspdata[0].qrcodedomain
                        }
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onclick(name){
                if(this.startmodify){
                    //内容修改完了，点击执行保存操作
                    this.$refs[name].validate((valid) => {
                        if (valid) {
                            this.$axios({
                                method:'post',
                                url:"/api/support/update",
                                data:this.formInline,
                                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                                transformRequest: function(obj) {
                                    var str = [];
                                    for(var p in obj){
                                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                    }
                                    return str.join("&");
                                }
                            }).then(function(res){
                                if(res.data.resultCode ==0){
                                    var rspdata = res.data.data
                                    this.$Message.success({content:res.data.msg, duration:3})
                                }
                                else{
                                    this.$Message.error({content:res.data.data.msg, duration:3})
                                }
                            }.bind(this));   

                            this.startmodify = false
                            this.buttonText ='点击修改'
                        }
                    })
                }
                else{
                    //点击开始修改内容
                    //允许修改内容
                    this.startmodify = true
                    this.buttonText ='点击保存'        
                }
            },

        },
        components:{
        }
}
</script>