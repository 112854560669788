<template>
    <Modal
        :value="show"
        :title="title"
        :mask-closable =false
        @on-ok="ok"
        @on-cancel="cancel">
        <p>{{msg}}</p>
    </Modal>
</template>
<script>
    export default {
        props:['show','title','msg','companyid','action'],
        data () {
            return {
                modal1: this.show
            }
        },
        methods: {
            ok () {
                this.$axios({
                    method:'post',
                    url:"/api/adminuser/"+this.action,
                    data:{
                        accountid:this.companyid,
                        actionuserid:this._self.$root.$store.state.LogonInfo.userid
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success(res.data.msg)
                        this.$emit('CloseConfirmAction','')
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                    
                }.bind(this))
            },
            cancel () {
                this.$emit('CloseConfirmAction','');
            }
        }
    }
</script>