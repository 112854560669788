<template>
    <div>
        <div style="display: none;">
            <partprint ref="partprint" />
        </div>
        <partstockoutedit v-if="editdata.showeditReq" @closeedit="onpartReqEditWinClose"/>
        <div id="partReqlistdiv" v-else>
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>
                        <td align="left">
                            <FormItem prop="status" label="申请单状态" style="margin-bottom:5px;margin-top:10px">
                                <Select v-model="formInline.status" @on-change="onOrderStatusSelect">
                                    <Option value=null key=100>请选择</Option> 
                                    <Option value=0 key=0>未领料</Option> 
                                    <Option value=1 key=1>已领料</Option> 
                                </Select>
                            </FormItem>
                        </td>                       
                        <td align="left">
                            <FormItem  label="下单日期:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="选择日期范围" v-model="formInline.createtime"  @on-change="formInline.createtime=$event" @on-clear="formInline.createtime=null"></DatePicker>
                            </FormItem>  
                        </td>                
                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('vehicleQueryForm')" >计划单查询</Button>    
                                <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">申请单列表</label>  
                <Button v-hasPermission="'partwarehouse:manage'" type="primary" v-if="departid=='108'"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="editdata.showeditReq=true">零散出库</Button>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row,index }" slot="requestType">
                    {{row.requestType == 0 ? '计划内':'零散'}}
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <div v-if="departid=='105'" class="opbar">
                        <Button  v-hasPermission="'partwarehouse:view'" type="info" size="small" @click="viewmaterial(index)" style="float:left">查看物料</Button>           
                    </div>
                    <div v-if="departid=='108'" class="opbar">
                        <Button  v-hasPermission="'partwarehouse:view'" type="info" size="small" @click="viewmaterial(index)" style="float:left">查看物料</Button>
                        <Poptip
                            confirm
                            title="确认出库?"
                            transfer
                            @on-ok="confirmout(row.id)">
                            <Button :disabled="row.status ==1"  v-hasPermission="'partwarehouse:manage'" type="error" size="small"  style="float:left">出库</Button>  
                        </Poptip>           
                    </div>
                </template>                
                </Table>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>
        </div>
        <Modal v-model="showdeleteconfirm" title="作废计划单" @on-ok="confirmDelete">确定要作废吗？</Modal>
        <Modal v-model="mdetail.showmaterialviewwin" title="查看物料清单" width="60%">
            <div id="printdiv" v-if="mdetail.showmaterialviewwin">
                <!--
<Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata" border></Table>
                -->
                <div style="width: 100%;text-align: center;">
                    <h1>生产领料单</h1>
                </div>
                <div>
                    车型：{{mdetail.orderinfo.modelVerName}}
                </div>
                <table border="1px solid black"  cellspacing="0" cellpadding="0" width="100%" >
                    <tr>
                        <th style="width:8%">序号</th>
                        <th style="width:12%">物料名称</th>
                        <th style="width:30%">物料规格</th>
                        <th style="width:10%">物料编码</th>
                        <th style="width:10%">数量</th>
                        <th style="width:10%">单位</th>
                        <th style="width:20%">供应商</th>
                    </tr>
                    <tr v-for="(item,index) in mdetail.tbldata">
                        <td>{{index+1}}</td>
                        <td>{{item.materialname}}</td>
                        <td>{{item.materialspec}}</td>
                        <td>{{item.mcode}}</td>
                        <td>{{item.amount}}</td>
                        <td>{{item.unit}}</td>
                        <td>{{item.supplier}}</td>
                    </tr>
                </table>
            </div>
            <div slot="footer">
                <Button type="warning" ghost size="large" @click="mdetail.showmaterialviewwin = false">关闭</Button>
                <Button type="primary" ghost size="large" @click="print(mdetail.activeindex)">打印</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js' 
import {getPrinters,public_piaoju,public_print_with_dir} from '@/zsjs/zskjprinter.js'
import PartStockOutEdit from '@/views/business/workshop/partstock/PartStockOutEdit'
import PartPrint from '@/views/business/workshop/partstock/PartPrint'

export default {
    name:'partReqOrder',
    props:['departid'],
    data () {
            return {
                showeditpartReq:false,
                editSellorderdata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    status:null,
                    createtime:null,
                    //expectdate: sysTool.getNowFormatDate(new Date()),
                    pageSize:20,
                    pageNum:0
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '类型',
                        slot: 'requestType',
                        resizable:true
                    }, 
                    {
                        title: '状态',
                        slot: 'status',
                        resizable:true
                    },           
                    {
                        title: '申请人',
                        key: 'requestUsername',
                        resizable:true
                    },     
                    {
                        title: '申请时间',
                        key: 'requestTime',
                        tooltip:true,
                        resizable:true
                    },                                                                   
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        resizable:true
                    }
                ],
                tbldata: [],
                editdata:{
                    showeditReq:false,
                    formInline:{
                        tbldata:[]
                    }
                },
                mdetail:{
                    showmaterialviewwin:false,
                    activeindex:null,
                    readfinished:false,
                    orderinfo:null,
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'total',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                }
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
  
                if(this.formInline.createtime !=null){
                    if(this.formInline.createtime[0] !=''){
                        postdata.createtimestart = this.formInline.createtime[0]
                    }
                    if(this.formInline.createtime[1] !=''){
                        postdata.createtimeend = this.formInline.createtime[1]
                    }
                }


                for(var p in postdata){
                    if(postdata[p] == null){
                        delete postdata[p]
                    }
                }

                console.log("查询计划订单的表单数据",postdata)
                
                this.$axios.post("/api/partRequest/query",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("查询物料申请单结果",res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onSubmmitOver(){                this.handleQuery()
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            confirmDelete(){
                this.$axios.post(
                    "/api/plan/order/delete",
                    {id:this.tbldata[this.deleteIndex].id})
                    .then(function(res){
                        if(res.data.resultCode ==0){
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.handleQuery()
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                }.bind(this));      
            },
            confirmout(id){
                let postdata={
                    'id': id
                }
                this.$axios.post("/api/partRequest/confirm",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("查询物料申请单结果",res.data.data)
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onOrderStatusSelect(status){
                if (status == null || status == 'null'){
                    this.formInline.status = null
                }
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            onpagechange(v){
                this.formInline.pageNum = v
                this.handleQuery()
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].status
                if(status ==0 || status == null){
                    return '未领料'
                }
                else if(status ==1){
                    return '已完成'
                }
            },
            onpartReqEditWinClose(){
                this.editdata.showeditReq=false
                this.handleQuery()
            },
            viewmaterial(index){
                this.mdetail.activeindex = index
                this.mdetail.showmaterialviewwin = true
                this.mdetail.orderinfo = this.tbldata[index]
                this.mdetail.tbldata = JSON.parse(this.tbldata[index].content)
            },
            print(index){
                console.log("打印物料信息",this.mdetail.tbldata)
                this.$refs['partprint'].printdata(this.tbldata[index],this.mdetail.tbldata,'llqd')
            }
        },
        components:{
            'partstockoutedit':PartStockOutEdit,
            'partprint':PartPrint
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }

    #printdiv {

    }

    #printdiv td{
        padding-left: 10px
    }
</style>