<template>
<div >
        <div style="height:40px;width:100%;">
            <Button v-hasPermission="'supplier:manage'" type="primary"  style="margin-top:3px;float:left" @click="addsupplier">添加供应商</Button>
        </div>
        <!-- 供应商列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="suppliername">
                <strong>{{ row.suppliername }}</strong>
            </template>
            <template slot-scope="{ row }" slot="paytype">
                <div v-if="row.paytype == null">
                    -
                </div>
                <div v-if="row.paytype == 0">
                    现结
                </div>
                <div v-if="row.paytype == 1">
                    月结: 每月{{row.payday}}日
                </div>
                <div v-if="row.paytype == 2">
                    年结: 每年{{row.paymonth}}月{{row.payday}}日
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button v-hasPermission="'supplier:delete'" type="error" size="small" @click="remove(index)" style="margin-right: 5px">删除</Button>
                <Button v-hasPermission="'supplier:manage'" type="info" size="small" ghost @click="modify(index)">修改</Button>
            </template>
            </Table>
        </div>
        <supplierEdit v-bind:show="showeditsupplier" v-bind:departid="departid" v-bind:supplierdata="editsupplierdata" @Closesupplier="onEditClose" @SaveOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="删除供应商" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>
import SupplierEdit from './SupplierEdit.vue'
export default {
    name:'supplier',
    props:["departid"],
    data () {
            return {
                showeditsupplier:false,
                editsupplierdata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '供应商名称',
                        slot: 'suppliername',
                    },
                    {
                        title: '产品范围',
                        key: 'scop',
                    },   
                    {
                        title: '备注',
                        key: 'note',
                    },              
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            if(this.departid == '104'){
                this.tblcolumns.splice(2,0,{
                title: '结算方式',
                slot: 'paytype',
            })
            }
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios.post("/api/supplier/query",{companyid:this._self.$root.$store.state.LogonInfo.companyid})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        this.$store.commit('changeMasterSuppliers',this.tbldata)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addsupplier(){
                this.editsupplierdata = null
                this.showeditsupplier = true                
            },
            modify (index) {
                this.editsupplierdata = this.tbldata[index]
                this.showeditsupplier = true
            },
            confirmDelete(){
                this.$axios.post("/api/supplier/delete",{id:this.tbldata[this.deleteIndex].id})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditsupplier =false
            },
            onSubmmitOver(){
                this.showeditsupplier =false
                this.handleQuery()
            },
        },
        components:{
            supplierEdit:SupplierEdit
        }
}
</script>