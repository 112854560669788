<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:100%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">仓库部门电动车出入库管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane label="生产计划车辆入库" v-if="checkpermission('productwarehouse:manage')">
                    <stockin type="inplan"></stockin>
                </TabPane>
                <TabPane label="零散车辆入库" v-if="checkpermission('productwarehouse:manage')">
                    <stockin type="outplan"></stockin>
                </TabPane>
                <TabPane label="零散车辆出库" v-if="checkpermission('productwarehouse:manage')">
                    <stockout ></stockout>
                </TabPane>
                <TabPane label="订单车辆出库" v-if="checkpermission('productwarehouse:manage')">
                    <saleorderstockout ></saleorderstockout>
                </TabPane>
                <TabPane label="库存统计" v-if="checkpermission('productwarehouse:view')">
                    <stockinfo ></stockinfo>
                </TabPane>
                <TabPane label="库存详情" v-if="checkpermission('productwarehouse:viewdetail')">
                    <stockdetail ></stockdetail>
                </TabPane>
                <TabPane label="出入库报表" >
                    <stockreport ></stockreport>
                </TabPane>
                <TabPane label="销售订单" v-if="checkpermission('productwarehouse:manage')">
                    <sellorder :departid='102'></sellorder>
                </TabPane>
            </Tabs>
            <div style="margin-top:18px;" v-if="!isV2">
                <a style="font-size:10px"  href="http://www.c-lodop.com/download/Lodop6.226_Clodop3.093.zip">打印插件下载</a>
            </div>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import StockIn from '@/views/business/workshop/stock/StockIn'
    import StockOut from '@/views/business/workshop/stock/StockOut'
    import SaleOrderStockOut from '@/views/business/workshop/stock/SaleOrderStockOut'
    import SellOrder from '@/views/business/workshop/sell/SellOrder'
    import StockDetail from '@/views/business/workshop/stock/StockDetail'
    import StockInfo from '@/views/business/workshop/stock/StockInfo'
    import StockReport from '@/views/business/workshop/stock/StockReport'

    export default {
        name:'WareHouse',
        components:{
            'stockin': StockIn,
            'stockout': StockOut,
            'saleorderstockout':SaleOrderStockOut,
            'sellorder':SellOrder,
            'stockdetail':StockDetail,
            'stockinfo': StockInfo,
            'stockreport':StockReport,
            loginfobar:UserInfoTitleBar,
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        }
    }
</script>
