<template>
    <div style="margin:20px auto;" v-show="showfooter">
        <div>
            <p style="font-size:14px">技术支持：<a :href="supportinfo.linkaddr">{{supportinfo.companyname}}</a>    &#160;&#160; &nbsp;服务电话:{{supportinfo.servicenumber}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 售后、技术电话：{{supportinfo.supportnumber}} </p>
        </div>
        <div style="margin: 10px auto;">
            <p style="font-size:12px">适用浏览器 Microsoft Edge、Chrome、360等，推荐Chrome浏览器 </p>
        </div>
        <div style="margin:10px auto ;text-align: center;font-size: 12px;display: inline-flex;">
            <span >友情链接:</span>
            <ul style="list-style:none;display: flex;">
                <li><a href="https://www.ai-compute.com" style="margin-left:10px;">天津智算科技</a> </li>
                <li><a href="http://www.cccap.org.cn/" style="margin-left:10px;">中汽认证中心</a> </li>
                <li><a href="http://www.ceva.org.cn/" style="margin-left:10px;">中国电动车协会</a> </li>
                <li><a href="http://www.xinhuanet.com/" style="margin-left:10px;">新华网</a> </li>
                <li><a href="http://www.people.com.cn/" style="margin-left:10px;">人民网</a></li>
            </ul>
        </div>
    </div>

</template>

<script>
export default {
    name:'PageFooterBar',
    props:['userinfo'],
    data(){
        return {
            supportinfo:this._self.$root.$store.state.SupportInfo,
            retrytimes:100,
            showfooter:true
        }
    },
    mounted(){

        if (this.$route.name == 'certificateshow' || this.$route.name == 'certificateshowsh'){
            this.showfooter = false
            return
        }
        else{
            this.showfooter = true
        }

        this.supportinfo = this._self.$root.$store.state.SupportInfo
        if(this.supportinfo == null){
            this.supportinfo ={}
        }
        this.handlequery()
    },
    methods:{
        handlequery(){
            this.$axios.post("/api/support/query",null)
            .then(function(res){
                if(res.data.resultCode ==0){
                    var rspdata = res.data.data
                    console.log("页面技术支持数据",rspdata)
                    if(rspdata !=null){
                        this.supportinfo.id = rspdata[0].id
                        this.supportinfo.companyname = rspdata[0].companyname
                        this.supportinfo.linkaddr = rspdata[0].linkaddr
                        this.supportinfo.servicenumber = rspdata[0].servicenumber
                        this.supportinfo.supportnumber = rspdata[0].supportnumber
                        this.supportinfo.qrcodedomain = rspdata[0].qrcodedomain

                        if(this.supportinfo.qrcodedomain == null){
                            setTimeout(()=>{
                                console.log('retry query supportinfo')
                                this.handlequery()
                                },3000)
                        }
                        else{
                            this.$store.commit('changeSupportInfo',this.supportinfo)
                        }
                        
                    }
                    //this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));   
        }
    }
}
</script>