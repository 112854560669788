<!-- 整车库存查询功能-->
<template>
    <div >
        <paccountedit id="paccountid" v-bind:aType='2' v-bind:show="showpaywin" v-bind:ordercode="activeOrdercode"  @CloseFinanceAccount="onEditPAccountClose" @OnSaveOver="onPAccountSaveOver"/>
        <Form ref="vehicleQueryForm" :model="formInline"  :rules="ruleInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="25%">
                        <FormItem  label="选择物料:" :label-width="120" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Cascader v-model="formInline.material"  :data="materialTypelist" ></Cascader>
                        </FormItem>
                    </td>
                    <td width="20%">
                        <FormItem  label="委外单状态:" :label-width="120" prop="status" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.status" >
                                <Option value="0" key="0">未确认</Option>
                                <Option value="1" key="1">已确认</Option>
                                <Option value="2" key="2">已完成</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="display: inline-flex;margin-top: 20px;">
            <div style="width:100%">
                <div style="height:40px;width:100%;">
                    <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold"><span style="margin-left: 400px;">共计： <span style="color: red;">{{tblpageinfo.total}} </span> 条符合条件的委外加工单</span></label>  
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
                </div>
                
                <!-- 整车编码列表-->
                <div style="clear: both;">
                    <Table ref="vehicletable" :row-class-name="rowClassName" border :columns="tblcolumns" :data="tbldata" no-data-text="">
                    <template slot-scope="{ row }" slot="vehicleno">
                        <strong>{{ row.vehicleno }}</strong>
                    </template>   
                    <template  slot-scope="{ row }" slot="status">
                        <div v-if="row.status == 0">
                            未确认
                        </div>
                        <div v-if="row.status == 1">
                            已确认
                        </div>
                        <div v-if="row.status == 2">
                            已完成
                        </div>
                    </template>
                    <template  slot-scope="{ row }" slot="fstatus">
                        <div v-if="row.fstatus == 0">
                            未结算
                        </div>
                        <div v-if="row.fstatus == 1">
                            已结算
                        </div>
                    </template>
                    <template  slot-scope="{ row }" slot="supplier">
                        {{row.supplier == null? '--未指定--' : row.supplier}}
                    </template>   
                    <template slot-scope="{ row, index }" slot="opuser">
                        {{usedwatertype == 'into'? row.intobyusername : row.outbyusername}}
                    </template>      
                    <template slot-scope="{ row, index }" slot="action" >
                        <!-- 108 零部件部门-->
                        <div  class="opbar" v-if="departid == '108'">
                            <Button v-if="row.status != 2" v-hasPermission="'partwarehouse:manage'"  type="success" size="small" @click="confirm(index)" >分配供应商</Button>  
                            <Button v-if="row.status != 2" v-hasPermission="'partwarehouse:manage'"  type="error" size="small" @click="finish(index)" >结束</Button>              
                        </div>     
                        <!-- 104 财务部门-->
                        <div  class="opbar" v-if="departid == '104'">
                            <Button :disabled="row.status ==0 || row.fstatus ==1" v-hasPermission="'finacial:manage'"  type="error" size="small" @click="payorder(index)" >结算</Button>              
                        </div>  
                    </template> 
                    </Table>
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>
                </div>
            </div>
        </div>
        <Modal v-model="confirmdata.showConfirmWin" title="分派供应商" ok-text="保存" >
            <Form ref="orderCreateForm" :model="confirmdata.formInline" label-position="right" :label-width="100" >
                <FormItem  label="供应商:" style="margin-bottom:5px;margin-top:10px">
                    <AutoComplete
                    v-model="confirmdata.formInline.supplier"
                    :data="suppliersearchlist"
                    @on-search="searchSupplier"
                    placeholder="供应商"
                    ></AutoComplete>
                </FormItem>
                <FormItem  label="备注:" >
                    <i-input type="textarea" v-model="confirmdata.formInline.note" :maxlength=200   placeholder='备注信息' style="width: 400px;"></i-input>
                </FormItem>
            </Form>

            <div slot="footer">
                <Button  type="primary" size="large" long @click="confirmOrder">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import PurchaseOrderAccountEdit from '@/views/business/workshop/finance/PurchaseOrderAccountEdit'
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'PartOutSourcing',
    props:['departid'],
    data () {
            return {

                usedwatertype:'',
                formInline: {
                    companyid:'',
                    material:[],
                    status:null,
                    materialid:null,                
                },
                ruleInline: {
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:20
                },
                tblcolumns: [
                {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '委外单号',
                        key: 'orderCode',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '结算状态',
                        slot: 'fstatus',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '委外供应商',
                        slot: 'supplier',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '物料名称',
                        key: 'materialName',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialSpec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'materialCode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'num',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },
                    {
                        title: '创建时间',
                        key: 'createTime',
                        resizable:true
                    },
                    {
                        title:"操作",
                        slot:'action',
                        resizable:true
                    }
                ],
                tbldata: [],
                postquerydata:null,
                confirmdata:{
                    showConfirmWin:false,
                    orderinfo:null,
                    formInline:{
                        supplier:null,
                        note:null
                    }
                },
                showpaywin:false,
                activeOrdercode:null,
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                //供应商匹配列表
                suppliersearchlist:[],
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.getMaterialMasterData()    
        },
        methods: {
            handleQuery() {  

                console.log("库存报表表单数据",this.formInline)
                this.postquerydata={
                    companyid:this.formInline.companyid,
                    status:this.formInline.status,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                }  

                if(this.formInline.material.length>1){
                    let materialidx = this.formInline.material[1]
                    this.postquerydata.materialId = this.masterdata.materialInfos[materialidx].id
                    
                }
                else{
                    this.formInline.material =[]
                }

                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log("委外单查询表单数据",this.postquerydata)
                this.$axios.post("/api/partOutsourcing/query",this.postquerydata)
                .then(function(res){
                    console.log("委外单查询结果",res.data.data)
                    if(res.data.resultCode ==0){
                        this.tbldata = res.data.data.data
                        this.tblpageinfo.total = res.data.data.totalnumber
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery()
            },
            confirm(index){
                this.confirmdata.orderinfo = this.tbldata[index]
                this.confirmdata.formInline.note = this.confirmdata.orderinfo.note
                this.confirmdata.formInline.supplier = this.confirmdata.orderinfo.supplier
                this.confirmdata.showConfirmWin = true
            },
            confirmOrder(){

                if(this.confirmdata.formInline.supplier == null || this.confirmdata.formInline.supplier == ''){
                    this.$Message.error({content:'供应商还未指定！',duration:5})
                    return;
                }

                var supplierlist =this.masterdata.supplierInfos
                let findsupplier = false
                for(var i=0;supplierlist !=null && i<supplierlist.length && !findsupplier;i++){
                    if(supplierlist[i].suppliername.indexOf(this.confirmdata.formInline.supplier) !=-1){
                        findsupplier = true
                    }
                }   

                if(!findsupplier){
                    this.$Message.error({content:'供应商列表没有该供应商！',duration:5})
                    return;
                }

                let postdata={
                    id: this.confirmdata.orderinfo.id,
                    supplier: this.confirmdata.formInline.supplier,
                    note:this.confirmdata.formInline.note
                }
                this.$axios.post("/api/partOutsourcing/confirm",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.confirmdata.showConfirmWin = false
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            onEditPAccountClose(){
                this.updateIndex = null
                this.showpaywin = false
            },
            onPAccountSaveOver(){
                this.updateIndex = null
                this.showpaywin = false
                this.handleQuery()
            },
            payorder (index) {
                this.updateIndex = index
                this.activeOrdercode = this.tbldata[index].orderCode
                this.showpaywin = true
            },
            finish(index){
                let postdata={
                    id: this.tbldata[index].id,
                }
                this.$axios.post("/api/partOutsourcing/finish",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.formInline.status = null
                this.formInline.materialId =null
                this.tbldata=[]
                this.tblpageinfo.total = 0

                this.handleQuery()
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            searchSupplier(value){
                if(value ==null){
                    this.suppliersearchlist =[]
                    return
                }
                var supplierlist =this.masterdata.supplierInfos
                this.suppliersearchlist=[]
                for(var i=0;supplierlist !=null && i<supplierlist.length;i++){
                    if(supplierlist[i].suppliername.indexOf(value) !=-1){
                        this.suppliersearchlist.push(supplierlist[i].suppliername)
                    }
                }            
            },
            getMaterialMasterData(){
                this.$axios.post('/api/material/masterdata/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                        .then(function(res){
                        if(res.data.resultCode ==0){
                            //this.$Message.success(res.data.msg)
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = this.processmaterialshowlist(res.data.data)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = i
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+') '+ mdata.materialInfos[j].supplier
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
            rowClassName (row, index) {
                if (row.supplier == null) {
                    return 'demo-table-warning-row';
                }
                return '';
            }
        },
        components:{
            'paccountedit':PurchaseOrderAccountEdit,
        }
}
</script>

<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #e7cac6;
        color: rgb(12, 12, 12);
    }

    .opbar {
        display: inline-flex;
        align-items: left;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>