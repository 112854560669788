<template>
    <div class="reports">
        <Form :model="formInline" :label-width="100"  style="width: 70%;">
          <FormItem label="统计月度范围:">
              <Row :gutter="20">
                  <Col span="6">
                    <DatePicker type="month" format="yyyy-MM" placeholder="选择起始月份" v-model="formInline.expectdatestart" :options="dateoption"  @on-change="onMonthStartChange" @on-clear="formInline.expectdatestart=null"></DatePicker>
                  </Col>
                  <Col span="2" style="text-align: center">-</Col>
                  <Col span="6">
                    <DatePicker type="month" format="yyyy-MM" placeholder="选择结束月份" v-model="formInline.expectdateend" :options="dateoption"  @on-change="onMonthEndChange" @on-clear="formInline.expectdateend=null"></DatePicker>
                  </Col>
                  <Col span="6">
                    <Button type="warning" size="small" @click="startcompute">开始统计</Button>
                  </Col>
              </Row>
            </FormItem>
        </Form>
        <div style="margin:20px auto">
            <div class="chart" id="myEchartPillar" style="width:45%;height:300px;margin-top:20px;float:left;margin-left:20px">
                report
            </div>
            <div class="chart" id="myEchartLine" style="width:45%;height:300px;margin-top:20px;float:right;margin-right:20px">
            </div>
        </div>
    </div>
</template>
<script>
export default {
//渲染的图表的方法放在mounted()，因为mounted()在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作 
  name:'FinanceReport',
  data (){
    return {
      formInline:{
        expectdatestart:null,
        expectdateend:null
      },
      echars:{
        hmonths:['1月','2月','3月','4月','5月','6月','7月','8月'],
        accountin:[0,0,0,0,0,0,0,0],
        accountout:[0,0,0,0,0,0,0,0],
        gains:[0,0,0,0,0,0,0,0]
      },

      dateoption: {
          disabledDate (date) {
              return date && ((date.getMonth() > new Date().getMonth() && date.getYear() ==new Date().getYear()) || (date.getYear()>new Date().getYear()));
          }
      },
    }
  },
  mounted() {
      console.log(this)
      console.log(this.$echarts)
      this.getPillar();
      this.getLine();
  },
  methods:{
    onMonthStartChange(e){
      this.formInline.expectdatestart = e+'-01'
      if(this.formInline.expectdateend !=null && new Date(this.formInline.expectdateend) < new Date(this.formInline.expectdatestart)){
        this.$Message.error('开始日期不能大于截至日期')
        this.formInline.expectdateend =null
      }
    },
    onMonthEndChange(e){
      this.formInline.expectdateend = e+'-31'
      if(this.formInline.expectdatestart !=null &&  new Date(this.formInline.expectdateend) < new Date(this.formInline.expectdatestart)){
        this.$Message.error('截至日期不能小于开始日期')
        this.formInline.expectdateend =null
      }
    },
    startcompute(){
      this.$axios({
          method:'post',
          url:"/api/fin/account/tongji",
          data:{
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            expectdatestart: this.formInline.expectdatestart,
            expectdateend:this.formInline.expectdateend
          },
          headers:{'Content-Type': 'application/x-www-form-urlencoded'},
          transformRequest: function(obj) {
              var str = [];
              for(var p in obj){
                  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
              }
              return str.join("&");
          }
      }).then(function(res){
          if(res.data.resultCode ==0){
              this.echars = res.data.data
              console.log(res.data.data)
              
              this.getPillar();
              this.getLine();
          }
          else{
              this.$Message.error({content:res.data.msg, duration:3})
          }
      }.bind(this));   
    },
    // 渲染柱形图表
    getPillar() {
      let that = this;
      // 基于准备好的dom(myEchartPillar)，初始化echarts实例
      let myChart =  this.$echarts.init(document.getElementById("myEchartPillar"));
      //指定图表的配置项和数据，绘制图表
      myChart.setOption({
        // 图表名称
        title: {
          text: "收入/支出统计图",
          textStyle:{
              fontSize:14
          }
        },
        // 图表颜色
        color: ["#6284d3"],
        // 提示
        tooltip: {
          //触发类型,axis:坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效，默认为直线，可选为：'line' | 'shadow'
            type: "shadow"
          }
        },
        grid: {
          show: true,
          //grid 组件离容器左侧的距离。
          left: "3%",
          //grid 组件离容器右侧的距离。
          right: "4%",
          //grid 组件离容器下侧的距离。
          bottom: "3%",
          //grid 区域是否包含坐标轴的刻度标签。true这常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件。
          containLabel: true,
          // 网格背景色，此配置项生效的前提是，设置了 show: true
          backgroundColor: "#E7F1F5"
        },
        //直角坐标系 grid 中的 x 轴
        xAxis: [
          {
            //'category' 类目轴
            type: "category",
            //坐标轴名称
            name: "月份",
            //坐标轴名称显示位置
            nameLocation: "end",
            //坐标数据
            data: this.echars.hmonths,
            //坐标轴刻度相关设置
            axisTick: {
              // 为 true 可以保证刻度线和标签对齐
              alignWithLabel: true
            },
            //坐标轴名称与轴线之间的距离
            nameGap: 2
          }
        ],
        //直角坐标系 grid 中的 y 轴
        yAxis: [
          {
            //'value' 数值轴，适用于连续数据
            type: "value",
            //坐标轴名称
            name: "金额（元）",
            //坐标轴的标签是否响应和触发鼠标事件，默认不响应
            triggerEvent: true
          }
        ],
        //系列列表。每个系列通过 type 决定自己的图表类型
        series: [
          {
            //系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列
            name: "收入",
            //类型为柱状/条形图
            type: "bar",
            //柱条的宽度，不设时自适应。支持设置成相对于类目宽度的百分比。
            barWidth: 8,
            //图形上的文本标签，可用于说明图形的一些数据信息
            label: {
              normal: {
                //是否显示标签
                show: true,
                //通过相对的百分比或者绝对像素值表示标签相对于图形包围盒左上角的位置
                position: "top"
              }
            },
            itemStyle : {
                color : 'green',
                barBorderRadius : [ 30, 30, 0, 0 ]
            },
            //系列中的数据内容数组
            data: this.echars.accountin
          },
            {
            //系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列
            name: "支出",
            //类型为柱状/条形图
            type: "bar",
            //柱条的宽度，不设时自适应。支持设置成相对于类目宽度的百分比。
            barWidth: 8,
            //图形上的文本标签，可用于说明图形的一些数据信息
            label: {
              normal: {
                //是否显示标签
                show: true,
                //通过相对的百分比或者绝对像素值表示标签相对于图形包围盒左上角的位置
                position: "top"
              }
            },
            itemStyle : {
                color : 'red',
                barBorderRadius : [ 30, 30, 0, 0 ]
            },
            //系列中的数据内容数组
            data: this.echars.accountout
          }          
        ]
      });
      //解决自适应
      setTimeout(function() {
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }, 500);
    },
    // 获取折线图
    getLine() {
      let that = this;
      let myChart =  this.$echarts.init(document.getElementById("myEchartLine"));
      myChart.setOption({
        title: {
          text: "月度盈利趋势图",
          textStyle:{
              fontSize:14
          }
          //  text: that.$t("profitChart.Revenuechart")
        },
        color: ["#6284d3"],
        tooltip: {
          trigger: "axis",
          formatter: "月份 : {b}<br/>收益 : {c}元",
          axisPointer: {
            type: "line"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            name: "月份",
            data: this.echars.hmonths,
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true
            },
            nameGap: 2
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "金额（元）"
          }
        ],
        series: [
          {
            name: "盈利金额",
            //类型为折线图
            type: "line",
            //折线样式
            lineStyle: {
              normal: {
                //宽度
                width: 3,
                //阴影颜色
                shadowColor: "rgba(0,0,0,0.1)",
                // 阴影的模糊范围
                shadowBlur: 10,
                // 阴影的纵向位移量
                shadowOffsetY: 10
              }
            },
            data: this.echars.gains
          }
        ]
      });
      setTimeout(function() {
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      }, 500);
    },
}
}
</script>
<style scoped>
  .reports {
    padding: 20px;
    margin: 0 auto;
  }
</style>