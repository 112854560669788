<template>
    <div style="width: 100%;padding:10px 30px">
        <div style="width: 100%;background: white;padding: 10px 20px;margin: 10px auto;">
            <div style="height: 30px;">
                <p style="color:#f60;text-align:left;font-size: 24px;padding: 0px;float: left;margin-left: 15px;">
                    <span>{{title}} </span>
                    <span style="color: royalblue;font-size: 16px;font-weight: bold;">(车型: {{modeldata.modelAlias +' / ' + modeldata.modelVer}}</span>
                    <span style="color: royalblue;font-size: 16px;font-weight: bold;">)</span>
                </p>                
            </div>
            <div style="margin:20px 10px;width:100%;text-align:left">BOM物料列表：</div>
            <Table  :columns="tblcolumns" :data="tbldata" border size="small"  height="400">
                <template slot-scope="{ row }" slot="materialname">
                    <strong>{{ row.materialname }}</strong>
                </template>      
                <template slot-scope="{ row, index }" slot="action" >
                    <Button v-if="action=='edit'" type="error" size="small" @click="remove(index)" >删除</Button>
                </template>                  
            </Table>  
            <Card v-if="action=='edit'" style="margin-top: 20px;">
                <p slot="title" style="text-align: left;">
                    <Icon type="md-add-circle" color="blue" />
                    {{'新增BOM物料'}}
                </p>
                <Form ref="BomEditForm" :model="detailFormInline"   label-position="right" :rules="ruleInline" >
                    <Row :gutter="10" index="" style="width: 100%;">
                            <i-col span="6">
                                <FormItem  label="选择物料:" prop="material">
                                    <Cascader v-model="detailFormInline.material"  :data="materialTypelist" @on-change="onMaterialChange"></Cascader>
                                </FormItem>
                            </i-col>
                            <i-col span="4" style="padding-left:5px">
                                <FormItem  label="数量:" prop="amount">
                                    <i-input type="number" v-model="detailFormInline.amount" :maxlength=10 placeholder="数量">
                                        <span slot="append">{{detailFormInline.unit}}</span>
                                    </i-input>
                                </FormItem>
                            </i-col>  
                            <i-col span="4" style="padding-left:5px">
                                <FormItem  label="参考价格:" prop="price">
                                    <i-input type="text" v-model="detailFormInline.price" :maxlength=10 placeholder="参考价格">
                                        <span slot="append">元</span>
                                    </i-input>
                                </FormItem>
                            </i-col>                                            
                            <i-col span="6" style="padding-left:15px;">
                                <Button type="success" style="font-size:10px;margin-top:33px" icon="md-add" @click="addmaterial('BomEditForm')" >添加到BOM</Button>
                            </i-col>             
                    </Row>                          
                </Form>
            </Card>
        </div>
        
        <div v-hasPermission="'bom:update'" slot="footer" style="margin: 25px auto; display: inline-flex;align-items: center;">
            <Button type="error" size="large"   @click="actionModify" style="margin: 0px 100px;width: 150px;">{{action=='view'?'修改':'放弃修改'}}</Button>
            <Button v-if="action=='edit'" type="primary" size="large"   @click="saveBom" style="margin: 0px 20px;width: 150px;">保存</Button>
        </div>
    </div>
</template>
<script>

    export default {
        name:'BomEdit',
        props:['modeldata'],
        data () {
            const validcheck = (rule, value, callback) => {
                if (value == null || value.length<2) {
                    return callback(new Error('请选择物料'))
                } else {
                    return callback()
                }
            }
            const validamount = (rule, value, callback) => {
                if (value == null || value<=0) {
                    return callback(new Error('请设置正确的数量'))
                } else {
                    return callback()
                }
            }
            return {
                title:'',
                action:'view',
                bomdata:null,
                detailFormInline:{
                    materialtypeindex:null,
                    materialinfoindex:0,
                    material:[],
                    price:null,
                    unit:null,
                    amount:0,
                },
                ruleInline: {
                    material: [
                        { validator:validcheck, required: true, trigger: 'blur'}
                    ],
                    amount: [
                        { validator:validamount,required: true, trigger: 'blur'}
                    ],
                    price:[
                        {required: true, message: '请填写正确的金额',pattern: /^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/}
                    ]
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '物料分类',
                        key: 'materialtype',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '物料编码',
                        key: 'mcode',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '物料名',
                        slot: 'materialname',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec'
                    },
                    {
                        title: '数量',
                        key: 'amount',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '单位',
                        key: 'unit',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '价格(单位:元)',
                        key: 'price',   
                        width:160,
                        resizable:true         
                    },
                    {
                        title: '供应商',
                        key: 'supplier',   
                        width:200,
                        resizable:true         
                    },
                    {
                        title: '-',
                        slot: 'action',
                        align: 'center'
                    }
                ],
                tbldata:[],
                oribomdata:[],
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                //供应商匹配列表
                suppliersearchlist:[],
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            console.log('bomview mounted=============')
            
            this.title ='查看车型BOM'
            this.refreshbom()
        },
        methods: {
            actionModify(){
                if(this.action == 'view'){
                    this.action = 'edit'
                    this.getMaterialMasterData();
                }
                else{
                    //放弃修改
                    this.action = 'view'
                    this.tbldata = this.oribomdata
                }
            },
            refreshbom(){
                axios.post('/api/bom/query',{companyid: this.modeldata.companyId,modelverid:this.modeldata.id})
                .then(function(res){
                        if(res.data.resultCode ==0){
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error({content:'没有该车型BOM数据',duration:5})
                                this.oribomdata =[]
                                this.tbldata = []
                            }
                            else{
                                this.bomdata = res.data.data
                                let bomcontent = res.data.data.content
                                this.oribomdata = JSON.parse(bomcontent)
                                this.tbldata = JSON.parse(bomcontent)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))                
            },
            handleClose(){
                this.$emit('closeview','')
            },
            getMaterialMasterData(){
                this.$axios.post("/api/material/masterdata/query",{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                .then(function(res){
                        if(res.data.resultCode ==0){
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = this.processmaterialshowlist(res.data.data)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = i
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+') '+ mdata.materialInfos[j].supplier
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
            searchSupplier(value){
                if(value ==null){
                    this.suppliersearchlist =[]
                    return
                }
                var supplierlist =this.masterdata.supplierInfos
                this.suppliersearchlist=[]
                for(var i=0;supplierlist !=null && i<supplierlist.length;i++){
                    if(supplierlist[i].suppliername.indexOf(value) !=-1){
                        this.suppliersearchlist.push(supplierlist[i].suppliername)
                    }
                }
                console.log(this.suppliersearchlist)
            },
            onMaterialChange(e){
                console.log(e)
                if(e.length>1){
                    this.detailFormInline.unit = this.masterdata.materialInfos[e[1]].unit
                    this.detailFormInline.suppliername = this.masterdata.materialInfos[e[1]].supplier
                }
                
            },
            remove(index){
                this.tbldata.splice(index, 1)
            },
            giveup(){
                this.detailFormInline.material =[]
                this.detailFormInline.price = null
                this.detailFormInline.amount =0
                this.detailFormInline.suppliername =null
            },
            getmaterialtypeindex(id){
                for(var i=0;i<this.masterdata.materialTypes.length;i++){
                    if(this.masterdata.materialTypes[i].id == id){
                        return i
                    }
                }
            },
            getmaterialinfoindex(id){
                for(var i=0;i<this.masterdata.materialInfos.length;i++){
                    if(this.masterdata.materialInfos[i].id == id){
                        return i
                    }
                }
            },
            saveBom(){
                if(this.tbldata.length == 0){
                    this.$Message.error({content:'bom为空',duration:3})
                    return
                }
                if(this.bomdata == null){
                    this.bomdata = {
                        companyid: this.modeldata.companyId,
                        modelverid: this.modeldata.id,
                        content: null
                    }
                }

                this.bomdata.content = JSON.stringify(this.tbldata)

                this.bomdata = this.removeEmptyChilds(this.bomdata)
                console.log("bom数据",this.bomdata)
                axios.post("/api/bom/update",this.bomdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.$Message.success({content:'bom提交成功',duration:3})
                        this.action = 'view'
                        this.oribomdata = JSON.parse(res.data.data)
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                }.bind(this))
            },
            addmaterial(formname){
                console.log(this.detailFormInline)
                this.$refs[formname].validate((valid)=>{
                    if(valid){
                        this.detailFormInline.materialinfoindex = this.detailFormInline.material[1]
                        this.detailFormInline.materialtypeindex = this.detailFormInline.material[0]
                        this.detailFormInline.companyid = this._self.$root.$store.state.LogonInfo.companyid
                        console.log(this.masterdata)

                        var postdata ={
                            materialid: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].id,
                            mcode : this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].mcode,
                            materialname:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].materialname,
                            materialspec:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].spec,
                            materialtypeid: this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].id,  
                            materialtype:this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].materialtype,
                            unit:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].unit,
                            price: this.detailFormInline.price,      
                            supplier:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].supplier,      
                            amount: this.detailFormInline.amount                   
                        }

                        this.tbldata.push(postdata)
                        console.log("物料项",postdata)
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            }
        },
        components:{

        }
    }
</script>