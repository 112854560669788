<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:100%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">销售部门业务管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane v-if="checkpermission('saleorder:view')" label="销售订单管理">
                    <sellorder departid="107"/>
                </TabPane>
                <TabPane v-if="checkpermission('saleplanorder:view')" label="销售预测计划">
                    <splanorder departid="107"/>
                </TabPane>
                <TabPane label="整车库存查询" v-if="checkpermission('productwarehouse:view')">
                    <p style="color:#f60;text-align:left">
                        <span>库存车辆统计信息</span>
                    </p>
                    <stockinfo />
                </TabPane>
                <TabPane v-if="checkpermission('sale:customer:view')" label="客户信息管理">
                    <customer></customer>
                </TabPane>
            </Tabs>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import SellOrder from './SellOrder.vue'
    import VehicleQuerySummary from '../../../common/VehicleQuerySummary.vue'
    import SalePlanOrder from '@/views/business/workshop/sell/saleplan/SalePlanOrder'
    import axios from '@/config/apibatch'
    import StockInfo from '@/views/business/workshop/stock/StockInfo'
    import Customer from '@/views/business/workshop/sell/customer/Customer'

    export default {
        name:'SellDesktop',
        components:{
            'sellorder': SellOrder,
            'splanorder':SalePlanOrder,
            'stockinfo':StockInfo,
            'viewsummary':VehicleQuerySummary,
            'customer':Customer,
            'loginfobar':UserInfoTitleBar,
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },
        mounted(){
            this.getMasterData()
            eventBus.$on('customerupdate',(data) => {
                console.log(data)
                this.getMasterData()
            })
        },
        beforeDestroy(){
            eventBus.$off("customerupdate");
        },
        methods:{
            getMasterData(){
                axios.all([
                    axios.post("/api/sale/customer/query",{
                        companyId:this._self.$root.$store.state.LogonInfo.companyid,
                        currentpage: 1,
                        pagesize: 2000
                    })
                ])
                .then(axios.spread(function (customerRsp) {
                    console.log('customer数据', customerRsp);
                    if(customerRsp.data.resultCode ==0){
                        this.$store.commit('changeMasterCustomers',customerRsp.data.data.data)
                    }
                    else{
                        //this.$Message.error({content:modelRsp.data.msg, duration:3})
                    }
                }.bind(this)));
            },
        }
    }
</script>
