<template>
    <div>
        <div id="planlistdiv" >
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>
                        <td align="left">
                            <FormItem prop="status" label="计划单状态" style="margin-bottom:5px;margin-top:10px">
                                <Select v-model="formInline.status" @on-change="onOrderStatusSelect">
                                    <Option value=null key=100>请选择</Option> 
                                    <Option value=0 key=0>未确认</Option> 
                                    <Option value=1 key=1>已确认</Option> 
                                    <Option value=2 key=2>已完成</Option>   
                                </Select>
                            </FormItem>
                        </td>                                     
                        <td align="left">
                            <FormItem  label="计划月度:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择月份" v-model="formInline.expectdate"  @on-change="onplanmonthselect" @on-clear="formInline.expectdate=null"></DatePicker>
                            </FormItem>  
                        </td>

                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('vehicleQueryForm')" >计划单查询</Button>    
                                <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">物料计划列表</label>  
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border>
                <template slot-scope="{ row }" slot="ordercode">
                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                </template>
                <template slot-scope="{ row }" slot="expectdate">
                    {{new Date(row.expectdate).Format("yyyy-MM-dd")}}
                </template>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <div  class="opbar">
                        <Button  type="success" size="small" @click="view(index)" >查看</Button>    
                        <Button v-hasPermission="'plan:manage'" type="error" size="small" @click="remove(index)">作废</Button>    
                    </div>        
                </template>                
                </Table>
            </div>
        </div>
        <Modal v-model="showdeleteconfirm" title="删除物料计划单" @on-ok="confirmDelete">确定要删除吗？</Modal>
        <Modal v-model="showviewwin" title="查看物料需求清单" width="80%">
            <Table :columns="mdetail.tblcolumns" :data="mdetail.tbldata"></Table>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'   

export default {
    name:'MaterialPlanOrder',
    props:['departid'],
    data () {
            return {
                showdeleteconfirm:false,
                deleteIndex:null,
                showviewwin:false,
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    status:null,
                    expectdate: sysTool.getNowFormatDate(new Date()),
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:7
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '计划单号',
                        slot: 'ordercode',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '计划单状态',
                        slot: 'status',
                        resizable:true
                    },          
                    {
                        title: '交货日期',
                        slot: 'expectdate',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '下单时间',
                        key: 'createtime',
                        tooltip:true,
                        resizable:true
                    },                                                                 
                    {
                        title: '计划员',
                        key: 'createusername',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        resizable:true
                    }
                ],
                tbldata: [],

                mdetail:{
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '数量',
                        key: 'amount',
                        resizable:true
                    },    
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                }
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
  
                if(this.formInline.createtime !=null){
                    if(this.formInline.createtime[0] !=''){
                        postdata.createtimestart = this.formInline.createtime[0]
                    }
                    if(this.formInline.createtime[1] !=''){
                        postdata.createtimeend = this.formInline.createtime[1]
                    }
                }

                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }

                if(postdata.ordercode !=null){
                    postdata.ordercode = this.formInline.ordercode.trim()
                }

                for(var p in postdata){
                    if(postdata[p] == null){
                        delete postdata[p]
                    }
                }

                console.log("查询物料需求计划订单的表单数据",postdata)
                
                this.$axios.post("/api/plan/materialorder/query",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("物料需求计划订单查询结果",res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios({
                    method:'post',
                    url:"/api/plan/orderdetail/query",
                    data:{
                        orderid: this.tbldata[index].id
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log("物料需求计划订单详情查询结果",res.data.data)
                        if(rspdata != null){
                            var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                            tmptbldata[index].products =rspdata
                            this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                            console.log("物料需求计划订单详情列表",this.tbldata)
                            callback(index)
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onSubmmitOver(){
                this.showeditSellorder =false
                this.handleQuery()
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            view (index) {
                this.mdetail.tbldata = JSON.parse(this.tbldata[index].content)
                this.showviewwin = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/plan/order/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            onOrderStatusSelect(status){
                if (status == null || status == 'null'){
                    this.formInline.status = null
                }
            },
            onplanmonthselect(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].status
                if(status ==0){
                    return '未确认'
                }
                else if(status ==1){
                    return '已确认'
                }
                else if(status ==2){
                    return '已完成'
                }
            },
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>