<!-- 整车出入库功能-->
<template>
    <div class="StockInOut">
        <Row style="background:#eee;padding:20px">
            <i-col span="11">
                <Card :bordered="false" style="height:450px">
                    <p slot="title">整车编码列表</p>
                    <i-input type="textarea" v-model="vehicleNoList" :maxlength="2000" :rows="17" 
                    placeholder="
                    批量车架号录入，以分号(;)或换行进行分割，
                    比如：
                    687345909087880;687345909087881;687345909087882 
                    或 
                    687345909087880
                    687345909087881
                    687345909087882"/>
                </Card>
            </i-col>
            <i-col span="12" offset="1">
                <Card shadow style="height:450px">
                    <div style="margin:120px auto;width:100%;display: inline-flex;align-items: center" align="left">
                        <Button type="primary" size="large" @click="handleIn()" style="margin:0 auto;width:35%;height:120px">入库</Button>
                    </div>
                </Card>
            </i-col>
        </Row>
        <Modal v-model="showinconfirm" title="确定要入库吗？" @on-ok="confirmpwd">共计{{postvinlist.length}}个车辆编码即将入库</Modal>
        <Modal v-model="showpwdconfirm" title="零散入库确认" >
            <div>
                <i-input type="password" v-model="adminpwd" placeholder="输入管理员密码"></i-input>
            </div>
            <div slot="footer">
                <Button type="primary" ghost size="large" @click="showpwdconfirm=false">取消</Button>
                <Button type="primary" size="large" @click="checkpwd">校验</Button>
            </div>
        </Modal>
        <Modal v-model="showresult" width="600px" height="90%" scrollable>
            <p slot="header" style="color:#f60;text-align:left">
                <span >库存操作结果</span>
            </p>
            <div style="display:block; overflow: auto; height:450px">
                <table v-if="showresult" border="1px solid black" cellspacing="0" style="margin-top: 20px;width: 100%;text-align: left;">
                    <tr>
                        <th style="width: 40%;">整车编码</th>
                        <th style="width: 40%;">结果</th>
                    </tr>
                    <tr v-for="(item,key) in stockresult.failedList" style="background: rgb(240, 203, 203);">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                    <tr v-for="(item,key) in stockresult.successList">
                        <td>{{key}}</td>
                        <td>{{item}}</td>
                    </tr>
                </table>
            </div>
        </Modal>
        <loading ref='loading' msg="正在入库..." />
    </div>
</template>

<script>
//import cityList from '@/components/zsjs/citylist.js'
import Loading from '@/components/Loading'
export default {
    name:'StockIn',
    props:['type'],
    data() {
        return {
            vehicleNoList: '',
            postvinlist:[],
            city: '全国其它区域',
            address:'',
            adminpwd:null,
            stockresult:null,
            showresult:false,
            showpwdconfirm:false,
            showinconfirm:false,
            showoutconfirm:false,
            showprint:false
        }
    },
    mounted(){
    },
    methods:{
        formatdata(){
            var inputdata=this.vehicleNoList.replace(new RegExp("\n","gm"),';').replace(new RegExp("；","gm"),';').replace(new RegExp(";;","gm"),';').replace(new RegExp(";;;","gm"),';')
            var arr=inputdata.split(";")
            Array.prototype.remove = function(val) {
                var index = this.indexOf(val);
                if (index > -1) {
                    this.splice(index, 1);
                }
            }
            arr.remove("")    
            this.postvinlist =[]
            for(let item=0;item<arr.length;item++){

                var itemdata=arr[item]
                if(itemdata.indexOf("，") != -1){
                    var no= (arr[item].split("，"))[1]
                    this.postvinlist.push(no)
                }
                else{
                    this.postvinlist.push(arr[item])
                }
            }
        },

        handleIn(){
            if(this.vehicleNoList==null || this.vehicleNoList.length==0){
                this.$Message.error('请输入整车编码')
                return
            }
            this.formatdata()

            console.log(this.postvinlist)
            this.showinconfirm = true
        },
        checkpwd(){
            if(this.adminpwd == null || this.adminpwd == ''){
                this.$Message.error({content:"请输入密码校验！",duration:5})
                return
            }
            this.$axios.post("/api/adminuser/users/check/userpwd",{userpwd: this.adminpwd})
            .then(function(res){
                if(res.data.resultCode ==0){
                    this.confirmin()
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }
            }.bind(this)); 
        },
        confirmpwd(){
            if(this.type == 'inplan'){
                this.confirmin()
                return
            }

            this.showpwdconfirm = true
        },
        confirmin(){
            var postdata= {vehiclenos:this.postvinlist}
            let stocktype = 0
            if(this.type == 'inplan'){
                stocktype = 0
            }
            else{
                stocktype = 1
            }
            postdata = this.extend({},postdata,{'stocktype':stocktype})

            delete postdata.expiryTime
            this.$refs['loading'].startloading()
            this.$axios.post("/api/vehicleIn/stockin",postdata)
            .then(function(res){
                if(res.data.resultCode ==0){
                    this.stockresult = res.data.data
                    console.log("库存操作结果",this.stockresult)
                    for(let item in this.stockresult.successList){
                        console.log(item)

                    }
                    this.$Message.success({content:res.data.msg, duration:3, closable:true})
                    this.showresult = true
                    this.showpwdconfirm = false
                }
                else{
                    this.$Message.error({content:res.data.msg, duration:3})
                }

                this.$refs['loading'].stoploading()
            }.bind(this));            
        },
    },
    components: {
        'loading':Loading
    },
}
</script>