<template>
    <div>
        <purchaseedit v-if="showeditPurchase" @closeedit="onSaveOver"/>
        <div id="printdiv" style="display: none;" >
            <purchaseprint ref="purchaseprint" />
        </div>
        <paccountedit id="paccountid" v-bind:aType='1' v-bind:show="showpaywin" v-bind:ordercode="activeOrdercode"  @CloseFinanceAccount="onEditPAccountClose" @OnSaveOver="onPAccountSaveOver"/>
        <porderhistory v-bind:show="showhistory" v-bind:ordercode="historyordercode" @OnCloseOrderHistory="onHistoryClose"/>
        <div id="purchaselistdiv" v-show="showeditPurchase==false">
            <Form ref="orderQueryForm" :model="formInline" label-position="right" :label-width="130" style="border:1px solid lightgray;background:lightblue;">
                <table width="100%">
                    <tr>
                        <td align="left" style="width: 15%;">
                            <FormItem prop="mstatus" label="采购单状态" style="margin-bottom:5px;margin-top:10px">
                                <Select v-model="formInline.mstatus" @on-change="onOrderStatusSelect">
                                    <Option value=null key=100>全部</Option> 
                                    <Option value='0' key=0>未确认</Option> 
                                    <Option value='1' key=1>待采购</Option> 
                                    <Option value='2' key=2>采购中</Option>  
                                    <Option value='3' key=3>已入库</Option>   
                                </Select>
                            </FormItem>
                        </td>         
                        <!--
                        <td align="left" style="width: 20%;" v-if="type=='0'">
                            <FormItem prop="saleplanordercode"  label="销售计划单号:" style="margin-bottom:5px;margin-top:10px">
                                <i-input type="text" v-model="formInline.saleplanordercode"></i-input>
                            </FormItem>  
                        </td> 
                        --> 
     
                        <td align="left" style="width: 15%;">
                            <FormItem prop="createtime"  label="下单月份:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择查询月份" v-model="formInline.createtime"  @on-change="onCreateMonthChange" @on-clear="formInline.createtime=null"></DatePicker>
                            </FormItem>  
                        </td>       
                        <td align="left" style="width: 15%;">
                            <FormItem prop="expectdate"  label="采购月度:" style="margin-bottom:5px;margin-top:10px">
                                <DatePicker type="month" format="yyyy-MM" placeholder="选择查询月份" v-model="formInline.expectdate"  @on-change="onMonthChange" @on-clear="formInline.expectdate=null"></DatePicker>
                            </FormItem>  
                        </td>
                        <td  colspan="4" align="right">
                            <div>
                                <Button type="primary" @click="handleQuery('vehicleQueryForm')" >采购单查询</Button>    
                                <Button  @click="handleReset('orderQueryForm')" style="margin-left:10px">重置</Button>
                            </div>
                        </td>
                    </tr>
                </table>
            </Form>    
            <div style="height:40px;width:100%;">
                <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">采购单列表</label>
                <Button v-hasPermission="'purchase:add'" type="primary" v-if="departid=='106' && type == '1'"  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="showeditPurchase=true">新增零星采购</Button>
                <Poptip
                confirm
                style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%"
                transfer
                title="确认采购单后将不可修改，确认？"
                @on-ok="confirmmultidetail(multiop.ids)"
                >
                <Button v-hasPermission="'purchase:manage'" type="success" v-if="multiop.showmulticonfirm"   >批量确认</Button>
                </Poptip>
                <Button v-hasPermission="'purchase:manage'" type="warning" v-if="multiop.showmultidopurchase "  style="margin-top:10px;float:left;margin-left:30px;font-size:10px;height:70%" @click="domultipurchase">批量下达采购</Button>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
            </div>        
            <!-- 订单列表-->
            <div style="padding-top:10px">
                <Table :columns="tblcolumns" :data="tbldata" stripe border @on-selection-change="selectionchange">
                <template slot-scope="{ row }" slot="ordercode">
                    <p style="font-size:10px"><strong>{{ row.ordercode }}</strong></p>
                </template>
                <template slot-scope="{ row }" slot="expectdate">
                    {{row.expectdate == null? '' : new Date(row.expectdate).Format("yyyy-MM-dd")}}
                </template>
                <template slot-scope="{ row }" slot="createtime">
                    {{new Date(row.createtime).Format("yyyy-MM-dd HH:mm:ss")}}
                </template>
                <template slot-scope="{ row,index }" slot="status">
                    {{getstatustext(index)}}
                </template>
                <template slot-scope="{ row,index }" slot="fstatus">
                    {{getfstatustext(row.fstatus)}}
                </template>
                <template slot-scope="{ row }" slot="from">
                    {{ row.fromsorderno ==null ? '零星采购': row.fromsorderno}}
                </template>
                <template slot-scope="{ row }" slot="huabo">
                    {{''}}
                </template>
                <template slot-scope="{ row,index }" slot="materialdata">
                        <table style="font-size:12px;float:left;width:90%" border="0px" rules="rows">
                            <tr style="font-weight:bold;height:15px" align="center">
                                <th style="padding-left:5px;width:20%">物料名</th>
                                <th style="padding-left:5px;width:50%">规格</th>
                                <th style="padding-left:5px;width:20%">数量</th>
                                <th style="padding-left:5px;width:10%">单位</th>
                            </tr>
                            <tbody  v-show="row.openprod">
                                <tr v-for="(item,idx) in row.products"  :key='idx' align="left">
                                    <td style="padding-left:5px;">{{(idx+1)+'. '+item.materialname}}</td>
                                    <td style="padding-left:5px;">{{item.materialspec}}</td>
                                    <td style="padding-left:5px;">{{item.amount}}</td>
                                    <td style="padding-left:5px;">{{item.unit}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="float:right;margin-top:15px">
                            <Icon  type="md-arrow-dropdown" v-show="!row.openprod" style="margin:0 auto;margin-left:50%" size="20" @click="clickopenprod(index)" />
                            <Icon  type="md-arrow-dropup" v-show="row.openprod" style="margin:0 auto;margin-left:50%" size="20" @click="clickopenprod(index)"/>
                        </div>
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                        <!-- 106 采购部门-->
                        <div  class="opbar" v-if="departid == '106'">
                            <Button type="success" size="small" v-hasPermission="'purchase:manage'" @click="viewdetail(index)" >查看/修改</Button>   
                            <Poptip
                            confirm
                            transfer
                            title="确认采购单后将不可修改，确认？"
                            @on-ok="confirmdetail(row.id)"
                            >
                                <Button v-if="row.mstatus ==0" v-hasPermission="'purchase:manage'" type="success" size="small" ghost  >确认</Button> 
                            </Poptip>
                            <Button v-if="row.mstatus ==1" v-hasPermission="'purchase:manage'" type="warning" size="small" ghost @click="startpurchase(index)" >下达采购</Button> 
                            <Button type="info" size="small" @click="viewhistory(row.ordercode)" >历史</Button>    
                            <Button type="success" size="small" @click="printcgdd(index)" >打印采购单</Button>             
                            <Button :disabled="!(row.mstatus==0 && row.fromsorderno == null)" v-hasPermission="'purchase:delete'" type="error" size="small" @click="remove(index)" >作废</Button> 
                        </div>
                         <!-- 104 财务部门-->
                        <div  class="opbar" v-if="departid == '104'">
                            <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>      
                            <Button type="success" size="small" @click="printcgdd(index)" >打印采购单</Button> 
                            <Button type="info" size="small" @click="viewhistory(row.ordercode)" >历史</Button>                
                            <Button :disabled="!(row.mstatus >1 && row.fstatus !=2)" v-hasPermission="'finacial:manage'" type="warning" size="small" ghost @click="payorder(index)" >结算</Button>            
                        </div>
                        <!-- 108 零件库部门-->
                        <div  class="opbar" v-if="departid == '108'">
                            <Button type="success" size="small" @click="viewdetail(index)" >查看</Button>
                            <Button type="success" size="small" @click="printrkd(index)" >打印入库单</Button> 
                            <Button type="success" size="small" @click="printcgdd(index)" >打印采购单</Button> 
                            <Poptip
                            confirm
                            transfer
                            title="确认已收货入库？"
                            @on-ok="receiveParts(row)"
                            >  
                            <Button v-if="row.mstatus ==2" v-hasPermission="'partwarehouse:manage'" type="info" size="small" ghost  >收货确认</Button>
                            </Poptip>
                        </div>
                </template>                
                </Table>
                <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
                <div style="clear: both;">

                </div>
            </div>
        </div>
        <Modal v-model="showdeleteconfirm" title="作废采购单" @on-ok="confirmDelete">确定要作废吗？</Modal>
        <Modal v-model="showupdatewin" title="采购单操作" @on-ok="confirmupdate" v-if="updateIndex !=null" ok-text="提交更新">
            <Form ref="orderUpdateForm" :model="formInline" label-position="right" :label-width="100" >
                <FormItem  label="采购单编号:" style="margin-bottom:5px;margin-top:10px">
                    <p>{{tbldata[updateIndex].ordercode}}</p>
                </FormItem>
                <FormItem  label="供应商名称:" style="margin-bottom:5px;margin-top:10px">
                    <p>{{tbldata[updateIndex].supplier}}</p>                  
                </FormItem>             
                <FormItem  label="采购单状态:" style="margin-bottom:5px;margin-top:10px">
                    <Select v-model="updateFormInline.mstatus" @on-change="onselectoperation" label-in-value>
                        <Option value=0 key=0 v-if="departid=='106'">已计划</Option> 
                        <Option value=1 key=1 v-if="departid=='106'">采购中</Option> 
                        <Option value=2 key=2 v-if="departid=='102'">已收货</Option> 
                        <Option value=3 key=3 v-if="departid=='106'">已完成</Option>    
                    </Select>
                </FormItem>
                <FormItem  label="备注:" style="margin-bottom:5px;margin-top:10px">
                    <i-input  v-model="updateFormInline.note" maxlength="100" show-word-limit type="textarea">
                    </i-input>                   
                </FormItem>  
            </Form>
        </Modal>
        <Modal v-model="showmorder" title="需求计划采购" width="90%">
            <div v-if="showmorder">
                <mplanpurchase />
            </div>
        </Modal>
        <Modal v-model="showdetail" title="采购物料清单" width="90%">
            <div>
                <Table :columns="pdetail.tblcolumns" size="small" :data="pdetail.tbldata" v-if="readfinished">
                    <template slot-scope="{ row,index }" slot="kehuabokucun">
                        {{row.kucun - row.numLocked >0? (row.kucun - row.numLocked) : 0}}
                    </template>
                    <template slot-scope="{ row,index }" slot="caigounum">
                        <div style="display: inline-flex;" @mouseover="mouseOver($event,index)" @mouseleave="mouseLeave($event,index)">
                            <div v-if="!pdetail.showinput || (pdetail.showinput && pdetail.activeindex != index)"  style="padding: 3px 0px;">
                                {{row.amount}}
                            </div>
                            <div v-else>
                                <i-input type="number" v-model="pdetail.activenum">
                                </i-input>
                            </div>
                        </div>
                    </template>
                    <template slot-scope="{ row,index }" slot="huabonum">
                        <div style="display: inline-flex;" @mouseover="mouseOver($event,index)" @mouseleave="mouseLeave($event,index)">
                            <div v-if="!pdetail.showinput || (pdetail.showinput && pdetail.activeindex != index)"  style="padding: 3px 0px;">
                                {{row.huabonum}}
                            </div>
                            <div v-else>
                                <i-input type="number" v-model="pdetail.activehuabonum">
                                </i-input>
                            </div>
                        </div>
                    </template>
                </Table>
                <div v-else>
                    正在读取数据...
                </div>
            </div>
            <div slot="footer" v-if="readfinished">
                <Button size="large" @click="showdetail=false">取消</Button>
                <Button v-if="pdetail.orderinfo.mstatus==0" type="warning" size="large" @click="detailmodify">{{pdetail.edittext}}</Button>
                <Button v-if="pdetail.orderinfo.mstatus!=0" type="primary" size="large" @click="showdetail=false">确定</Button>
            </div>
        </Modal>
        <Modal v-model="dopurchase.showdlg" title="下达采购" >
            <Form ref="dopurchaseForm" :model="dopurchase.formInline" label-position="right" :label-width="100" v-if="dopurchase.orderinfo !=null">
                <FormItem  label="供应商名称:" style="margin-bottom:5px;margin-top:10px">
                    <p>{{dopurchase.orderinfo.supplier}}</p>                  
                </FormItem>          
                <FormItem  label="预计到货日期:" style="margin-bottom:5px;margin-top:10px">
                    <DatePicker type="date" v-model="dopurchase.formInline.expectdate" format="yyyy-MM-dd" @on-change="onPurchaseExpectdateChange"   @on-clear="dopurchase.formInline.expectdate=null"></DatePicker>
                </FormItem>   
                <FormItem  label="备注:" style="margin-bottom:5px;margin-top:10px">
                    <i-input  v-model="dopurchase.formInline.note" maxlength="100" show-word-limit type="textarea">
                    </i-input>                   
                </FormItem>  
            </Form>
            <div slot="footer">
                <Button size="large" @click="dopurchase.showdlg=false">取消</Button>
                <Button  type="primary" size="large" @click="confirmpurchase">下达采购</Button>
            </div>
        </Modal>
        <Modal v-model="dopurchase.multishowdlg" title="批量下达采购" >
            <Form ref="dopurchaseMultiForm" :model="dopurchase.formInline" label-position="right" :label-width="100" v-if="multiop.orders.length >0">         
                <FormItem  label="预计到货日期:" style="margin-bottom:5px;margin-top:10px">
                    <DatePicker type="date" v-model="dopurchase.formInline.expectdate" format="yyyy-MM-dd" @on-change="onPurchaseExpectdateChange"   @on-clear="dopurchase.formInline.expectdate=null"></DatePicker>
                </FormItem>   
                <FormItem  label="备注:" style="margin-bottom:5px;margin-top:10px">
                    <i-input  v-model="dopurchase.formInline.note" maxlength="100" show-word-limit type="textarea">
                    </i-input>                   
                </FormItem>  
            </Form>
            <div slot="footer">
                <Button size="large" @click="dopurchase.showdlg=false">取消</Button>
                <Button  type="primary" size="large" @click="confirmmultipurchase">下达采购</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import * as sysTool from '@/zsjs/systemTool.js'
import {getPrinters,public_piaoju,public_print_with_dir} from '@/zsjs/zskjprinter.js'
import PurchaseEdit from '@/views/business/workshop/purchase/PurchaseEdit'    
import PurchaseOrderAccountEdit from '@/views/business/workshop/finance/PurchaseOrderAccountEdit'
import PurchaseOrderHistory from '@/views/business/workshop/purchase/PurchaseOrderHistory'
import MPlanPurchase from '@/views/business/workshop/purchase/MPlanPurchase'
import PurchasePrint from '@/views/business/workshop/purchase/PurchasePrint'

export default {
    name:'PurchaseOrder',
    //type: 0-计划采购任务   1-零散采购任务
    props:['departid','type'],
    data () {
            return {
                showeditPurchase:false,
                editSellorderdata:null,
                showdeleteconfirm:false,
                showmorder:false,
                deleteIndex:null,
                updateIndex:null,
                showhistory:false,
                showdetail:false,
                readfinished:false,
                historyordercode:null,
                multiop:{
                    showmulticonfirm:false,
                    showmultidopurchase:false,
                    ids:[],
                    orders:[]
                },
                formInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    ordercode:null,
                    mstatus:null,
                    createtime:sysTool.getNowFormatDate(new Date()),
                    expectdate:null,
                    saleplanordercode:null,
                    pageSize:40,
                    pageNum:0
                },
                showupdatewin:false,
                showpaywin:false,
                activeOrdercode:null,
                updateFormInline:{
                    mstatus:null,
                    note:null
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:40
                },
                tblcolumns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '采购单号',
                        slot: 'ordercode',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '状态',
                        slot: 'status',
                        resizable:true
                    },      
                    /* 
                    {
                        title: '物料清单',
                        slot: 'materialdata',
                        resizable:true
                    },   
                    
                    {
                        title: '划拨预警',
                        slot: 'huabo',
                        resizable:true
                    }, */
                    {
                        title: '供应商',
                        key: 'supplier',
                        resizable:true
                    },
                    {
                        title: '来源',
                        slot: 'from',
                        resizable:true
                    },  
                    {
                        title: '预计到货日期',
                        slot: 'expectdate',
                        tooltip:true,
                        resizable:true
                    },    
                    {
                        title: '创建时间',
                        slot: 'createtime',
                        tooltip:true,
                        resizable:true
                    },                                                                
                    {
                        title: '下单人员',
                        key: 'createusername',
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right',
                        width:420,
                        resizable:true
                    }
                ],
                tbldata: [],
                pdetail:{
                    editmode:false,
                    edittext:'点击修改',
                    showinput:false,
                    activeindex:null,
                    activenum:0,
                    activehuabonum:0,
                    orderinfo:null,
                    tbldata_origin:[],
                    tbldata:[],
                    tblcolumns:[
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '应用车型',
                        key: 'modelver',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'mcode',
                        resizable:true
                    },   
                    {
                        title: '采购数量',
                        slot: 'caigounum',
                        resizable:true
                    },    
                    {
                        title: '划拨数量',
                        slot: 'huabonum',
                        resizable:true
                    },   
                    {
                        title: '可划拨库存',
                        slot: 'kehuabokucun',
                        resizable:true
                    }, 
                    {
                        title: '总库存',
                        key: 'kucun',
                        resizable:true
                    },  
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },                         
                    {
                        title: '供应商',
                        key: 'supplier',
                        tooltip:true,
                        resizable:true
                    },    
                    ]
                },
                dopurchase:{
                    showdlg:false,
                    multishowdlg:false,
                    orderinfo:null,
                    formInline:{
                        expectdate:null,
                        note:null
                    }
                    
                },
            }
        },
        mounted (){
            if(this.departid == '104'){
                this.tblcolumns.splice(3,0,{
                    title: '付款状态',
                    slot: 'fstatus',
                    resizable:true
                })

                this.formInline.mstatus = '3'

                this.pdetail.tblcolumns.splice(5,3,{
                    title: '原单价',
                    key: 'baseprice',
                    resizable:true
                },
                {
                    title: '优惠单价',
                    key: 'price',
                    resizable:true
                }
                )
            }
            else if(this.departid == '108'){
                this.formInline.mstatus = '2'
            }
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                var postdata = JSON.parse(JSON.stringify(this.formInline))
                postdata.ptype = this.type

                if(this.formInline.expectdate !=null && this.formInline.expectdate!=''){
                    if(typeof(this.formInline.expectdate) == 'object'){
                        postdata.expectdate = sysTool.getNowFormatDate(this.formInline.expectdate)
                    }
                    else{
                        postdata.expectdate = this.formInline.expectdate
                    }
                    
                }

                if(this.formInline.createtime !=null && this.formInline.createtime!=''){
                    if(typeof(this.formInline.createtime) == 'object'){
                        postdata.createtime = sysTool.getNowFormatDate(this.formInline.createtime)
                    }
                    else{
                        postdata.createtime = this.formInline.createtime
                    }
                    
                }

                if(postdata.ordercode !=null){
                    postdata.ordercode = this.formInline.ordercode.trim()
                }

                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] == ''){
                        delete postdata[p]
                    }
                }

                console.log(postdata)
                this.$axios.post("/api/purchase/order/query",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            queryOrderDetail(index,callback) {
                this.$axios.post("/api/purchase/orderdetail/query",{orderid: this.tbldata[index].id})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data.data)
                        if(rspdata != null){
                            /*
                            var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                            tmptbldata[index].products =rspdata
                            this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                            console.log(this.tbldata)
                            callback(index)*/
                            callback(index,rspdata)
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            handleReset(name){
                this.$refs[name].resetFields();
                this.handleQuery()
            },
            onSaveOver(){
                this.showeditPurchase =false
                this.handleQuery()


            },
            onHistoryClose(){
                this.showhistory = false
                this.handleQuery()
            },
            viewdetail(index){
                this.readfinished = false
                this.showdetail = true
                
                this.queryOrderDetail(index,(idx,rspdata)=>{
                    this.pdetail.tbldata = JSON.parse(JSON.stringify(rspdata))
                    this.pdetail.tbldata_origin = JSON.parse(JSON.stringify(this.pdetail.tbldata))
                    this.pdetail.orderinfo = this.tbldata[index]
                    this.readfinished = true
                })
            },
            confirmdetail(orderid){
                let postdata = {
                    orderid: orderid
                }

                this.$axios.post("/api/purchase/order/confirm",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:5})
                    }
                }.bind(this)); 
            },
            confirmmultidetail(orderids){
                let postdata = {
                    ids: orderids
                }

                this.$axios.post("/api/purchase/order/multiconfirm",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:5})
                    }
                }.bind(this)); 
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            updateorder (index) {
                this.updateIndex = index
                this.showupdatewin = true
            },
            payorder (index) {
                this.updateIndex = index
                this.activeOrdercode = this.tbldata[index].ordercode
                this.showpaywin = true
            },
            viewhistory (ordercode){
                this.historyordercode = ordercode
                this.showhistory = true
            },
            printcgdd(index){
                this.queryOrderDetail(index,(idx,rspdata)=>{
                    this.$refs['purchaseprint'].printdata(this.tbldata[idx],rspdata,"cgdd")
                })
            },
            printrkd(index){
                this.queryOrderDetail(index,(idx,rspdata)=>{
                    this.$refs['purchaseprint'].printdata(this.tbldata[idx],rspdata,"rkd")
                })
            },
            receiveParts(data){
                let postdata = {
                    'id' : data.id,
                    'ordercode':data.ordercode
                }

                this.$axios.post("/api/purchase/order/doreceive",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            startpurchase(index){
                this.dopurchase.orderinfo = this.tbldata[index]
                this.dopurchase.showdlg = true
            },
            confirmpurchase(){

                if(this.dopurchase.formInline.expectdate == null || this.dopurchase.formInline.expectdate == ''){
                    this.$Message.error({content:'请输入预计到货日期',duration:5})
                    return
                }
                let postdata = {
                    id : this.dopurchase.orderinfo.id,
                    ordercode : this.dopurchase.orderinfo.ordercode,
                    expectdate: this.dopurchase.formInline.expectdate,
                    note : this.dopurchase.formInline.note
                }

                postdata = this.removeEmptyChilds(postdata)

                this.$axios.post("/api/purchase/order/dopurchase",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()

                        this.dopurchase.showdlg = false
                        this.dopurchase.orderinfo = null
                        this.dopurchase.formInline.expectdate = null
                        this.dopurchase.formInline.note = null
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            confirmupdate (){
                let postdata = {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    mstatus : this.updateFormInline.mstatus,
                    operatorid : this._self.$root.$store.state.LogonInfo.userid,
                    operatorname : this._self.$root.$store.state.LogonInfo.fullname,
                    operatordeptid : this._self.$root.$store.state.LogonInfo.departmentid,
                    orderid : this.tbldata[this.updateIndex].id,
                    ordercode : this.tbldata[this.updateIndex].ordercode,
                    note : this.updateFormInline.note
                }

                this.$axios.post("/api/purchase/order/updatemstatus",postdata)
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.updateIndex = null
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));                    
            },
            onselectoperation(v){
                this.updateFormInline.note = v.label
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/purchase/order/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            onOrderStatusSelect(status){
                if (status == null || status == 'null'){
                    this.formInline.mstatus = null
                }
            },
            onMonthChange(e){
                if(e == null || e==''){
                    this.formInline.expectdate =null
                }
                else{
                    this.formInline.expectdate=e+'-01'
                }
            },
            onCreateMonthChange(e){
                if(e == null || e==''){
                    this.formInline.createtime =null
                }
                else{
                    this.formInline.createtime=e+'-01'
                }
            },
            onPurchaseExpectdateChange(e){
                this.dopurchase.formInline.expectdate=e
            },
            onpagechange(v){
                this.formInline.pageNum = v
                this.handleQuery()
            },
            getfstatustext(fstatus){
                if(fstatus == null || fstatus == undefined){
                    return "未结算"
                }
                else if(fstatus ==0){
                    return '未结算'
                }
                else if(fstatus ==1){
                    return '定金结算'
                }
                else if(fstatus ==2){
                    return '已结算'
                }
            },
            getstatustext(index){
                if(this.tbldata[index].deleted){
                    return '已作废'
                }
                let status = this.tbldata[index].mstatus
                if(status ==0){
                    return '未确认'
                }
                else if(status ==1){
                    return '待采购'
                }
                else if(status ==2){
                    return '采购中'
                }
                else if(status ==3){
                    return '已入库'
                }
            },
            clickopenprod(index){
                if(this.tbldata[index].products == null){
                    this.queryOrderDetail(index,(index)=>{
                        this.clickopenprod(index)        
                    })
                }
                else{

                    var tmptbldata = JSON.parse(JSON.stringify(this.tbldata))
                    tmptbldata[index].openprod = !tmptbldata[index].openprod
                    this.tbldata = JSON.parse(JSON.stringify(tmptbldata))
                }
            },
            onEditPAccountClose(){
                this.updateIndex = null
                this.showpaywin = false
            },
            onPAccountSaveOver(){
                this.updateIndex = null
                this.showpaywin = false
                this.handleQuery()
            },
            mouseOver(event,index){
                if(this.pdetail.editmode){
                    if(this.pdetail.activeindex == index && this.pdetail.showinput){
                        //重复激发mouseover事件
                        return
                    }
                    this.pdetail.activeindex = index
                    this.pdetail.activenum = this.pdetail.tbldata[index].amount
                    this.pdetail.activehuabonum = this.pdetail.tbldata[index].huabonum
                    this.pdetail.showinput = true
                }
            },
            mouseLeave(e,index){
                if(!this.pdetail.editmode){
                    return
                }
                let neednum = this.pdetail.tbldata[index].amount
                if(index == this.pdetail.activeindex && this.pdetail.showinput){
                    if(this.pdetail.activenum <0){
                        this.pdetail.activenum =0
                    }
                    if(this.pdetail.activehuabonum <0){
                        this.pdetail.activehuabonum =0
                    }

                    console.log("采购单编辑数据",this.pdetail)
                    let kehuabokucun = this.pdetail.tbldata[index].kucun - this.pdetail.tbldata[index].numLocked
                    kehuabokucun = kehuabokucun <0 ? 0 : kehuabokucun
                    if(this.pdetail.activehuabonum > kehuabokucun){
                        this.$Modal.error({
                            content:'划拨数量大于可划拨数量，请调整划拨数量!',
                            onOk:()=>{
                                this.pdetail.activehuabonum =0
                            }
                        })
                        return
                    }
/*
                    if(this.pdetail.activenum + this.pdetail.activehuabonum != neednum){
                        this.$Modal.error({
                            content:'采购数量+划拨数量与需求量（ '+neednum+' ）不一致!',
                            onOk:()=>{
                                this.pdetail.activenum =neednum
                            }
                        })
                        return
                    }
*/
                    this.pdetail.tbldata[index].huabonum=this.pdetail.activehuabonum
                    this.pdetail.tbldata[index].amount=this.pdetail.activenum
                }
                console.log("mouseLeave",this.pdetail.tbldata)
                this.pdetail.showinput = false
            },
            submmitmodify(){
                this.pdetail.editmode = false
                this.pdetail.edittext = '点击修改'
                console.log("修改后的采购单详情数据",this.pdetail.tbldata)
                let postdata = {
                    purchasedetail: JSON.stringify(this.pdetail.tbldata)
                }

                this.$axios.post("/api/purchase/orderdetail/update",postdata).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                        this.showdetail = false
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:5})
                    }
                }.bind(this));  
            },
            detailmodify(){
                if(this.pdetail.editmode){

                    //检查需求数是否等于划拨数+采购数
                    for(let i=0;i<this.pdetail.tbldata.length;i++){
                        let neednum = Number(this.pdetail.tbldata_origin[i].amount)
                        let actnum = Number(this.pdetail.tbldata[i].amount) + Number(this.pdetail.tbldata[i].huabonum)
                        console.log(this.pdetail.tbldata[i].materialname+"需求量:" , neednum)

                        console.log("编辑后的采购量：",this.pdetail.tbldata[i].amount)
                        console.log("编辑后的划拨量：",this.pdetail.tbldata[i].huabonum)
                        console.log("编辑后的实际量：",actnum)
                        if(actnum != neednum){
                            this.$Modal.confirm({
                                content:'物料:<span style="color:red">'+this.pdetail.tbldata[i].materialname+'</span> <br>采购数量+划拨数量与需求量（<span style="color:red">'+neednum+'</span>）不一致! <br>提交后将不可修改， 确认提交？',
                                okText:'确认提交',
                                cancelText:'先不提交，再调整一下',
                                onOk:()=>{
                                    this.submmitmodify()
                                }
                            })
                            return
                        }
                    }
                    this.submmitmodify()
                }
                else{
                    this.pdetail.editmode = true
                    this.pdetail.edittext = '保存修改'
                }
            },
            domultipurchase(){
                this.dopurchase.multishowdlg=true
            },
            confirmmultipurchase(){
                //this.dopurchase.
                
                if(this.dopurchase.formInline.expectdate == null || this.dopurchase.formInline.expectdate == ''){
                    this.$Message.error({content:'请输入预计到货日期',duration:5})
                    return
                }
                let postdata = {
                    orders: this.multiop.orders,
                    expectdate: this.dopurchase.formInline.expectdate,
                    note : this.dopurchase.formInline.note
                }

                postdata = this.removeEmptyChilds(postdata)

                console.log("批量下达采购数据", postdata)
                this.$axios.post("/api/purchase/order/domultipurchase",{multidata: JSON.stringify(postdata)})
                .then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()

                        this.dopurchase.showmultidlg = false
                        this.dopurchase.formInline.expectdate = null
                        this.dopurchase.formInline.note = null
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            loaddata(data){
                this.tbldata=[]
                this.formInline.saleplanordercode = data.splanOrdercode
                this.formInline.pici = data.pici
                this.handleQuery()
            },
            selectionchange(selection){
                console.log("多选数据",selection)
                this.multiop.showmulticonfirm = false
                this.multiop.showmultidopurchase = false
                this.multiop.ids=[]
                this.multiop.orders = []

                if(selection.length >0){
                    let showmulticonfirm = true
                    let showmultidopurchase = true

                    for(let i=0;i<selection.length;i++){
                        if(selection[i].mstatus !=0){
                            showmulticonfirm = false
                           // break
                        }
                        if(selection[i].mstatus !=1){
                            showmultidopurchase = false
                            //break
                        }

                        this.multiop.ids.push(selection[i].id)
                    }

                    this.multiop.orders = selection
                    this.multiop.showmulticonfirm = showmulticonfirm
                    this.multiop.showmultidopurchase = showmultidopurchase
                }

            }
        },
        components:{
            'purchaseedit':PurchaseEdit,
            'paccountedit':PurchaseOrderAccountEdit,
            'mplanpurchase':MPlanPurchase,
            'purchaseprint':PurchasePrint,
            'porderhistory':PurchaseOrderHistory
        },
        watch:{
            'showdetail':{
                handler: function(newV, oldV) {
                    if(!newV){
                        this.pdetail.editmode = false
                        this.pdetail.edittext = '点击修改'
                        this.pdetail.activeindex = null
                    }
                }
            },
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: left;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>