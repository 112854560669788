import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'view-design'
import {Modal,Message} from 'view-design'
import echarts from 'echarts'
import zskjutils from "./zsjs/zskjutils"
import "./utils/install"
import ApiService from "./config/apiservice";
import 'view-design/dist/styles/iview.css'
import db from '@/utils/localstorage'
//import Vconsole from 'vconsole';

Vue.config.devtools = true;
Vue.config.productionTip = false
Vue.use(iView)
Vue.prototype.$echarts = echarts
Vue.prototype.$db = db
window.eventBus = new Vue()

//下面的路由拦截需要放在 new vue挂载router之前，否则地址栏直接输入url无法走到该拦截钩子函数里面
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta.requireAuth) {
    var token = store.state.AccessToken
    var logoninfo = store.state.LogonInfo
    console.log("登录身份信息",logoninfo)
    console.log("页面跳转",to)
    if (token !=null && token !='null' && token !='' && logoninfo !=null) {
      if(logoninfo.userCategory =='' || logoninfo.userCategory ==null){
        next({ name: 'home' })
        Modal.error({
          title:'权限错误',
          content:'登录信息失效，请重新登录',
          okText:'我知道了',
        })
        return false
      }
      else{
        var role = to.meta.role
        if(role ==null || role == ''){
          //没有定义role角色权限
          next()
        }
        else if(role.indexOf(logoninfo.userCategory) == -1){
          //role角色权限定义里没有当前登录用户所属的角色
          next({ name: 'home' })
          Modal.error({
            title:'权限错误',
            content:'很抱歉，您没有该页面权限，请联系您的管理员进行授权',
            okText:'我知道了',
          })
          return false
        }
        else{
          if(logoninfo.userCategory == 'slave'){
            //可以在这里校验权限
            var required_permission = to.meta.permission
            var allow = false
            console.log("所需权限",required_permission)
            if(required_permission == null){
              allow = true

              var required_dept = to.meta.deptid
              console.log("权限所需部门",required_dept)
              if(required_dept != null && required_dept.indexOf(logoninfo.departmentid)==-1){
                allow = false
              }
              else{
                allow = true
              }
            }
            else if(logoninfo.permissions.indexOf(required_permission) !=-1){
              allow = true
            }
            else{
              allow = false
            }

            if(allow){
              //过期
              if(new Date()> new Date(logoninfo.expiryTime)){
                next({ name: 'home' })
              }
              else{
                next()
              }
            }
            else{
              Modal.error({
                title:'权限错误',
                content:'很抱歉，您没有该页面权限，请联系您的管理员进行授权',
                okText:'我知道了',
              })
              return false
            }
          }
          else{
            next()
          }
        }
      }
      next()
    } else {
      next({ name: 'home' })
      Modal.error({
        title:'权限错误',
        content:'登录信息失效，请重新登录',
        okText:'我知道了',
      })
    }
  } else {
    next()
  }
});

router.afterEach((to,from,next) =>{
  window.scrollTo(0,0)
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

document.title = '一物一码企业管理系统'

ApiService.init();

Vue.prototype.extend = function () {
  var length = arguments.length
  var target = arguments[0] || {}
  if (typeof target !== 'object' && typeof target !== 'function') {
    target = {}
  }
  var i = 1
  if (length === 1) {
    target = this
    i--
  }
  for (i; i < length; i++) {
    var source = arguments[i]
    for (var key in source) {
      // 使用for in会遍历数组所有的可枚举属性，包括原型。
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key]
      }
    }
  }
  return target
}

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
      this.splice(index, 1);
  }
}

Vue.prototype.removeEmptyChilds = function(data){
    for(var p in data){
      if(data[p] == null){
          delete data[p]
      }
  }
  return data;
};

Vue.prototype.checkpermission=function(permission){
  if(this.$db.get('logonInfo').permissions.indexOf(permission) !=-1){
      return true
  }
  return false
}
