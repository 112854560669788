<template>
    <div >
        <stocksummary :departid='200'></stocksummary>
    </div>
</template>

<script>
import VerhicleEdit from './VerhicleEdit'
import StockSummary from '@/views/business/workshop/StockSummary.vue'
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]
export default {
    name:'VehicleList',
    data () {
            return {
                showVerhicleEdit:false,
            }
        },
        mounted(){

        },
        methods: {
            
        },
        components:{
            'verhicleEdit': VerhicleEdit,
            'stocksummary':StockSummary,
        }
}
</script>