<template>
    <div style="width:100%;background:#eee;padding:10px 20px 10px">
        <Row :gutter="10" index="">
          <i-col span="12" align="center">
                <Card :bordered="false" style="height:450px">
                    <p slot="title">出库贴编辑</p>
                    <Form ref="StockOutPrintForm" :model="formInline" label-position="right" :label-width="120">
                        <FormItem label="客户信息">
                          <i-input v-model="formInline.customer"/>
                        </FormItem>
                        <FormItem label="销售">
                          <i-input v-model="formInline.saler"/>
                        </FormItem>
                        <FormItem label="商品/颜色">
                          <i-input v-model="formInline.product"/>
                        </FormItem>        
                        <FormItem label="订单编号">
                          <i-input v-model="formInline.ordercode" @on-blur="barcode"/>
                        </FormItem>   
                        <FormItem label="出货日期">
                          <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择日期" v-model="formInline.outdate" style="width: 200px" @on-change="formInline.outdate=$event"></DatePicker>
                        </FormItem>     
                        <FormItem label="打印张数">
                          <i-input type="number" v-model="formInline.printnum" />
                        </FormItem>                  
                    </Form>    
                </Card>
          </i-col>
          <i-col span="12" align="center">
                <Card :bordered="false" style="height:450px">
                    <p slot="title">出库贴信息</p>
                    <div id="stockprintid" style="margin:0 auto;justify-content:center;align-items:center;">
                        <table  border="1px solid black" cellspacing="0" width="300px">
                            <tr style="font-size:14px;font-weight:bold" >
                                <td width="35%">客户信息</td>
                                <td width="65%">{{formInline.customer}}</td>
                            </tr>
                            <tr style="font-size:14px;font-weight:bold">
                                <td>销售</td>
                                <td>{{formInline.saler}}</td>
                            </tr>
                            <tr style="font-size:14px;font-weight:bold">
                                <td>商品/颜色</td>
                                <td>{{formInline.product}}</td>
                            </tr>
                            <tr style="font-size:10px;font-weight:bold">
                                <td>订单编号</td>
                                <td>{{formInline.ordercode}}</td>
                            </tr>
                            <tr style="font-size:10px;font-weight:bold">
                                <td>出货日期</td>
                                <td>{{formInline.outdate instanceof Date ? formInline.outdate.Format("yyyy-MM-dd"):formInline.outdate}}</td>
                            </tr>           
                                         
                        </table> 
                        <div style="margin:0 auto;width:100%;display:flex;justify-content:center;align-items:center;" >
                            <img id="barcode" />
                        </div>                        
                    </div>
                    
                    <div style="margin:0 auto;margin-top:40px;">
                        <Button type="success" @click="startprint()">开始打印</Button>    
                        <Row v-show="false">
                          <i-col span="12" align="right">
                            <Select  placeholder="选择打印机" @on-change="onprinterselect">
                                <Option v-for="(item,index) in printer.printerlist" :value="item.name" :key="index">{{ item.name }}</Option>                              
                            </Select>      
                          </i-col>
                          <i-col span="11" align="center">
                            <Button type="success" @click="startprint()">开始打印</Button>        
                          </i-col>
                        </Row>
                    </div>
                </Card>
          </i-col>
        </Row>
    </div>
</template>

<script>
import {getPrinters,public_print} from '@/zsjs/zskjprinter.js'
import JsBarcode from 'jsbarcode'

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'StockOutPrint',
    data(){
        return {
            formInline:{
                customer:'',
                saler:'',
                product:'',
                ordercode:'',
                outdate:'',
                printnum:1
            },
            printer:{
                printerlist:null,
                codeprinter:null
            }
        }
    },
    mounted(){
        //this.printer.printerlist = getPrinters()
    },
    methods:{
        onprinterselect(v){
            this.printer.codeprinter = v
        },
        startprint(){
            if(this.formInline.printnum >0){
                public_print("stockprintid",this.formInline.printnum)
            }
            else{
                this.$Message.error({content:'打印数量不能小于1 !'})
            }
/*             if(this.printer.codeprinter == null || this.printer.codeprinter == ''){
                this.$Message.error({content:'请先配置打印机', duration:3})
            }
            else{
                public_print("stockprintid")
            } */
        },
        barcode() {
            if(this.formInline.ordercode == null || this.formInline.ordercode == ''){
                return
            }
            JsBarcode("#barcode", this.formInline.ordercode, {
                format: "code128",  //条形码的格式
                lineColor: "#000",  //线条颜色
                width:2, //线宽
                height:55,  //条码高度
                fontSize:18,
                //fontOptions:"bold",//使文字加粗体或变斜体
                font:"fantasy",//设置文本的字体
                displayValue: true //是否显示文字信息
            });
        }
    }
}
</script>