<template>
  <div class="home"  >
    <div id="titleid">
      <div id="titlebar">
          <img alt="Vue logo" src="../assets/ddc.png"  v-on:dblclick="ondevshow">
          <!--
          <p id="companyid">牛商企业服务有限公司</p>
          -->

      </div>
        <p id="systemid">一物一码(生产企业)业务系统</p>
    </div>
    <div id="panelid">
      <div id="deptentryid" class="panelitem" @click="deptclick()">
        部门登录
      </div>
      <div id="adminentryid" class="panelitem" @click="adminclick()">
        管理员登录
      </div>
      <div id="registerentryid" class="panelitem" @click="regclick()">
        商家注册
      </div>
      <div id="ycid" class="panelitem" @click="ycclick()">
        认证登录
      </div>
    </div>
    <!--


    <div id="panelid">
      <i-row :gutter="10" index="" style="margin: 0 auto;width: 100%;" type="flex" align="middle" justify="center">
        <i-col span="8" >
          <div id="deptentryid" class="panelitem" @click="deptclick()">
            部门登录
          </div>
        </i-col>
        <i-col span="8">
          <div id="adminentryid" class="panelitem" @click="adminclick()">
            管理员登录
          </div>
        </i-col>
        <i-col span="8">
          <div id="registerentryid" class="panelitem" @click="regclick()">
            商家注册
          </div>
        </i-col>
      </i-row>
         
    </div>
     -->
    <div @click="cleardata()" style="margin-top:400px">清除全部缓存数据</div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
const departmap=[
  {id:101,departname:'车间'},
  {id:102,departname:'成品'},
  {id:103,departname:'技术'},
  {id:104,departname:'财务'},
  {id:105,departname:'计划'},
  {id:106,departname:'采购'},
  {id:107,departname:'销售'},
  {id:108,departname:'零件'},
  {id:200,departname:'主账号管理员'}
]
//import {getLodop} from '@/zsjs/LodopFuncs'
//import {getPrinters} from '@/zsjs/zskjprinter.js'
import test from './test.vue'
export default {
  name: 'home',
  data(){
    return {
      showdev:false,
      showtest:false
    }
  },
  mounted(){
    //localStorage.clear()
  },
  components:{
    test:test
  },
  methods:{
    ondevshow(){
      console.log(this.showdev)
      this.showdev = !this.showdev
    },
    doshowtest(){
      this.showtest = !this.showtest
    },
    cleardata(){
      localStorage.clear()
      this.$Message.success({content:'清除完毕',duration:3})
    },
    deptclick(){
      this.$router.push({name:'userlogon'})
    },
    adminclick(){
      this.$router.push({path:'/adminlogin'})
    },
    regclick(){
      this.$router.push({path:'/register'})
    },
    ycclick(){
      this.$router.push({path:'/yclogin'})
    },
    testclick(){
            var LODOP = getLodop();
        LODOP.ADD_PRINT_HTM("8mm", "5mm", "95%","95%", document.getElementById("homeid").innerHTML);
        LODOP.ADD_PRINT_SETUP_BKIMG("<img border='0' src='http://192.168.0.104/api/files/get/watermark?id=2'>");
        LODOP.SET_SHOW_MODE("BKIMG_PRINT",1);		
        LODOP.PREVIEW();
    },
  }
}
</script>
<style>
  .home {
    margin:0 auto;
    padding-top: 10px;
    height:700px;
    text-align: center;
    background-color:  rgb(166, 191, 247);
    /*
        background-color:  rgb(241, 206, 88);
    background-image: url("~@/assets/ddcbk.png");
    background-blend-mode: multiply;
    background-size:cover;
    filter:blur(0.2);
    opacity: .8;
    */
  }

  #titleid {
    margin: 0 auto;
    background:  rgba(75, 130, 250, 0.8);
    width: 80%;
    text-align: left;
  }

  #titlebar {
    display: inline-flex;
    margin-left: 0px;
  }

  #titlebar img {
    margin-left: 0px;
    height: 100px;
  }

  #companyid {
    margin-left: 20px;
    height: 100px;
    line-height: 100px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    color: white;
  }

  #systemid {
    font-size: 72px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
    color: white;
  }

  #panelid {
    display: inline-flex;
    margin: 20px auto;
    padding:15px;
    width: 80%;
    background: rgba(81, 133, 245,.3);
  }

  .panelitem {
    background: rgba(75, 130, 250, 0.8);
    width: 22%;
    height: 300px;
    margin: 10px auto;
    border: 1px dotted blue;
    color: white;
    cursor: pointer;
    text-align: center;
    line-height: 300px;
    font-size: 36px;
    word-break: break-all;
    vertical-align: middle;
  }

  .panelitem:hover {
    background: rgba(75, 130, 250, 0.4);
  }
</style>