<template>
  <div style="height:700px">
      <h1 style="padding-top:30px">各部门子系统用户登录</h1>
      <Form ref="loginformInline" :model="formInline" :rules="ruleInline" label-position="right" :label-width="120" style="width:20%;margin:auto;padding-top:40px">
          <FormItem prop="companycode" label="公司主账号:">
              <i-input type="text" v-model="formInline.companycode" placeholder="公司主账号" :autofocus="true">
              </i-input>
          </FormItem>
          <FormItem prop="departmentcode" label="所属部门:" style="padding-top:30px;">
              <i-select v-model="formInline.departmentcode">
                  <i-option v-for="(item,index) in departmap" :value="item.id" :key="index">{{item.departname}}</i-option>
              </i-select>
          </FormItem>
          <FormItem prop="username" style="padding-top:30px;" label="部门账号:">
              <i-input type="text" v-model="formInline.username" placeholder="账号" >
              </i-input>
          </FormItem>
          <FormItem prop="password" style="padding-top:30px;" label="登录密码:">
              <i-input type="password" v-model="formInline.password" placeholder="密码">
              </i-input>
          </FormItem>
          <FormItem prop="yzm" style="padding-top:30px;" label="验证码:">
            <Row type="flex" align="middle" justify="center">
              <i-col span="8">
                <i-input type="text" v-model="formInline.yzm" placeholder="验证码" @on-enter="handleSubmit('loginformInline')">
                </i-input>
              </i-col>
              <i-col span="1">  </i-col>
              <i-col span="8" ><yzm :identifyCode="identifyCode" v-on:onrefresh="refreshcode"></yzm></i-col>
            </Row>
          </FormItem>
          <FormItem style="padding-top:30px;">
              <Button type="primary" @click="handleSubmit('loginformInline')" style="width:100%">登录</Button>
          </FormItem>
      </Form>
      <Modal v-model="recharge.showrechargealert" title="续费提醒" ok-text="现在去通知续费" cancel-text="我知道了，稍后再去通知" @on-cancel="rechargecancel" @on-ok="rechargeOk">
        <p>{{recharge.rechargealerttext}}</p>
        <p>请及时通知公司管理人员进行续费！</p>
      </Modal>
    </div>
</template>
<script>
import {nowdatediff} from '@/zsjs/systemTool.js'
import SIdentify from '@/components/SIdentify.vue' //* *引入验证码组件**
export default {
  name: 'UserLogin',
  data () {
    const validateyzm = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请输入验证码'))
      } else if (value != this.identifyCode) {
        return callback(new Error('验证码输入错误'))
      } else {
        return callback()
      }
    }

    const validatedept = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请选择所属部门'))
      } else {
        return callback()
      }
    }

    return {
      formInline: {
        companycode: '',
        username: '',
        password: '',
        yzm: '',
        departmentcode:''
      },
      recharge:{
        showrechargealert:false,
        rechargealerttext:'',
        afterlogonpath:'',
        //alerttype 1--即将过期   2--已过期  3--未激活
        alerttype: 1
      },
      identifyCodes: '1234567890',
      identifyCode: '12354',
      ruleInline: {
        companycode: [
          { required: true, message: '请输入公司主账号', trigger: 'blur' }
        ],
        departmentcode:[
          {validator:validatedept, required: true, trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 8, message: '密码不能低于8位', trigger: 'blur' }
        ],
        yzm: [
          { validator: validateyzm, required:true, trigger: 'blur' }
        ]
      },
      departmap:[
        {id:101,departname:'车间部门',entry:'workshop/'},
        {id:102,departname:'成品部门',entry:'warehouse/'},
        {id:103,departname:'技术部门',entry:'technology/'},
        {id:104,departname:'财务部门',entry:'Finance/'},
        {id:105,departname:'计划部门',entry:'Plan/'},
        {id:106,departname:'采购部门',entry:'purchase/'},
        {id:107,departname:'销售部门',entry:'Sell/'},
        {id:108,departname:'零件部门',entry:'parthouse/'},
        {id:150,departname:'认证部门',entry:'checkdept/'},
      ]
    }
  },
  components: {
    'yzm': SIdentify
  },
  mounted () {
    this.refreshcode()
  },
  methods: {
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
            //this.$router.push({ name: 'workshop', params: { companyCode: 'TJDH' }})
          axios.post("/api/slaveuser/users/login",this.formInline)
          .then(function(res){
            if(res.data.resultCode ==0){
              this.$Message.success({content:res.data.msg, duration:3, closable:true})
              this.$store.commit('changeLogin',res.data.data)
              console.log(this.formInline.companycode)
              var deptcode = this.formInline.departmentcode
              var newpath = ''
              for(var i =0;i<this.departmap.length;i++){
                if(this.departmap[i].id == deptcode){
                  newpath = '/business/depart/'+this.formInline.companycode+'/'+this.departmap[i].entry
                  break
                }
              }

              if(newpath ==''){
                this.$Message.error({content:res.data.msg, duration:3})
              }
              else{
                //nowdatediff
                console.log(res.data.data)
                var expiryTime = res.data.data.logonInfo.expiryTime
                if(expiryTime == null || expiryTime == ''){
                  this.$Message.error({content:'账号已于'+expiryTime+'过期，请联系公司管理人员先续费！！', duration:15})
                  this.recharge.alerttype = 3
                  this.recharge.rechargealerttext =   '您公司的账号还未激活'
                  this.recharge.showrechargealert = true
                }
                else{
                  var datediff = nowdatediff(expiryTime)
                  console.log(datediff)
                  if(datediff >30){
                    this.recharge.showrechargealert = false
                    this.$router.push({ path: newpath })
                  }
                  else if(datediff >0){
                    this.recharge.alerttype = 1
                    this.recharge.afterlogonpath = newpath
                    this.recharge.rechargealerttext =   '您公司的账号即将于【'+expiryTime+'】过期'
                    this.recharge.showrechargealert = true
                  }
                  else{
                    this.recharge.alerttype = 2
                    this.recharge.rechargealerttext =   '您公司的账号已于【'+expiryTime+'】过期'
                    this.recharge.showrechargealert = true
                  }
                }

              }
            }
            else{
              this.$Message.error({content:res.data.msg, duration:3})
            }
          }.bind(this));
        } else {
          this.$Message.error('请输入正确的内容')
        }
      })
    },
    yzmOver () {
      if (this.formInline.yzm == this.identifyCode) {
        console.log('验证码correct')
      } else {
        console.log('验证码incorrect')
      }
    },
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    },
    refreshcode: function () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    getSystemName(id){
      for(var v in this.userDeptMap){
        if(this.userDeptMap[v].id == id){
          return this.userDeptMap[v].systemName
        }
      }
    },

    rechargecancel(){
      this.recharge.showrechargealert = false
      console.log('start to new page11111 this.recharge.alerttype='+this.recharge.alerttype)
      if(this.recharge.alerttype == 1){
        console.log('start to new page')
        setTimeout(()=>{
          console.log('start to new page,path='+this.recharge.afterlogonpath)
          this.$router.push({ path: this.recharge.afterlogonpath })
        }, 1000)

      }
    },
    rechargeOk(){
      this.recharge.showrechargealert = false
    }
  }
}
</script>

<style>
    #center {
        position: fixed;
        top: 50%;
        left: 50%;
        text-align: center;
        background-color: #ffffcc;
        width:70%;
        height: 70%;
        -webkit-transform: translateX(-50%) translateY(-70%);
    }
</style>
