<template>
    <div >
        <Form ref="certificateQueryForm" :model="formInline"  inline label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <!--
            <FormItem prop="cnmodelno" label="中文型号:" style="margin-bottom:5px;margin-top:10px">
                <Select v-model="formInline.cnmodelno">
                  <Option value="" key="">请选择</Option>
                  <Option v-for="(item,index) in CnModelList" :value="item" :key="index">{{ item }}</Option>
                </Select>
            </FormItem>
            -->
            <FormItem  label="选择车型:" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                <Cascader v-model="formInline.modelids"  :data="MasterModelDatalist"></Cascader>
            </FormItem>
            <FormItem prop="certificateno" label="合格证编号:" style="margin-bottom:5px;margin-top:10px">
                <i-input type="text" v-model="formInline.certificateno">
                </i-input>
            </FormItem>
            <FormItem prop="modelno" label="产品型号:" style="margin-bottom:5px;margin-top:10px">
                <i-input type="text" v-model="formInline.modelno">
                </i-input>
            </FormItem>
            <FormItem style="margin-bottom:8px;margin-top:10px">
                <Button type="primary" @click="handleQuery('certificateQueryForm')">查询</Button>
                <Button  @click="handleReset('certificateQueryForm')" style="margin-left: 5px">重置</Button>
            </FormItem>
        </Form>
        <div style="height:1px;margin-top:0px;background:lightgray" ></div>
        <div style="height:40px;width:100%;">
            <Button v-hasPermission="'certemplate:add'" type="primary"  style="margin-top:3px;float:left" @click="addCertificate">添加合格证</Button>
        </div>
        
        <!-- 合格证列表-->
        <div>
            <Table border :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="certificateno">
                <strong>{{ row.certificateno }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action">
                <div class="opbar">
                    <Button v-hasPermission="'certemplate:update'" type="primary" size="small"  @click="modify(index)">修改</Button>
                    <Button v-hasPermission="'certemplate:add'" type="warning" size="small"  @click="copycertificate(index)">复制</Button>
                    <Button v-hasPermission="'certemplate:delete'" type="error" size="small" @click="remove(index)">删除</Button>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="orgport">
                <div class="opbar">
                    <Button v-hasPermission="'certemplate:port:edit'" type="primary" size="small"  @click="editport(index)">
                        <Icon type="ios-checkmark" size="20" v-if="getorglink(index)==true"/>
                        编辑
                    </Button>
                    <Poptip
                    confirm
                    title="确定要清除端口吗？"
                    transfer
                    @on-ok="confirmdeleteport(index)"
                    >
                        <Button v-hasPermission="'certemplate:port:edit'" v-if="getorglink(index)==true" type="error" size="small" >
                        删除</Button>
                    </Poptip>
                </div>
            </template>
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right" @on-change="onpagechange"/>

        </div>
        <Modal v-model="showDeleteDialog" title="删除合格证" @on-ok="confirmDelete">
          <p>确定要删除吗?</p>
        </Modal>
        <Modal v-model="showorgporteditwin" title="上传数据" @on-ok="onsaveorgport" @on-cancel="oncancelorgport"  ok-text="保存">
            <div style="display: inline-flex;width: 100%;margin: 0 auto;">
                <Input type="text" v-model="orgportaddr"  placeholder="请输入上传端口地址" style="width: 100%;"/>
            </div>
            <div slot="footer">
                <Button type="text" @click="oncancelorgport">取消</Button>
                <Button type="primary" @click="onsaveorgport">保存</Button>
            </div>
        </Modal>
        <ceredit ref="cereditref" v-bind:visibility="showceredit" v-bind:action="ceraction" @onSaveOver="oneditClose" @onCancel="showceredit=false"/>
    </div>
</template>
<script>
    import CertificateEditModal from '@/views/admin/CertificateEditModal'
    export default {
        name:'CertificateTemplate',
        data () {
            return {
                formInline: {
                    certificateno: '',
                    modelno: '',
                    modelids:[]
                },
                showceredit:false,
                ceraction:'add',
                orgportaddr:null,
                showorgporteditwin:false,
                username:this._self.$root.$store.state.LogonInfo.username,
                userlevel:this._self.$root.$store.state.LogonInfo.level,
                companyid:this._self.$root.$store.state.LogonInfo.companyid,
                CnModelList:null,
                MasterModelDatalist:[],
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:10
                },
                tblcolumns: [
                    {
                        title: '合格证编号',
                        slot: 'certificateno'
                    },
                    {
                        title: '合格证别名',
                        key: 'certificatealias'
                    },
                    {
                        title: '中文产品型号',
                        key: 'cnmodelno'
                    },
                    {
                        title: '产品型号',
                        key: 'modelno'
                    },
                    /*
                    {
                        title: 'CCC证书编号',
                        key: 'cccno'
                    },*/
                    {
                        title: '电动机型号',
                        key: 'motormodel'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 200,
                        align: 'center'
                    },
                    {
                        title: '机构端口',
                        slot: 'orgport',
                        width: 200,
                        align: 'center'
                    }
                ],
                tbldata: [],
                tblSelected:0,
                showDeleteDialog:false,
                orgportlist:[]
            }
        },
        mounted(){
            this.MasterModelDatalist = this._self.$root.$store.state.MasterModelChooseList
            console.log("合格证模板页面挂载车型选择级联菜单数据",this.MasterModelDatalist)
            this.handleQuery('certificateQueryForm')
        },
        methods: {
            handleQuery(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        var postdata ={
                                companyid:this.companyid,
                                certificateno:this.formInline.certificateno,
                                modelverid:null,
                                modelno:this.formInline.modelno,
                                pageNum:this.tblpageinfo.currentpage,
                                pageSize:this.tblpageinfo.pagesize
                            }
                        if(this.formInline.modelids.length ==2){
                            postdata.modelverid = this.formInline.modelids[1]
                        }

                        postdata = this.removeEmptyChilds(postdata)
                        this.$axios({
                            method:'post',
                            url:"/api/certificatetemplate/query",
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.tbldata = res.data.data.data
                                this.tblpageinfo.total = res.data.data.totalnumber
                                this.queryorgport()
                            }
                            else{
                                this.$Message.error({content:res.data.data.msg, duration:3})
                            }
                        }.bind(this));   
                        //this.$Message.success('Success!');
                    } else {
                        this.$Message.error('Fail!');
                    }
                })
            },
            queryCnmodellist(){
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/cnmodelno/query",
                    data:{
                        companyid:this.companyid,
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        this.CnModelList = res.data.data
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));                   
            },
            queryorgport (){
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/cerorgport/query",
                    data:{companyid: this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.orgportlist = res.data.data
                        console.log("机构端口列表",this.orgportlist)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.handleQuery('certificateQueryForm')
            },
            addCertificate(){
                this.$store.commit('changeCertificateData',null)
                let category = this._self.$root.$store.state.LogonInfo.userCategory

                this.ceraction = "add"
                this.showceredit = true
                /*
                if(category == 'master'){
                    this.$router.push({name:'CertificateEdit',params:{action:'add'}})
                }
                else{
                    this.$router.push({name:'SlaveCertificateEdit',params:{action:'add'}})
                }*/
            },            
            modify (index) {
                this.$store.commit('changeCertificateData',this.tbldata[index])
                let category = this._self.$root.$store.state.LogonInfo.userCategory
                /*
                if(category == 'master'){
                    this.$router.push({name:'CertificateEdit',params:{action:'modify'}})
                }
                else{
                    this.$router.push({name:'SlaveCertificateEdit',params:{action:'modify'}})
                }*/
                this.ceraction = "modify"
                this.showceredit = true
                this.tblSelected =index
            },
            oneditClose(){
                this.handleQuery('certificateQueryForm')
                this.showceredit = false
            },
            editport (index){
                this.showorgporteditwin = true
                this.tblSelected =index
                this.orgportaddr = null
                console.log(this.tbldata[index])
                /*
                for(var i=0;i<this.orgportlist.length;i++){
                    if(this.orgportlist[i].cerid == this.tbldata[index].id){
                        if(this.orgportlist[i].orgportlinkaddr !=null){
                            this.orgportaddr = this.orgportlist[i].orgportlinkaddr
                            return
                        }
                    }
                }*/
            },
            confirmdeleteport(index){
                let orgport=null;
                for(var i=0;i<this.orgportlist.length;i++){
                    if(this.orgportlist[i].cerid == this.tbldata[index].id){
                        orgport =  this.orgportlist[i]
                        break
                    }
                }

                if(orgport == null){
                    this.$Message.error({content:'异常，请刷新页面重试!',duration:3})
                    return
                }
                axios.post("/api/certificatetemplate/orgport/update",{
                    id: orgport.id,
                    companyid:orgport.companyid,
                    cerid:orgport.cerid
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        //this.$Message.success({content:res.data.msg, duration:3})
                        this.queryorgport()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            copycertificate (index) {
                this.$store.commit('changeCertificateData',this.tbldata[index])
                let category = this._self.$root.$store.state.LogonInfo.userCategory

                this.ceraction = "copy"
                this.showceredit = true
                /*
                if(category == 'master'){
                    this.$router.push({name:'CertificateEdit',params:{action:'copy'}})
                }
                else{
                    this.$router.push({name:'SlaveCertificateEdit',params:{action:'copy'}})
                }*/
                this.tblSelected =index
            },
            remove (index) {
                //this.tbldata.splice(index, 1);
                this.tblSelected =index
                this.showDeleteDialog = true
            },
            confirmDelete(){
                console.log('confirmDelete')
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/delete",
                    data:{
                        id:this.tbldata[this.tblSelected].id,
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.queryCnmodellist()
                        this.handleQuery('certificateQueryForm')
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));                   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('certificateQueryForm')
            },
            oncancelorgport(){
                this.showorgporteditwin = false
                this.orgportaddr = null
            },
            getorglink(index){
                if(this.orgportlist == null || this.orgportlist.length ==0){
                    return false
                }

                for(var i=0;i<this.orgportlist.length;i++){
                    if(this.orgportlist[i].cerid == this.tbldata[index].id){
                        if(this.orgportlist[i].orgportlinkaddr !=null && this.orgportlist[i].orgportlinkaddr !=''){
                            return true
                        }
                    }
                }

                return false
            },
            onsaveorgport(){
                if(this.orgportaddr==null || this.orgportaddr == ''){
                    this.$Message.error('端口地址不能为空！')
                    return
                }
                if(this.orgportaddr.toLowerCase().indexOf("http") != 0) {
                    this.$Message.error('端口地址格式错误，必须是以http开头的合法URL地址！')
                    return
                }

                var postdata={
                        companyid:this._self.$root.$store.state.LogonInfo.companyid,
                        modifyuser: this._self.$root.$store.state.LogonInfo.userid,
                        modifyusername: this._self.$root.$store.state.LogonInfo.fullname,
                        cerid:this.tbldata[this.tblSelected].id,
                        certificateno:this.tbldata[this.tblSelected].certificateno,
                        orgportlinkaddr:this.orgportaddr
                    }
                this.$axios({
                    method:'post',
                    url:"/api/certificatetemplate/orgport/update",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.showorgporteditwin = false
                        this.orgportaddr = null
                        this.queryorgport()
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));                   
            }
        },
        components:{
            'ceredit':CertificateEditModal
        }
    }
</script>
<style>
    .ivu-form-item {
        margin-bottom: 8px;
    }
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>