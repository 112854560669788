<template>
<div >
        <Form ref="companyQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <Row :gutter="20">
              <i-col span="8">
                <FormItem prop="companyName" label="公司名称:" style="margin-bottom:5px;margin-top:10px">
                    <i-input type="text" v-model="formInline.companyName">
                    </i-input>
                </FormItem>
              </i-col>
              <i-col span="8">
                <FormItem prop="companyCode" label="公司编码:" style="margin-bottom:5px;margin-top:10px">
                    <i-input type="text" v-model="formInline.companyCode">
                    </i-input>
                </FormItem>    
              </i-col>
              <i-col span="8">   
                <FormItem style="margin-bottom:5px;margin-top:10px" :label-width="1" >
                    <Button type="primary" @click="handleQuery()" >查询</Button>    
                    <Button  @click="handleReset('companyQueryForm')" style="margin-left:10px">重置</Button>
                </FormItem>             
              </i-col>
            </Row>
        </Form>
        
        <!-- 整车编码列表-->
        <div style="padding-top:10px">
            <Table border :row-class-name="rowClassName" :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row, index }" slot="companyname">
                <strong><a href="#" @click="showdetail(index)">{{ row.companyname }}</a></strong>
            </template>
            <template slot-scope="{ row }" slot="lastlogintime">
                <p v-if="row.lastlogintime ==null">-</p>
                <p v-else>{{row.lastlogintime}}</p>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button type="success" size="small" ghost v-if="row.status == '未激活'" style="margin-right: 5px" @click="onActivate(index)">激活</Button>  
                <Button type="info" size="small"  v-if="row.status != '未激活'" style="margin-right: 5px" @click="onRecharge(index)">充值</Button>
                <Button type="warning" size="small" v-if="row.status != '已禁用' && row.status != '未激活'" style="margin-right: 5px" @click="lock(index)">禁用</Button>
                <Button type="success" size="small" v-if="row.status == '已禁用' && row.status != '未激活'" style="margin-right: 5px" @click="unlock(index)">解禁</Button>
                <Button type="error" size="small" @click="remove(index)" style="margin-right: 5px">删除</Button>
                <Button type="info" size="small" ghost @click="modify(index)" style="margin-right: 5px">改密</Button>
                <Button type="success" size="small" ghost @click="tongji(index)" >统计</Button>
            </template>
            </Table>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right" @on-change="onpagechange"/>

        </div>
        <recharge :show='showRecharge' v-bind:companyid="activeUser.companyid" v-bind:companyname="activeUser.companyname" v-bind:expiryTime="activeUser.expiryTime" @CloseRecharge='onRechargeClose'/>
        <activate :show='showActivate' v-bind:companyid="activeUser.companyid" v-bind:companyname="activeUser.companyname" v-bind:expiryTime="activeUser.expiryTime" @CloseActivate='onActivateClose'/>
        <confirmaction :show='confirmdata.showconfirm'  v-bind:companyid="confirmdata.companyid" v-bind:title="confirmdata.title" :msg="confirmdata.msg" :action="confirmdata.action" @CloseConfirmAction="onActionClose" style="width:30%"/>
        <modifyuser :show='modifydata.show'  v-bind:companyid="modifydata.companyid" v-bind:companyname="modifydata.companyname" :username="modifydata.username"  @CloseModify="onModifyClose" style="width:30%"/>
        <tongji :show='showTongji' v-bind:companyid="activeUser.companyid" v-bind:companyname="activeUser.companyname" v-bind:expiryTime="activeUser.expiryTime" @CloseTongji='onTongjiClose'/>
    </div>
</template>

<script>
import recharge from './recharge.vue'
import activate from './activateCompany.vue'
import ConfirmAction from './ConfirmAction.vue'
import ModifyUser from './ModifyUser.vue'
import tongji from '@/views/admin/systemowner/tongji'

const accountstatusmap=[
    {status:0,text:'未激活'},
    {status:1,text:'已激活'},
    {status:2,text:'已过期'},
    {status:3,text:'已禁用'},
]

function getaccountstatustext(status)
{
    for(var item in accountstatusmap){
        if(accountstatusmap[item].status == status){
            return accountstatusmap[item].text
        }
    }

    return ''
}


// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

//两个时间相差天数 兼容firefox chrome
function datediff(sDate1, sDate2) {    //sDate1和sDate2是2006-12-18格式 
    var dateSpan,
        tempDate,
        iDays;

    sDate1 = Date.parse(sDate1);
    sDate2 = Date.parse(sDate2);
    dateSpan = sDate1 - sDate2;
    //dateSpan = Math.abs(dateSpan);
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000))+1;

    return iDays
};

export default {
    name:'SuperAdmin',
    data () {
            return {
                formInline: {
                    companyName: '',
                    companyCode: ''
                },
                showRecharge:false,
                showActivate:false,
                showTongji:false,
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:10
                },
                activeUser:{
                    companyid:'',
                    companyname:'',
                    expiryTime:null,
                },
                confirmdata:{
                    companyid:'',    
                    action:'',
                    title:'',
                    msg:'',
                    showconfirm:false
                },
                modifydata:{
                    companyid:'',
                    companyname:'',
                    username:'',
                    show:false,
                },
                tblcolumns: [
                    {
                        title: '公司名称',
                        slot: 'companyname',
                        width: 260,
                        resizable:true
                    },
                    {
                        title: '公司编码',
                        key: 'companycode',
                        width: 100,
                        resizable:true
                    },
                    {
                        title: '手机号码',
                        key: 'phonenumber',
                        width: 130,
                        resizable:true
                    },
                    {
                        title: '激活状态',
                        key: 'status',
                        width: 120,
                        sortable: 'custom',
                        resizable:true
                    },
                    {
                        title: '失效日期',
                        key: 'expirytime',
                        width: 180,
                        resizable:true
                    },                     
                    {
                        title: '激活日期',
                        key: 'activateDate',
                        width: 180,
                        resizable:true
                    },         
                    {
                        title: '剩余有效期',
                        key: 'validityLeft',
                        width: 110,
                        resizable:true
                    },                    
                    {
                        title: '最后登录时间',
                        slot: 'lastlogintime',
                        width: 180,
                        resizable:true
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 280,
                        align: 'left',
                        fixed: 'right'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/adminuser/query",
                    data:{
                        companyname:this.formInline.companyName,
                        companycode:this.formInline.companyCode,
                        level:100,
                        pageNum:this.tblpageinfo.currentpage,
                        pageSize:this.tblpageinfo.pagesize
                    },
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        var status =getaccountstatustext(1)
                        for(var item in rspdata){
                            rspdata[item].status = getaccountstatustext(rspdata[item].status)
                            rspdata[item].activateDate = rspdata[item].activatetime
                            
                            if(rspdata[item].level ==100){
                                if(rspdata[item].expirytime !=null){
                                    var left =datediff(rspdata[item].expirytime,new Date().Format("yyyy-MM-dd"))
                                    left = left>=0? left:0
                                    rspdata[item].validityLeft =left +'天'
                                    if(left ==0){
                                        rspdata[item].status =getaccountstatustext(2)        
                                    }
                                }
                                else{
                                    rspdata[item].validityLeft = '0天'
                                    rspdata[item].status =getaccountstatustext(0)  
                                }
                            }
                            else{
                                rspdata[item].validityLeft ="无限"
                            }
                        }
                        this.tbldata =rspdata
                        this.tblpageinfo.total = res.data.data.totalnumber
                        console.log(this.tbldata)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            handleReset (name) {
                this.$refs[name].resetFields();
            },
            showdetail(index){
                this.$router.push({name:"viewcompanydetail",params:{companyid: this.tbldata[index].id}})
            },
            modify (index) {
                this.modifydata.companyid =this.tbldata[index].id
                this.modifydata.companyname = this.tbldata[index].companyname 
                this.modifydata.username = this.tbldata[index].username 
                this.modifydata.show = true
            },
            lock (index){
                this.confirmdata.action ='lock'
                this.confirmdata.companyid = this.tbldata[index].id
                this.confirmdata.title='锁定公司账号' +  this.tbldata[index].companyname      
                this.confirmdata.msg ='确定要禁用该公司账号吗?'      
                this.confirmdata.showconfirm = true;
            },
            unlock (index){
                this.confirmdata.action ='unlock'
                this.confirmdata.companyid = this.tbldata[index].id
                this.confirmdata.title='解禁公司账号' +  this.tbldata[index].companyname      
                this.confirmdata.msg ='确定要解禁该公司账号吗?'      
                this.confirmdata.showconfirm = true;
            },
            remove (index) {
                this.confirmdata.action ='delete'
                this.confirmdata.companyid = this.tbldata[index].id
                this.confirmdata.title='删除公司账号' +  this.tbldata[index].companyname      
                this.confirmdata.msg ='确定要删除该公司账号吗?删除后该公司数据就没有了!'      
                this.confirmdata.showconfirm = true;
            },
            tongji (index) {
                this.activeUser.companyid = this.tbldata[index].id
                this.activeUser.companyname = this.tbldata[index].companyname
                this.activeUser.expiryTime = this.tbldata[index].expirytime 
                console.log(this.activeUser)
                this.showTongji = true
            },
            onRecharge (index) {
                this.activeUser.companyid = this.tbldata[index].id
                this.activeUser.companyname = this.tbldata[index].companyname
                this.activeUser.expiryTime = this.tbldata[index].expirytime                
                this.showRecharge = true
            },
            onRechargeClose(){
                this.showRecharge = false
                this.handleQuery()
            },
            onTongjiClose(){
                this.showTongji = false
            },
            onActionClose(){
                this.confirmdata.showconfirm = false
                this.handleQuery() 
            },
            onActivate (index) {
                this.activeUser.companyid = this.tbldata[index].id
                this.activeUser.companyname = this.tbldata[index].companyname
                this.activeUser.expiryTime = this.tbldata[index].expirytime
                this.showActivate = true
            },
            onActivateClose(){
                this.showActivate = false
                this.handleQuery()
            },
            onModifyClose(){
                this.modifydata.show =false
            },
            onpagechange(num){
            this.tblpageinfo.currentpage = num
            this.handleQuery()
            },
            rowClassName (row, index) {
                if (row.status == '已过期') {
                    return 'demo-table-warning-row';
                }
                return '';
            }
        },
        components:{
            'recharge':recharge,
            'activate':activate,
            'confirmaction':ConfirmAction,
            'modifyuser':ModifyUser,
            'tongji':tongji
        }
}
</script>
<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #e7cac6;
        color: #fff;
    }
</style>