<!-- 整车库存查询功能-->
<template>
    <div >
        <Form ref="vehicleQueryForm" :model="formInline"  :rules="ruleInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td width="25%">
                        <!--
                        <FormItem  label="选择物料:" :label-width="120" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Cascader v-model="formInline.material"  :data="materialTypelist" ></Cascader>
                        </FormItem>
                        -->
                        <FormItem  label="选择物料分类:" :label-width="120" prop="modelids" style="margin-bottom:5px;margin-top:10px">
                            <Select v-model="formInline.materialType"  >
                                <Option v-for="(item,index) in masterdata.materialTypes" :value="item.value" :key="index">{{item.label}}</Option>
                            </Select>
                        </FormItem>
                    </td>
                    <td align="left" style="vertical-align: middle;">
                        <div style="display: inline-flex;">
                            <div style="width:20%;display: flex;align-items: center;justify-content: center;text-align: justify;">库存范围:</div>
                            <div style="width:80%">
                                <i-input type="number" style="width:40%" v-model="formInline.numMin" ></i-input>-
                                <i-input type="number" style="width:40%" v-model="formInline.numMax"></i-input>
                            </div>
                        </div>
                    </td> 
                    <td  colspan="2" align="center">
                        <div>
                            <Button type="primary" @click="handleQuery('vehicleQueryForm')" >查询</Button>    
                            <Button  @click="handleReset('vehicleQueryForm')" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="display: inline-flex;margin-top: 20px;">
            <div style="width:100%">
                <div style="height:40px;width:100%;">
                    <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">库存明细结果<span style="margin-left: 400px;">共计： <span style="color: red;">{{tblpageinfo.total}} </span> 条 <span style="color: red;">物料库存</span> 记录</span></label>  
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
                </div>
                
                <!-- 整车编码列表-->
                <div style="clear: both;">
                    <Table ref="vehicletable" border :columns="tblcolumns" :data="tbldata" no-data-text="">
                    <template slot-scope="{ row }" slot="vehicleno">
                        <strong>{{ row.vehicleno }}</strong>
                    </template>   
                    <template  slot-scope="{ row }" slot="count">
                        {{row.num}}
                    </template>
                    <template  slot-scope="{ row }" slot="opdate">
                        {{usedwatertype == 'into'? row.intodate : row.outdate}}
                    </template>   
                    <template slot-scope="{ row, index }" slot="opuser">
                        {{usedwatertype == 'into'? row.intobyusername : row.outbyusername}}
                    </template>            
                    </Table>
                    <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:5px;float:right"  @on-change="onpagechange"/>
        
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
const statusdata=[
    //0--已下线  1--已打印  2--已入库 3--已锁定  4--已出库
    {status:0,text:'未打印'},
    {status:1,text:'已打印'},
    {status:2,text:'已入库'},
    {status:3,text:'已锁定'},
    {status:4,text:'已出库'},
]

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
// 例子： 
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18 
Date.prototype.Format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份 
        "d+": this.getDate(), //日 
        "H+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
export default {
    name:'PartStockReport',
    props:['departid'],
    data () {
            return {

                usedwatertype:'',
                formInline: {
                    companyid:'',
                    material:[],
                    materialid:null,
                    materialType:null,
                    numMin:null,
                    numMax:null,
                    opdate:[]
                },
                ruleInline: {
                    modelids: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ]
                },
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:50
                },
                tblcolumns: [
                {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料名称',
                        key: 'materialname',
                        tooltip:true,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'materialspec',
                        resizable:true
                    },   
                    {
                        title: '物料编码',
                        key: 'materialCode',
                        resizable:true
                    },   
                    {
                        title: '库存数量',
                        key: 'num',
                        resizable:true
                    },  
                    {
                        title: '采购中数量',
                        key: 'numInBuying',
                        resizable:true
                    },   
                    {
                        title: '单位',
                        key: 'unit',
                        resizable:true
                    },
                    {
                        title: '供应商',
                        key: 'supplier',
                        resizable:true
                    }
                ],
                tbldata: [],
                postquerydata:null,
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            var logonInfo = this._self.$root.$store.state.LogonInfo
            this.formInline.companyid = logonInfo.companyid
            this.getMaterialMasterData()    
        },
        methods: {
            handleQuery(name) {  

                console.log("库存报表表单数据",this.formInline)
                this.postquerydata={
                    companyid:this.formInline.companyid,
                    materialTypeId: this.formInline.materialType,
                    numMin:this.formInline.numMin,
                    numMax:this.formInline.numMax,
                    pageNum:this.tblpageinfo.currentpage,
                    pageSize:this.tblpageinfo.pagesize
                }  

                if(this.formInline.material.length>1){
                    let materialidx = this.formInline.material[1]
                    this.postquerydata.materialId = this.masterdata.materialInfos[materialidx].id
                    
                }
                else{
                    this.formInline.material =[]
                }


                this.postquerydata = this.removeEmptyChilds(this.postquerydata)
                console.log("零部件库存查询表单数据",this.postquerydata)
                this.$axios.post("/api/partMaterialStock/query",this.postquerydata)
                .then(function(res){
                    console.log("零部件库存查询结果",res.data.data)
                    if(res.data.resultCode ==0){
                        //let summary = res.data.data.summary
                        let detail = res.data.data

                        this.tbldata = detail.data
                        this.tblpageinfo.total = detail.totalnumber

                        this.usedwatertype = this.formInline.eventType
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            onpagechange(num){
                this.tblpageinfo.currentpage = num
                this.handleQuery('vehicleQueryForm')
            },
            handleReset (name) {
                this.$refs[name].resetFields();
                this.formInline.numMax = null
                this.formInline.numMin = null
                this.formInline.material =[]
                this.tbldata=[]
                this.tblpageinfo.total = 0
            },
            onmodelchange(e,selectedData){
              console.log(e)
              console.log(selectedData)
              if(selectedData.length >1){
                this.formInline.modelverid = selectedData[1].value
              }
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getdaterangetext(){
                //console.log("getdaterangetext",this.formInline.opdate)
                if(this.formInline.opdate !=null && this.formInline.opdate.length >0){
                    let dstr =''
                    if(this.formInline.opdate[0] !=''){
                        dstr = this.formInline.opdate[0] +' 至 '
                    }
                    else{
                        return '全部'
                    }

                    if(this.formInline.opdate[1] !=''){
                        dstr = dstr + this.formInline.opdate[1]
                    }
                    else{
                        dstr = dstr + "当前"
                    }

                    return dstr
                }
                else{
                    return "全部"
                }
            },
            getMaterialMasterData(){
                this.$axios.post('/api/material/masterdata/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                        .then(function(res){
                        if(res.data.resultCode ==0){
                            //this.$Message.success(res.data.msg)
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = this.processmaterialshowlist(res.data.data)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = mdata.materialTypes[i].id
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+') '+ mdata.materialInfos[j].supplier
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
        }
}
</script>