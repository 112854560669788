<template>
  <div class="Testpage">
    <h1>This is an testpage page</h1>
    <input v-model="age" type="text"  />
    <input v-model="age" type="text"  />
    <div id="printcontent">
        <p>hello</p>
        <p>my name is leo</p>
    </div>
    <button  v-print="'#printcontent'" > 点击打印 </button>
    <button  v-on:click="onclick" > 点击修改年龄 </button>
    <button  onclick="javascript:alert('hello')" > 测试js </button>
    <store ref="mystore"/>
  </div>
</template>

<script>
import Vue from 'vue'
import Print from 'vue-print-nb'
import store from './store'

Vue.use(Print)

export default {
  name: 'Testpage',
  data () {
    return {
      age: store.age,
      sex: store.sex
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.meta.requireAuth) {
      console.log('beforeRouteUpdate--requireAuth')
      var login = false
      if (login) {
        next()
      } else {
        next({ path: '/about' })
      }
    } else {
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  mounted () {
    Vue.axios({
      method: 'get',
      url: '/test',
      data: '',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      transformRequest: function (obj) {
        var str = []
        for (var p in obj) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
        return str.join('&')
      }
    }).then(function (res) {
      console.log(res.data)
    })
  },
  components: { store },
  methods: {
    onclick: function () {
      console.log(this)
      this.$refs.mystore.$emit('update', 'testdata')
    }
  }
}
</script>
