<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" @on-ok="oncancel" :mask-closable=false>
        <p slot="header" style="color:#f60;text-align:left">
            <span>生产统计</span>
        </p>
        <Form ref="tongjiform" :model="formInline" :label-width="100" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem  label="公司名称:">
                    <p>{{companyname}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
              <i-col span="24">
                <FormItem  label="当前有效期:">
                    <p>{{expiryTime==null?'无':expiryTime}}</p>
                </FormItem>
              </i-col>
            </Row>
            <Row>                
                <i-col span="24">
                    <FormItem  label="总生产量:">
                        <p>{{totalcount}} 辆</p>
                    </FormItem>
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">
                    <FormItem  label="查询月份:">
                        <DatePicker type="month" @on-change="onDateChange"></DatePicker>
                    </FormItem>
                </i-col>
            </Row>              
            <Row>  
                <i-col span="24">
                    <FormItem  label="月生产量:">
                        <p>{{produceCountMonth}}</p>
                    </FormItem>
                </i-col>
            </Row>
        </Form>
    </Modal>
</template>
<script>
    export default {
        name:'tongji',
        props:['show','companyid','companyname','expiryTime'],
        data () {
            return {
                formInline: {
                    queryDate: ''
                },
                produceCountMonth:0,
                totalcount:0,
                ruleInline: {
                    queryDate: [
                        { required: true, message: '不能为空', trigger: 'blur',pattern: /.+/ }
                    ]
                }
            }
        },
        mounted (){
            console.log("tongji dialog mounted")
        },
        watch :{
            'companyid': function (v){
                console.log('companyid='+v)
                if(v !=null){
                    this.handleQuery(null)
                }
            }
        },
        methods: {
            handleQuery (qdate) {
                var tongjiparam ={
                    id:this.companyid
                }

                if(qdate !=null){
                    tongjiparam.queryDate = qdate
                }

                this.$axios({
                    method:'post',
                    url:"/api/vehicle/tongji",
                    data:tongjiparam,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        if(qdate ==null){
                            this.totalcount = res.data.data.count
                        }
                        else{
                            this.produceCountMonth = res.data.data.count
                        }
                        this.$Message.success(res.data.msg)
                    }
                    else{
                        this.$Message.error(res.data.msg)
                    }
                    
                }.bind(this))
            },
            oncancel(){
                this.$emit('CloseTongji','')
                this.produceCountMonth = 0
            },

            onDateChange(e){
                console.log(e)
                this.formInline.queryDate = e + '-01'
                this.handleQuery(this.formInline.queryDate)
            },
            onSelect(v){
                console.log(v)
                var nowedate = this.expiryTime == null? new Date() :new Date(this.expiryTime)
                nowedate.setFullYear(nowedate.getFullYear()+v);
                this.formInline.expiryDate = nowedate
                console.log(nowedate)
                
            }
        }
    }
</script>
